import React, { useState } from "react";
import { AssessmentTemplate, TemplateWithQuestionnaires } from "../../../Models";
import { Combobox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

const displayValue = (assessment: TemplateWithQuestionnaires | null): string => {
    if (!assessment) return ""; 
    return assessment.name + (assessment.isOutOfBounds ? " ⚠️" : "");
};
export const TemplateDropdown = ({
    assessments,
    selectedAssessment,
    setSelectedAssessment,
    placeholder,
}: {
    assessments: TemplateWithQuestionnaires[];
    selectedAssessment: TemplateWithQuestionnaires | null;
    setSelectedAssessment: React.Dispatch<
        React.SetStateAction<TemplateWithQuestionnaires | null>
    >;
    placeholder: string;
}) => {
    const [query, setQuery] = useState("");

    const filteredAssessments =
        query === ""
            ? assessments
            : assessments.filter((assessment) => {
                return assessment.name.toLowerCase().includes(query.toLowerCase());
            });

    return (
        <Combobox
            as="div"
            value={selectedAssessment}
            onChange={setSelectedAssessment}
        >
            <div className="relative">
                <Combobox.Input
                    className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-transparent dark:text-gray-300  dark:ring-gray-500"
                    placeholder={placeholder}
                    onChange={(event) => setQuery(event.target.value)}
                    displayValue={displayValue}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                    <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                    />
                </Combobox.Button>
                {filteredAssessments.length > 0 && (
                    <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm dark:bg-darkbglight">
                        {filteredAssessments.map((process) => (
                            <Combobox.Option
                                key={process.id}
                                value={process}
                                className={({ active }) =>
                                    classNames(
                                        "relative cursor-default select-none py-2 pl-3 pr-9",
                                        active
                                            ? "bg-entntblue text-white"
                                            : "text-gray-900 dark:text-gray-300"
                                    )
                                }
                            >
                                {({ active, selected }) => (
                                    <>
                                        <span
                                            className={classNames(
                                                "block truncate",
                                                selected ? "font-semibold" : ""
                                            )}
                                        >
                                            {process ? <div className="flex items-center flex-row gap-2">
                                                {process.name}
                                                {process.isOutOfBounds ? <ExclamationTriangleIcon color="orange" height={18}/> : null}
                                            </div> : "Untitled"}
                                        </span>

                                        {selected && (
                                            <span
                                                className={classNames(
                                                    "absolute inset-y-0 right-0 flex items-center pr-4",
                                                    active ? "text-white" : "text-entntblue"
                                                )}
                                            >
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                        )}
                                    </>
                                )}
                            </Combobox.Option>
                        ))}
                    </Combobox.Options>
                )}
            </div>
        </Combobox>
    );
};
const AllTemplatesDropdown = ({
    allAssessments,
    selectedAssessmentTemplate,
    setSelectedAssessmentTemplate,
}: {
    allAssessments: TemplateWithQuestionnaires[];
    selectedAssessmentTemplate: TemplateWithQuestionnaires | null;
    setSelectedAssessmentTemplate: React.Dispatch<
        React.SetStateAction<TemplateWithQuestionnaires | null>
    >;
}) => {
    const handleAssessmentSelection = (
        newValue: React.SetStateAction<TemplateWithQuestionnaires | null>
    ) => {
        setSelectedAssessmentTemplate((prevState) =>
            typeof newValue === "function" ? newValue(prevState) : newValue
        );

    };

    return (
        <div className="">
            <div className="mt-2 sm:col-span-2 sm:mt-0">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300  dark:ring-gray-500 focus-within:ring-1 focus-within:ring-inset focus-within:ring-indigo-600"></div>
                <TemplateDropdown
                    assessments={allAssessments}
                    selectedAssessment={selectedAssessmentTemplate}
                    setSelectedAssessment={handleAssessmentSelection}
                    placeholder=" Select From Templates"
                />
            </div>
        </div>
    );
};

export default AllTemplatesDropdown;