import React, { useEffect, useState } from "react";
import useApiToken from "../../hooks/useApiToken";
import { useParams } from "react-router-dom";
import { GetStatusForRole } from "../../WebCalls";
import { RoleStatus } from "../../Models";

export default function SelectRoleStatus({
  commentType,
  handleCommentType,
}: {
  commentType: string;
  handleCommentType: (t: string) => void;
}) {
  const [roleStatus, setRoleStatus] = useState<RoleStatus[]>([]);
  const token = useApiToken();
  const { roleId } = useParams();
  useEffect(() => {
    if (!roleId || !token) return;

    GetStatusForRole(roleId, token).then((sr) =>
      setRoleStatus(
        sr.roleStatuses.sort((a, b) => a.order - b.order).slice(1, -2)
      )
    );
  }, [token]);

  return (
    <div className="min-w-40">
      <select
        id="grade"
        className="p-1 border rounded w-full dark:text-gray-400 dark:bg-slate-800"
        value={commentType}
        onChange={(e) => handleCommentType(e.target.value)}
      >
        <option value="">All</option>
        {roleStatus.map((rs) => (
          <option key={rs.id} value={rs.id}>
            {rs.name}
          </option>
        ))}
      </select>
    </div>
  );
}
