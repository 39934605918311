import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getTemplateLogs, restoreTemplate } from "../../WebCalls";
import { TemplateLogs } from "../../Models";
import { ArrowLongRightIcon, ArrowPathIcon, ArrowUpOnSquareStackIcon, PlusCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { dateConverter } from "../../Helpers";

function TemplateLogsComponent() {
    const [templateLogs, setTemplateLogs] = useState<TemplateLogs[]>([]);
    const navigate = useNavigate();

    const fetchTemplateLogs = async () => {
        await getTemplateLogs().then((res) => {
            setTemplateLogs(res);
        });
    }
    useEffect(() => {
        fetchTemplateLogs();
    }, []);

    const handleRestore = async (id: string) => {
        await restoreTemplate(id);
        fetchTemplateLogs();
    }
    return (
        <div className="p-6">
            <button
                className="bg-green-400 text-white px-4 py-2 rounded mb-8"
                onClick={() => navigate(-1)}
            >
                Go Back
            </button>
            <div className="relative border-l-2 border-gray-300 pl-6">
                {templateLogs.map((t: TemplateLogs, index) => (
                    <div key={index} className="mb-8 flex flex-row gap-5 items-center">
                        {t.actionResult.split(' ')[1].toLocaleLowerCase() === "created" && <PlusCircleIcon height={40} color="#3b82f6 " />}
                        {t.actionResult.split(' ')[1].toLocaleLowerCase() === "recalibrated" && <ArrowPathIcon height={40} color="#22c55e" />}
                        {t.actionResult.split(' ')[1].toLocaleLowerCase() === "restored" && <ArrowPathIcon height={40} color="#3b82f6" />}
                        {t.actionResult.split(' ')[1].toLocaleLowerCase() === "deleted" && <XCircleIcon height={40} color="#ef4444" />}
                        {t.actionResult.split(' ')[1].toLocaleLowerCase() === "updated" && <ArrowUpOnSquareStackIcon height={40} color="#eab308" />}
                        <div className="flex flex-col gap-1">
                            <div className="dark:text-gray-100  text-gray-700 ">
                                {dateConverter(t.date, true)}
                            </div>
                            <div className="text-gray-500 font-bold flex flex-row items-center gap-4">{t.templateInitial.name}
                                {t.actionResult.split(' ')[1].toLocaleLowerCase() === "recalibrated" &&
                                    <>
                                        <span> <ArrowLongRightIcon height={20} className="dark:text-white text-gray-600" /> </span>
                                        <div className="text-gray-500 font-bold">
                                            {t.templateFinal?.name}
                                        </div>
                                    </>
                                }
                            </div>
                            <div className="text-gray-500 flex flex-row gap-5">{t.actionResult.split(' ')[1]}
                                {t.actionResult.split(' ')[1].toLocaleLowerCase() === "deleted" && t.templateInitial.isDeleted &&
                                    <div className="dark:text-gray-100 cursor-pointer text-gray-700 font-bold" onClick={() => handleRestore(t.templateInitial.id)}>Undo</div>
                                }
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default TemplateLogsComponent;
