import { useEffect, useState } from "react";
import { getRoleWorkflows, postWorkflowAndStatuses } from "../../WebCalls";
import { RoleWorkflow } from "../../Models";
import { Spinner } from "../Shared/Spinner";
import NoData from "../Shared/NoData";
import WorkflowCard from "./WorkflowCard";
import WorkFlowModal from "./WokflowModal";
import { recruitmentProcess } from "../Roles/CreateRole/CreateRoleForm";
import Notification from "../Shared/Notification";


export default function Workflows() {
    const [loading, setLoading] = useState(true);
    const [workflows, setWorkflows] = useState<RoleWorkflow[]>([]);
    const [notificationText, setNotificationText] = useState<{ text: string, type: "success" | "error" | "info" }>({
        text: '',
        type: 'success'
    });
    const [toggleNofication, setToggleNofication] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    useEffect(() => {
        fetchWorkflows();
    }, []);


    const fetchWorkflows = async () => {
        setLoading(true);
        await getRoleWorkflows().then((res) => {
            setWorkflows(res);
            setLoading(false);
        })
    }
    const confirmAdd = async (workflowName: string, processess: recruitmentProcess[]) => {
        const updatedProcesses = processess.map((process, index) => ({
            ...process,
            order: index + 1,
        }));
        await postWorkflowAndStatuses(workflowName, updatedProcesses);
        setIsModalOpen(false);
        fetchWorkflows();
        setNotificationText({ text: 'Workflow has been created!', type: "success" });
        setToggleNofication(true);
    };
    const confirmReset = () => {
        // window.location.reload();
        setIsModalOpen(false);
    };

    return (
        <>
            <header className="bg-gray-100 py-8 dark:bg-darkbglight ">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex items-center justify-between flex-wrap">
                    <h1 className="mt-2 text-2xl font-bold leading-7 text-gray-800 sm:text-3xl sm:tracking-tight dark:text-gray-300">
                        Workflows
                    </h1>
                    <button
                        type="button"
                        className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-entntorange focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={() => setIsModalOpen(true)}
                    >
                        Create new workflow
                    </button>
                </div>
            </header>
            {loading ? (
                <div className="h-[50vh] flex items-center justify-center">
                    <Spinner height="h-14" width="h-14" />
                </div>
            ) : workflows.length === 0 ? (
                <NoData
                    title="No Workflows"
                    description="
      There are currently no available workflows. Click 'Create new workflow' to start adding."
                />
            ) : (
                workflows.map((workflow) => <WorkflowCard workflow={workflow} key={workflow.id} fetchWorkflows={fetchWorkflows} />)
            )}
            {isModalOpen &&
                <WorkFlowModal
                    title={""}
                    content={""}
                    size={"large"}
                    onAdd={confirmAdd}
                    onCancel={confirmReset}
                    isModalClosed={confirmReset}
                    prevProcesses={[]}
                    isEditable={false}
                    prevWorkflow={undefined}
                    isView={false}
                />

            }
            {
                toggleNofication &&
                <Notification show={toggleNofication} setShow={setToggleNofication} title={notificationText.type.charAt(0).toUpperCase() + notificationText.type.slice(1)} description={notificationText.text} type={notificationText.type} />
            }
        </>
    );
}
