import { useState, useEffect, Fragment, useMemo } from "react";
import { Disclosure } from "@headlessui/react";
import { MinusIcon, PlusIcon, TrashIcon } from "@heroicons/react/20/solid";
import { NIL } from "uuid";
import useApiToken from "../../../../hooks/useApiToken";
import ConfirmModal from "../../../Shared/ConfirmModal";
import { CategoryFull, City } from "../../../../Models";
import {
    deleteRankType,
  GetCities,
} from "../../../../WebCalls";
import {
  IFilter,
} from "../../../Roles/Sidebar/RoleSidebarCandidate";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ChevronUpDownIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { PopUp, Tags } from "./RankingSideBarBody";
import TagFilter from "./TagFilter";

const unknownFilter = { value: null, label: "Unknown", checked: false };
const initialFilters: IFilter[] = [
  {
    id: "rankingId",
    name: "Ranking Type",
    options: [{ value: NIL, label: "Overall", checked: true }],
  },
  {
    id: "rankingCategory.Title",
    name: "Ranking",
    options: [],
  },
];

const initialCompanyFilter: IFilter[] = [
  {
    id: "hotStartup",
    name: "Hot Startup",
    options: [
      { value: "Yes", label: "Yes", checked: false },
      { value: "No", label: "No", checked: false },
      unknownFilter,
    ],
  },
  {
    id: "relevance",
    name: "Relevance",
    options: [
      { value: "High", label: "High", checked: false },
      { value: "Low", label: "Low", checked: false },
      unknownFilter,
    ],
  },
  {
    id: "stockMarket",
    name: "Stock Market",
    options: [
      { value: "India", label: "Listed, In India", checked: false },
      { value: "Not India", label: "Listed, Outside India", checked: false },
      { value: "Yes", label: "Listed, Unknown", checked: false },
      { value: "No", label: "No", checked: false },
      unknownFilter,
    ],
  },
];

const initialLocationFilter: IlocationFilter = {
  id: "workLocation1City.Id",
  name: "Location",
  options: [],
  filteredOptions: [],
};


interface Props {
  applyFilters: (filter: any) => void;
  setSelectedRoleType: any;
  rankTypes: { id: string; name: string }[];
  rankCategoryLabels: CategoryFull[];
  tab: string;
  fetchAndSetRankType: () => Promise<void>;
  handleFilterQuery: (filter: string) => void;
  selectedColumns: string[];
  setSelectedColumns: (columns: string[]) => void;
  setNotificationPopUp: (popUp: PopUp) => void;
  refreshTags: () => void;
  tags: Tags[];
  setShow: (show: boolean) => void;
  getData: (obj: any) => void;
}

interface filterOption {
  value: string | number | boolean | null;
  label: string;
  checked: boolean;
  extraData?: number;
};

interface IlocationFilter{
  id: string;
  name: string;
  options: filterOption[];
  filteredOptions: filterOption[];
};

interface DeleteType {
  id: string,
  type: string
}

const RankingFilters = ({
  applyFilters,
  setSelectedRoleType,
  rankTypes,
  rankCategoryLabels,
  tab,
  fetchAndSetRankType,
  handleFilterQuery,
  selectedColumns,
  setSelectedColumns,
  refreshTags,
  tags,
  setShow,
  getData,
  setNotificationPopUp
}: Props) => {
  const [filters, setFilters] = useState([
    ...initialFilters,
    ...initialCompanyFilter,
  ]);
  const [deleteRankTypeModal, setDeleteRankTypeModal] = useState(false);
  const [deleteRankTypeId, setDeleteRankTypeId] = useState<DeleteType>();
  const [locationSearch, setLocationSearch] = useState("");
  const [cities, setCities] = useState<City[]>([]);
  const [rankType, setRankType] = useState<string>(NIL);
  const token = useApiToken();
  const navigate = useNavigate();
  const location = useLocation();

  const [locationFilter, setLocationFilter] = useState(initialLocationFilter);
  
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const type = queryParams.get("type");
    setRankType(type ?? NIL);
  }, [location.search]);

  useEffect(() => {
    setLocationSearch("");
  }, [tab]);

  useEffect(() => {
    GetCities().then((c) => {
      setCities(c);
    });    
  }, []);
 
  const filteredCities = useMemo(() => {
    const filteredCity = locationSearch
      ? cities.filter((c) =>
          c.name.toLowerCase().startsWith(locationSearch.toLowerCase())
        )
      : cities;
  
    return locationFilter.options.filter((lf) =>
      filteredCity.some((fc) => fc.id === lf.value)
    );
  }, [locationSearch, cities, locationFilter.options]);
  
  useEffect(() => {
    setLocationFilter((prev) => ({ ...prev, filteredOptions: filteredCities }));
  }, [filteredCities]);

  // @ts-ignore
  const setFilterForLocationAndTag = (type, initialFilter, setStateFilter) => {
    const updatedFilterOption = type.map((c:any) => ({
      checked: false,
      value: c.id,
      label: c.name,
      extraData: c.count ?? undefined
    }));

    const typeFilter = {
      ...initialFilter,
      options: updatedFilterOption,
    };
    setStateFilter(typeFilter);
  }

  useEffect(() => {
    setFilterForLocationAndTag(cities, initialLocationFilter, setLocationFilter);
  }, [cities, tab]);

  useEffect(() => {

    const allFilter =
      tab === "Companies"
        ? [...initialFilters, ...initialCompanyFilter]
        : [...initialFilters];
    const localFilters: IFilter[] = allFilter.map((filter) => {
      if (filter.id === "rankingId") {
        const updatedOptions = rankTypes.map((role) => {
          return {
            value: role.id,
            label: role.name,
            checked: role.id === (rankType ?? NIL),
          };
        });
        return { ...filter, options: updatedOptions };
      }
      return filter;
    });
    setFilters(localFilters);
  }, [token, tab, rankTypes]);

  useEffect(() => {
    const updateFilter = (prevFilters: IFilter[]) => {
      const uf = prevFilters.map((f) => {
        if (f.id === "rankingCategory.Title") {
          const updatedOptions: filterOption[] = rankCategoryLabels.map(
            (rc) => {
              return { value: rc.title, label: rc.title, checked: false };
            }
          );
          updatedOptions.push({
            value: null,
            label: "Unranked",
            checked: false,
          });
          return { ...f, options: updatedOptions };
        }
        return f;
      });
      return uf;
    };
    setFilters((prev) => {
      const updatedFilter = updateFilter(prev);
      return updatedFilter;
    });
  }, [rankCategoryLabels]);

  useEffect(() => {
    let query: string[] = [];
    filters.slice(1).forEach((f) => {
      let sameFilter: string[] = [];
      f.options.forEach((fo) => {
        if (fo.value !== "")
          if (fo.checked) {
            const stockCombinedOperation =
              f.id === "stockMarket" &&
              (fo.value === "Not India" || fo.value === "India");
            if (stockCombinedOperation) {
              switch (fo.value) {
                case "Not India":
                  sameFilter.push(
                    `${f.id} ne null and ${f.id} !contains "India" and ${f.id} ne "Yes"`
                  );
                  break;
                case "India":
                  sameFilter.push(`${f.id} contains "India"`);
              }
            } else {
              const isUnranked =
                f.id === "rankingCategory.Title" && fo.value === null;
              sameFilter.push(
                `${isUnranked ? "rankingCategory" : f.id} eq ${
                  typeof fo.value === "string"
                    ? `"${fo.value}"`
                    : fo.value === true
                      ? null
                      : fo.value
                }`
              );
            }
          }
      });

      if (sameFilter.length > 0) {
        if (f.id === "stockMarket") {
          let temp = sameFilter[0];
          sameFilter[0] = sameFilter[sameFilter.length - 1];
          sameFilter[sameFilter.length - 1] = temp;
        }
        let joinedSameFilter = "(" + sameFilter.join(" or ") + ")";
        if (f.id === "stockMarket") {
          const indiaListed = 'stockMarket contains "India"';
          const negateIndiaListed = 'and stockMarket !contains "India"';
          const listedUnknown = 'stockMarket eq "Yes"';
          const negateListedUnknown = 'and stockMarket ne "Yes"';
          const unknown = "stockMarket eq null";
          const negateUnknown = "stockMarket ne null and";
          if (
            joinedSameFilter.includes(unknown) &&
            joinedSameFilter.includes(negateUnknown)
          )
            joinedSameFilter = joinedSameFilter.replace(negateUnknown, "");
          if (
            joinedSameFilter.includes(negateIndiaListed) &&
            joinedSameFilter.includes(indiaListed)
          )
            joinedSameFilter = joinedSameFilter.replace(negateIndiaListed, "");
          if (
            joinedSameFilter.includes(negateListedUnknown) &&
            joinedSameFilter.includes(listedUnknown)
          )
            joinedSameFilter = joinedSameFilter.replace(
              negateListedUnknown,
              ""
            );
        }
        query.push(joinedSameFilter);
      }
    });

    handleFilterQuery(query.join(" and "));
  }, [filters, locationFilter]);

  const handleFilterChange = (
    section: IFilter,
    checkedOption: filterOption
  ) => {
    const updatedFilters = filters.map((filter) => {
      if (section.id === filter.id) {
        const updatedOptions = filter.options.map((option) => {
          if (
            option.value === checkedOption.value &&
            option.label === checkedOption.label
          ) {
            return { ...option, checked: !option.checked };
          }

          if (section.id === "rankingId") return { ...option, checked: false };
          return option;
        });

        return { ...filter, options: updatedOptions };
      }

      return filter;
    });
    if (section.id === "rankingId") {
      const theRole = rankTypes?.find(
        (role: { id: string; name: string }) => role.id === checkedOption.value
      );
      navigate(`${window.location.pathname}?type=${theRole?.id}`);
      setSelectedRoleType(theRole);
    }

    setFilters(updatedFilters);
  };

  const handleDeleteRankType = async () => {
    if (!token || !deleteRankTypeId) return;
    
    await deleteRankType(deleteRankTypeId.id, token);
    fetchAndSetRankType();
    setDeleteRankTypeModal(false);
  };

  const handleLocationFilterChange = async (changedOption: filterOption) => {

    const updatedOptions = locationFilter.options.map((option) => {
      if (option.value === changedOption.value) {
        return { ...option, checked: !option.checked };
      }
      return option;
    });
    const updatedFilteredOptions = locationFilter.filteredOptions.map((fo) => {
      if (fo.value === changedOption.value) {
        return { ...fo, checked: !fo.checked };
      }
      return fo;
    });

    setLocationFilter((prev) => ({
      ...prev,
      options: updatedOptions,
      filteredOptions: updatedFilteredOptions,
    }));
    applyFilters([{
      FilterType: "location",
      Locations: updatedOptions.filter(o => o.checked).map(o => o.label)
    }]);
    
  };

  return (
    <>
      <div className="px-4">
        
        {filters.map((section, ind) => {
          return (
            <Disclosure
              as="div"
              key={section.id}
              className="border-b border-gray-200 py-4 dark:border-gray-500"
            >
              {({ open, close }) => (
                <div className="relative">
                  <h3 className="-my-4 flow-root">
                    <Disclosure.Button className="flex w-full items-center justify-between py-3 text-sm text-gray-400 hover:text-gray-500 dark:text-gray-300 dark:hover:text-gray-200">
                      <span className="font-medium text-gray-900 dark:text-gray-300">
                        {section.name}
                      </span>
                      <span className="ml-6 flex items-center">
                        {open ? (
                          <MinusIcon className="h-5 w-5" aria-hidden="true" />
                        ) : (
                          <PlusIcon className="h-5 w-5" aria-hidden="true" />
                        )}
                      </span>
                    </Disclosure.Button>
                  </h3>

                  <Disclosure.Panel
                    className={`py-3 px-4 absolute right-4 bg-white rounded-md z-50 border border-gray-200 shadow-sm dark:bg-darkbglight dark:border-gray-500"
                    } h-fit`}
                  >
                    <div className={`space-y-2`}>
                      {section.options.map((option, optionIdx) => {
                        return (
                          <div key={optionIdx} className="flex items-center">
                            <input
                              id={`filter-${section.id}-${optionIdx}`}
                              name={`${section.id}[]`}
                              type={ind === 0 ? "radio" : "checkbox"}
                              onChange={() => {
                                handleFilterChange(section, option);
                              }}
                              checked={option.checked}
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />

                            <label
                              htmlFor={`filter-${section.id}-${optionIdx}`}
                              className="ml-3 text-sm text-gray-600 flex justify-between w-full pr-2 dark:text-gray-400"
                            >
                              <span>{option.label}</span>
                            </label>
                            {ind === 0 && option.value !== NIL && (
                              <button>
                                <TrashIcon
                                  className="h-4 text-gray-500 hover:text-gray-600"
                                  onClick={() => {
                                    setDeleteRankTypeId({ id: option.value as string, type: "rank"});
                                    setDeleteRankTypeModal(true);
                                  }}
                                />
                              </button>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </Disclosure.Panel>
                  {open && (
                    <div
                      className="fixed inset-0 z-20"
                      onClick={() => {
                        close();
                      }}
                    ></div>
                  )}
                </div>
              )}
            </Disclosure>
          );
        })}
        <Disclosure
        as="div"
        className="border-b border-gray-200 py-4 dark:border-gray-500"
      >
        {({ open, close }) => (
          <div>
            <div className="relative">
              <h3 className="-my-4 flow-root">
                <Disclosure.Button className="flex w-full items-center justify-between py-3 text-sm text-gray-400 hover:text-gray-500">
                  <span className="font-medium text-gray-900 dark:text-gray-300">
                    {locationFilter.name}
                  </span>
                  <span className="ml-3 flex items-center">
                    <div className="relative w-full cursor-default overflow-hidden rounded-md bg-white text-left ring-1 ring-gray-300 sm:text-sm dark:ring-gray-500">
                      <input
                        type="text"
                        value={locationSearch}
                        placeholder="Search Location"
                        className="w-full border-none py-1 pl-3 pr-10 text-sm text-gray-900 focus:ring-0 placeholder:text-gray-400 dark:text-gray-300 dark:bg-darkbg"
                        onChange={(event) =>
                          setLocationSearch(event.target.value)
                        }

                      />
                      <div className="absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                          className="h-5 w-5 text-gray-400 dark:text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </span>
                </Disclosure.Button>
              </h3>

              <Disclosure.Panel
                className={`dark:bg-darkbglight dark:border-gray-500 shadow-sm pl-1 py-1 h-fit absolute bg-white rounded-md z-50 border border-gray-200 bottom-[2.6rem] -right-[0.20rem]`}
              >
                <div className="max-h-[10.5rem] min-w-[11.8rem] pl-2 py-1 overflow-auto thin-scroll bg-transparent z-10">
                  <div className="space-y-2">
                    {locationFilter.filteredOptions.map((option, optionIdx) => {
                      return (
                        <div key={optionIdx} className="flex items-center">
                          <input
                            id={`locaiton-filter-${optionIdx}`}
                            type={"checkbox"}
                            onChange={() => {
                              handleLocationFilterChange(option);
                            }}
                            checked={option.checked}
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          />
  
                          <label
                            id={`locaiton-filter-${optionIdx}`}
                            className="ml-3 text-sm text-gray-600 flex justify-between w-full pr-2 dark:text-gray-400"
                          >
                            <span>{option.label}</span>
                          </label>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </Disclosure.Panel>
            </div>
            {open && (
              <div
                className="fixed inset-0 z-20"
                onClick={() => {
                  close();
                }}
              ></div>
            )}
            <div className="z-10 text-xs flex flex-wrap box-border mt-2">
              {locationFilter.options.map((o, index) =>
                o.checked ? (
                  <span
                    key={o.label + index}
                    className="flex items-center bg-blue-100 text-entntblue py-[0.10rem] px-2 mr-2 mt-2 rounded-full relative "
                  >
                    {o.label}
                    <XMarkIcon
                      onClick={() => {
                        handleLocationFilterChange(o);
                      }}
                      className="h-4 w-4 cursor-pointer text-gray-500 ml-1 hover:text-gray-600"
                    />
                  </span>
                ) : (
                  <Fragment key={index}></Fragment>
                )
              )}
            </div>
          </div>
        )}
      </Disclosure>
      <TagFilter
         applyFilters = {applyFilters}
         tab={tab}
         fetchAndSetRankType={fetchAndSetRankType}
         selectedColumns={selectedColumns}
         setSelectedColumns={setSelectedColumns}
         refreshTags={refreshTags}
         tags={tags}
         getData={(obj) => getData(obj)}
         setShow={setShow}
         setNotificationPopUp={setNotificationPopUp}
      />
      </div>
      <ConfirmModal
        open={deleteRankTypeModal}
        setOpen={setDeleteRankTypeModal}
        onConfirm={handleDeleteRankType}
        type="danger"
        title="Delete Rank Type"
        description="Are you sure? Deleting this will also remove all related rankings."
      />
    </>
  );
};

export default RankingFilters;
