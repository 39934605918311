import React, { useEffect, useState } from 'react';
import { ChevronDoubleRightIcon, ChevronDoubleLeftIcon, MagnifyingGlassIcon, FunnelIcon, ArrowPathIcon, PlusIcon, MinusIcon, BarsArrowDownIcon } from "@heroicons/react/20/solid";
import { Disclosure } from '@headlessui/react';
import { Role, Skill } from '../../../Models';
import Slider from 'rc-slider';
import { History } from "@mui/icons-material";

import 'rc-slider/assets/index.css';
import { useNavigate } from 'react-router-dom';

interface Options {
    id: string;
    name: string;
}

interface FilterSection {
    name: string;
    options: Options[];
}

interface TemplateLeftSideBarProps {
    onToggle: (isOpen: boolean) => void;
    handleTemplateSearch: (event: React.ChangeEvent<HTMLInputElement> | string) => void;
    skills: Skill[];
    getTemplateFilters: Function,
    roles: Role[],
    getFilters: Function,
    fetchTemplatesAndRoles:Function
    // currentPage: number
}
interface skillAvg {
    id: string,
    avgMin: number
    avgMax: number
}
const TemplateLeftSideBar: React.FC<TemplateLeftSideBarProps> = ({ onToggle, handleTemplateSearch, skills, getTemplateFilters, roles, getFilters,fetchTemplatesAndRoles }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [currentAccuracy, setCurrentAccuracy] = useState({ min: 0, max: 100 });
    const [selectedFilters, setSelectedFilters] = useState<Record<string, skillAvg[]>>({
        'Skills': [{ id: 'all', avgMin: 0, avgMax: 100 }],
        'Roles': [{ id: 'all', avgMin: 0, avgMax: 100 }],
        'Difficulty': [{ id: 'all', avgMin: 0, avgMax: 100 }],
        'Results': [{ id: 'all', avgMin: 0, avgMax: 100 }],
        'Variation': [{ id: 'all', avgMin: 0, avgMax: 100 }],
        'Num_of_Questions': [{ id: 'all', avgMin: 0, avgMax: 100 }],
        'Time': [{ id: 'all', avgMin: 0, avgMax: 100 }],
    });
    const [selectedPrevFilters, setSelectedPrevFilters] = useState<Record<string, skillAvg[]>>({
        'Skills': [{ id: 'all', avgMin: 0, avgMax: 100 }],
        'Roles': [{ id: 'all', avgMin: 0, avgMax: 100 }],
        'Difficulty': [{ id: 'all', avgMin: 0, avgMax: 100 }],
        'Results': [{ id: 'all', avgMin: 0, avgMax: 100 }],
        'Variation': [{ id: 'all', avgMin: 0, avgMax: 100 }],
        'Num_of_Questions': [{ id: 'all', avgMin: 0, avgMax: 100 }],
        'Time': [{ id: 'all', avgMin: 0, avgMax: 100 }],
    });
    const [prevAccuracy, setPrevAccuracy] = useState({ min: 0, max: 100 })
    const [openSkillId, setOpenSkillId] = useState<string>('');
    const [openSectionName, setSectionName] = useState<string>('');
    const navigate = useNavigate();
    useEffect(() => {
        getFilters(selectedFilters);
    }, [selectedFilters,selectedPrevFilters])
    const filterSections: FilterSection[] = [
        {
            name: 'Skills',
            options: [
                { id: 'all', name: 'All' },
                ...skills.map(skill => ({ id: skill.id, name: skill.name }))
            ],
        },
        {
            name: 'Roles',
            options: [
                { id: 'all', name: 'All' },
                ...roles.map(skill => ({ id: skill.id, name: skill.title }))
            ],
        },
        {
            name: 'Difficulty',
            options: [
                { id: 'all', name: 'All' },
                ...skills.map(skill => ({ id: skill.id, name: skill.name }))
            ],
        },
        {
            name: 'Results',
            options: [
                ...skills.map(skill => ({ id: skill.id, name: skill.name }))
            ],
        },
        {
            name: 'Variation',
            options: [
                { id: 'all', name: 'All' },
                ...skills.map(skill => ({ id: skill.id, name: skill.name }))
            ],
        },
        {
            name: 'Num_of_Questions',
            options: [
                { id: 'all', name: 'All' },
                ...skills.map(skill => ({ id: skill.id, name: skill.name }))
            ],
        },
        {
            name: 'Time',
            options: [
                { id: 'all', name: 'All' },
            ],
        },

    ];


    const handleResetFilters = () => {
        window.location.reload();
    }

    const handleToggle = () => {
        setIsSidebarOpen(!isSidebarOpen);
        onToggle(!isSidebarOpen);
    };

    const handleFilterChange = (sectionName: string, id: string) => {
        setSelectedFilters((prevFilters) => {
            let newSelected: skillAvg[];
            if (id === 'all') {
                newSelected = [{ id: 'all', avgMin: 0, avgMax: 100 }];
            } else {
                newSelected = prevFilters[sectionName].filter((item) => item.id !== 'all');

                const skillIndex = newSelected.findIndex((item) => item.id === id);

                if (skillIndex !== -1) {
                    newSelected = newSelected.filter((item) => item.id !== id);
                } else {
                    newSelected.push({ id, avgMin: 0, avgMax: 100 });
                }

                if (newSelected.length === 0) {
                    newSelected = [{ id: 'all', avgMin: 0, avgMax: 100 }];
                }
            }
            return {
                ...prevFilters,
                [sectionName]: newSelected,
            };
        });
        setSelectedPrevFilters((prevFilters) => {
            let newSelected: skillAvg[];
            if (id === 'all') {
                newSelected = [{ id: 'all', avgMin: 0, avgMax: 100 }];
            } else {
                newSelected = prevFilters[sectionName].filter((item) => item.id !== 'all');

                const skillIndex = newSelected.findIndex((item) => item.id === id);

                if (skillIndex !== -1) {
                    newSelected = newSelected.filter((item) => item.id !== id);
                } else {
                    newSelected.push({ id, avgMin: 0, avgMax: 100 });
                }

                if (newSelected.length === 0) {
                    newSelected = [{ id: 'all', avgMin: 0, avgMax: 100 }];
                }
            }
            return {
                ...prevFilters,
                [sectionName]: newSelected,
            };
        });
    };


    useEffect(() => {
        getTemplateFilters(selectedFilters, { min: 100 - currentAccuracy.max, max: 100 - currentAccuracy.min }, searchQuery);
    }, [selectedFilters, currentAccuracy, searchQuery]);

    const handleToggleDialog = (id: string, sectionName: string) => {
        if (openSkillId === id) {
            setOpenSkillId('');
            setSectionName('')
        } else {
            setOpenSkillId(id);
            setSectionName(sectionName)
        }
    };

    return (
        <aside
            className={`select-none fixed z-10 top-[4.1rem] block h-[calc(99vh-4rem)] left-0 shadow-md border dark:border-gray-500  border-gray-200  transition-transform transform duration-200 w-[19rem] ${isSidebarOpen ? "translate-x-0" : "-translate-x-[100%]"
                }`}
        >
            <div className="mx-auto bg-gray-100 dark:bg-darkbglight">
                <div className="min-w-0 px-4 pt-2 pb-1">
                    <div className="text-gray-900 dark:text-gray-300 flex items-center space-x-2 justify-center">
                        <h3 className="text-center text-lg font-semibold leading-7 sm:text-base sm:tracking-tight">
                            Filters
                        </h3>
                    </div>
                </div>
            </div>
            <div
                className={`absolute top-10 ${isSidebarOpen ? "-right-2 shadow-md" : "-right-4 shadow-xl"
                    } h-16 border border-gray-300 rounded-md flex items-center justify-center cursor-pointer bg-white dark:bg-darkbglight dark:border-gray-500 dark:text-gray-300`}
                onClick={handleToggle}
            >
                {isSidebarOpen ? (
                    <ChevronDoubleLeftIcon width={16} />
                ) : (
                    <ChevronDoubleRightIcon width={16} />
                )}
            </div>
            <div className="pb-12 h-full bg-white thin-scroll overflow-y-auto dark:bg-darkbg w-full flex flex-col items-center mt-2">
                <div className="relative flex focus-within:z-10 mt-2 items-center justify-end w-[90%] mb-4">
                    {/* <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
                        <MagnifyingGlassIcon
                            className="h-5 w-5 text-gray-400 dark:text-gray-300"
                            aria-hidden="true"
                        />
                    </div> */}
                    {/* <input
                        type="text"
                        name="search"
                        id="search"
                        className="block w-10/12 border-0 pl-9 text-gray-900 ring-1 ring-inset ring-gray-300 dark:ring-gray-500 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-entntorange sm:text-sm bg-transparent dark:text-gray-300 dark:placeholder:text-gray-400 rounded-md"
                        placeholder="Quick Search"
                        onChange={(e) => {
                            setSearchQuery(e.target.value);
                            // handleTemplateSearch(e);
                        }}
                        value={searchQuery}
                    /> */}
                    <div
                        className="text-gray-400 hover:text-gray-500 cursor-pointer relative dark:hover:text-gray-300"
                        onClick={handleResetFilters}
                    >
                        <FunnelIcon width={24} />
                        <ArrowPathIcon width={14} className="absolute bottom-0 -right-1" />
                    </div>
                </div>
                <div className="flex w-full items-center justify-between py-2 px-3 text-gray-400 " onClick={() => navigate('/sets/logs')}>
                    <span className="font-medium text-sm text-gray-900 dark:text-gray-300 cursor-pointer" >Actions Log</span>
                    <History
                        className={"w-4 cursor-pointer"}

                    />
                </div>
                {filterSections.map((section, index: number) => (
                    <Disclosure as="div" key={index} className="w-[90%] border-b border-gray-200 dark:border-gray-500 ">
                        {({ open }) => (
                            <>
                                <Disclosure.Button className="flex w-full justify-between py-3 text-sm text-gray-800 hover:text-gray-500 dark:text-gray-300 font-semibold">
                                    <span >{section.name.split("_").join(" ")}</span>
                                    {open ? (
                                        <MinusIcon className="h-5 w-5 text-gray-200" />
                                    ) : (
                                        <PlusIcon className="h-5 w-5 text-gray-200" />
                                    )}
                                </Disclosure.Button>
                                <Disclosure.Panel className="pb-2 text-sm text-gray-500 dark:text-gray-400">
                                    {section.options.map((option) => (
                                        <div key={option.id} className="flex items-center mb-2 relative ">
                                            {section.name === "Time" ? <>
                                                <div key={index} className="flex items-center m-2 p-4 w-[100%]">
                                                    <Slider
                                                        range
                                                        max={100}
                                                        min={0}
                                                        value={[
                                                            selectedPrevFilters[section.name]?.find(item => item.id === option.id)?.avgMin || 0,
                                                            selectedPrevFilters[section.name]?.find(item => item.id === option.id)?.avgMax || 100
                                                        ]}
                                                        onChange={(value: any) => {
                                                            const [min, max] = value as number[];
                                                            setSelectedPrevFilters((prevFilters) => {
                                                                const newSelected = [...prevFilters[section.name]];

                                                                const skillIndex = newSelected.findIndex(item => item.id === option.id);

                                                                if (skillIndex !== -1) {
                                                                    newSelected[skillIndex] = {
                                                                        id: option.id,
                                                                        avgMin: min,
                                                                        avgMax: max,
                                                                    };
                                                                }
                                                                return {
                                                                    ...prevFilters,
                                                                    [section.name]: newSelected,
                                                                };
                                                            });
                                                        }}
                                                        onChangeComplete={(value: any) => {
                                                            const [min, max] = value as number[];
                                                            setSelectedFilters((prevFilters) => {
                                                                const newSelected = [...prevFilters[section.name]];

                                                                const skillIndex = newSelected.findIndex(item => item.id === option.id);

                                                                if (skillIndex !== -1) {
                                                                    newSelected[skillIndex] = {
                                                                        id: option.id,
                                                                        avgMin: min,
                                                                        avgMax: max,
                                                                    };
                                                                }
                                                                return {
                                                                    ...prevFilters,
                                                                    [section.name]: newSelected,
                                                                };
                                                            });
                                                        }}
                                                        marks={{
                                                            0: 0,
                                                            [100]: 100,
                                                        }}
                                                        styles={{
                                                            track: { background: "#0D42EC" },
                                                            handle: { borderColor: "#0D42EC" },
                                                        }}
                                                    />
                                                </div>

                                            </> :
                                                <>
                                                    <div className='flex flex-row items-center justify-between w-[100%]' onMouseLeave={() => handleToggleDialog('', '')}>
                                                        <div>
                                                            <input
                                                                id={`${section.name}-${option.id}`}
                                                                name={`${section.name}[]`}
                                                                type="checkbox"
                                                                checked={selectedFilters[section.name].some(filter => filter.id === option.id)}
                                                                onChange={() => handleFilterChange(section.name, option.id)}
                                                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                            />
                                                            <label
                                                                htmlFor={`${section.name}-${option.id}`}
                                                                className="ml-3 text-sm text-gray-600 dark:text-gray-400"
                                                            >
                                                                {option.name}
                                                            </label>
                                                        </div>
                                                        {((section.name === 'Difficulty' || section.name === 'Results' || section.name === 'Variation' || section.name === "Num_of_Questions") && selectedFilters[section.name].some(filter => filter.id === option.id)) && <BarsArrowDownIcon
                                                            className={`w-4 h-4  cursor-pointer ${openSkillId === option.id && section.name === openSectionName ? "text-white" : "text-gray-400"}`}
                                                            onClick={() => handleToggleDialog(option.id, section.name)}
                                                        />}
                                                        {openSkillId === option.id && section.name === openSectionName &&
                                                            <div
                                                                className="absolute left-20 top-4 w-48 dark:bg-darkbglight bg-white rounded-lg shadow-lg z-50  p-6"
                                                                style={{ overflow: 'visible' }}
                                                                onMouseLeave={() => handleToggleDialog('', '')}
                                                            >
                                                                <Slider
                                                                    range
                                                                    max={100}
                                                                    min={0}
                                                                    value={[
                                                                        selectedPrevFilters[section.name]?.find(item => item.id === option.id)?.avgMin || 0,
                                                                        selectedPrevFilters[section.name]?.find(item => item.id === option.id)?.avgMax || 100
                                                                    ]}
                                                                    onChange={(value: any) => {
                                                                        const [min, max] = value as number[];
                                                                        setSelectedPrevFilters((prevFilters) => {
                                                                            const newSelected = [...prevFilters[section.name]];
                                                                            const skillIndex = newSelected.findIndex(item => item.id === option.id);
                                                                            if (skillIndex !== -1) {
                                                                                newSelected[skillIndex] = {
                                                                                    id: option.id,
                                                                                    avgMin: min,
                                                                                    avgMax: max,
                                                                                };
                                                                            }
                                                                            return {
                                                                                ...prevFilters,
                                                                                [section.name]: newSelected,
                                                                            };
                                                                        });
                                                                    }}
                                                                    onChangeComplete={(value: any) => {
                                                                        const [min, max] = value as number[];
                                                                        setSelectedFilters((prevFilters) => {
                                                                            const newSelected = [...prevFilters[section.name]];
                                                                            const skillIndex = newSelected.findIndex(item => item.id === option.id);
                                                                            if (skillIndex !== -1) {
                                                                                newSelected[skillIndex] = {
                                                                                    id: option.id,
                                                                                    avgMin: min,
                                                                                    avgMax: max,
                                                                                };
                                                                            }
                                                                            return {
                                                                                ...prevFilters,
                                                                                [section.name]: newSelected,
                                                                            };
                                                                        });
                                                                    }}
                                                                    marks={{
                                                                        0: 0,
                                                                        [100]: 100,
                                                                    }}

                                                                    styles={{
                                                                        track: { background: "#0D42EC" },
                                                                        handle: { borderColor: "#0D42EC" },
                                                                    }}
                                                                />

                                                            </div>

                                                        }
                                                    </div>
                                                    {/* {

                                                        (section.name === 'Difficulty' || section.name === 'Results' || section.name === 'Variation' || section.name === "Num_of_Questions") && (
                                                            <div key={index} className="flex items-center m-2 p-4 ">
                                                                <Slider
                                                                    range
                                                                    max={100}
                                                                    min={0}
                                                                    value={[prevAccuracy.min, prevAccuracy.max]}
                                                                    onChange={(value: any) => {
                                                                        const [min, max] = value as number[];
                                                                        setPrevAccuracy({ min, max })
                                                                    }}
                                                                    onChangeComplete={(value: any) => {
                                                                        const [min, max] = value as number[];
                                                                        setCurrentAccuracy({ min, max });
                                                                        setPrevAccuracy({ min, max })

                                                                    }}
                                                                    marks={{
                                                                        0: 0,
                                                                        [100]: 100,
                                                                    }}
                                                                    styles={{
                                                                        track: { background: "#0D42EC" },
                                                                        handle: { borderColor: "#0D42EC" },
                                                                    }}
                                                                />
                                                            </div>
                                                        )
                                                    } */}

                                                </>}
                                        </div>
                                    ))
                                    }
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>
                ))}

            </div>
        </aside>
    );
};

export default TemplateLeftSideBar;
