import React, { useEffect, useState } from 'react';
import { ChevronDoubleRightIcon, ChevronDoubleLeftIcon, MagnifyingGlassIcon, FunnelIcon, ArrowPathIcon, PlusIcon, MinusIcon } from "@heroicons/react/20/solid";
import { Disclosure } from '@headlessui/react';
import { AssessmentQuestionnaire, Question, Skill } from '../../../Models';
import Slider from 'rc-slider';
import { getAllAssessments } from '../../../WebCalls';

interface Options {
    id: string;
    name: string;
}

interface FilterSection {
    name: string;
    options: Options[];
}

interface QuestionLeftSideBarProps {
    onToggle: (isOpen: boolean) => void;
    handleQuestionSearch: (event: React.ChangeEvent<HTMLInputElement> | string) => void;
    questionGroups: Question[];
    skills: Skill[];
    getQuestionFilters: Function,
    currentPage: number
}

const QuestionLeftSideBar: React.FC<QuestionLeftSideBarProps> = ({ onToggle, handleQuestionSearch, questionGroups, skills, getQuestionFilters, currentPage }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [currentAccuracy, setCurrentAccuracy] = useState({ min: 0, max: 100 });
    const [selectedFilters, setSelectedFilters] = useState<Record<string, string[]>>({
        'Question_Groups': ['all'],
        'Skills': ['all'],
        'Assessments': ['all'],
        'Type': ['all'],
    });
    const [prevAccuracy, setPrevAccuracy] = useState({ min: 0, max: 100 })
    const [assessments, setAssessments] = useState<AssessmentQuestionnaire[]>([]);

    const fetchAssessments = async () => {
        await getAllAssessments().then((res) => setAssessments(res));
    }

    useEffect(() => {
        fetchAssessments();
    }, []);

    const handleToggle = () => {
        setIsSidebarOpen(!isSidebarOpen);
        onToggle(!isSidebarOpen);
    };

    const handleResetFilters = () => {
        handleQuestionSearch(searchQuery);
        setSearchQuery('');
        setSelectedFilters({
            'Question_Groups': ['all'],
            'Skills': ['all'],
            'Assessments': ['all'],
            'Type': ['all'],
        });
        setCurrentAccuracy({ min: 0, max: 100 });
        setPrevAccuracy({ min: 0, max: 100 });
    }


    const filterSections: FilterSection[] = [
        {
            name: 'Question_Groups',
            options: [
                { id: 'all', name: 'All' },
                ...questionGroups.map(group => ({ id: group.id, name: group.title }))
            ],
        },
        {
            name: 'Skills',
            options: [
                { id: 'all', name: 'All' },
                ...skills.map(skill => ({ id: skill.id, name: skill.name }))
            ],
        },
        {
            name: 'Assessments',
            options: [
                { id: 'all', name: 'All' },
                ...assessments.map(assessment => ({ id: assessment.id, name: assessment.title }))
            ],
        },
        {
            name: 'Difficulty',
            options: [],
        },
        {
            name: 'Type',
            options: [
                { id: 'all', name: 'All' },
                { id: 'single', name: 'Single' },
                { id: 'multiple', name: 'Multiple' },
                { id: 'text', name: 'Text' },
                { id: 'video', name: 'Video' },
                { id: 'audio', name: 'Audio' },
            ],
        },

    ];

    const handleFilterChange = (sectionName: string, id: string) => {
        setSelectedFilters((prevFilters) => {
            let newSelected = [...prevFilters[sectionName]];
            if (id === 'all') {
                newSelected = ['all'];
            } else {
                newSelected = newSelected.filter(item => item !== 'all');
                if (newSelected.includes(id)) {
                    newSelected = newSelected.filter(item => item !== id);
                } else {
                    newSelected.push(id);
                }

                if (newSelected.length === 0) {
                    newSelected = ['all'];
                }
            }

            return {
                ...prevFilters,
                [sectionName]: newSelected,
            };
        });
    };

    useEffect(() => {
        getQuestionFilters(selectedFilters, { min:  currentAccuracy.min, max:  currentAccuracy.max }, searchQuery);
    }, [selectedFilters, currentAccuracy, searchQuery, currentPage])
    return (
        <aside
            className={`select-none fixed z-10 top-[4.1rem] block h-[calc(99vh-4rem)] left-0 shadow-md border dark:border-gray-500 transition-transform transform duration-200 w-[19rem] ${isSidebarOpen ? "translate-x-0" : "-translate-x-[100%]"
                }`}
        >
            <div className="mx-auto bg-gray-100 dark:bg-darkbglight">
                <div className="min-w-0 px-4 pt-2 pb-1">
                    <div className="text-gray-900 dark:text-gray-300 flex items-center space-x-2 justify-center">
                        <h3 className="text-center text-lg font-semibold leading-7 sm:text-base sm:tracking-tight">
                            Question Filters
                        </h3>
                    </div>
                </div>
            </div>
            <div
                className={`absolute top-10 ${isSidebarOpen ? "-right-2 shadow-md" : "-right-4 shadow-xl"
                    } h-16 border border-gray-300 rounded-md flex items-center justify-center cursor-pointer bg-white dark:bg-darkbglight dark:border-gray-500 dark:text-gray-300`}
                onClick={handleToggle}
            >
                {isSidebarOpen ? (
                    <ChevronDoubleLeftIcon width={16} />
                ) : (
                    <ChevronDoubleRightIcon width={16} />
                )}
            </div>
            <div className="pb-12 h-full bg-white thin-scroll overflow-y-auto dark:bg-darkbg w-full flex flex-col items-center mt-2">
                <div className="relative flex focus-within:z-10 mt-2 items-center justify-between w-[90%] mb-4">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
                        <MagnifyingGlassIcon
                            className="h-5 w-5 text-gray-400 dark:text-gray-300"
                            aria-hidden="true"
                        />
                    </div>
                    <input
                        type="text"
                        name="search"
                        id="search"
                        className="block w-10/12 border-0 pl-9 text-gray-900 ring-1 ring-inset ring-gray-300 dark:ring-gray-500 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-entntorange sm:text-sm bg-transparent dark:text-gray-300 dark:placeholder:text-gray-400 rounded-md"
                        placeholder="Quick Search"
                        onChange={(e) => {
                            setSearchQuery(e.target.value);
                            // handleQuestionSearch(e);
                        }}
                        value={searchQuery}
                    />
                    <div
                        className="text-gray-400 hover:text-gray-500 cursor-pointer relative dark:hover:text-gray-300"
                        onClick={handleResetFilters}
                    >
                        <FunnelIcon width={24} />
                        <ArrowPathIcon width={14} className="absolute bottom-0 -right-1" />
                    </div>
                </div>
                {filterSections.map((section, index: number) => (
                    <Disclosure as="div" key={index} className="w-[90%] border-b border-gray-200 dark:border-gray-500">
                        {({ open }) => (
                            <>
                                <Disclosure.Button className="flex w-full justify-between py-3 text-sm text-gray-400 hover:text-gray-500 dark:text-gray-300 font-semibold">
                                    <span className='text-gray-900 dark:text-gray-400'>{section.name.split("_").join(" ")}</span>
                                    {open ? (
                                        <MinusIcon className="h-5 w-5 text-gray-200" />
                                    ) : (
                                        <PlusIcon className="h-5 w-5 text-gray-200" />
                                    )}
                                </Disclosure.Button>
                                <Disclosure.Panel className="pb-2 text-sm text-gray-500 dark:text-gray-400">
                                    {section.name === 'Difficulty' ? (
                                        <div key={index} className="flex items-center m-2 p-4">
                                            <Slider
                                                range
                                                max={100}
                                                min={0}
                                                value={[prevAccuracy.min, prevAccuracy.max]}
                                                onChange={(value: any) => {
                                                    const [min, max] = value as number[];
                                                    setPrevAccuracy({ min, max })
                                                }}
                                                onChangeComplete={(value: any) => {
                                                    const [min, max] = value as number[];
                                                    setCurrentAccuracy({ min, max });
                                                    setPrevAccuracy({ min, max })

                                                }}
                                                marks={{
                                                    0: 0,
                                                    [100]: 100,
                                                }}
                                                styles={{
                                                    track: { background: "#0D42EC" },
                                                    handle: { borderColor: "#0D42EC" },
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        section.options.map((option) => (
                                            <div key={option.id} className="flex items-center mb-2">
                                                <input
                                                    id={`${section.name}-${option.id}`}
                                                    name={`${section.name}[]`}
                                                    type="checkbox"
                                                    checked={selectedFilters[section.name].includes(option.id)}
                                                    onChange={() => handleFilterChange(section.name, option.id)}
                                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                />
                                                <label
                                                    htmlFor={`${section.name}-${option.id}`}
                                                    className="ml-3 text-sm text-gray-900 dark:text-gray-400"
                                                >
                                                    {option.name}
                                                </label>
                                            </div>
                                        ))
                                    )}
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>
                ))}

            </div>
        </aside>
    );
};

export default QuestionLeftSideBar;
