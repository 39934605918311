import React, { useCallback, useEffect, useState } from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";
import { QuarantineCompany, QuarantineUniversity } from "../../../Models";
import {
  fetchCompanyLocations,
  fetchConfirmedUniversities,
} from "../../../WebCalls";

type SearchDropDownType = {
  label?: string;
  placeHolder: string;
  dropDownValues: (QuarantineCompany | QuarantineUniversity)[];
  mandatoryField?: boolean;
  dropDownOnly?: boolean;
  hideOthers?: boolean;
  disable?: boolean;
  currentTab?: string;
  setReject: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
  quarantineId:string;
  setRejectSearchQuery:React.Dispatch<React.SetStateAction<string>>;
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function SearchDropDown({
  label,
  placeHolder,
  dropDownValues,
  mandatoryField,
  dropDownOnly,
  hideOthers,
  disable = false,
  currentTab,
  quarantineId,
  setReject,
  setRejectSearchQuery
}: SearchDropDownType) {
  const [hoveredOption, setHoveredOption] = useState<any>(null);
  const [query, setQuery] = useState<string>("");
  const [currentValue, setCurrentValue] = useState<any>();

  return (
    <Combobox
      as="div"
      value={currentValue}
      onChange={(newValue) => {
        setCurrentValue(newValue);
        setReject((prev) => ({
          ...prev,
          [quarantineId]: newValue.id,
        }));
      }}
      className="w-full"
      disabled={disable || false}
    >
      <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
        {label}
        {mandatoryField && <span className="text-red-600 pl-1">*</span>}
      </Combobox.Label>
      <div className="relative mt-2">
        <Combobox.Button className="w-full relative flex items-center rounded-md focus:outline-none">
          <input
            id="searchInput"
            className="w-full !text-sm font-medium rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            onChange={(e) => {
              setCurrentValue(null); 
              setQuery(e.target.value);
              setRejectSearchQuery(e.target.value);
            }}
            value={currentValue ? currentValue.name : query}
            placeholder={placeHolder}
            disabled={disable}
          />
          <ChevronUpDownIcon
            className="absolute right-2 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>
        {hoveredOption && (
          <div className="absolute text-sm text-gray-600 top-0 left-full ml-4 w-64 p-2 border bg-white shadow-lg z-10">
            {currentTab === "Companies" ? (
              <p>City: {hoveredOption.cityName}</p>
            ) : (
              <p>City: {hoveredOption.city?.name}</p>
            )}
            <p>URL: {hoveredOption?.url}</p>
          </div>
        )}

        {dropDownValues.length > 0 && query.length >= 1 && (
          <Combobox.Options className="absolute z-10 overflow-y-auto mt-1 max-h-60 w-full rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {dropDownValues.map((value) => (
              <Combobox.Option
                key={value.id}
                value={value}
                onMouseEnter={() => setHoveredOption(value)}
                onMouseLeave={() => setHoveredOption(null)}
                onClick={() => setHoveredOption(null)}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-indigo-600 text-white" : "text-gray-900"
                  )
                }
                title={value.name}
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={classNames(
                        "block truncate",
                        selected ? "font-semibold" : ""
                      )}
                    >
                      {value.name}
                    </span>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-indigo-600"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}
