import { getColorShade } from "../../../Helpers";
import UpdateRanking from "./UpdateRanking";
import { CategoryFull } from "../../../Models";
import {
  XMarkIcon,
  MapPinIcon,
  ArrowTopRightOnSquareIcon,
} from "@heroicons/react/20/solid";
import { deallocateTag } from "../../../WebCalls";
import { CustomTooltip } from "../../Shared/CustomToolTip";

interface Props {
  setSelectedRow: (entry: string | null) => void;
  selectedRow : string | null; 
  columnData: any;
  tab: string;
  checked: boolean;
  handleSelectColumn: (columnId: string) => void;
  handleEditRankLabel: (
    category: CategoryFull,
    selectedColumn?: string
  ) => void;
  rankCategoryLabels: CategoryFull[];
  getData: () => Promise<any>;
}

const RankTableColumn = ({
  setSelectedRow,
  selectedRow,
  columnData,
  tab,
  checked,
  handleSelectColumn,
  handleEditRankLabel,
  rankCategoryLabels,
  getData
}: Props) => {

  const handleRemoveTag = async (tagId:string, entityId:string) => {
    try{
      if(tab === "Companies"){
        await deallocateTag(entityId, tagId, true);
      }
      else{
        await deallocateTag(entityId, tagId, false);
      }
      getData();
    }
    catch(ex){
      console.log(ex);
    }
  }
  return (
    <>
      <tr
        className="bg-white border-b dark:bg-gray-900 border-2 cursor-pointer	border-gray-100 dark:border-gray-700"
        key={columnData.id}
        onClick={() => selectedRow === columnData.id ? setSelectedRow(null) : setSelectedRow(columnData.id)}
      >
        <td className="sm:table-cell text-center px-2 w-10">
          <input
            type="checkbox"
            className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 dark:border-gray-100"
            checked={checked}
            onChange={() => handleSelectColumn(columnData.id)}
            onClick = {(event) => event.stopPropagation()}
          />
        </td>

        <td className="px-4 py-1 text-gray-900 dark:text-white font-semibold">
            {
              columnData.name
            }
        </td>
        {tab !== "Companies" && (
          <td className="px-6 py-1 text-gray-900  dark:text-white">
            {columnData?.state?.name}
          </td>
        )}
        {tab !== "Companies" && (
          <td className="px-6 py-1 text-gray-900  dark:text-white">
            {
              columnData.url && 
              <CustomTooltip content="Visit website">
                <button
                onClick={(event) => {
                  event.stopPropagation(); 
                  window.open(columnData.url, '_blank');
                }}
                className="border-2 border-gray-300 p-2 rounded"
                >
                  <ArrowTopRightOnSquareIcon className="h-3 w-3"/>
                </button>
              </CustomTooltip>
            }
          </td>
        )}
        <td className="px-6 py-1 flex gap-2 text-gray-600">
          <div
            style={{
              background: columnData.rankingCategory
                ? tab === "Universities"
                  ? `linear-gradient(to right, ${
                      (
                        getColorShade(
                          columnData.rankingCategory.rankNumber,
                          true
                        ) as { grad: string; postGrad: string }
                      ).grad
                    } 50%, ${
                      (
                        getColorShade(
                          columnData.rankingCategory.rankNumber,
                          true
                        ) as { grad: string; postGrad: string }
                      ).postGrad
                    } 50%)`
                  : (getColorShade(
                      columnData.rankingCategory.rankNumber,
                      false
                    ) as string)
                : "gray",
            }}
            className={`flex items-center space-x-2 max-w-max px-2 py-1 rounded-lg font-semibold ${
              columnData.rankingCategory === null
                ? "text-white"
                : columnData.rankingCategory.rankNumber >= 3
                  ? "text-gray-800"
                  : "text-gray-100"
            }
            }`}
          >
            <UpdateRanking
              selected={columnData.rankingCategory?.rankCategoryId}
              handleEditRankLabel={handleEditRankLabel}
              selectedColumn={columnData.id}
              rankCategoryLabels={rankCategoryLabels}
            />

            <span className="pr-4">
              {columnData.rankingCategory === null
                ? "unranked"
                : columnData.rankingCategory.title}
            </span>
          </div>
        </td>

        {
          tab === "Companies" && (
            <td className="px-2 py-1 text-gray-900 text-center dark:text-white">
              <div className="flex gap-2 text-center">
                 {
                  columnData.url && 
                  <CustomTooltip content="Visit website">
                    <button
                    onClick={(event) => {
                      event.stopPropagation(); 
                      window.open(columnData.url, '_blank');
                    }}
                    className="border-2 border-gray-300 p-2 rounded"
                    >
                      <ArrowTopRightOnSquareIcon className="h-3 w-3"/>
                    </button>
                  </CustomTooltip>
                 }
                 {
                  columnData.linkedIn && 
                  <CustomTooltip content="View on linkedin">
                    <button
                    onClick={(event) => {
                      event.stopPropagation(); 
                      window.open(columnData.linkedIn, '_blank');
                    }}
                    className="border-2 border-gray-400 p-2 rounded"
                    >
                      <svg fill="currentColor" viewBox="0 0 24 24" className="h-3 w-3 text-entntblue hover:scale-105 hover:text-gray-400" aria-hidden="true">
                        <path
                          fillRule="evenodd"
                          d="M6.94 5a2 2 0 1 1-4-.002 2 2 0 0 1 4 .002zM7 8.48H3V21h4V8.48zm6.32 0H9.34V21h3.94v-6.57c0-3.66 4.77-4 4.77 0V21H22v-7.93c0-6.17-7.06-5.94-8.72-2.91l.04-1.68z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </CustomTooltip>
                 }
              </div>
            </td>
          )
        }

        <td className="px-2 py-1 text-gray-900  dark:text-white">
          <div className="flex gap-3">
            {
              columnData.tags.map((tag:any, index: number) => 
              <span 
              className="rounded-xl border-2 bg-blue-100 text-entntblue px-2 py-1 flex gap-3 items-center dark:bg-entntblue dark:text-blue-100 font-medium"
              key={tag.Id + index}
              >
                {tag.name} 
                <XMarkIcon
                onClick={(event) => {
                  event.stopPropagation();
                  handleRemoveTag(tag.id, columnData.id);
                }}
                className="h-4 w-4 cursor-pointer text-gray-500 ml-1 hover:scale-110 hover:text-red-300"
                /> 
              </span>)
            }
          </div>
        </td>
        
      </tr>
      {
        (tab === "Companies" && selectedRow === columnData.id) && 
        <tr>
          <td colSpan={6} className="py-6 text-xs ">
            <div className="px-16 flex gap-4 ">
              <h4 className="font-medium">Location: </h4>
              <p className="text-black dark:text-gray-200 flex gap-1 items-center"> <MapPinIcon className="h-3 w-3"/> {columnData?.location}</p>
            </div>
            <div className="px-16 flex gap-4">
              <h4 className="font-medium">Revenue: </h4>
              <p className="text-black dark:text-gray-200">{columnData?.revenue}</p>
            </div>
            <div className="px-16 flex gap-4">
              <h4 className="font-medium">Stock market: </h4>
              <p className="text-black dark:text-gray-200">{columnData?.stockMarket}</p>
            </div>
          </td>
        </tr>
      }
    </>
  );
};

export default RankTableColumn;
