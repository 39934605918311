import { useEffect, useState } from "react";
import useApiToken from "../../hooks/useApiToken";
import { RadioGroup } from "@headlessui/react";
import {
  GetCandidateScore,
  GetCandidateStatus,
  GetStatusForRole,
  GradeCandidate,
} from "../../WebCalls";
import { CandidateStatus, RoleStatus } from "../../Models";
import { StarIcon } from "@heroicons/react/20/solid";

const getGrade = (grade: number | undefined) => {
  if (!grade) return "-";
  return grade >= 80 && grade < 90 ? "P" : grade >= 90 ? "E" : "F";
};
type props = {
  candidateId?: string;
  roleId?: string;
};

export default function ScoreGrades({ candidateId, roleId }: props) {
  const [grades, setGrades] = useState<string[]>([]);
  const [roleStatuses, setRoleStatuses] = useState<RoleStatus[]>([]);
  const [candidateStatuses, setCandidateStatuses] = useState<CandidateStatus[]>(
    []
  );
  const [scores, setScores] = useState<number[]>([]);
  const token = useApiToken();

  useEffect(() => {
    if (!roleId || !token) return;
    GetStatusForRole(roleId, token).then((sr) =>
      setRoleStatuses(sr.roleStatuses.sort((a, b) => a.order - b.order))
    );
    if (!candidateId) return;
    GetCandidateScore(candidateId, token).then(
      (s: { score: number; roleId: string }[]) => {
        setScores(
          s
            .filter((sco) => sco.roleId === roleId && sco.score !== null)
            .map((scor) => scor.score)
        );
      }
    );
    GetCandidateStatus(candidateId, token).then((cs) => {
      setCandidateStatuses(cs.filter((c) => c.roleId === roleId));
    });
  }, [token, roleId]);

  useEffect(() => {
    const candidateGrades = roleStatuses
      .slice(1, -2)
      .map((rs) =>
        getGrade(candidateStatuses.find((c) => c.roleStatusId === rs.id)?.grade)
      );
    setGrades(candidateGrades);
  }, [roleStatuses, candidateStatuses]);

  const handleGradeChange = (
    index: number,
    newGrade: string,
    rs: RoleStatus
  ) => {
    const candidateStatus = candidateStatuses.find(
      (c) => c.roleStatusId === rs.id
    );
    if (!token || !candidateStatus) return;
    const updateCandidateStatus: CandidateStatus = {
      candidateId: candidateStatus.candidateId,
      roleId: candidateStatus.roleId,
      roleStatusId: candidateStatus.roleStatusId,
      date: new Date(candidateStatus.date).toISOString(),
      email: candidateStatus.email,
      slots: candidateStatus.slots,
      grade: newGrade === "E" ? 100 : newGrade === "P" ? 89 : 79,
    };
    GradeCandidate(updateCandidateStatus, token).then((d) => {
      const newGrades = [...grades];
      newGrades[index] = newGrade;
      setGrades(newGrades);
    });
  };

  const candidateReachedStatus = (rs: RoleStatus) => {
    return candidateStatuses.some((c) => c.roleStatusId === rs.id);
  };
  return (
    <div className="mt-4">
      <div
        className={
          "py-3 px-4 bg-white rounded-md z-[11] border border-gray-200  h-fit dark:bg-darkbglight dark:border-gray-500"
        }
      >
        <div className="border-b-2 py-2">
          {scores.length !== 0 ? (
            scores.map((s) => (
              <div className="flex space-x-2" key={s}>
                <span className="font-medium text-gray-700">Assessment </span>
                <span className="flex">
                  <StarIcon className="h-5 text-yellow-500" />
                  {s}
                </span>
              </div>
            ))
          ) : (
            <span className="font-medium text-gray-700">No Scores </span>
          )}
        </div>
        <div className="flex justify-between w-full py-2 pt-4">
          <div className={`space-y-3`}>
            {grades &&
              roleStatuses.slice(1, -2).map((rs, index) => (
                <RadioGroup
                  key={index}
                  value={grades[index]}
                  onChange={(newGrade) =>
                    handleGradeChange(index, newGrade, rs)
                  }
                  className={`flex relative ${
                    !candidateReachedStatus(rs) ? "hidden" : "block"
                  }`}
                >
                  <RadioGroup.Label className="flex items-center font-medium text-gray-700 min-w-[9rem] dark:text-gray-300">
                    {rs.name} :{" "}
                  </RadioGroup.Label>
                  <div className="flex space-x-1">
                    <RadioGroup.Option value="E">
                      {({ checked }) => (
                        <span
                          className={`${
                            checked ? "bg-green-600 text-white" : ""
                          }  rounded-full py-1 px-2 text-xs font-medium cursor-pointer`}
                        >
                          Excellent
                        </span>
                      )}
                    </RadioGroup.Option>
                    <RadioGroup.Option value="P">
                      {({ checked }) => (
                        <span
                          className={`${
                            checked ? "bg-blue-600 text-white" : ""
                          } rounded-full py-1 px-2 text-xs font-medium cursor-pointer`}
                        >
                          Pass
                        </span>
                      )}
                    </RadioGroup.Option>
                    <RadioGroup.Option value="F">
                      {({ checked }) => (
                        <span
                          className={`${
                            checked ? "bg-red-600 text-white" : ""
                          } rounded-full py-1 px-2 text-xs font-medium cursor-pointer`}
                        >
                          Fail
                        </span>
                      )}
                    </RadioGroup.Option>
                  </div>
                </RadioGroup>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
