import React, { useEffect, useRef, useState } from 'react';
import { Answer, QuestionGroup, Questions } from '../../Models';
import { ChevronDownIcon, ChevronUpIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/20/solid';
import { deleteQuestions, getQuestionById } from '../../WebCalls';
import ConfirmModal from '../Shared/ConfirmModal';
import Notification from '../Shared/Notification';
import parse from 'html-react-parser'
import Loading from '../Shared/Loading';


interface QuestionDetails {
  answeredCorrectly: null | number,
  answeredIncorrectly: null | number,
  unanswered: null | number,
  questionGroup: QuestionGroup | null,
  answers: Answer[] | null;

}

const QuestionCard = ({ question, selected,
  handleSelect, getSelectedEditQuestion, onQuestionExpand, questionExpandId, index }: {
    question: Questions;
    selected: boolean;
    handleSelect: () => void;
    getSelectedEditQuestion: Function,
    onQuestionExpand: Function,
    questionExpandId: string,
    index: number,

  }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState(false);
  const { id, text, type, tags, time, accuracy } = question;
  const [loading, setLoading] = useState<boolean>(false);


  const handleEdit = () => {
    // navigate(`/questions/edit/${id}`)
    getSelectedEditQuestion(id);

  }

  const [questionDetails, setQuestionDetails] = useState<QuestionDetails>({
    answeredCorrectly: null,
    answeredIncorrectly: null,
    unanswered: null,
    questionGroup: null,
    answers: null
  })


  const getQuestionDetails = async () => {
    setLoading(true);
    await getQuestionById(id).then((res) => {
      setQuestionDetails({
        answeredCorrectly: res.answeredCorrectly,
        answeredIncorrectly: res.answeredIncorrectly,
        unanswered: res.unanswered,
        questionGroup: res.questionGroup,
        answers: res.answers
      })
      setLoading(false);
    })
  }

  const handleDelete = () => {
    setIsModalOpen(true);
  }

  const confirmDelete = async () => {
    setIsModalOpen(false);
    await deleteQuestions([id]);
    setShowNotification(true);
    setTimeout(() => {
      window.location.reload();
    }, 2000)

  };

  const [modalTop, setModalTop] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (modalRef.current) {
      const viewportHeight = window.innerHeight;
      const modalBottomPosition =
        modalRef.current.getBoundingClientRect().bottom + 220;
      if (modalBottomPosition > viewportHeight) {
        setModalTop(true);
      } else {
        setModalTop(false);
      }
    }
  }, [questionExpandId]);


  return (
    <>
      <tr className="cursor-pointer hover:bg-gray-50 dark:hover:bg-[#1c2229] border-t dark:border-gray-500 rounded-sm">
        <td className="px-4 py-4">
          <input
            type="checkbox"
            checked={selected}
            onChange={handleSelect}
            className="form-checkbox z-[11] bg-transparent left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 "
          />
        </td>
        <td className="px-6 py-4 font-medium text-gray-900 dark:text-white relative" onMouseLeave={(e) => { onQuestionExpand('') }}>
          <div
            className="text-left dark:text-gray-300 relative"
            ref={modalRef}
            onMouseEnter={() => {
              setTimeout(() => {
                if (questionExpandId !== question.id) {

                  onQuestionExpand(question.id);
                  getQuestionDetails();
                }
              }, 500)
            }}

          >
            {text.length > 200 ? (
              expanded ? (
                <div className="flex flex-col items-center">
                  <div>{parse(text)}</div>
                  <ChevronUpIcon
                    height={17}
                    color="gray"
                    onClick={() => setExpanded(false)}
                    className="cursor-pointer mt-2 dark:hover:text-white"
                  />
                </div>
              ) : (
                <div className="flex flex-col items-center" >
                  <div>{`${text.substring(0, 200)}...`}</div>
                  <ChevronDownIcon
                    height={17}
                    color="gray"
                    onClick={() => setExpanded(true)}
                    className="cursor-pointer mt-2 dark:hover:text-white"
                  />
                </div>
              )
            ) : (
              parse(text)
            )}
            {questionExpandId === question.id && (
              <div
                className={`absolute 
                 left-6
                  ${modalTop ? "bottom-4" : "top-4"}  mt-2 dark:bg-darkbglight dark:text-white bg-white text-black text-xs w-80 ${questionDetails?.answers && questionDetails?.answers.length > 0 ? "h-60" : "h-52"} p-3 rounded-md shadow-lg z-30 absolute  `}
                onMouseEnter={() => {
                  if (questionExpandId !== question.id) {
                    onQuestionExpand(question.id);
                  }
                }
                }
                onMouseLeave={(e) => { onQuestionExpand('') }}
              >
                {!loading ? <>
                  <div className="flex flex-row gap-6">
                    <div className={`h-24 w-24 self-center justify-self-center flex items-center justify-center bg-gray-100 text-black rounded-full border-4 ${type === 'audio' ||
                      type === 'text' ||
                      type === 'video' || accuracy === -1 ? "border-gray-400" :
                      ( accuracy) <= 40 ? "border-green-600" : (accuracy) <= 75 ? "border-orange-300" : "border-red-500"} `}>
                      <p className="text-lg">
                        {(type === 'audio' ||
                          type === 'text' ||
                          type === 'video' ||
                          accuracy === -1) ? (
                          <small> N/A </small>
                        ) : (
                          accuracy + '%'
                        )}
                      </p>
                    </div>
                    <div className="flex flex-col items-start gap-2 text-[10px]">
                      <p className="dark:bg-gray-600 bg-gray-200 px-1 py-[2px] rounded-md text-[10px] max-w-40 dark:text-white">
                        <strong>Question Group:</strong> {questionDetails.questionGroup?.title}
                      </p>
                      <p className="dark:bg-gray-600 bg-gray-200 px-1 py-[2px] rounded-md text-[10px] max-w-40 dark:text-white">
                        <strong>Answered Correctly:</strong> {questionDetails.answeredCorrectly} times
                      </p>
                      <p className="dark:bg-gray-600 bg-gray-200 px-1 py-[2px] rounded-md text-[10px] max-w-40 dark:text-white">
                        <strong>Answered Incorrectly:</strong> {questionDetails.answeredIncorrectly} times
                      </p>
                      <p className="dark:bg-gray-600 bg-gray-200 px-1 py-[2px] rounded-md text-[10px] max-w-40 dark:text-white">
                        <strong>Unanswered:</strong> {questionDetails.unanswered} times
                      </p>

                    </div>
                  </div>
                  {questionDetails?.answers && questionDetails?.answers.length > 0 && <div className="mt-4 pt-2 text-[10px] h-[20%] overflow-hidden  flex-row flex flex-wrap" >
                    {!(type === 'audio' ||
                      type === 'text' ||
                      type === 'video')
                      && <> Options: </>}
                    {questionDetails?.answers &&
                      questionDetails.answers.map((o) => {
                        return (
                          <span>
                            {o.isCorrect && o.isCorrect ? (
                              <span
                                className="  underline underline-offset-1 truncate rounded-lg decoration-gray-500 m-1 dark:bg-green-600 bg-green-200  p-1"
                                title={o.text}
                              >
                                {o.text.slice(0, 10)}
                              </span>
                            ) : (
                              <span
                                className="underline underline-offset-1 truncate rounded-lg decoration-gray-500 m-1 dark:bg-gray-600 bg-gray-200  p-1"
                                title={o.text}
                              >
                                {o.text.slice(0, 10)}
                              </span>
                            )}
                          </span>
                        );
                      })}
                  </div>}
                  <div className="mt-2 text-[10px]">
                    Tags :{' '}
                    {question.tags.split(',').map((t) => {
                      return (
                        <span className="underline underline-offset-1 decoration-gray-500 m-1">
                          {t}
                        </span>
                      );
                    })}
                  </div>
                  <div className="mt-2 text-[10px] flex flex-row items-center gap-3">
                    <span className="dark:bg-gray-600 bg-gray-200 px-1 py-[2px] rounded-md text-[10px] max-w-40 dark:text-white">
                      Question Type :{' '}
                      {question.type.toLocaleUpperCase()}
                    </span>
                    <p className="dark:bg-gray-600 bg-gray-200 px-1 py-[2px] rounded-md text-[10px] max-w-40 dark:text-white">
                      <strong>Time:</strong> {time} sec
                    </p>
                  </div>

                </> : <Loading />}
              </div>
            )}
          </div>
        </td>
        <td className="px-6 py-4 hidden sm:table-cell">
          <div
            className="flex flex-row flex-wrap overflow-x-auto p-2 gap-1 justify-center"
            style={{ maxHeight: '100px' }}
          >
            {question.skills?.map((s, index) => (
              <div key={index} className="mr-2">
                <span
                  className={`dark:bg-gray-600 bg-gray-200 px-2 py-[2px] rounded-md text-xs truncate max-w-40 dark:text-white`}
                >
                  {s.name}
                </span>
              </div>
            ))}
          </div>
        </td>
        <td className="px-6 py-4 hidden sm:table-cell text-center">
          {(type === 'audio' ||
            type === 'text' ||
            type === 'video' ||
            accuracy === -1) ? (
            <small> N/A </small>
          ) : (
            accuracy + '%'
          )}
        </td>
        <td className="px-6 py-4 text-center">
          <div className="flex justify-center items-center">
            <PencilSquareIcon
              className="font-medium text-gray-400 cursor-pointer dark:text-blue-500 hover:underline"
              onClick={handleEdit}
              height={20}
            />
            <span className="mx-2">|</span>
            <TrashIcon
              className="font-medium text-red-600 cursor-pointer dark:text-red-500 hover:underline"
              onClick={handleDelete}
              height={20}
            />
          </div>
        </td>
      </tr>

      <ConfirmModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        onConfirm={confirmDelete}
        type="danger"
        title="Delete Question"
        description="Are you sure you want to delete this question? This action cannot be undone."
      />
      <Notification
        show={showNotification}
        setShow={setShowNotification}
        title="Success"
        description="The question was successfully deleted"
        type="success"
      />
    </>

  );
};


export default QuestionCard;
