import React, { useCallback, useEffect, useState } from 'react'
import { Question, Questions, Skill } from '../../Models';
import { CalculateAccuracy, deleteQuestions, fetchAllSkills, fetchQuestionGroups, getAllFilterQuestions, } from '../../WebCalls';
import QuestionCard from './QuestionCard';
import { useNavigate } from 'react-router-dom';
import PaginationComponent from '../Shared/PaginationComponent';
import Loading from '../Shared/Loading';
import { debounce } from '../../helpers/constants';
import { ArrowDownIcon, ArrowsUpDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid';
import NoData from '../Shared/NoData';
import QuestionLeftSideBar from './Sidebars/QuestionLeftSideBar';
import QuestionRightSideBar from './Sidebars/QuestionRightSideBar';
import QuestionAddEditModal from './Modals/QuestionAddEditModal';
import Notification from '../Shared/Notification';
import { ArrowPathIcon } from '@heroicons/react/24/outline';

export type CurrentFilters = {
    selectedFilters: Record<string, string[]>;
    currentAccuracy: { min: number; max: number };
    searchQueryString: string;
};

function QuestionsComponent() {
    const [questions, setQuestions] = useState<Questions[]>([]);
    const [questionGroups, setQuestionGroups] = useState<Question[]>([])
    const [currentPage, setCurrentPage] = useState<number>(1);
    const itemsPerPage = 15;
    const [totalCount, setTotalCount] = useState<number>()
    const [loading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate();
    const [skills, setSkills] = useState<Skill[]>([]);
    const [selectedQuestionId, setSelectedQuestionId] = useState<string>('');
    const [selectedQuestions, setSelectedQuestions] = useState<string[]>([]);
    const [selectAll, setSelectAll] = useState(false);
    const [isLeftSidebarOpen, setIsLeftSidebarOpen] = useState(true);
    const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(false);
    const [isQuestionModalOpen, setIsQuestionModalOpen] = useState(false);
    const [currentFilters, setCurrentFilters] = useState<CurrentFilters>({
        selectedFilters: {
            Question_Groups: ["all"],
            Skills: ["all"],
            Assessments: ["all"],
            Type: ["all"],
        },
        currentAccuracy: { min: 0, max: 100 },
        searchQueryString: '',
    });


    const [questionExpandId, setQuestionExpandId] = useState<string>('')
    interface SortOption {
        field: "text" | "accuracy";
        direction: "default" | "asc" | "desc";
    }

    const [sortOptions, setSortOptions] = useState<SortOption[]>([]);
    useEffect(() => {
        getQuestionFilters(currentFilters.selectedFilters, currentFilters.currentAccuracy, currentFilters.searchQueryString);
    }, [sortOptions])

    useEffect(() => {
        if (selectAll) {
            setSelectedQuestions(questions.map((q) => (q.id)));
        } else {
            setSelectedQuestions([]);
        }
    }, [selectAll, questions]);


    const getAllSkills = useCallback(() => {
        fetchAllSkills().then((res) => {
            setSkills(res);

        });
    }, [])
    const getAllQuestionGroups = useCallback(() => {
        fetchQuestionGroups().then((res) => {
            setQuestionGroups(res);
        });
    }, [])

    useEffect(() => {
        getAllSkills();
        getAllQuestionGroups();
    }, [getAllSkills, getAllQuestionGroups]);

    const handleQuestionSearch = debounce(async (e: any) => {
        const val = e.target?.value.trim() ?? '';
        setLoading(true);
        if (val === '') {
            setCurrentPage(1);
        }
    }, 500)

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };



    const handleToggleLeftSidebar = (isOpen: any) => {
        setIsLeftSidebarOpen(isOpen);
    };
    const handleToggleRightSidebar = (isOpen: any) => {
        setIsRightSidebarOpen(isOpen);
    };
    const isModalClosed = () => {
        setIsQuestionModalOpen(false);
        setSelectedQuestionId('')

    }
    const toggleModal = () => {
        setIsQuestionModalOpen(!isQuestionModalOpen);
    }
    const handleSelectAll = () => {
        setSelectAll(!selectAll);
    };

    const getSelectedEditQuestion = (questionId: string) => {
        toggleModal();
        setSelectedQuestionId(questionId)
    }


    const handleSelect = (questionId: string) => {
        if (selectedQuestions.includes(questionId)) {
            setSelectedQuestions(selectedQuestions.filter((id) => id !== questionId));
        } else {
            setSelectedQuestions([...selectedQuestions, questionId]);
        }
    };

    const getQuestionFilters = async (selectedFilters: Record<string, string[]>, currentAccuracy: { min: number, max: number }, searchQueryString: string) => {
        setLoading(true);
        await debouncedGetQuestionFilters(selectedFilters, currentAccuracy, searchQueryString, sortOptions);
        setCurrentFilters({
            selectedFilters,
            currentAccuracy,
            searchQueryString,
        })
    }

    const debouncedGetQuestionFilters = useCallback(
        debounce(
            async (
                selectedFilters: Record<string, string[]>,
                currentAccuracy: { min: number; max: number },
                searchQueryString: string,
                currentSortOptions: SortOption[]
            ) => {
                const questionGroups = selectedFilters?.Question_Groups.filter((q) => q !== "all")
                const skills = selectedFilters?.Skills.filter((q) => q !== "all")
                const assessments = selectedFilters?.Assessments.filter((q) => q !== "all")
                const type = selectedFilters?.Type.filter((q) => q !== "all");
                await getAllFilterQuestions(currentAccuracy, questionGroups, skills, assessments, type, currentPage, searchQueryString, currentSortOptions).then((res) => {
                    if (res.totalCount > 0 && res?.questions.length === 0) {
                        const totalPages = Math.ceil(res.totalCount / itemsPerPage);
                        if (currentPage > totalPages) {
                            setCurrentPage(totalPages);
                        }
                    }
                    else {
                        setQuestions(res?.questions)
                    }
                    setTotalCount(res.totalCount)
                    setLoading(false);
                })
            },
            500
        ),
        [currentPage]
    );
    const handleQuestionsDelete = async () => {
        await deleteQuestions(selectedQuestions);
    }
    const onQuestionExpand = (id: string) => {
        setQuestionExpandId(id);
    }


    const handleSortChange = (column: "text" | "accuracy") => {
        setSortOptions((prevOptions) => {
            const existingOption = prevOptions.find((option) => option.field === column);
            const otherColumn = column === "text" ? "accuracy" : "text";

            let newOptions: SortOption[] = [];

            if (existingOption) {
                const newDirection: SortOption['direction'] =
                    existingOption.direction === "default" ? "asc" :
                        existingOption.direction === "asc" ? "desc" : "default";

                if (newDirection !== "default") {
                    newOptions.push({ field: column, direction: newDirection });
                }
            } else {
                newOptions.push({ field: column, direction: "asc" });
             }
            if (newOptions.length > 0) {
                newOptions.push({ field: otherColumn, direction: "default" });
            }

            return newOptions;
        });
    };

    const handleRefresh = async () => {
        await CalculateAccuracy();
        setTimeout(() => {
            window.location.reload();
        }, 500);
    }
    return (
        <div className="bg-white dark:bg-darkbg dark:text-gray-400 w-full text-sm flex flex-col">
            <div className={`transition-all duration-200 ${isLeftSidebarOpen ? "pl-[19rem]" : "pl-0"} ${isRightSidebarOpen ? "pr-[19rem]" : "pl-0"}`}>

                <div className={`${!isLeftSidebarOpen
                    ? " mx-8"
                    : "relative sm:transform "
                    } transition-all duration-200 
            ease-out`}>
                    {(!loading && questions && questions?.length > 0) ?
                        <div>
                            <div className="relative overflow-x-auto shadow-md sm:rounded-lg min-h-[87vh]">
                                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                    <thead className="bg-white dark:bg-darkbglight z-20 before:border-b before:border-gray-300 before:absolute before:content-[''] before:w-full before:h-full before:-z-10 dark:before:border-gray-500 dark:before:border-t">
                                        <tr className=''>
                                            <th scope="col" className="px-4 py-3 ">
                                                <input
                                                    type="checkbox"
                                                    checked={selectAll}
                                                    onChange={handleSelectAll}
                                                    className="form-checkbox  left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                />
                                            </th>
                                            <th scope="col" className="px-6 py-3 w-[65%]">
                                                <div className="flex items-center justify-start">
                                                    Question
                                                    {sortOptions.find((opt) => opt.field === 'text')?.direction === 'asc' ? (
                                                        <ArrowUpIcon className="w-4 h-4 ml-1 text-gray-500 cursor-pointer" onClick={() => handleSortChange('text')} />
                                                    ) : sortOptions.find((opt) => opt.field === 'text')?.direction === 'desc' ? (
                                                        <ArrowDownIcon className="w-4 h-4 ml-1 text-gray-500 cursor-pointer" onClick={() => handleSortChange('text')} />
                                                    ) : (
                                                        <ArrowsUpDownIcon className="w-4 h-4 ml-1 text-gray-500 cursor-pointer" onClick={() => handleSortChange('text')} />
                                                    )}

                                                    <ArrowPathIcon className='h-4 ml-2 cursor-pointer' onClick={handleRefresh} />
                                                </div>
                                            </th>
                                            <th scope="col" className="px-6 py-3 w-[20%] text-center">
                                                Skills
                                            </th>
                                            <th scope="col" className="px-6 py-3 w-[7%]">
                                                <div className="flex items-center justify-center">
                                                    Difficulty
                                                    {sortOptions.find((opt) => opt.field === 'accuracy')?.direction === 'asc' ? (
                                                        <ArrowUpIcon className="w-4 h-4 ml-1 text-gray-500 cursor-pointer" onClick={() => handleSortChange('accuracy')} />
                                                    ) : sortOptions.find((opt) => opt.field === 'accuracy')?.direction === 'desc' ? (
                                                        <ArrowDownIcon className="w-4 h-4 ml-1 text-gray-500 cursor-pointer" onClick={() => handleSortChange('accuracy')} />
                                                    ) : (
                                                        <ArrowsUpDownIcon className="w-4 h-4 ml-1 text-gray-500 cursor-pointer" onClick={() => handleSortChange('accuracy')} />
                                                    )}
                                                </div>
                                            </th>


                                            <th scope="col" className="px-6 py-3 w-[8%] text-center">
                                                Actions
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className='relative'>
                                        {questions.map((q, index) => (
                                            <QuestionCard
                                                key={q.id}
                                                question={q}
                                                selected={selectedQuestions.includes((q.id))}
                                                handleSelect={() => handleSelect((q.id))}
                                                getSelectedEditQuestion={getSelectedEditQuestion}
                                                questionExpandId={questionExpandId}
                                                onQuestionExpand={onQuestionExpand}
                                                index={index}

                                            />
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                        </div >
                        : !loading && questions?.length === 0 ? <div className='' >
                            <NoData title='No Questions Found' description="There are currently no available Questions. Click 'Add Question' to start adding Questions for the Assessment." />
                        </div>
                            :
                            <>
                                <Loading />
                            </>
                    }
                </div >
            </div>
            <QuestionLeftSideBar skills={skills} questionGroups={questionGroups} onToggle={handleToggleLeftSidebar} handleQuestionSearch={handleQuestionSearch} getQuestionFilters={getQuestionFilters} currentPage={currentPage} />
            <QuestionRightSideBar onToggle={handleToggleRightSidebar} toggleModal={toggleModal} selectedQuestions={selectedQuestions} handleQuestionsDelete={handleQuestionsDelete} getQuestionFilters={getQuestionFilters} currentFilters={currentFilters} skills={skills} />

            {(totalCount !== undefined && totalCount > 15 && questions && questions.length > 0) && (
                <div className={` ${isLeftSidebarOpen ? "pl-[19rem]" : "pl-0"} ${isRightSidebarOpen ? "pr-[19rem]" : "pl-0"}`}>
                    <PaginationComponent
                        currentPage={currentPage}
                        totalPages={Math.ceil((totalCount || 0) / itemsPerPage)}
                        handlePageChange={handlePageChange}
                    />
                </div>
            )}
            {isQuestionModalOpen && <QuestionAddEditModal
                onCancel={() => { toggleModal(); setSelectedQuestionId('') }}
                size="small"
                isModalClosed={isModalClosed}
                selectedQuestionId={selectedQuestionId}
            />}

        </div>

    )
}

export default QuestionsComponent;
