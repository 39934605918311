import React from "react";

type ToggleSwitchTypes = {
  id: string;
  label?: string;
  checked: boolean;
  setState: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function ToggleSwitch({
  id,
  label,
  checked,
  setState,
}: ToggleSwitchTypes) {
  const handleChange = () => {
    setState((prevState) => !prevState);
  };
  return (
    <div className="flex gap-4">
      <h3 className="text-sm font-medium dark:text-gray-200">{label}</h3>
      <label
        className="relative inline-flex items-center cursor-pointer"
        htmlFor={id}
      >
        <input
          type="checkbox"
          className="sr-only peer"
          value=""
          id={id}
          checked={checked}
          onChange={handleChange}
        />
        <div className="group peer bg-white rounded-full duration-300 w-12 h-6 ring-2 ring-entntorange after:duration-300 after:bg-entntorange peer-checked:after:bg-entntblue peer-checked:ring-entntblue after:rounded-full after:absolute after:h-4 after:w-4 after:top-1 after:left-1 after:flex after:justify-center after:items-center peer-checked:after:translate-x-6 peer-hover:after:scale-95"></div>
      </label>
    </div>
  );
}
