import { useEffect, useState } from "react";
import RankTableColumn from "./RankTableColumn";
import { CategoryFull, Company, UniversityCompany } from "../../../Models";
import { updateRanking } from "../../../WebCalls";
import UpdateRanking from "./UpdateRanking";
import useApiToken from "../../../hooks/useApiToken";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/20/solid";
import Notification from "../../Shared/Notification";

interface Props {
  data: Company[] | UniversityCompany[];
  tab: string;
  rankTypeId: string;
  selectedColumns: string[];
  rankCategoryLabels: CategoryFull[];
  sortOrder: { name: boolean; rank: boolean };
  getData: () => Promise<any>;
  handleSelectedColumns: (c: string[]) => void;
  handleSortOrder: (s: { name: boolean; rank: boolean }) => void;
  handleSortChange: (s: string) => void;
}

const  RankTable = ({
  data,
  tab,
  rankTypeId,
  selectedColumns,
  rankCategoryLabels,
  sortOrder,
  handleSelectedColumns,
  handleSortOrder,
  getData,
  handleSortChange,
}: Props) => {
  const [tableFields, setTableFields] = useState<string[]>([]);
  const [errorNotification, setErrorNotification] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [ selectedRow, setSelectedRow ] = useState<string | null>(null);


  const token = useApiToken();

  useEffect(() => {
    let fields;
    if (tab === "Companies") {
      fields = [
        "name",
        "ranking",
        "Links",
        "Tags"
      ];
    } else {
      fields = ["name", "state", "url", "ranking", "Tags"];
    }
    setTableFields(fields);
  }, [tab]);

  useEffect(() => {
    if (selectedColumns.length === 0) {
      setSelectAll(false);
    }
  }, [selectedColumns]);

  const handleTableFilter = async (filterType: string) => {
    switch (filterType) {
      case "name":
        handleSortOrder({ ...sortOrder, name: !sortOrder.name });
        handleSortChange("Name");
        setSelectAll(false);
        handleSelectedColumns([]);
        break;
      case "ranking":
        handleSortOrder({ ...sortOrder, rank: !sortOrder.rank });
        handleSortChange("Rank number");
        setSelectAll(false);
        handleSelectedColumns([]);
        break;
      default:
        break;
    }
  };

  const handleSelectColumnData = (columnId: string) => {
    if (selectedColumns.includes(columnId)) {
      handleSelectedColumns(selectedColumns.filter((id) => id !== columnId));
    } else {
      handleSelectedColumns([...selectedColumns, columnId]);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      handleSelectedColumns([]);
      setSelectAll(!selectAll);
    } else if (selectedColumns.length !== 0) {
      handleSelectedColumns([]);
    } else {
      handleSelectedColumns(data.map((data) => data.id));
      setSelectAll(!selectAll);
    }
  };

  const handleEditRankLabel = async (
    category: CategoryFull,
    selectedColumn?: string
  ) => {
    if (!token) return;
    try {
      if (selectedColumn) {
        await updateRanking(
          tab,
          rankTypeId,
          selectedColumn,
          category.id,
          token
        );
      } else {
        await Promise.all(
          selectedColumns.map(
            async (id) =>
              await updateRanking(tab, rankTypeId, id, category.id, token)
          )
        );
      }
      getData();
    } catch (err: any) {
      setErrorNotification(true);
      setErrorMessage(err.message);
    }
    if (!selectedColumn) handleSelectAll();
  };

  return (
    <>
      <div className="min-h-full">
        {data && data.length > 0 ? (
          <div className="relative overflow-visible sm:rounded-t-lg min-h-[87vh]">
            <div className="absolute left-[0.75rem] sm:left-[0.85rem] top-2">
              {!selectAll && selectedColumns.length === 0 ? (
                <input
                  type="checkbox"
                  className={`rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 dark:border-gray-100`}
                  onChange={handleSelectAll}
                />
              ) : (
                <div className="flex m-auto items-center md:justify-center">
                  <span
                    className="flex items-center justify-center h-5 w-5 border-2 rounded border-gray-300 text-center bg-indigo-600 relative"
                    onClick={handleSelectAll}
                  >
                    <span className="inline-block w-[0.5rem] h-[0.17rem] bg-gray-100"></span>
                  </span>
                  <div className="">
                    <UpdateRanking
                      rankCategoryLabels={rankCategoryLabels}
                      handleEditRankLabel={handleEditRankLabel}
                    />
                  </div>
                </div>
              )}
            </div>
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
              <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400 ">
                <tr>
                  <th scope="col"></th>
                  {tableFields?.map((item) => {
                    return (
                      <th
                        key={item}
                        scope="col"
                        className="px-6 py-3 sm:table-cell"
                      >
                        {item === "name" ? (
                          <div className="flex items-center gap-2">
                            {item.toUpperCase()}
                            <span
                              className="hover:bg-gray-300 rounded-full py-0.5 px-1.5 cursor-pointer hover:dark:text-black"
                              onClick={() => handleTableFilter(item)}
                            >
                              {sortOrder.name ? (
                                <ArrowUpIcon className="h-4" />
                              ) : (
                                <ArrowDownIcon className="h-4" />
                              )}
                            </span>
                          </div>
                        ) : item === "ranking" ? (
                          <div className="flex items-center gap-2">
                            {item.toUpperCase()}
                            <span
                              className="hover:bg-gray-300 rounded-full py-0.5 px-1.5 cursor-pointer hover:dark:text-black"
                              onClick={() => handleTableFilter(item)}
                            >
                              {sortOrder.rank ? (
                                <ArrowUpIcon className="h-4" />
                              ) : (
                                <ArrowDownIcon className="h-4" />
                              )}
                            </span>
                          </div>
                        ) : (
                          item.toUpperCase()
                        )}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {data?.map((item) => {
                  return (
                    <RankTableColumn
                      setSelectedRow = {(entry: string | null) => setSelectedRow(entry)}
                      selectedRow = {selectedRow}
                      key={item.id}
                      columnData={item}
                      tab={tab}
                      checked={selectedColumns.includes(item.id)}
                      handleSelectColumn={handleSelectColumnData}
                      handleEditRankLabel={handleEditRankLabel}
                      rankCategoryLabels={rankCategoryLabels}
                      getData = {getData}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="bg-blue-100 rounded-lg py-8 mt-8 dark:bg-darkbglight font-bold items-center text-center dark:text-gray-400">
            <h1>{`No ${tab} Found`}</h1>
          </div>
        )}
      </div>
      {errorMessage && (
        <Notification
          type="error"
          show={errorNotification}
          setShow={setErrorNotification}
          title="Cannot update ranking"
          description={"Error while updating ranks : " + errorMessage}
        />
      )}
    </>
  );
};

export default RankTable;
