  import { useState } from "react";

export const CustomTooltip: React.FC<{ content: string; children: React.ReactNode }> = ({ content, children }) => {
    const [isVisible, setIsVisible] = useState(false);
  
    return (
      <div 
      className="relative inline-block"
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      {children}
      {isVisible && (
        <div className="min-w-max absolute z-10 px-2 py-1 text-xs font-medium text-white bg-entntblue rounded-md shadow-sm bottom-10 left-1/2 transform -translate-x-1/2">
          {content}
          <div className="tooltip-arrow absolute w-2 h-2 bg-entntblue transform rotate-45 -bottom-1 left-1/2 -translate-x-1/2"></div>
        </div>
      )}
    </div>
    )
  }