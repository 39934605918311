import { useEffect, useRef, useState } from "react";
import {
  ChevronDoubleRightIcon,
  ChevronDoubleLeftIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import { useLocation } from "react-router-dom";
import {
  generateCompanyRankings,
  generateUniversityRankings,
  getCompaniesByRankType,
  getCompanyRankingCategories,
  getRankType,
  getUniversitiesByRankType,
  getUniversityRankingCategories,
  paginationData,
} from "../../WebCalls";
import { UniversityCompany, Company, CategoryFull } from "../../Models";
import ConfirmModal from "../Shared/ConfirmModal";
import { NIL } from "uuid";
import { Spinner } from "../Shared/Spinner";
import Notification from "../Shared/Notification";
import CreateRankingSystem from "./Sidebar/RightSideBar/CreateRankingSystem";
import RankTable from "./RankTable/RankTable";
import Loading from "../Shared/Loading";
import RankingSideBar from "./Sidebar/LeftSidebar/RankingSideBarHeader";
import RankingSideBarBody from "./Sidebar/LeftSidebar/RankingSideBarBody";
import CreateNewTypeModal from "./Sidebar/RightSideBar/CreateNewTypeModal";
import useApiToken from "../../hooks/useApiToken";
import { debounce } from "../../helpers/constants";import { FilterRanking } from "../../Models";

const tabs = ["Companies", "Universities"];

const RankingBoard = () => {
  const [selectedRankType, setSelectedRoleType] = useState<{
    id: string;
    name: string;
  }>({
    id: NIL,
    name: "Overall",
  });
  const [currentTab, setCurrentTab] = useState<string>("Companies");
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const [currentData, setCurrentData] = useState<
    Company[] | UniversityCompany[]
  >([]);
  const [categoriesUpdate, setCategoriesUpdate] = useState<boolean>(false);
  const [rankCategoryLabels, setRankCategoryLabels] = useState<CategoryFull[]>(
    []
  );

  const [computeRankModal, setComputeRankModal] = useState(false);
  const [generatingRank, setGeneratingRank] = useState(false);

  const [rankUpdateNotification, setRankUpdateNotification] = useState(false);
  const [isSidebarOpen, setIsSideBarOpen] = useState<boolean>(true);
  const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [filters, setFilters] = useState<FilterRanking[]>();
  const [filterQuery, setFilterQuery] = useState("");
  const [sortQuery, setSortQuery] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<{ name: boolean; rank: boolean }>({
    name: true,
    rank: true,
  });

  const [rankTypes, setRankTypes] = useState<{ id: string; name: string }[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [ paginationData, setPaginationData ] = useState<paginationData>();

  const [currentPage, setCurrentPage] = useState<number>(1);

  const location = useLocation();
  const searchRef = useRef<HTMLInputElement>(null);
  const token = useApiToken();

  useEffect(() => {
    if (
      location.pathname.includes("/companies") ||
      location.pathname === "/rankboard"
    ) {
      setCurrentTab(tabs[0]);
    } else if (location.pathname.includes("/universities")) {
      setCurrentTab(tabs[1]);
    }
  }, [location.pathname]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const type = queryParams.get("type");
    const rankType = rankTypes.find((r) => r.id === type);

    if (rankType) {
      setSelectedRoleType(rankType);
    }
  }, [location.pathname, rankTypes]);

  useEffect(() => {
    const resetStates = () => {
      setCurrentPage(1);
      setSortQuery("");
      setSortOrder({
        name: true,
        rank: true,
      });
      setSelectedColumns([]);
      if (searchRef.current) searchRef.current.value = "";
      setSearchQuery("");
      setFilterQuery("");
    };  
    setFilters(undefined);
    resetStates();
  }, [currentTab]);

  const fetchAndSetRankType = async () => {
    if (!token) return;
    const data = await getRankType(token);
    data.unshift({
      id: NIL,
      name: "Overall",
    });
    setRankTypes(data);
  };

  useEffect(() => {
    fetchAndSetRankType();
  }, [token]);

  useEffect(() => {
    const getCategories = async () => {
      if (!token) return;
      let categories;
      if (currentTab === "Companies") {
        categories = await getCompanyRankingCategories(
          selectedRankType.id,
          token
        );
      } else {
        categories = await getUniversityRankingCategories(
          selectedRankType.id,
          token
        );
      }
      setRankCategoryLabels(categories);

      setCategoriesUpdate(false);
    };

    getCategories();
  }, [selectedRankType, currentTab, categoriesUpdate, token]);

  const handleComputeRanking = async () => {
    if (!token) return;
    setComputeRankModal(false);
    setGeneratingRank(true);
    let gr;
    if (selectedColumns.length === 0) gr = {};
    else
      gr = {
        ids: selectedColumns,
      };
    try {
      if (currentTab === tabs[0]) {
        await generateCompanyRankings(selectedRankType.id, gr, token);
      } else {
        await generateUniversityRankings(selectedRankType.id, gr, token);
      }
      setRankUpdateNotification(true);
    } catch (er) {}

    setGeneratingRank(false);
    setSelectedColumns([]);
  };

  const getData = async (getDataObj : any = { pageChange: false, reload: false }) => {
    let data : any;
    let page = getDataObj.reload ? 1 : currentPage;

    let allRecords: any[] = [];
  let paginationMetadata = null;
    do{
      const pageData =
      currentTab === tabs[0]
        ? await getCompaniesByRankType(
            selectedRankType.id,
            page,
            100,
            sortQuery,
            sortQuery === "Name" ? !sortOrder.name : !sortOrder.rank,
            searchQuery,
            filterQuery,
            filters
          )
        : await getUniversitiesByRankType(
            selectedRankType.id,
            page,
            100,
            sortQuery,
            sortQuery === "Name" ? !sortOrder.name : !sortOrder.rank,
            searchQuery,
            filterQuery,
            filters
          );

          if(getDataObj.reload){
            allRecords = [...allRecords, ...pageData.records];
            paginationMetadata = pageData.paginationMetadata;
            page += 1;
          }
          else{
            data = pageData;
            break;
          }
    }
    while(getDataObj.reload && page <= currentPage);
   
    if(getDataObj.reload === true){
      setCurrentData(allRecords);
      setPaginationData(paginationMetadata);
    }
    else{
      setPaginationData(data.paginationMetadata);
      if(getDataObj.pageChange){
        setCurrentData((prev) => [...prev, ...data.records]);
      }
      else{
        setCurrentData(data.records);
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop = window.scrollY || document.documentElement.scrollTop;

      if (scrollTop + windowHeight + 1 >= documentHeight){
        setCurrentPage((prev) =>
        {
          return !paginationData
          ? prev
          : prev >= paginationData.totalPages
            ? prev
            : prev + 1
        }
        );
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [paginationData]);


  useEffect(() => {
    setIsLoading(true);
    getData().then(() => {
      setIsLoading(false);
    });

  }, [
    categoriesUpdate,
    filterQuery,
    searchQuery,
    selectedRankType,
    sortQuery,
    sortOrder,
    filters
  ]);

  useEffect(() => {
    getData({ pageChange: true, reload: false });
  }, [
    currentPage,
  ]);

  const delayedSearch = debounce((query: string) => {
    setSearchQuery(query);
  }, 500);

  const handleSearchOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(1);
    delayedSearch(e.target.value);
  };

  return (
    <>
      <div className="bg-transparent">
        <div className="flex relative">
          <aside
            className={`dark:border-gray-500 fixed z-10 top-[4.1rem] block h-[calc(100vh-4rem)] left-0 shadow-md border border-gray-200 transition-transform transform duration-200 w-[19rem] ${
              isSidebarOpen ? "translate-x-0" : "-translate-x-[100%]"
            }`}
          >
            <div
              className={`absolute top-10 ${
                isSidebarOpen ? "-right-2 shadow-md" : "-right-4 shadow-xl"
              } h-16 rounded-md flex items-center justify-center cursor-pointer bg-white dark:bg-darkbglight dark:border-gray-500 dark:text-gray-300`}
              onClick={() => {
                setIsSideBarOpen(!isSidebarOpen);
              }}
            >
              {isSidebarOpen ? (
                <ChevronDoubleLeftIcon width={16} />
              ) : (
                <ChevronDoubleRightIcon width={16} />
              )}
            </div>
            <div
              className={`h-full bg-white thin-scroll overflow-y-auto dark:bg-darkbg`}
            >
              <RankingSideBar
                selectedRankType={selectedRankType}
                currentTab={currentTab}
              />
 
              <div className="flex flex-row justify-end items-end sm:py-4 w-full">
                <div className="relative w-full mx-2">
                  <input
                    type="text"
                    placeholder="Search"
                    className="w-full x1 rounded-lg bg-white py-2 pl-10 pr-3 text-left border border-gray-300 focus:outline-none focus-visible:border-indigo-500 dark:text-gray-400 dark:border-gray-600 dark:bg-transparent focus-visible:ring-offset-blue-100 sm:text-sm"
                    onChange={handleSearchOnChange}
                    ref={searchRef}
                  />
                  <MagnifyingGlassIcon
                    className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400 dark:text-gray-500"
                    aria-hidden="true"
                  />
                </div>
              </div>
              
              <RankingSideBarBody
                applyFilters = {(filter) => {
                  setCurrentPage(1);
                  setFilters(filter)
                }}
                selectedColumns = {selectedColumns}
                setSelectedColumns = { (columns: string[]) => setSelectedColumns(columns)}
                setSelectedRoleType={setSelectedRoleType}
                rankTypes={rankTypes}
                tab={currentTab}
                fetchAndSetRankType={fetchAndSetRankType}
                rankCategoryLabels={rankCategoryLabels}
                handleFilterQuery={(f) => {
                  setCurrentPage(1);
                  setFilterQuery(f);
                }}
                getData={(obj) => getData(obj)}
              />
            </div>
          </aside>

          <main
            className={`${
              !isSidebarOpen
                ? "w-full mx-8"
                : "relative sm:transform sm:translate-x-[17rem] sm:w-[calc(100%-19rem)]"
            } ${
              !isRightSidebarOpen
                ? "w-full mx-8"
                : "relative sm:transform sm:-translate-x-[1rem] sm:w-[calc(100%-14rem)]"
            }
            ${
              isSidebarOpen && isRightSidebarOpen
                ? "sm:w-[calc(100%-38rem)] sm:transform sm:translate-x-[19.1rem]"
                : "w-full"
            }
             transition-all duration-200 
            ease-out `}
          >
            {currentData && !isLoading ? (
              <RankTable
                selectedColumns={selectedColumns}
                handleSelectedColumns={(c: string[]) => setSelectedColumns(c)}
                data={currentData}
                tab={currentTab}
                rankTypeId={selectedRankType.id}
                rankCategoryLabels={rankCategoryLabels}
                handleSortOrder={(s) => setSortOrder(s)}
                sortOrder={sortOrder}
                getData={getData}
                handleSortChange={(s) => {
                  setSortQuery(s);
                }}
              />
            ) : (
              <>
                <Loading />
              </>
            )}
          </main>

          <aside
            className={`dark:border-gray-500 fixed z-10 top-[4.1rem] block h-[calc(100vh-4rem)] right-0 shadow-md border border-gray-200 transition-transform transform duration-200 w-[19rem] ${
              isRightSidebarOpen ? "translate-x-0" : "translate-x-[100%]"
            }`}
          >
            <div
              className={`absolute top-10 ${
                isRightSidebarOpen ? "-left-2 shadow-md" : "-left-4 shadow-xl"
              } h-16 border border-gray-300 rounded-md flex items-center justify-center cursor-pointer bg-white dark:bg-darkbglight dark:border-gray-500 dark:text-gray-300`}
              onClick={() => {
                setIsRightSidebarOpen(!isRightSidebarOpen);
              }}
            >
              {!isRightSidebarOpen ? (
                <ChevronDoubleLeftIcon width={16} />
              ) : (
                <ChevronDoubleRightIcon width={16} />
              )}
            </div>
            <div
              className={`h-full bg-white thin-scroll overflow-y-auto dark:bg-darkbg overflow-x-hidden`}
            >
              <div className="bg-gray-100 dark:bg-darkbglight">
                <div className="min-w-0 px-4 pt-2 pb-1">
                  <div className="text-gray-900 dark:text-gray-300 flex items-center space-x-2 justify-center">
                    <h3 className="text-center text-lg font-semibold leading-7 sm:text-base sm:tracking-tight">
                      Ranking System
                    </h3>
                  </div>
                </div>
              </div>
              <CreateRankingSystem
                rankTypeId={selectedRankType.id}
                tab={currentTab}
                setCategoriesUpdate={setCategoriesUpdate}
              />
              <div className="flex flex-col space-y-3 py-4 pr-4 max-w-60 mt-16">
                <button
                  type="button"
                  className=" inline-flex items-center rounded-md bg-entntblue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-entntorange focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:hover:bg-entntblue disabled:cursor-not-allowed sm:ml-3"
                  disabled={generatingRank}
                  onClick={() => {
                    setComputeRankModal(true);
                  }}
                >
                  {generatingRank ? (
                    <div className="flex">
                      <Spinner />
                      <span className="ml-2">Generating</span>
                    </div>
                  ) : (
                    "Compute Ranking"
                  )}
                </button>
                <button
                  type="button"
                  className="inline-flex items-center rounded-md bg-entntblue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-entntorange focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:hover:bg-entntblue disabled:cursor-not-allowed sm:ml-3"
                  onClick={() => {
                    setIsModalOpen(true);
                  }}
                >
                  Create new type
                </button>
              </div>
            </div>
          </aside>
        </div>
      </div>
      <ConfirmModal
        open={computeRankModal}
        setOpen={setComputeRankModal}
        onConfirm={handleComputeRanking}
        type="info"
        title="Generate Rankings."
        description={
          <div className="flex flex-col text-gray-900 text-sm">
            <span>
              If no {currentTab.toLowerCase()} are selected, all{" "}
              {currentTab.toLowerCase()} will be ranked. If specific{" "}
              {currentTab.toLowerCase()} are selected, only those{" "}
              {currentTab.toLowerCase()} will be ranked. The ranking will be
              generated based on the ranking system.
            </span>
          </div>
        }
      />
      <Notification
        show={rankUpdateNotification}
        setShow={setRankUpdateNotification}
        title="Ranks Updated"
        description="Ranks updated successfully."
        type="success"
      />
      <CreateNewTypeModal
        isOpen={isModalOpen}
        fetchAndSetRankType={fetchAndSetRankType}
        onCancel={setIsModalOpen}
        title="Create new rank type"
        rankTypes={rankTypes}
      />
    </>
  );
};

export default RankingBoard;
