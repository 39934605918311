import { useState, Fragment, useEffect } from "react";
import { TagIcon, FunnelIcon } from "@heroicons/react/20/solid";
import { CategoryFull } from "../../../../Models";
import RankingFilters from "./RankingFilters";
import { TagManagement } from "./TagManagement";
import Notification from "../../../Shared/Notification";
import { NIL } from "uuid";
import { getTags } from "../../../../WebCalls";
import { useLocation } from "react-router-dom";

export interface PopUp {
  title: string;
  description: string;
  type: "success" | "error" | "info";
}

export interface Tags {
  id: string;
  name: string;
  count: number;
}

interface Props {
  selectedColumns: string[];
  setSelectedColumns: (columns: string[]) => void;
  applyFilters: (filter: any) => void;
  setSelectedRoleType: any;
  rankTypes: { id: string; name: string }[];
  rankCategoryLabels: CategoryFull[];
  tab: string;
  fetchAndSetRankType: () => Promise<void>;
  handleFilterQuery: (filter: string) => void;
  // getData: () => Promise<any>;
  getData: (obj: any) => void;  
}

const RankingSideBarBody = ({
  applyFilters,
  setSelectedRoleType,
  setSelectedColumns,
  rankTypes,
  rankCategoryLabels,
  tab,
  fetchAndSetRankType,
  handleFilterQuery,
  selectedColumns,
  getData
}: Props) => {

  const [isTag, setIsTag] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [notificationPopUp, setNotificationPopUp] = useState<PopUp>({
    title: "",
    description: "",
    type: "info"
  });
  const [rankType, setRankType] = useState<string>(NIL);
  const [tags, setTags] = useState<Tags[]>([]);
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const type = queryParams.get("type");
    setRankType(type ?? NIL);
}, [location.search]);

  useEffect(() => {
      refreshTags();
  }, [rankType, tab]);

  const refreshTags = () => {
    let tags: any[] = [];
    if(tab === "Companies"){
      getTags(rankType, true).then((t) => {
        if(t && t.length > 0){
          tags = t.map((crt:any) => ({
            id: crt.id,
            name: crt.name,
            count: crt.count
          })); 
        }
        setTags(tags);
      });
    }
    else{
      getTags(rankType, false).then((t) => {
        if(t && t.length > 0){
          tags = t.map((crt:any) => ({
            id: crt.id,
            name: crt.name,
            count: crt.count
          })); 
        }
        setTags(tags);
      });
    }
  }

  return (
    <>
      <div className="flex gap-3 items-center px-3 my-4 text-sm text-gray-500 dark:text-gray-300 border-b-2 border-gray-300">
        <div className= {`flex gap-1 items-center pr-2 pb-3 dark:text-gray-300 ${isTag ? "" : "border-b-2 border-entntblue text-black dark:text-white"}`}>
          <FunnelIcon className="h-3 w-3"/>
          <button 
          onClick={() => {
            applyFilters([]);
            setIsTag(false);
          }}
          >
            <h3 className="font-semibold">Filters</h3>
          </button>
        </div>
        <div className={`flex gap-1 items-center pb-3 dark:text-gray-300 ${isTag ? "border-b-2 border-entntblue text-black dark:text-white" : "" }`}>
          <TagIcon className="h-3 w-3"/>
          <button onClick={() => {
            applyFilters([]);
            setIsTag(true);
          }}>
            <h6 className="font-semibold">Tags</h6>
          </button>
        </div>
      </div>

      {
        isTag ? (
          <TagManagement
            tab={tab}
            setNotificationPopUp={(popUp: PopUp) => setNotificationPopUp(popUp)}
            refreshTags={refreshTags}
            rankType={rankType}
            setShow={(show: boolean) => setShow(show)}
          />
          
        ):
        <RankingFilters
            applyFilters = {applyFilters}
            setSelectedRoleType={setSelectedRoleType}
            rankTypes={rankTypes}
            tab={tab}
            fetchAndSetRankType={fetchAndSetRankType}
            rankCategoryLabels={rankCategoryLabels}
            handleFilterQuery={handleFilterQuery}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            refreshTags={refreshTags}
            tags={tags}
            setShow={(show: boolean) => setShow(show)}
            getData={(obj) => getData(obj)}
            setNotificationPopUp={(popUp: PopUp) => setNotificationPopUp(popUp)}

        />
      }
      
      <Notification
         show = {show}
         setShow = {setShow}
         {
          ...notificationPopUp
         }
      />
    </>
  );
};

export default RankingSideBarBody;
