import { Fragment, useCallback, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
  DocumentTextIcon,
  EyeIcon,
  PencilSquareIcon,
} from "@heroicons/react/20/solid";

import { AssessmentCondition, recruitmentProcess } from "./CreateRoleForm";
import { reorder } from "./CreateRoleStatus";
import TextEditorPreviewModal from "../../Shared/TextEditorPreviewModal";
import { useLocation } from "react-router-dom";
import { AssessmentDropdown } from "./CreateProcessAutomation";
import TemplatesDropdown from "../../Question/Dropdowns/TemplatesDropdown";
import { AssessmentTemplate, TemplateWithQuestionnaires } from "../../../Models";
import AllTemplatesDropDown from "./AllTemplatesDropdown";

type props = {
  setProcesses: React.Dispatch<React.SetStateAction<recruitmentProcess[]>>;
  process: recruitmentProcess;
  open: Boolean;
  processes: recruitmentProcess[];
  handleTextEditor: (type: string, inputType: string) => void;
  setRecruitmentProcessId: React.Dispatch<React.SetStateAction<string>>;
  assessments: TemplateWithQuestionnaires[]
};
export function RoleStatusEditPopup({
  setProcesses,
  process,
  open,
  processes,
  handleTextEditor,
  setRecruitmentProcessId,
  assessments
}: props) {
  const [currentProcessValue, setCurrentProcessValue] = useState(process);
  const [order, setOrder] = useState<string>();
  const [selectedAssessmentTemplate, setSelectedAssessmentTemplate] = useState<TemplateWithQuestionnaires | null>(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const location = useLocation();
  const isEditRole = location.pathname.includes("edit");
  const [isRejectionEmail, setIsRejectionEmail] = useState<boolean>(true);
  const [isSubmittedEmail, setIsSubmittedEmail] = useState<boolean>(true);
  const [currentPreviewContent, setCurrentPreviewContent] = useState('');



  const orderIsInvalid = useCallback(
    (order: number): boolean => {
      return order <= 0 || order > processes.length;
    },
    [processes.length]
  );

  useEffect(() => {
    if (!open) {
      if (currentProcessValue.title.trim() === "")
        setCurrentProcessValue({
          ...currentProcessValue,
          title: process.title,
        });
      if (orderIsInvalid(Number(order)))
        setOrder(String(processes.indexOf(process) + 1));
    }
  }, [open, process, currentProcessValue, processes, order, orderIsInvalid]);

  useEffect(() => {
    setOrder(String(processes.indexOf(process) + 1));
  }, [processes, process]);

  const handleOnSave = () => {
    const updatedProcesses: recruitmentProcess[] = processes.map((el) => {
      if (el.id === currentProcessValue.id) {
        return {
          id: el.id,
          title:
            currentProcessValue.title.trim() === ""
              ? process.title
              : currentProcessValue.title,
          email: currentProcessValue.email,
          order: currentProcessValue.order,
          assessmentTemplateId: selectedAssessmentTemplate ? selectedAssessmentTemplate.id : currentProcessValue.assessmentTemplateId,
          passedEmail: currentProcessValue.passedEmail,
          rejectionEmail: isRejectionEmail ? currentProcessValue.rejectionEmail : "",
          submittedEmail: isSubmittedEmail ? currentProcessValue.submittedEmail: "",
        };
      } else return el;
    });
    const currentProcessValueIndex = updatedProcesses.findIndex(
      (up) => up.id === currentProcessValue.id
    );
    const reorderedProcesses = reorder(
      updatedProcesses,
      currentProcessValueIndex,
      Number(order) - 1
    );
    setProcesses(reorderedProcesses);
  };

  useEffect(() => {
    if(currentProcessValue.rejectionEmail === "") setIsRejectionEmail(false);
    if(currentProcessValue.submittedEmail === "") setIsSubmittedEmail(false);
  }, [process,currentProcessValue.rejectionEmail, currentProcessValue.submittedEmail])

  // useEffect(() => {
  //   if(!isRejectionEmail){
  //     setCurrentProcessValue({...currentProcessValue, rejectionEmail: ""})
  //   }
  //   if(!isSubmittedEmail){
  //     setCurrentProcessValue({...currentProcessValue, submittedEmail: ""})
  //   }

  // },[isRejectionEmail, isSubmittedEmail,currentProcessValue])

  const setId = () => {
    setRecruitmentProcessId(currentProcessValue.id);
  };

  const handlePreview = (e: any) => {
    e.preventDefault();
    setIsPreviewOpen(true);
  };

  const onTemplateSelect = (id: string) => {
    let selectedTemplate = assessments.find((a) => a.id === id);
    if (selectedTemplate) {
      setSelectedAssessmentTemplate(selectedTemplate);
    }
  }
  useEffect(() => {
    const currSelectedAssessmentTemplate = assessments.find((r) => process.assessmentTemplateId === r.id) ?? null;
    setSelectedAssessmentTemplate(currSelectedAssessmentTemplate);
  }, [process, assessments]);

  const findIndex = processes.findIndex((el) => el.id === process.id);
  return (
    <>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <div className="relative z-20">
          <Popover.Panel className={`absolute ${(findIndex % 3 === 0 || findIndex % 4 === 0) && findIndex !== 0 ? "right-1/4 " : "left-1/2 -translate-x-1/3"} mt-5 flex w-screen max-w-min  px-4 `}>
            {({ close }) => (
              <div className="w-max shrink rounded-xl bg-white p-4 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5 dark:bg-darkbg ">
                <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-4">
                  <label
                    htmlFor="title"
                    className="block text-xs font-medium leading-6 text-gray-900 sm:pt-1.5 dark:text-gray-400"
                  >
                    Title
                    <span className="text-red-500"> *</span>
                  </label>
                  <div className="mt-2 sm:col-span-3 sm:mt-0">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-1 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md font-normal dark:ring-gray-500 ">
                      <input
                        type="text"
                        name="title"
                        id="title"
                        autoComplete=""
                        className="block disabled:cursor-not-allowed  flex-1 border-0 bg-transparent py-1.5 pl-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 dark:text-gray-300"
                        placeholder={currentProcessValue.title}
                        onChange={(e) => {
                          setCurrentProcessValue({
                            ...currentProcessValue,
                            title: e.target.value,
                          });
                        }}
                        value={currentProcessValue.title}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-4">
                  <label
                    htmlFor="title"
                    className="block text-xs font-medium leading-6 text-gray-900 sm:pt-1.5 dark:text-gray-400"
                  >
                    Order
                    <span className="text-red-500"> *</span>
                  </label>
                  <div className="mt-2 sm:col-span-3 sm:mt-0">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-1 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md font-normal dark:ring-gray-500 ">
                      <input
                        type="number"
                        name="title"
                        id="title"
                        min={1}
                        max={processes.length}
                        className="disabled:cursor-not-allowed  block flex-1 border-0 bg-transparent py-1.5 pl-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 dark:text-gray-300"
                        onChange={(e) => {
                          setOrder(e.target.value);
                        }}
                        value={order}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-4">
                  <label
                    htmlFor="emailBody"
                    className="block text-xs font-medium leading-6 text-gray-900 sm:pt-1.5 dark:text-gray-400"
                  >
                    Email body
                  </label>
                  <div className="mt-2 sm:col-span-3 sm:mt-0 flex gap-2">
                    {process.email && (
                      <button
                        onClick={(e) => { handlePreview(e); setCurrentPreviewContent(process.email) }}
                        className="flex gap-2 items-center justify-center ring-1 bg-entntblue text-nowrap rounded-md bg-none px-5 py-1 text-sm font-bold text-white shadow-sm w-full hover:bg-entntorange"
                      >
                        Preview
                        <EyeIcon className="w-6 h-6" />
                      </button>
                    )}
                    <button
                      onClick={() => {
                        handleTextEditor(currentProcessValue.id, "emailBody");
                        setId();
                      }}
                      className="w-full flex gap-2 items-center justify-center ring-1 ring-entntblue text-nowrap rounded-md bg-none px-5 py-1 text-sm font-bold text-entntblue shadow-sm hover:ring-entntorange hover:text-entntorange"
                    >
                      {!process.email ? (
                        <>
                          Open Text Editor
                          <DocumentTextIcon className="w-6 h-6" />
                        </>
                      ) : (
                        <>
                          Edit
                          <PencilSquareIcon className="w-6 h-6" />
                        </>
                      )}
                    </button>
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-4">
                  <label
                    htmlFor="emailBody"
                    className="block text-xs font-medium leading-6 text-gray-900 sm:pt-1.5 dark:text-gray-400"
                  >
                    Passed Email
                  </label>
                  <div className="mt-2 sm:col-span-3 sm:mt-0 flex gap-2">
                    {process.passedEmail && (
                      <button
                        onClick={(e) => { handlePreview(e); setCurrentPreviewContent(process.passedEmail) }}
                        className="flex gap-2 items-center justify-center ring-1 bg-entntblue text-nowrap rounded-md bg-none px-5 py-1 text-sm font-bold text-white shadow-sm w-full hover:bg-entntorange"
                      >
                        Preview
                        <EyeIcon className="w-6 h-6" />
                      </button>
                    )}
                    <button
                      onClick={() => {
                        handleTextEditor(currentProcessValue.id, "passedBody");
                        setId();
                      }}
                      className="w-full flex gap-2 items-center justify-center ring-1 ring-entntblue text-nowrap rounded-md bg-none px-5 py-1 text-sm font-bold text-entntblue shadow-sm hover:ring-entntorange hover:text-entntorange"
                    >
                      {!process.passedEmail ? (
                        <>
                          Open Text Editor
                          <DocumentTextIcon className="w-6 h-6" />
                        </>
                      ) : (
                        <>
                          Edit
                          <PencilSquareIcon className="w-6 h-6" />
                        </>
                      )}
                    </button>
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-4">
                  <label
                    htmlFor="emailBody"
                    className=" text-xs font-medium leading-6 text-gray-900 sm:pt-1.5 dark:text-gray-400 flex gap-2 items-center"
                  >
                    Submitted Email
                    <input type="checkbox" className="h-3 w-3 rounded-sm ring-0" checked={isSubmittedEmail} onChange={(e) => { setIsSubmittedEmail(e.target.checked); }} />
                  </label>
                  <div className="mt-2 sm:col-span-3 sm:mt-0 flex gap-2">
                    {process.submittedEmail && (
                      <button
                        onClick={(e) => { handlePreview(e); setCurrentPreviewContent(process.submittedEmail) }}
                        className="flex gap-2 items-center justify-center ring-1 bg-entntblue text-nowrap rounded-md bg-none px-5 py-1 text-sm font-bold text-white shadow-sm w-full hover:bg-entntorange"
                        disabled={!isSubmittedEmail}
                      >
                        Preview
                        <EyeIcon className="w-6 h-6" />
                      </button>
                    )}
                    <button
                      onClick={() => {
                        handleTextEditor(currentProcessValue.id, "submittedBody");
                        setId();
                      }}
                      disabled={!isSubmittedEmail}
                      className={`w-full flex gap-2 items-center justify-center ring-1 text-nowrap rounded-md bg-none px-5 py-1 text-sm font-bold shadow-sm 
                    ${!isSubmittedEmail
                          ? 'text-gray-400 ring-gray-300 cursor-not-allowed'
                          : 'ring-entntblue text-entntblue hover:ring-entntorange hover:text-entntorange'
                        }`}
                    >
                      {!process.submittedEmail ? (
                        <>
                          Open Text Editor
                          <DocumentTextIcon className="w-6 h-6" />
                        </>
                      ) : (
                        <>
                          Edit
                          <PencilSquareIcon className="w-6 h-6" />
                        </>
                      )}
                    </button>
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-4">
                  <label
                    htmlFor="emailBody"
                    className=" text-xs font-medium leading-6 text-gray-900 sm:pt-1.5 dark:text-gray-400 flex gap-2 items-center"
                  >
                    Rejection Email
                    <input type="checkbox" className="h-3 w-3 rounded-sm ring-0" checked={isRejectionEmail} onChange={(e) => { setIsRejectionEmail(e.target.checked); }} />
                  </label>
                  <div className="mt-2 sm:col-span-3 sm:mt-0 flex gap-2">
                    {process.rejectionEmail && (
                      <button
                        onClick={(e) => { handlePreview(e); setCurrentPreviewContent(process.rejectionEmail) }}
                        className="flex gap-2 items-center justify-center ring-1 bg-entntblue text-nowrap rounded-md bg-none px-5 py-1 text-sm font-bold text-white shadow-sm w-full hover:bg-entntorange"
                        disabled={!isRejectionEmail}
                      >
                        Preview
                        <EyeIcon className="w-6 h-6" />
                      </button>
                    )}
                    <button
                      onClick={() => {
                        handleTextEditor(currentProcessValue.id, "rejectedBody");
                        setId();
                      }}
                      disabled={!isRejectionEmail}
                      className={`w-full flex gap-2 items-center justify-center ring-1 text-nowrap rounded-md bg-none px-5 py-1 text-sm font-bold shadow-sm 
      ${!isRejectionEmail
                          ? 'text-gray-400 ring-gray-300 cursor-not-allowed'
                          : 'ring-entntblue text-entntblue hover:ring-entntorange hover:text-entntorange'
                        }`}
                    >
                      {!process.rejectionEmail ? (
                        <>
                          Open Text Editor
                          <DocumentTextIcon className="w-6 h-6" />
                        </>
                      ) : (
                        <>
                          Edit
                          <PencilSquareIcon className="w-6 h-6" />
                        </>
                      )}
                    </button>
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-4">
                  <label
                    htmlFor="emailBody"
                    className="block text-xs font-medium leading-6 text-gray-900 sm:pt-1.5 dark:text-gray-400"
                  >
                    Assessment Set
                  </label>
                  <div className="mt-2 sm:col-span-3 sm:mt-0  gap-2">
                    {/* <TemplatesDropdown templates={assessments} onTemplateSelect={onTemplateSelect} resetSelectedTemplate={false} /> */}

                    <AllTemplatesDropDown allAssessments={assessments} selectedAssessmentTemplate={selectedAssessmentTemplate} setSelectedAssessmentTemplate={setSelectedAssessmentTemplate} />
                  </div>
                </div>

                <div className="flex items-center justify-end gap-x-6 pt-4">
                  <button
                    className="inline-flex justify-center rounded-md bg-entntblue px-5 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-entntblue disabled:bg-gray-300 disabled:cursor-not-allowed hover:bg-entntorange"
                    disabled={
                      currentProcessValue.title.trim() === "" ||
                      orderIsInvalid(Number(order))
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      close();
                      handleOnSave();
                    }}
                  >
                    Save
                  </button>
                  <button
                    className="inline-flex items-center justify-center max-w-[10rem] rounded-md bg-white px-5 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    onClick={(e) => {
                      e.preventDefault();
                      close();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </Popover.Panel>
        </div>
      </Transition>
      <TextEditorPreviewModal
        open={isPreviewOpen}
        setOpen={setIsPreviewOpen}
        title="Preview"
        content={currentPreviewContent}
      />
    </>
  );
}
