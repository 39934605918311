import React, { useEffect, useState } from 'react';
import { Assessment} from '../../Models';
import {  ChevronDownIcon, ChevronUpIcon, EllipsisVerticalIcon} from '@heroicons/react/20/solid';
import { deleteAssessment } from '../../WebCalls';
import ConfirmModal from '../Shared/ConfirmModal';
import Notification from '../Shared/Notification';
import parse from 'html-react-parser'
import { useNavigate } from 'react-router-dom';


const AssessmentRow = ({ assessment, selected,
    handleSelect, getSelectedEditAssessment, getSelectedQuestions, expanded, onExpand }: {
        assessment: Assessment;
        selected: boolean;
        handleSelect: () => void;
        getSelectedEditAssessment: Function,
        getSelectedQuestions: Function,
        expanded: boolean,
        onExpand: Function,
        
    }) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    // const [expanded, setExpanded] = useState<boolean>(false);
    const [showNotification, setShowNotification] = useState(false);
    const { id, title, time, baseScore, description, exceptionalScore, lastUpdateDate, numOfQuestions, skills, questions } = assessment;
    const [selectedAssessment, setSelectedAssessment] = useState<string | undefined>();
    const [assessmentDeleteNotification, setAssessmentDeleteNotification] = useState(false);

    const [openAssessmentDialog, setOpenAssessmentDialog] = useState<string | null | undefined>(null);
    const [openAssessmentDialogQuestionId, setOpenAssessmentDialogQuestionId] = useState<string | null>(null);

    const [selectAllQuestions, setSelectAllQuestions] = useState(false);
    const [selectedQuestions, setSelectedQuestions] = useState<string[]>([]);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);



    const navigate = useNavigate();


    useEffect(() => {
        if (selectAllQuestions && questions) {
            setSelectedQuestions(questions.map((q) => (q.id)));
        } else {
            setSelectedQuestions([]);
        }
    }, [selectAllQuestions, questions, expanded]);

    useEffect(() => {
        getSelectedQuestions(selectedQuestions);
    }, [selectedQuestions])




    const handleAddAssessment = () => {
        navigate('/assessments/add')
    }


    const handleEdit = (assessmentId: string) => {
        navigate(`/assessments/edit/${assessmentId}`)
    }


    const handleDelete = (assessmentId: string) => {
        // setIsModalOpen(true);
        setSelectedAssessment(assessmentId);
    }

    const confirmDelete = () => {
        setIsDeleteModalOpen(false)
        try {
            deleteAssessment(selectedAssessment);
            setSelectedAssessment('');
            setAssessmentDeleteNotification(true);
            setTimeout(() => {
                window.location.reload();
            }, 2000)

        } catch (error) {
            console.log(error);
        }
    };

    const handleAssessmentQuestionRoute = (e: any, id: string) => {
        if (e.target.closest('.dropdown-button')) {
            return;
        }
        navigate(`/assessment/questions/${id}`)

    }


    //dialog
    const toggleAssessmentDialog = (e: any, assessmentId: string | null) => {
        setOpenAssessmentDialog((prevOpenDropdown) => (prevOpenDropdown === assessmentId ? null : assessmentId));

    };

    const toggleDialogAssessmentQuestion = (e: React.MouseEvent, questionId: string) => {
        e.stopPropagation();
        setOpenAssessmentDialogQuestionId(openAssessmentDialogQuestionId === questionId ? null : questionId);
    };


    //handleAssessnebt
    const handleEditAssessment = (e: React.MouseEvent, questionId: string) => {
        e.stopPropagation();
        console.log('Edit assessment question', questionId);
        // Add your edit logic here
    };

    const handleDeleteAssessment = (e: React.MouseEvent, questionId: string) => {
        e.stopPropagation();
        console.log('Delete assessment question', questionId);
        // Add your delete logic here
    };



    const handleSelectAllQuestions = () => {
        setSelectAllQuestions(!selectAllQuestions);
    }


    const handleSelectQuestions = (questionId: string) => {
        if (selectedQuestions.includes(questionId)) {
            setSelectedQuestions(selectedQuestions.filter((id) => id !== questionId));
        } else {
            setSelectedQuestions([...selectedQuestions, questionId]);
        }
    };
    const colors = [
        "bg-red-500", "bg-red-600", "bg-red-700",
        "bg-blue-500", "bg-blue-600", "bg-blue-700",
        "bg-orange-500", "bg-orange-600", "bg-orange-700",
        "bg-purple-500", "bg-purple-600", "bg-purple-700",
        "bg-green-500", "bg-green-600", "bg-green-700",
        "bg-yellow-500", "bg-yellow-600", "bg-yellow-700",
        "bg-indigo-500", "bg-indigo-600", "bg-indigo-700",
        "bg-teal-500", "bg-teal-600", "bg-teal-700",

    ];
    const getColorBySkill = (skillName: string) => {
        let hash = 0;
        for (let i = 0; i < skillName.length; i++) {
            hash = skillName.charCodeAt(i) + ((hash << 5) - hash);
        }
        const index = Math.abs(hash % colors.length);
        return colors[index];
    };

    return (
        <>
            <tr className=" cursor-pointer hover:bg-gray-50 dark:hover:bg-[#1c2229] border-t border-gray-200 dark:border-gray-600 rounded-sm">
                <td className="px-4 py-2">
                    <input
                        type="checkbox"
                        checked={selected}
                        onChange={handleSelect}
                        className="form-checkbox z-[11] bg-transparent  left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 "
                    />
                </td>
                <td className=" py-4 font-medium text-gray-900 dark:text-white flex flex-row gap-5">
                    <div >
                        {expanded ? (
                            <ChevronUpIcon height={20} onClick={() => onExpand(null)} />
                        ) : (
                            <ChevronDownIcon height={20} onClick={() => onExpand(assessment.id)} />
                        )}
                    </div>
                    <div className="text-left dark:text-gray-300" onClick={(e) => handleAssessmentQuestionRoute(e, id)}>
                        {title}
                    </div>
                </td>
                <td className="px-6 py-2 hidden sm:table-cell text-center">{time} <span className='text-[9px]'>mins</span></td>
                <td className="px-6 py-2 hidden sm:table-cell text-center">{questions ? questions.length : 0}</td>
                <td className="px-6 py-2 hidden sm:table-cell">
                    <div className="flex flex-row flex-wrap overflow-x-auto p-2 gap-1 justify-center" style={{ maxHeight: '100px' }}>
                        {skills && skills?.length > 0 ? skills?.map((s, index) => (
                            <div key={index} className="mr-2 ">
                                <span className={`${getColorBySkill(s.name)} text-white px-2 py-[2px] font-semibold rounded-md text-xs truncate max-w-40`}>
                                    {s.name}
                                </span>

                            </div>
                        ))
                            : <>
                                <span className={`dark:bg-gray-600 bg-gray-200 px-2 py-[2px] rounded-md text-xs truncate max-w-40 dark:text-white`}>
                                    N/A
                                </span>
                            </>}
                    </div>
                </td>
                <td className="">
                    <div className='relative inline-block text-left '>
                        <div>
                            <button
                                type='button'
                                className='inline-flex justify-center w-full px-2 py-2 text-gray-400 hover:text-gray-600 dark:hover:text-gray-200  dropdown-button'
                                id={`options-menu-${assessment.id}`}
                                aria-haspopup='true'
                                aria-expanded={openAssessmentDialog === assessment.id}
                                onClick={(e) => toggleAssessmentDialog(e, assessment.id)}
                            >
                                <EllipsisVerticalIcon height={15} />
                            </button>
                        </div>
                        <div className={`z-10 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-darkbglight ${openAssessmentDialog === assessment.id ? 'block' : 'hidden'}`}>
                            <div className='py-1' role='menu' aria-orientation='vertical' aria-labelledby={`options-menu-${assessment.id}`}>
                                <button
                                    onClick={() => handleEdit(assessment.id)}
                                    className='block px-4 py-2 text-sm text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-gray-900 dark:hover:text-gray-100 w-full text-left dropdown-button'
                                    role='menuitem'
                                >
                                    Edit
                                </button>
                                <button
                                    onClick={() => {
                                        setIsDeleteModalOpen(true)
                                        handleDelete(assessment.id)
                                    }}
                                    className='block px-4 py-2 text-sm text-red-600 dark:text-red-400 hover:bg-red-100 dark:hover:bg-red-700 hover:text-red-900 dark:hover:text-red-100 w-full text-left dropdown-button'
                                    role='menuitem'
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            {expanded && (
                <tr>
                    <td colSpan={6} className=" ">
                        <table className="w-full   text-left rtl:text-right text-gray-500 dark:text-gray-300 text-xs">
                            <thead className="   bg-white dark:bg-darkbglight z-20  before:absolute before:content-[''] before:w-full before:h-full before:-z-10">
                                <tr>
                                    <th scope="col" className="px-4 pt-2 ">
                                        <input
                                            type="checkbox"
                                            checked={selectAllQuestions}
                                            onChange={handleSelectAllQuestions}
                                            className="form-checkbox  left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                        />
                                    </th>
                                    <th scope="col" className="px-6 py-2">Text</th>
                                    <th scope="col" className="px-6 py-2">Type</th>
                                    <th scope="col" className="px-6 py-2">Difficulty</th>
                                    <th scope="col" className="px-6 py-2"></th>

                                </tr>
                            </thead>
                            <tbody>
                                {questions && questions.map((question, index) => (
                                    <tr key={index} className="cursor-pointer hover:bg-gray-50 dark:hover:bg-[#1c2229] border-t border-gray-200 dark:border-gray-600 rounded-sm">
                                        <td className="px-4 py-2">
                                            <input
                                                type="checkbox"
                                                checked={selectedQuestions.includes(question.id)}
                                                onChange={() => handleSelectQuestions(question.id)}
                                                className="form-checkbox z-[11] bg-transparent  left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 "
                                            />
                                        </td>
                                        <td className="px-6 py-3">{parse(question.text)}</td>
                                        <td className="px-6 py-3">{question.type}</td>
                                        <td className="px-6 py-3">{100 - question.accuracy}%</td>
                                        <td className="px-6 py-3">
                                            <div className="relative inline-block text-left">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="inline-flex justify-center w-full px-2 py-2 text-gray-400 hover:text-gray-600 dark:hover:text-gray-200"
                                                        onClick={(e) => toggleDialogAssessmentQuestion(e, question.id)}
                                                    >
                                                        <EllipsisVerticalIcon height={15} />
                                                    </button>
                                                </div>
                                                {openAssessmentDialogQuestionId === question.id && (
                                                    <div className="origin-top-right absolute right-0 z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-darkbglight">
                                                        <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                                            <button
                                                                onClick={(e) => handleEditAssessment(e, question.id)}
                                                                className="block px-4 py-2 text-sm text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-gray-900 dark:hover:text-gray-100 w-full text-left"
                                                                role="menuitem"
                                                            >
                                                                Edit
                                                            </button>
                                                            <button
                                                                onClick={(e) => handleDeleteAssessment(e, question.id)}
                                                                className="block px-4 py-2 text-sm text-red-600 dark:text-red-400 hover:bg-red-100 dark:hover:bg-red-700 hover:text-red-900 dark:hover:text-red-100 w-full text-left"
                                                                role="menuitem"
                                                            >
                                                                Delete From Assessment
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </td>
                </tr>
            )}
            <ConfirmModal
                open={isModalOpen}
                setOpen={setIsModalOpen}
                onConfirm={confirmDelete}
                type="danger"
                title="Delete Question"
                description="Are you sure you want to delete this question? This action cannot be undone."
            />
            <Notification
                show={showNotification}
                setShow={setShowNotification}
                title="Success"
                description="The question was successfully deleted"
                type="success"
            />
            <ConfirmModal
                open={isDeleteModalOpen}
                setOpen={setIsDeleteModalOpen}
                onConfirm={confirmDelete}
                type="danger"
                title="Delete Assessment"
                description=" Are you sure you want to delete this Assessment? All of the
        data will be permanently removed. This action cannot
        be undone."
            />
        </>
    );
};


export default AssessmentRow;
