import React, {  useEffect, useRef, useState } from 'react';
import {  Role, Skill, TemplateQuestionnaires, TemplateSkillMapping, TemplateTestData, TemplateWithQuestionnaires } from '../../Models';
import {  ArrowUpRightIcon, CheckBadgeIcon, ChevronDownIcon, ChevronUpIcon, EllipsisVerticalIcon, ExclamationTriangleIcon, ListBulletIcon, TrashIcon } from '@heroicons/react/20/solid';
import { createAssessmentCopy, deleteAssessment, deleteTemplate, GenerateAndRecalibrateTemplate, generateAssessmentTemplateTests, getTemplateSkillMappings, recalibrateTemplate } from '../../WebCalls';
import ConfirmModal from '../Shared/ConfirmModal';
import Notification from '../Shared/Notification';
import { useNavigate } from 'react-router-dom';
import GenerateTemplateTestModal, { Question } from './Modals/GenerateTemplateTestModal';
import { ArrowPathRoundedSquareIcon } from '@heroicons/react/24/outline';
import RecalibrateTempalteModal from './Modals/ConfirmModal';

const TemplateRow = ({ template, roles, fetchTemplatesAndRoles, getIsEditable, selected,
    handleSelect, getCurrentExpandId }: {
        getIsEditable: Function, getCurrentExpandId: Function, fetchTemplatesAndRoles: Function, template: TemplateWithQuestionnaires, roles: Role[],
        selected: boolean;
        handleSelect: () => void;
    }) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [showNotification, setShowNotification] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const { id, name, numOfQuestions, positivePoints, time, negativePoints, assessmentQuestionnaires } = template;
    const questions = template.assessmentQuestionnaires as TemplateQuestionnaires[];

    const [isDialogVisible, setIsDialogVisible] = useState(false);
    //
    const [menuOpenId, setMenuOpenId] = useState<string | null>(null);
    const [isGenerateTestModalOpen, setIsGenerateTestModalOpen] = useState<boolean>(false);
    const [templateTestData, setTemplateTestData] = useState<TemplateTestData | undefined>(undefined);
    const [currentClickedAssessment, setCurrentClickedAssessment] = useState<string>();
    const [currentClickedTemplate, setCurrentClickedTemplate] = useState<string>();

    const [currentDeleteId, setcurrentDeleteId] = useState<string>('');
    const [selectedRoleAndAssessment, setSelectedRoleAndAssessment] = useState<{ roleId: string | undefined, assessmentId: string }>({
        roleId: '',
        assessmentId: ''
    });
    const [generateMoreTestsId, setGenerateMoreTestsId] = useState('');
    const [isEditable, setIsEditable] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [currentSwap, setCurrentSwap] = useState<string>('');
    const [modalInfo, setModalInfo] = useState<{
        title: string, description: string, confirmButtonTitle: string, type: "info" | "danger" | "none", selected: string
    }>({
        title: '',
        description: '',
        confirmButtonTitle: '',
        type: 'none',
        selected: ''
    });
    const [notificationText, setNotificationText] = useState<{ text: string, type: "success" | "error" | "info" }>({
        text: '',
        type: 'success'
    });
    const [toggleNofication, setToggleNofication] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [currentSelectedTemplate, setCurrentSelectedTemplate] = useState<TemplateWithQuestionnaires>();
    const [recalibratedAssessments, setRecalibratedAssessments] = useState<Question[][]>([]);
    const [prevSkillMappings, setPrevSkillMappings] = useState<TemplateSkillMapping[]>([]);
    const [assignTest, setAssingTest] = useState<{ derivatives: boolean, test: boolean }>(
        {
            derivatives: false,
            test: false
        }
    );
    const navigate = useNavigate();
    const parentDivRef = useRef<HTMLDivElement | null>(null);

    const handleOutsideClick = (event: MouseEvent) => {
        if (parentDivRef.current && !parentDivRef.current.contains(event.target as Node)) {
          setMenuOpenId(null); 
        }
      };
    
      useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);
    
        return () => {
          document.removeEventListener("mousedown", handleOutsideClick);
        };
      }, []);
    const handleEdit = (template: TemplateWithQuestionnaires) => {
        getIsEditable(template);
        setIsEditable(true);

    };
    const toggleAccordion = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const target = e.target as HTMLElement;

        if (
            (target as HTMLInputElement).type === 'checkbox' ||
            target.closest('td')?.querySelector('input[type="checkbox"]')
        ) {
            return;
        }

        setIsExpanded(!isExpanded);
    };
    const handleRoleSelect = (roleId: string | undefined, assessmentId: string) => {
        setSelectedRoleAndAssessment((prev) => ({ ...prev, roleId: roleId, assessmentId: assessmentId }));
    };
    const isQuestionnaireMappedToRole = (templateId: string): Role | null => {
        // const role = roles.find((role) => role.assessmentTemplate?.id === templateId);
        return null;
    };

    const handleUpdateRoleTemplate = async () => {
        // await updateRoleAssessment(selectedRoleAndAssessment);
        setCurrentClickedTemplate('')
        setCurrentSwap('')
    };
    const cancelAdd = () => {
        setIsGenerateTestModalOpen(false);
    };
    const isTemplateModalClosed = () => {
        setIsGenerateTestModalOpen(false);
        fetchTemplatesAndRoles();
        setCurrentSelectedTemplate(undefined)
        setIsEditable(false);
        setPrevSkillMappings([]);
    }
    useEffect(() => {
        if (!isGenerateTestModalOpen) {
            setPrevSkillMappings([]);
        }
    }, [isGenerateTestModalOpen])
    const getTemplateTestData = (value: number | string, name: string) => {
        setTemplateTestData((prevData) => ({
            ...prevData,
            [name]: value
        } as TemplateTestData));
    }
    const handleAddAssessmentTemplateTest = async () => {
        fetchTemplatesAndRoles();
        setGenerateMoreTestsId('');
        setCurrentSelectedTemplate(undefined)
    }

    const onCofirmModal = async () => {
        setOpenModal(false);
        if (modalInfo.selected === 'clearAssessment') {
            setNotificationText({ text: 'Assessment is removed from role successfully', type: "success" });
            setToggleNofication(true);
            await handleUpdateRoleTemplate();
        }

        if (modalInfo.selected === "deleteTemplate") {
            await deleteTemplate(currentDeleteId);
            setNotificationText({ text: 'Template is deleted successfully', type: "success" });
        }

        if (modalInfo.selected === "deleteAssessment") {
            await deleteAssessment(currentDeleteId).then(() =>{
                setNotificationText({ text: 'Assessment is deleted successfully', type: "success" });
                setToggleNofication(true);
            }).catch((err) => {
                setNotificationText({ text: "Assessment is already assigned to a candidate. Unable to delete", type: "error" });
                setToggleNofication(true);
            });
        }
        setTimeout(() => {
            fetchTemplatesAndRoles();
        }, 1000);
        setcurrentDeleteId('');
        setCurrentClickedTemplate('');
        setToggleNofication(true);
        setCurrentSelectedTemplate(undefined)


    };

    const onCancelModal = () => {
        setOpenModal(false);
        setModalInfo({
            title: '',
            description: '',
            confirmButtonTitle: '',
            type: 'none',
            selected: ''
        });
        setNotificationText({
            text: '',
            type: 'success'
        })
        setCurrentClickedTemplate('');
        setcurrentDeleteId('');
    }

    const handleMenuToggle = (id: string) => {
        setMenuOpenId(menuOpenId === id ? null : id);
    };

    const handleDelete = (id: string) => {
        setcurrentDeleteId(id)
        setModalInfo({ confirmButtonTitle: 'Confirm', title: 'Delete Template', description: "Are you sure you want to delete this Template? And all it's associated assessments?", type: 'danger', selected: "deleteTemplate" })
        setOpenModal(true);
    };
    const [openAssessmentDropdown, setOpenAssessmentDropdown] = useState(false);
    const [openAssessmentModal, setOpenAssessmentModal] = useState(false);
    const [currentAssessmentId, setCurrentAssessmentId] = useState('');

    const isAssessmentModalClosed = () => {
        setOpenAssessmentModal(false);
        setOpenAssessmentDropdown(false);
    }
    const calculateTemplateDifficulty = () => {
        let d = 0;
        template.assessmentQuestionnaires.forEach((q) => {
            if (q.difficulty) {
                d += q.difficulty;
            }
        });
        return (d / template.assessmentQuestionnaires.length).toFixed(1);
    }
    const fetchPrevSkillMappings = async (templateId: string): Promise<TemplateSkillMapping[]> => {
        if (templateId) {
            const res = await getTemplateSkillMappings(templateId);
            const updatedMappings: TemplateSkillMapping[] = res.map((r: TemplateSkillMapping) => ({
                difficultyMax: r.difficultyMax,
                difficultyMin: r.difficultyMin,
                variationMax: r.variationMax,
                variationMin: r.variationMin,
                noOfQuestionsMin: 0,
                noOfQuestionsMax: 100,
                questionPercentage: r.questionPercentage,
                newQuestionPercentageMin: r.newQuestionPercentageMin,
                newQuestionPercentageMax: r.newQuestionPercentageMax,
                aboveThresholdPercentage: 0,
                belowThresholdPercentage: 0,
                skillId: r.skillId,
                locked: true,
            }));
            setPrevSkillMappings(updatedMappings);
            return updatedMappings;
        }
        return [];
    };
    const distributeQuestionsEfficiently = async (
        skills: TemplateSkillMapping[],
        numberOfQuestionsPerTest: number
    ) => {
        let minTotal = skills.reduce((sum, skill) => sum + Math.round(skill.noOfQuestionsMin), 0);
        let maxTotal = skills.reduce((sum, skill) => sum + Math.round(skill.noOfQuestionsMax), 0);

        if (numberOfQuestionsPerTest) {
            if ((numberOfQuestionsPerTest < minTotal || numberOfQuestionsPerTest > maxTotal)) {
                setNotificationText({
                    text: `The sum of all minimum range for questions must be equal to ${currentSelectedTemplate ? currentSelectedTemplate.numOfQuestions : template?.numOfQuestions}`,
                    type: 'error'
                });
                setToggleNofication(true);
                return;
            }

            let questionDistribution = skills.map(skill => ({
                skillId: skill.skillId,
                noOfQuestions: skill.noOfQuestionsMin,
                belowThresholdQuestions: 0,
                aboveThresholdQuestions: 0
            }));

            let remainingQuestions = numberOfQuestionsPerTest - minTotal;

            while (remainingQuestions > 0) {
                for (let i = 0; i < questionDistribution.length; i++) {
                    if (questionDistribution[i].noOfQuestions < skills[i].noOfQuestionsMax) {
                        questionDistribution[i].noOfQuestions++;
                        remainingQuestions--;
                    }
                    if (remainingQuestions === 0) break;
                }
            }

            const updatedSkills = skills.map((skill, index) => {
                const skillDistribution = questionDistribution[index];
                const totalQuestions = skillDistribution.noOfQuestions;

                const newQuestionPercentageMin = skill.newQuestionPercentageMin;
                const newQuestionPercentageMax = skill.newQuestionPercentageMax;

                const minNewQuestions = Math.ceil(totalQuestions * (newQuestionPercentageMin / 100));
                const maxNewQuestions = Math.floor(totalQuestions * (newQuestionPercentageMax / 100));

                const belowThresholdQuestions = Math.max(minNewQuestions,
                    Math.min(maxNewQuestions, Math.round(totalQuestions * (newQuestionPercentageMin / 100))));

                const aboveThresholdQuestions = totalQuestions - belowThresholdQuestions;

                return {
                    ...skill,
                    questionPercentage: (totalQuestions / numberOfQuestionsPerTest) * 100,
                    belowThresholdPercentage: (belowThresholdQuestions / totalQuestions) * 100,
                    aboveThresholdPercentage: (aboveThresholdQuestions / totalQuestions) * 100,
                    belowThresholdQuestions,
                    aboveThresholdQuestions
                };
            });
            return updatedSkills;
        }
    };

    const getColorByPercentage = (percentage: number) => {
        if (percentage >= 80) {
            return "bg-green-500";
        } else if (percentage >= 60) {
            return "bg-blue-500";
        } else if (percentage >= 40) {
            return "bg-yellow-500";
        } else if (percentage >= 20) {
            return "bg-orange-500";
        } else {
            return "bg-red-500";
        }
    };

    const getQuestionVariation = (i: number): number => {
        if (
            !assessmentQuestionnaires ||
            assessmentQuestionnaires.length <= i ||
            assessmentQuestionnaires.length === 0
        ) {
            return 0;
        }
        const currentAssessmentQuestions = assessmentQuestionnaires[i].questions;
        const firstAssessmentQuestions = assessmentQuestionnaires[0].questions;
        const totalQuestions = template.numOfQuestions;

        const firstAssessmentQuestionIds = new Set(
            firstAssessmentQuestions && firstAssessmentQuestions.map(q => q.id)
        );

        let uniqueQuestionsCount = 0;
        if (currentAssessmentQuestions) {
            currentAssessmentQuestions.forEach(question => {
                if (!firstAssessmentQuestionIds.has(question.id)) {
                    uniqueQuestionsCount++;
                }
            });
        }
        const variation = (uniqueQuestionsCount / totalQuestions) * 100;

        return variation;
    };
    const colors = [
        "bg-red-500", "bg-red-600", "bg-red-700",
        "bg-blue-500", "bg-blue-600", "bg-blue-700",
        "bg-orange-500", "bg-orange-600", "bg-orange-700",
        "bg-purple-500", "bg-purple-600", "bg-purple-700",
        "bg-green-500", "bg-green-600", "bg-green-700",
        "bg-yellow-500", "bg-yellow-600", "bg-yellow-700",
        "bg-indigo-500", "bg-indigo-600", "bg-indigo-700",
        "bg-teal-500", "bg-teal-600", "bg-teal-700",

    ];


    const getColorBySkill = (skillName: string) => {
        let hash = 0;
        for (let i = 0; i < skillName.length; i++) {
            hash = skillName.charCodeAt(i) + ((hash << 5) - hash);
        }
        const index = Math.abs(hash % colors.length);
        return colors[index];
    };
    const isTemplateOutOfBound = (): boolean => {
        return assessmentQuestionnaires.some(
            questionnaire => questionnaire.isOutOfBounds
        );
    }

    const [isHoveredRoleMapping, setIsHoveredRoleMapping] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [isChangeParams, setIsChangeParams] = useState(false);

    const recalibrateTest = async (template: TemplateWithQuestionnaires, skillMappings: TemplateSkillMapping[]) => {
        const assessmentQuestions = template.assessmentQuestionnaires
            .map(questionnaire => ({
                questions: questionnaire.questions?.map(question => ({
                    id: question.id,
                    text: question.text,
                    type: question.type,
                    tags: question.tags,
                    time: question.time,
                    accuracy: question.accuracy,
                })) || [],
                IsOutOfBounds: questionnaire.isOutOfBounds || false
            }))
            .filter(assessment => assessment.questions.length > 0);
        const updatedSkillMappings = await distributeQuestionsEfficiently(skillMappings, template.numOfQuestions);
        const recalibrateTypeA = await GenerateAndRecalibrateTemplate(template.id, updatedSkillMappings, assessmentQuestions);
        if (recalibrateTypeA && recalibrateTypeA.length === assessmentQuestions.length) {
            setRecalibratedAssessments(recalibrateTypeA);
            setTimeout(() => {
                onModalSaveTestConfirm(recalibrateTypeA);
            }, 1000)
        }
        else {
            await generateAssessmentTemplateTests(template.numOfQuestions, assessmentQuestions.length, 0, updatedSkillMappings).then((res) => {
                if (res && res.length === assessmentQuestions.length) {
                    setRecalibratedAssessments(res);
                    setTimeout(() => {
                        onModalSaveTestConfirm(res);
                    }, 1000)
                }
                else {
                    setRecalibratedAssessments(res);
                    setIsConfirmModalOpen(true)
                }
            });
        }
    };

    const onModalSaveTestConfirm = async (recalibrateTypeA?: any) => {
        const skillMappings = await fetchPrevSkillMappings(template.id);
        var templateData = {
            name: String(template?.name),
            description: String(template?.description),
            time: String(template?.assessmentQuestionnaires[0].time),
            numOfQuestions: String(template?.numOfQuestions),
            positivePoints: String(template?.positivePoints),
            negativePoints: String(template?.negativePoints),
        }
        if (template?.id)
            await recalibrateTemplate(template?.id, templateData, recalibrateTypeA ?? recalibratedAssessments, skillMappings, String(template?.assessmentQuestionnaires[0].baseScore)).then(() => {
            setNotificationText({ text: 'Template Recalibrated successfully', type: "success" });
            setToggleNofication(true);
                setTimeout(async () => {
                    fetchTemplatesAndRoles();
                }, 500);
                setMenuOpenId(null);
                setIsConfirmModalOpen(false);
            })
    }
    const onModalClose = () => {
        setIsConfirmModalOpen(false);
    }

    const onChangeParameters = () => {
        setIsChangeParams(true);
    }
    return (
        <>
            <tr className="cursor-pointer hover:bg-gray-50 dark:hover:bg-[#1c2229] border-t dark:border-gray-600 rounded-sm" onClick={toggleAccordion}>
                <td className="px-2 ">
                    <input
                        type="checkbox"
                        checked={selected}
                        onChange={(e) => { e.stopPropagation(); handleSelect() }}
                        className="form-checkbox z-[11] bg-transparent  left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 "
                    />
                </td>
                <td className="w-[22%] font-medium text-gray-900 dark:text-white ">
                    <div className="flex items-center"  >
                        {isExpanded ? (
                            <ChevronUpIcon className="h-4 w-4" onClick={getCurrentExpandId('')} />
                        ) : (
                            <ChevronDownIcon className="h-4 w-4 " onClick={getCurrentExpandId(template.id)} />
                        )}
                        <div
                            className="text-left dark:text-gray-300 relative"
                            onMouseEnter={() => setIsDialogVisible(true)}
                            onMouseLeave={() => setIsDialogVisible(false)}
                        >
                            <div className="flex flex-row flex-wrap overflow-x-auto p-2 gap-1 " style={{ maxHeight: '100px' }}>
                                {template.skills && template.skills.length > 0 ? (
                                    template.skills
                                        .sort((a, b) => b.percentage - a.percentage)
                                        .map((s, index) => (
                                            <div key={index} className="mr-2">
                                                <span
                                                    className={`${getColorBySkill(s.name)} text-white px-2 py-[2px] font-semibold rounded-md text-xs truncate max-w-40`}
                                                    title={`${s.percentage.toLocaleString()}% Questions`}
                                                >
                                                    {s.name} {s.percentage ? s.percentage.toFixed(2) : s.percentage}%
                                                </span>
                                            </div>
                                        ))
                                ) : "N/A"}

                            </div>

                            {isDialogVisible && (
                                <div className="absolute left-5 top-full mt-2 dark:bg-darkbglight dark:text-white bg-white text-black text-xs w-64 p-3 rounded-md shadow-lg z-10"
                                >
                                    <div className='flex flex-row justify-between gap-2'>
                                        <div className={`min-h-24 min-w-24 self-center justify-self-center flex items-center justify-center bg-gray-100 text-black rounded-full border-4 ${isNaN(parseFloat(calculateTemplateDifficulty())) ? "border-gray-400" :
                                            parseFloat(calculateTemplateDifficulty()) <= 40 ? "border-green-600" : parseFloat(calculateTemplateDifficulty()) <= 75 ? "border-orange-300" : "border-red-500"} `}>
                                            <p className={`text-lg `}>
                                                {!isNaN(parseFloat(calculateTemplateDifficulty())) ? `${calculateTemplateDifficulty()}%` : "N/A"}
                                            </p>
                                        </div>
                                        <div className='flex flex-col items-start gap-2 text-[10px]'>
                                            <div className='text-xs bold underline'>{name}</div>
                                            <div className=''>Num Of Questions : {numOfQuestions}</div>
                                            <div>Positive Points : {positivePoints}</div>
                                            <div>Negative Points : {negativePoints}</div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                </td>
                <td className="w-[4%] py-1 text-center dark:text-white sm:table-cell">
                    <div className="relative flex items-center gap-3">
                        <ListBulletIcon
                            height={15}
                            onMouseEnter={() => setIsHoveredRoleMapping(true)}
                            onMouseLeave={() => setIsHoveredRoleMapping(false)}
                            className="cursor-pointer"
                        />

                        {isHoveredRoleMapping && (
                            <div className="absolute top-8 left-0 z-10 w-52 p-4 bg-darkbglight text-white text-sm rounded-lg shadow-lg ">
                                {template.previouslyMappedRoles.length === 0 ? <div className='text-xs text-left'>
                                    No roles have been mapped to this template
                                </div> : template.previouslyMappedRoles.map((s, index) => {
                                    return <div className='text-left'>
                                        <ul className="list-disc list-inside text-xs">
                                            <li key={index}>{s.title}</li>

                                        </ul>
                                    </div>
                                })}
                            </div>
                        )}

                        {isTemplateOutOfBound() ? (
                            <ExclamationTriangleIcon color="orange" height={20} />
                        ) : (
                            <CheckBadgeIcon height={20} color="green" />
                        )}
                    </div>
                </td>
                <td className="w-[6%] text-center p-0 m-0 dark:text-white">
                    <div
                        title={`${parseFloat(calculateTemplateDifficulty())?.toFixed(1)}% Difficulty`}
                        className={`inline-block p-1 w-fit text-xs dark:text-white cursor-pointer border-2 rounded-lg text-black min-w-12 ${parseFloat(calculateTemplateDifficulty()) && parseFloat(calculateTemplateDifficulty()) <= 30 ? 'border-green-600' : parseFloat(calculateTemplateDifficulty()) && parseFloat(calculateTemplateDifficulty()) <= 60 ? 'border-orange-300' : parseFloat(calculateTemplateDifficulty()) && parseFloat(calculateTemplateDifficulty()) <= 75 ? 'border-orange-300' : !parseFloat(calculateTemplateDifficulty()) ? "border-gray-500" : 'border-red-500'}`}
                        style={{ margin: '0 auto' }} // Ensure centering
                    >
                        {parseFloat(calculateTemplateDifficulty()) ? parseFloat(calculateTemplateDifficulty()) > 100 ? 100 : `${parseFloat(calculateTemplateDifficulty())?.toFixed(1)}%` : "N/A"}
                    </div>
                </td>
                <td className="w-[4%] py-1 text-center dark:text-white sm:table-cell">{template.testNum ? template.testNum : 0} </td>
                <td className="w-[5%] py-1 text-center dark:text-white sm:table-cell">{template.varAvg ? template.varAvg.toFixed(0) + "%" : "N/A"} </td>
                <td className="w-[5%] py-1 text-center dark:text-white sm:table-cell">{template.varDiff ? template.varDiff.toFixed(0) + "%" : "N/A"} </td>
                <td className="w-[5%] py-1 text-center dark:text-white sm:table-cell">{template.numOfQuestions}</td>
                <td className="w-[5%] py-1 sm:table-cell flex flex-row justify-center text-center dark:text-white">{template.time} <span className='text-[8px]'>(mins)</span> </td>
                <td className="w-[5%] py-1 sm:table-cell flex flex-row justify-center text-center dark:text-white">{template.positivePoints} </td>
                <td className="w-[5%] py-1 sm:table-cell flex flex-row justify-center text-center dark:text-white">{template.negativePoints} </td>
                <td className="w-[18%] py-1">
                    <div className="flex flex-row flex-wrap overflow-x-auto p-2 gap-1" style={{ maxHeight: '100px' }}>
                        {template.skills
                            ?.map((skill) => {
                                const totalPercentage = template.assessmentQuestionnaires.reduce((acc, questionnaire) => {
                                    const skillResult = questionnaire.skillResults.find(s => s.skillName === skill.name);
                                    return acc + (skillResult ? skillResult.percentage : 0);
                                }, 0);

                                const averagePercentage = template.assessmentQuestionnaires.length > 0
                                    ? totalPercentage / template.assessmentQuestionnaires.length
                                    : 0;

                                return {
                                    name: skill.name,
                                    averagePercentage,
                                };
                            })
                            .sort((a, b) => b.averagePercentage - a.averagePercentage)
                            .map((skill, i) => (
                                <div key={skill.name} className="mr-2">
                                    <span
                                        className={`${getColorByPercentage(skill.averagePercentage)} text-white px-2 py-[2px] rounded-md text-xs truncate max-w-40 font-semibold`}
                                        title={`${skill.averagePercentage.toFixed(2).toLocaleString()}% Average`}
                                    >
                                        {skill.name} {skill.averagePercentage.toFixed(2)}%
                                    </span>
                                </div>
                            ))}
                    </div>
                </td>


                <td className="w-[16%] px-4 py-1">
                    <div className="flex flex-row justify-between items-center">
                        {template.assessmentQuestionnaires.length > 0 ?
                            <>
                                <button
                                    className="rounded-md px-2 text-xs py-1 font-semibold shadow-sm focus:outline-none bg-indigo-600 hover:bg-indigo-500 text-white cursor-pointer"
                                    onClick={(e) => {
                                        fetchPrevSkillMappings(template.id);
                                        e.stopPropagation();
                                        setIsGenerateTestModalOpen(true);
                                        getTemplateTestData(template.id, "id");
                                        setCurrentSelectedTemplate(template)
                                        setAssingTest({
                                            derivatives: true,
                                            test: false
                                        })
                                    }}
                                >
                                    <div>
                                        Generate Derivatives
                                    </div>

                                </button>
                                <button
                                    className="rounded-md px-2 text-xs py-1 font-semibold shadow-sm focus:outline-none bg-indigo-600 hover:bg-indigo-500 text-white cursor-pointer"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setIsGenerateTestModalOpen(true);
                                        getTemplateTestData(template.id, "id");
                                        setCurrentSelectedTemplate(template)
                                        setAssingTest({
                                            derivatives: false,
                                            test: true
                                        })
                                    }}
                                >
                                    <div>
                                        Generate Tests
                                    </div>

                                </button>
                            </> :
                            <button
                                className="rounded-md px-2 text-xs py-1 font-semibold shadow-sm focus:outline-none bg-indigo-600 hover:bg-indigo-500 text-white cursor-pointer"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setIsGenerateTestModalOpen(true);
                                    getTemplateTestData(template.id, "id");
                                    setCurrentSelectedTemplate(template)
                                }}
                            >
                                <div>
                                    Generate Tests
                                </div>
                            </button>}
                        <div className="relative">
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleMenuToggle(template.id);
                                }}
                                className="focus:outline-none"
                            >
                                <EllipsisVerticalIcon height={15} />
                            </button>
                            {menuOpenId === template.id && (
                                <div className="z-10 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-darkbglight" ref={parentDivRef}>
                                    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby={`options-menu-${template.id}`}>
                                        <button
                                            onClick={async (e) => {
                                                e.stopPropagation();
                                                // setIsRecalibrateModalOpen(true);
                                                // handleEdit(template);
                                                setCurrentSelectedTemplate(template);
                                                // setMenuOpenId('');
                                                const updatedMappings = await fetchPrevSkillMappings(template.id);
                                                recalibrateTest(template, updatedMappings);

                                            }}
                                            className=" px-4 py-1 text-sm text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-gray-900 dark:hover:text-gray-100 w-full text-left dropdown-button flex flex-row items-center gap-2"
                                            role="menuitem"
                                        >
                                            Recalibrate <ArrowPathRoundedSquareIcon color='orange' height={20} />
                                        </button>
                                        <button
                                            onClick={(e) => {
                                                setCurrentSelectedTemplate(template);
                                                e.stopPropagation();
                                                handleEdit(template);
                                                setIsEditable(true);
                                                setMenuOpenId('');
                                            }}
                                            className="block px-4 py-1 text-sm text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-gray-900 dark:hover:text-gray-100 w-full text-left dropdown-button"
                                            role="menuitem"
                                        >
                                            Edit
                                        </button>
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleDelete(template.id);
                                                setMenuOpenId('');
                                            }}
                                            className="block px-4 py-1 text-sm text-red-600 dark:text-red-400 hover:bg-red-100 dark:hover:bg-red-700 hover:text-red-900 dark:hover:text-red-100 w-full text-left dropdown-button"
                                            role="menuitem"
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </td>
            </tr>
            {isExpanded && (
                <tr>
                    <td colSpan={13}>
                        <div className="my-2">
                            <table className="w-full text-sm">
                                <tbody className=''>
                                    {assessmentQuestionnaires.length === 0 ? <>
                                        <p className="italic text-sm p-6">There are no assessments created for this template. <br /> Click on <b>Generate Tests</b> to create assessments.</p>
                                    </> : assessmentQuestionnaires.map((questionnaire, i: number) =>
                                    (
                                        <tr key={questionnaire.id} className="flex flex-row justify-between items-center p-2 border-2 dark:border-gray-700 rounded-md my-1 m-4">
                                            <td className="w-[20%] text-xs flex flex-row items-center gap-3">{questionnaire.title}
                                                {questionnaire.isOutOfBounds ?
                                                    <>
                                                        <ExclamationTriangleIcon color='orange' height={20} title={questionnaire.outOfBoundsCount?.toString()} />
                                                        {questionnaire.outOfBoundsSkills && questionnaire.outOfBoundsSkills.map((s: Skill, index) => {
                                                            return <div key={index} className="">
                                                                <span
                                                                    className={`bg-[#ff9400] text-white px-2 py-[2px] rounded-md text-xs truncate font-semibold max-w-40`}>
                                                                    {s.name}
                                                                </span>
                                                            </div>
                                                        })}
                                                    </>
                                                    : <></>}
                                            </td>

                                            <td title={`${questionnaire?.difficulty?.toFixed(1)}% Difficulty`} className={`p-1 text-xs dark:text-white cursor-pointer border-2 rounded-lg text-black ${questionnaire?.difficulty && questionnaire?.difficulty <= 30 ? 'border-green-600' : questionnaire.difficulty && questionnaire?.difficulty <= 60 ? 'border-orange-300' : questionnaire?.difficulty && questionnaire?.difficulty <= 75 ? 'border-orange-300' : 'border-red-500'}`}>{questionnaire?.difficulty && questionnaire?.difficulty > 100 ? 100 : questionnaire?.difficulty?.toFixed(1)} %</td>
                                            <td className='bg-gray-500 text-white px-2 py-[2px] rounded-md text-xs truncate max-w-40'>
                                                Variation : {getQuestionVariation(i).toFixed(0)}%
                                            </td>
                                            <td className="w-[10%] text-xs">
                                                <div className="group flex flex-row text-xs">
                                                    <div className="flex flex-row hover:bg-gray-200 p-2 rounded-md cursor-pointer dark:hover:bg-gray-600 dark:text-white" onClick={(e) => {
                                                        e.stopPropagation();
                                                        navigate(`/assessment/questions/${questionnaire.id}`)
                                                    }}>
                                                        <span>View/Edit Questions</span>
                                                        <ArrowUpRightIcon color="gray" height={20} />
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="w-[20%] text-xs">
                                                <div className="flex flex-row flex-wrap overflow-x-auto p-2 gap-2 " style={{ maxHeight: '100px' }}>
                                                    {questionnaire.skillResults && questionnaire.skillResults.length > 0 ? (
                                                        questionnaire.skillResults
                                                            .sort((a, b) => b.percentage - a.percentage)
                                                            .map((s, index) => (
                                                                <div key={index} className="mr-2">
                                                                    <span
                                                                        className={`${getColorByPercentage(s.percentage)} text-white px-2 py-[2px] rounded-md text-xs truncate  font-semibold max-w-40`}
                                                                        title={`${s.percentage.toLocaleString()}% Questions`}
                                                                    >
                                                                        {s.skillName} {s.percentage.toFixed(2)}%
                                                                    </span>
                                                                </div>
                                                            ))
                                                    ) : "N/A"}
                                                </div>
                                            </td>

                                            <div className="w-fit p-2 relative flex flex-row">
                                            <button
                                                            className="block px-4 py-1  w-full text-left dropdown-button text-xs"
                                                            onClick={() => {
                                                                setModalInfo({
                                                                    confirmButtonTitle: 'Confirm', title: 'Delete Assessment', description: isQuestionnaireMappedToRole(questionnaire.id) ? `This Assessment is already mapped to a ${isQuestionnaireMappedToRole(questionnaire.id)?.title} role. Are you sure you want to delete this Assessment?`
                                                                        : 'Are you sure you want to delete this Assessment?', type: 'danger'
                                                                    , selected: "deleteAssessment"
                                                                })
                                                                setcurrentDeleteId(questionnaire.id);
                                                                setOpenModal(true)
                                                            }}
                                                        >
                                                            <TrashIcon height={14} color="red" cursor={"pointer"} />
                                                        </button>
                                                {/* <TrashIcon height={14} color="red" cursor={"pointer"} onClick={() => {
                                                    setModalInfo({
                                                        confirmButtonTitle: 'Confirm', title: 'Delete Assessment', description: isQuestionnaireMappedToRole(questionnaire.id) ? `This Assessment is already mapped to a ${isQuestionnaireMappedToRole(questionnaire.id)?.title} role. Are you sure you want to delete this Assessment?`
                                                            : 'Are you sure you want to delete this Assessment?', type: 'danger'
                                                        , selected: "deleteAssessment"
                                                    })
                                                    setcurrentDeleteId(questionnaire.id);
                                                    setOpenModal(true)
                                                }} /> */}
                                                {/* <EllipsisVerticalIcon
                                                    height={15}
                                                    color="gray"
                                                    cursor="pointer"
                                                    onClick={() => {
                                                        setOpenAssessmentDropdown(!openAssessmentDropdown)
                                                        setCurrentAssessmentId(questionnaire.id)
                                                    }
                                                    } // Toggle dropdown visibility
                                                /> */}
                                               
                                            </div>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </td>
                </tr>
            )}

            <Notification
                show={showNotification}
                setShow={setShowNotification}
                title="Success"
                description="The template was successfully deleted"
                type="success"
            />
            {isGenerateTestModalOpen && (
                <GenerateTemplateTestModal
                    title=""
                    content=""
                    onCancel={cancelAdd}
                    isModalClosed={isTemplateModalClosed}
                    size="small"
                    getTemplateTestData={getTemplateTestData}
                    handleAddAssessmentTemplateTest={handleAddAssessmentTemplateTest}
                    currentSelectedTemplate={currentSelectedTemplate}
                    prevSkillMappings={prevSkillMappings}
                    isCreating={false}
                    fetchTemplatesAndRoles={fetchTemplatesAndRoles}
                    assignTest={assignTest}
                    isRecalibrating={false}
                    isEditable={isEditable}
                />
            )
            }
            {isConfirmModalOpen && (
                <RecalibrateTempalteModal
                    title="Recalibrate Test"
                    description={`${recalibratedAssessments.length}/${template.assessmentQuestionnaires.length} tests are able to create right now! Do you want to save or change the parameters`}
                    open={isConfirmModalOpen}
                    setOpen={setIsConfirmModalOpen}
                    onConfirm={onModalSaveTestConfirm}
                    onCancel={onModalClose}
                    onChange={onChangeParameters}
                    type={`${recalibratedAssessments.length === template.assessmentQuestionnaires.length ? "info" : "danger"}`}
                    canConfirm={recalibratedAssessments.length > 0}

                />
            )
            }
            {isChangeParams && <GenerateTemplateTestModal
                title=""
                content=""
                onCancel={cancelAdd}
                isModalClosed={isTemplateModalClosed}
                size="small"
                getTemplateTestData={getTemplateTestData}
                handleAddAssessmentTemplateTest={handleAddAssessmentTemplateTest}
                currentSelectedTemplate={currentSelectedTemplate}
                prevSkillMappings={prevSkillMappings}
                isCreating={false}
                fetchTemplatesAndRoles={fetchTemplatesAndRoles}
                assignTest={assignTest}
                isRecalibrating={true}
                isEditable={false}
            />}
            {openModal && <ConfirmModal
                type={modalInfo.type}
                open={openModal}
                setOpen={setOpenModal}
                onConfirm={onCofirmModal}
                onCancel={onCancelModal}
                confirmButtonTitle={modalInfo.confirmButtonTitle}
                description={modalInfo.description}
                title={modalInfo.title}

            />
            }
            {toggleNofication &&
                <Notification show={toggleNofication} setShow={setToggleNofication} title={notificationText.type.charAt(0).toUpperCase() + notificationText.type.slice(1)} description={notificationText.text} type={notificationText.type} />
            }


        </>
    );
};

export default TemplateRow;