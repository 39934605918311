import React, { useState } from "react";
import parse from "html-react-parser";
import {
  DocumentTextIcon,
  EyeIcon,
  PencilSquareIcon,
} from "@heroicons/react/20/solid";
import { convertFromHTML } from "draft-js";

import TextEditorPreviewModal from "./TextEditorPreviewModal";

type TextEditorFieldsProps = {
  label: string;
  state: string;
  setTextEditorType: React.Dispatch<React.SetStateAction<string>>;
  setShowTextEditor: React.Dispatch<React.SetStateAction<boolean>>;
};

export const TextEditorFields = ({
  label,
  state,
  setTextEditorType,
  setShowTextEditor,
}: TextEditorFieldsProps) => {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const handleTextEditor = (type: string) => {
    setTextEditorType(type);
    setShowTextEditor(true);
    window.scrollTo(0, 0);
  };

  const handlePreview = (e: any) => {
    e.preventDefault();
    setIsPreviewOpen(true);
  };

  return (
    <>
      <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 dark:text-gray-400">
        {label}
        <span className="text-red-500"> *</span>
      </label>
      <div className="mt-2 sm:col-span-2 sm:mt-0 sm:space-y-2">
        <div
          className={`relative group bg-transparent select-none p-3 rounded-md h-28 shadow-sm overflow-hidden ring-1 ring-inset ring-gray-300 dark:ring-gray-500
    ${(!state || convertFromHTML(state).contentBlocks.length === 0) &&
            "text-xl font-medium flex justify-center items-center text-gray-400 bg-transparent ring-1 ring-inset ring-gray-300 dark:ring-gray-500 dark:text-gray-300"
            }`}
        >
          {state && convertFromHTML(state).contentBlocks.length !== 0 ? (
            <div className="dark:text-white">{parse(state)}</div>
          ) : (
            `Design your ${label}`
          )}
          <div className="hidden absolute w-full h-full top-0 right-0 rounded p-2 group-hover:flex backdrop-blur-sm flex-col gap-2 items-center justify-center">
            {state && convertFromHTML(state).contentBlocks.length !== 0 && (
              <button
                type="button"
                onClick={(e) => handlePreview(e)}
                className="flex gap-2 items-center ring-1 bg-entntblue text-nowrap rounded-md bg-none px-5 py-1 text-sm font-bold text-white shadow-sm hover:bg-entntorange"
              >
                Preview
                <EyeIcon className="w-6 h-6" />
              </button>
            )}
            <button
              type="button"
              onClick={() => handleTextEditor(label)}
              className="flex gap-2 items-center ring-1 bg-entntblue text-nowrap rounded-md bg-none px-8 py-1 text-sm font-bold text-white shadow-sm hover:bg-entntorange"
            >
              {!state || convertFromHTML(state).contentBlocks.length === 0 ? (
                <>
                  Open Text Editor
                  <DocumentTextIcon className="w-6 h-6" />
                </>
              ) : (
                <>
                  Edit
                  <PencilSquareIcon className="w-6 h-6" />
                </>
              )}
            </button>
          </div>
        </div>
      </div>
      <TextEditorPreviewModal
        open={isPreviewOpen}
        setOpen={setIsPreviewOpen}
        title="Preview"
        content={state}
      />
    </>
  );
};
