import React, { useEffect, useState } from 'react';
import { ChevronDoubleRightIcon, ChevronDoubleLeftIcon, PlusIcon, ListBulletIcon, MinusCircleIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { Assessment, AssessmentQuestionnaire, AssessmentTemplate } from '../../../Models';
import AssessmentDropdown from '../../Question/Dropdowns/AssessmentDropdown';
import { deleteAssessment, deleteMultipleTemplates, deleteTemplate, RemoveQuestionsFromAssessment, RemoveQuestionsFromTemplate } from '../../../WebCalls';
import { useNavigate } from 'react-router-dom';
import ConfirmModal from '../../Shared/ConfirmModal';
import { set } from 'date-fns';

interface AssessmentRightSideBarProps {
    onToggleRightSidebar: (isOpen: boolean) => void;
    selectedQuestions: string[],
    selectedAssessments: string[],
    assessments: AssessmentQuestionnaire[]
    expandedAssessmentId: string | null,
    getQuestionFilters: Function,
}

const AssessmentRightSideBar: React.FC<AssessmentRightSideBarProps> = ({ onToggleRightSidebar, selectedQuestions, selectedAssessments, assessments, expandedAssessmentId,getQuestionFilters }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [selectedAssessmentTemplates, setSelectedAssessmentTemplates] = useState<AssessmentTemplate[]>([]);
    const [toggleNofication, setToggleNofication] = useState<boolean>(false);
    const [modalInfo, setModalInfo] = useState<{
            title: string, description: string, confirmButtonTitle: string, type: "info" | "danger" | "none", selected: string
        }>({
            title: '',
            description: '',
            confirmButtonTitle: '',
            type: 'none',
            selected: ''
        });
    const [currentSelectedModal,setCurrentSelectedModal] = useState<string>('');
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [selectedFilters, setSelectedFilters] = useState<Record<string, string[]>>({
        'Templates': ['all'],
        'Skills': ['all'],
    });
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [currentDifficulty, setCurrentDifficulty] = useState({ min: 0, max: 100 });

    const handleToggle = () => {
        setIsSidebarOpen(!isSidebarOpen);
        onToggleRightSidebar(!isSidebarOpen);
    };
    const [isDialogVisible, setIsDialogVisible] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        saveAssessmentTemplates();
    }, [selectedAssessments])

    const saveAssessmentTemplates = () => {
        const filteredTemplates = selectedAssessments.map((selectedId) => {
            const foundAssessment = assessments.find(assessment => assessment.id === selectedId);
            if (foundAssessment) {
                return foundAssessment.assessmentTemplate;
            }
            return null;
        }).filter((template): template is AssessmentTemplate => template !== null);

        const uniqueTemplates = Array.from(new Map(filteredTemplates.map(template => [template.id, template])).values());
        setSelectedAssessmentTemplates(uniqueTemplates);
    };


    const handleAssessmentsDelete = () => {
        setModalInfo({ title: 'Delete Assessments', description: 'Are you sure you want to delete the selected assessments?', confirmButtonTitle: 'Delete', type: 'danger', selected: '' });
        setOpenModal(true);
        setCurrentSelectedModal('DeleteAssessments');

    }
    const deleteSelectedTemplates = () => {
        setModalInfo({ title: 'Delete Templates', description: 'Are you sure you want to delete the selected templates?', confirmButtonTitle: 'Delete', type: 'danger', selected: '' });
        setOpenModal(true);
        setCurrentSelectedModal('DeleteTemplates');

    }
    const handleRemoveQuestionsFromTemplate = async () => {
        var selectedAssessment = assessments.find((a) => a.id === expandedAssessmentId);
        if (selectedAssessment?.assessmentTemplate?.id) {
            setModalInfo({
                title: 'Remove Questions',
                description: 'Are you sure you want to remove the selected questions from the template set?',
                confirmButtonTitle: 'Remove',
                type: 'danger',
                selected: ''
            });
            setOpenModal(true);
            setCurrentSelectedModal('RemoveQuestionsFromTemplate');
        }
    }
    const handleRemoveQuestionsFromAssessment = async () => {
            setModalInfo({
                title: 'Remove Questions',
                description: 'Are you sure you want to remove the selected questions from the assessment?',
                confirmButtonTitle: 'Remove',
                type: 'danger',
                selected: ''
            });
            setOpenModal(true);
            setCurrentSelectedModal('RemoveQuestionsFromAssessment');
    }

    const handleAddAssessment = () => {
        navigate('/assessments/add')
    }

    const onCofirmModal = () => {
        if(currentSelectedModal === 'RemoveQuestionsFromAssessment'){
            if (expandedAssessmentId) {
                RemoveQuestionsFromAssessment(expandedAssessmentId, selectedQuestions)
            }
        }
        if(currentSelectedModal === 'RemoveQuestionsFromTemplate'){
            var selectedAssessment = assessments.find((a) => a.id === expandedAssessmentId);
            if (selectedAssessment?.assessmentTemplate?.id) {
                RemoveQuestionsFromTemplate(selectedAssessment?.assessmentTemplate?.id, selectedQuestions)  
            }
        }
        if(currentSelectedModal === 'DeleteAssessments'){
            selectedAssessments.map(async (s) => {
                await deleteAssessment(s);
            })
        }
        if(currentSelectedModal === 'DeleteTemplates'){
            let templateIds: string[] = [];
            selectedAssessmentTemplates.map(async (s) => {
                templateIds.push(s.id);
            })
            deleteMultipleTemplates(templateIds);
        }
        setOpenModal(false);
        setCurrentSelectedModal('');
        setTimeout(() => { getQuestionFilters(selectedFilters, currentDifficulty, searchQuery); } , 1000);

    }

    const onCancelModal = () => {
        setOpenModal(false);
    }
    return (
        <aside
            className={`select-none fixed z-10 top-[4.1rem] block h-[calc(99vh-4rem)] right-0 shadow-md border border-gray-500 transition-transform transform duration-200 w-[19rem] ${isSidebarOpen ? "translate-x-0" : "translate-x-[100%]"}`}
        >
            <div className="mx-auto bg-gray-100 dark:bg-darkbglight">
                <div className="min-w-0 px-4 pt-2 pb-1">
                    <div className="text-gray-900 dark:text-gray-300 flex items-center space-x-2 justify-center">
                        <h3 className="text-center text-lg font-semibold leading-7 sm:text-base sm:tracking-tight">
                            Assessment Actions
                        </h3>
                    </div>
                </div>
            </div>
            <div
                className={`absolute top-10 ${isSidebarOpen ? "-left-2 shadow-md" : "-left-4 shadow-xl"} h-16 border border-gray-300 rounded-md flex items-center justify-center cursor-pointer bg-white dark:bg-darkbglight dark:border-gray-500 dark:text-gray-300`}
                onClick={handleToggle}
            >
                {isSidebarOpen ? <ChevronDoubleRightIcon width={16} /> : <ChevronDoubleLeftIcon width={16} />}
            </div>
            <div className="pb-12 h-fit bg-white thin-scroll overflow-y-auto dark:bg-darkbg w-full flex flex-col items-center mt-2 border-b border-gray-500">
                <button className='flex items-center justify-between rounded-md bg-entntblue px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-entntorange focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:hover:bg-entntblue disabled:cursor-not-allowed ' onClick={handleAddAssessment}> Add Assessment </button>
            </div>
            <div className="pb-12 h-[50rem] bg-white thin-scroll overflow-y-auto dark:bg-darkbg w-full flex flex-col items-center mt-2">
                <div className='w-full flex flex-col items-center h-[25%] border-b border-gray-400 my-8'>

                    <button className='items-center rounded-md bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:hover:bg-entntblue disabled:cursor-not-allowed sm:ml-3 w-[70%] flex flex-row justify-between'> ({selectedAssessments.length}) Selected Assessments <ListBulletIcon height={20} /> </button>

                    {selectedAssessments.length > 0 && <button className='items-center rounded-md bg-entntblue m-4 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-entntorange focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:hover:bg-entntblue disabled:cursor-not-allowed sm:ml-3  flex flex-row justify-between' onClick={() => {
                        if (selectedAssessments.length > 0) {
                            handleAssessmentsDelete();
                        }
                    }}>Delete Assessments</button>}

                    <div className='relative'>
                        {selectedAssessmentTemplates.length > 0 && selectedAssessments.length > 0 && (
                            <div className='flex flex-row items-center'>
                                <button
                                    className='items-center rounded-md bg-entntblue m-4 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-entntorange focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:hover:bg-entntblue disabled:cursor-not-allowed sm:ml-3 flex flex-row justify-between'
                                    onClick={() => {
                                        if (selectedAssessments.length > 0) {
                                            deleteSelectedTemplates();
                                        }
                                    }}
                                >
                                    Delete Assigned Templates
                                </button>
                                <div
                                    className='items-center rounded-md bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex flex-row justify-between cursor-pointer'
                                    onMouseEnter={() => setIsDialogVisible(true)}
                                    onMouseLeave={() => setIsDialogVisible(false)}
                                >
                                    {selectedAssessmentTemplates.length}
                                </div>
                                {isDialogVisible && (
                                    <div className='absolute z-50 right-0 top-14 bg-white border rounded-md shadow-lg px-3 pb-1  text-center mt-1 text-xs text-gray-700'>
                                        <ul className='mt-1'>
                                            {selectedAssessmentTemplates.map((template, index) => (
                                                <li key={index} className='py-1'>{template.name}</li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <div className="w-full flex flex-col items-center gap-4">
                    <button
                        className="flex items-center justify-between rounded-md bg-gray-300 px-4 py-2 text-sm font-semibold text-gray-600 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:hover:bg-entntblue disabled:cursor-not-allowed w-[70%]"
                    >
                        ({selectedQuestions.length}) Selected Questions <ListBulletIcon height={20} />
                    </button>

                    {selectedQuestions.length > 0 && (
                        <div className="flex flex-col gap-4 w-[70%]">
                            <button
                                className="flex items-center justify-between rounded-md bg-entntblue px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:hover:bg-entntblue disabled:cursor-not-allowed"
                                onClick={() => {
                                    if (selectedQuestions.length > 0) {
                                        handleRemoveQuestionsFromAssessment();
                                    }
                                }}
                            >
                                Remove From Assessment
                            </button>
                            {assessments.find((a) => a.id === expandedAssessmentId)?.assessmentTemplate?.id && (
                            <button
                                className="flex items-center justify-between rounded-md bg-entntblue px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-600  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:hover:bg-entntblue disabled:cursor-not-allowed"
                                onClick={() => {
                                    if (selectedQuestions.length > 0) {
                                        handleRemoveQuestionsFromTemplate();
                                    }
                                }}
                            >
                                Remove From Template Set
                            </button>
                            )}
                        </div>
                    )}
                </div>

            </div>
 {
                openModal && <ConfirmModal
                    type={modalInfo.type}
                    open={openModal}
                    setOpen={setOpenModal}
                    onConfirm={onCofirmModal}
                    onCancel={onCancelModal}
                    confirmButtonTitle={modalInfo.confirmButtonTitle}
                    description={modalInfo.description}
                    title={modalInfo.title}

                />
            }
        </aside >
    );
};

export default AssessmentRightSideBar;
