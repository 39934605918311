import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { InterviewSlots } from "../../../Models";
import { GetInterviewSlotById } from "../../../WebCalls";
import CandidateDetails from "../../Candidates/CandidateDetails";
import Avatar from "../../Shared/Avatar";
import CandidateNotes from "../../Candidates/CandidateNotes";
import ScoreGrades from "../../Candidates/ScoreGrades";

const InterviewNotes = () => {
  const { slotId } = useParams<{ slotId: string }>();
  const [slotDetails, setSlotDetails] = useState<InterviewSlots | null>(null);

  useEffect(() => {
    if (!slotId) {
      return;
    }

    const fetchSlotDetails = async () => {
      try {
        const slot = await GetInterviewSlotById(slotId);
        setSlotDetails(slot);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchSlotDetails();
  }, []);

  return (
    <div className="flex flex-col md:flex-row">
      <div className="md:w-1/2 p-4">
        <h3 className="text-xl font-semibold mb-4 text-gray-700 dark:text-gray-300">
          Interview Details
        </h3>
        {slotDetails && (
          <div className="p-6 bg-blue-100  dark:bg-slate-900 rounded-lg mb-6 space-y-1 ">
            <p className="text-gray-600 dark:text-gray-400  ">
              <strong className="text-gray-600 dark:text-gray-400">
                Start Time:
              </strong>{" "}
              {new Date(slotDetails.startTime).toLocaleDateString("en-IN", {
                hour: "2-digit",
                minute: "2-digit",
                weekday: "short",
                day: "2-digit",
                month: "short",
                year: "numeric",
              })}
            </p>
            <p className="text-gray-600 dark:text-gray-400">
              <strong className="text-gray-600 dark:text-gray-400">
                End Time:
              </strong>{" "}
              {new Date(slotDetails.endTime).toLocaleDateString("en-IN", {
                hour: "2-digit",
                minute: "2-digit",
                weekday: "short",
                day: "2-digit",
                month: "short",
                year: "numeric",
              })}
            </p>
            <p className="text-gray-600 dark:text-gray-400">
              <strong className="text-gray-600 dark:text-gray-400">
                Interviewer Email:
              </strong>{" "}
              {slotDetails.interviewerEmail}
            </p>
            <p className="text-gray-600 dark:text-gray-400">
              <strong className="text-gray-600 dark:text-gray-400">
                Role:
              </strong>{" "}
              {slotDetails.role.title}{" "}
              <strong className="text-gray-600 dark:text-gray-400">
                Stage :
              </strong>{" "}
              {slotDetails.roleStatus.name}
            </p>

            {slotDetails.meetingLink && (
              <p className="text-gray-600 dark:text-gray-400">
                <strong className="text-gray-600 dark:text-gray-400">
                  Meeting Link:
                </strong>{" "}
                <a
                  href={slotDetails.meetingLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 underline"
                >
                  Join Meeting
                </a>
              </p>
            )}
          </div>
        )}
        {slotDetails?.candidateId && (
          <CandidateNotes
            candidateId={slotDetails?.candidateId}
            roleId={slotDetails?.roleId}
            roleStatusId={slotDetails?.roleStatusId}
          />
        )}
        {slotDetails?.candidateId && (
          <ScoreGrades
            candidateId={slotDetails?.candidateId}
            roleId={slotDetails?.roleId}
          />
        )}
      </div>

      <div className="md:w-1/2 p-6 border-t md:border-l md:border-t-0">
        <h2 className="text-xl font-semibold text-gray-700 dark:text-gray-300 mb-6">
          Candidate Details
        </h2>
        {slotDetails?.candidate && slotDetails?.role ? (
          <div className="">
            <div className=" w-full  pl-2 pr-2 flex items-center justify-between">
              <div className="flex items-center">
                <Avatar />

                <div className="text-gray-800 dark:text-gray-300 text-xl pl-2 font-bold">
                  {slotDetails.candidate.firstName +
                    " " +
                    slotDetails.candidate.lastName}
                </div>
              </div>
            </div>
            <CandidateDetails
              candidate={slotDetails.candidate}
              candidateRole={[slotDetails.role]}
            />
          </div>
        ) : (
          <p className="text-gray-500 dark:text-gray-300 ">
            No candidate details available.
          </p>
        )}
      </div>
    </div>
  );
};

export default InterviewNotes;
