import { useEffect, useState } from "react";
import {
  CheckIcon,
  ChevronUpDownIcon,
  TrashIcon,
} from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";
import { recruitmentProcess } from "./CreateRoleForm";
import { AutoAdvanceCondition, AssessmentTemplate } from "../../../Models";
import { v4 as uuidv4 } from "uuid";
import { AssessmentCondition } from "./CreateRoleForm";
import RangeSelectionComponent from "./RangeSelectionComponent";
import Notification from "../../Shared/Notification";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const displayValue = (assessment: AssessmentTemplate | null): string => {
  return assessment ? assessment.name : "";
};
export const AssessmentDropdown = ({
  assessments,
  selectedAssessment,
  setSelectedAssessment,
  placeholder,
}: {
  assessments: AssessmentTemplate[];
  selectedAssessment: AssessmentTemplate | null;
  setSelectedAssessment: React.Dispatch<
    React.SetStateAction<AssessmentTemplate | null>
  >;
  placeholder: string;
}) => {
  const [query, setQuery] = useState("");

  const filteredAssessments =
    query === ""
      ? assessments
      : assessments.filter((assessment) => {
        return assessment.name.toLowerCase().includes(query.toLowerCase());
      });

  return (
    <Combobox
      as="div"
      value={selectedAssessment}
      onChange={setSelectedAssessment}
    >
      <div className="relative">
        <Combobox.Input
          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-transparent dark:text-gray-300  dark:ring-gray-500"
          placeholder={placeholder}
          onChange={(event) => setQuery(event.target.value)}
          displayValue={displayValue}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>
        {filteredAssessments.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm dark:bg-darkbglight">
            {filteredAssessments.map((process) => (
              <Combobox.Option
                key={process.id}
                value={process}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active
                      ? "bg-entntblue text-white"
                      : "text-gray-900 dark:text-gray-300"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={classNames(
                        "block truncate",
                        selected ? "font-semibold" : ""
                      )}
                    >

                      {process?.name ?? "Untitled"}
                    </span>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-entntblue"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
};
const ProcessDropdown = ({
  processes,
  selectedProcess,
  setSelectedProcess,
  placeholder,
}: {
  processes: recruitmentProcess[];
  selectedProcess: recruitmentProcess | null;
  setSelectedProcess: React.Dispatch<
    React.SetStateAction<null | recruitmentProcess>
  >;
  placeholder: string;
}) => {
  const [query, setQuery] = useState("");

  const filteredProcess =
    query === ""
      ? processes
      : processes.filter((process) => {
        return process?.title.toLowerCase().includes(query.toLowerCase());
      });
  return (
    <Combobox as="div" value={selectedProcess} onChange={setSelectedProcess}>
      <div className="relative ">
        <Combobox.Input
          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-transparent dark:text-gray-300  dark:ring-gray-500 "
          placeholder={placeholder}
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(process: recruitmentProcess) => process?.title}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        {filteredProcess.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm dark:bg-darkbglight">
            {filteredProcess.map((process) => (
              <Combobox.Option
                key={process.id}
                value={process}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active
                      ? "bg-entntblue text-white"
                      : "text-gray-900 dark:text-gray-300"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={classNames(
                        "block truncate",
                        selected ? "font-semibold" : ""
                      )}
                    >
                      {process?.title}
                    </span>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-entntblue"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
};
export default function CreateProcessAutomation({
  processes,
  processAutomations,
  setProcessAutomations,
  assessments,
  allAssessments
}: {
  processes: recruitmentProcess[];
  processAutomations: AutoAdvanceCondition[];
  setProcessAutomations: React.Dispatch<
    React.SetStateAction<AutoAdvanceCondition[]>
  >;
  assessments: AssessmentTemplate[];
  allAssessments: AssessmentTemplate[];
}) {
  const [selectedFromProcess, setSelectedFromProcess] =
    useState<recruitmentProcess | null>(null);
  const [toProcesses, setToProcesses] = useState<recruitmentProcess[]>([]);
  const [selectedToProcess, setSelectedToProcess] =
    useState<recruitmentProcess | null>(null);
  const [selectedAssessment, setSelectedAssessment] =
    useState<AssessmentTemplate | null>(null);
  const [currentMinimumScore, setcurrentMinimumScore] = useState(0);
  const [currentMaximumScore, setcurrentMaximumScore] = useState(100);
  const [isRangeSelectionOpen, setIsRangeSelectionOpen] = useState(false);
  const [errorNotification, setErrorNotification] = useState<boolean>(false);

  const toggleRangeSelection = () => {

    setIsRangeSelectionOpen((prevState) => !prevState);
  };
  const handleRangeSelectionConfirm = (min: number, max: number) => {

    // Close the range selection component
    setcurrentMinimumScore(min);
    setcurrentMaximumScore(max);
    // handleAddProcessAutomation()
    toggleRangeSelection();
  };
  useEffect(() => {
    handleAddProcessAutomation();
    // handleAddProcessAutomation is called with updated scores
  }, [currentMinimumScore, currentMaximumScore]);
  useEffect(() => {
    if (selectedFromProcess) {
      const toProcesses = processes.slice(
        processes.indexOf(selectedFromProcess) + 1,
        processes.length
      );
      setToProcesses(toProcesses);
    }
  }, [selectedFromProcess, processes]);

  const handleAddProcessAutomation = () => {
    if (selectedFromProcess && selectedToProcess && selectedAssessment) {
      // Check if the conditions range overlaps with existing process automations
      const overlaps = processAutomations.some(automation =>
        automation.startStatusId === selectedFromProcess.id &&

        automation.assessmentQuestionnaireId === selectedAssessment.id &&
        (
          (automation.minimumScore >= currentMinimumScore && automation.minimumScore <= currentMaximumScore) ||
          (automation.maximumScore >= currentMinimumScore && automation.maximumScore <= currentMaximumScore)
        )
      );

      if (overlaps) {

        setErrorNotification(true);
        return;
      }

      // If no overlap, add the new process automation
      setProcessAutomations([
        ...processAutomations,
        {
          id: uuidv4(),
          startStatusId: selectedFromProcess.id,
          autoAdvanceStatusId: selectedToProcess.id,
          assessmentQuestionnaireId: selectedAssessment.id,
          minimumScore: currentMinimumScore,
          maximumScore: currentMaximumScore,
        },
      ]);
    }


    setSelectedFromProcess(null);
    setSelectedToProcess(null);
    setSelectedAssessment(null);
    setcurrentMaximumScore(100);
    setcurrentMinimumScore(0);
  };
  const handleRemoveProcessAutomation = (
    processAutomation: AutoAdvanceCondition
  ) => {
    setProcessAutomations(
      processAutomations.filter((pa) => pa.id !== processAutomation.id)
    );
  };
  return (
    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
      <label
        htmlFor="emailBody"
        className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 dark:text-gray-400"
      >
        Process Automation
      </label>
      <div className="mt-2 sm:col-span-2 sm:grid-cols-2 sm:mt-0">
        <div className="mt-2 sm:flex items-center gap-1 justify-between sm:mt-0 ">
          <ProcessDropdown
            processes={processes}
            selectedProcess={selectedFromProcess}
            setSelectedProcess={setSelectedFromProcess}
            placeholder="From"
          />
          <ProcessDropdown
            processes={toProcesses}
            selectedProcess={selectedToProcess}
            setSelectedProcess={setSelectedToProcess}
            placeholder="To"
          />
          <AssessmentDropdown
            assessments={assessments}
            selectedAssessment={selectedAssessment}
            setSelectedAssessment={setSelectedAssessment}
            placeholder="Selection Criteria"
          />

          <div
            className=" relative
           "
          >
            <div className=" absolute bottom-5 right-0">
              {isRangeSelectionOpen && selectedAssessment && (
                <RangeSelectionComponent
                  isVisible={isRangeSelectionOpen}
                  onClose={toggleRangeSelection}
                  onConfirm={handleRangeSelectionConfirm}
                  lowerLimitOfScore={0}
                  upperLimitofScore={100}
                />
              )}
            </div>

            <div className="px-2">
              <button
                className="justify-center items-center rounded-md bg-entntblue px-5 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-entntblue disabled:bg-gray-300 disabled:cursor-not-allowed hover:bg-entntorange mt-4 sm:mt-0"
                onClick={(e) => {
                  e.preventDefault();
                  toggleRangeSelection();
                }}
                disabled={
                  !selectedFromProcess ||
                  !selectedToProcess ||
                  !selectedAssessment
                }
              >
                Condition
              </button>
            </div>
          </div>
        </div>
        <dd className="mt-6 text-sm text-gray-900 dark:text-gray-300">
          <div>
            {processAutomations.length > 0 && (
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className=" px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-300 lg:table-cell"
                    >
                      From
                    </th>
                    <th
                      scope="col"
                      className=" px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-300 sm:table-cell"
                    >
                      To
                    </th>
                    <th
                      scope="col"
                      className=" px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-300 sm:table-cell"
                    >
                      Criteria
                    </th>
                    <th
                      scope="col"
                      className=" px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-300 sm:table-cell"
                    >
                      Condition
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-transparent">
                  {processAutomations.map((pa) => (
                    <tr key={pa.id}>
                      <td className=" px-3 py-2 text-sm text-gray-900 lg:table-cell dark:text-gray-300">
                        {
                          processes.find((p) => p.id === pa.startStatusId)
                            ?.title
                        }
                      </td>
                      <td className=" px-3 py-2 text-sm text-gray-900 sm:table-cell dark:text-gray-300">
                        {
                          processes.find((p) => p.id === pa.autoAdvanceStatusId)
                            ?.title
                        }
                      </td>
                      <td className=" px-3 py-2 text-sm text-gray-900 sm:table-cell dark:text-gray-300">
                        {
                          allAssessments.find((p) => p.id === pa.assessmentQuestionnaireId)
                            ?.name
                        }
                      </td>
                      <td className=" px-3 py-2 text-sm text-gray-900 sm:table-cell dark:text-gray-300">
                        {pa.minimumScore + " - " + pa.maximumScore}
                      </td>

                      <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                        <button
                          type="button"
                          onClick={() => handleRemoveProcessAutomation(pa)}
                          className="sm:inline-flex items-center bg-transparent px-2.5 py-1.5 text-sm font-semibold text-entntblue shadow-sm ring-1 ring-inset ring-gray-300 hover:text-entntorange"
                        >
                          <TrashIcon className="h-5 w-5 fill-red-600" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </dd>
      </div>
      {errorNotification && (
        <Notification
          show={errorNotification}
          setShow={setErrorNotification}
          title="Error"
          description="Conditions range overlaps with existing process automation."
          type="error"
        />
      )}
    </div>
  );
}
