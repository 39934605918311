import React, { useEffect, useRef, useState } from "react";
import {
  DeleteInterviewSlot,
  GetInterviewSlots,
  updateInterviewSlot,
} from "../../../WebCalls";
import type { InterviewSlots } from "../../../Models";
import ConfirmModal from "../../Shared/ConfirmModal";
import { Spinner } from "../../Shared/Spinner";
import {
  ChatBubbleBottomCenterTextIcon,
  TrashIcon,
  PencilSquareIcon,
} from "@heroicons/react/20/solid";
import { CalendarDaysIcon, XCircleIcon } from "@heroicons/react/24/outline";
import ToggleSwitch from "../../Shared/ToggleSwitch";
import { Link } from "react-router-dom";
import ReactDatePicker from "react-datepicker";
import useApiToken from "../../../hooks/useApiToken";
import useClickOutside from "../../../hooks/useClickOutside";

type InterviewSlotslProps = {
  isOpen: boolean;
  onClose: () => void;
  roleId: string | undefined;
};
const InterviewSlotShow = ({
  isOpen,
  onClose,
  roleId,
}: InterviewSlotslProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [slots, setSlots] = useState<InterviewSlots[]>([]);
  const [filteredSlots, setFilteredSlots] = useState<InterviewSlots[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState<InterviewSlots | null>(null);
  const [showPastSlots, setShowPastSlots] = useState(false);
  const [now, setNow] = useState<Date>(new Date());
  const [showEditModal, setShowEditModal] = useState(false);
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null);
  const [meetingLink, setMeetingLink] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [interviewerEmail, setInterviewerEmail] = useState<string | null>(null);
  const token = useApiToken();
  const editSlotRef = useRef(null);
  useEffect(() => {
    if (!token) return;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    setNow(today);
    fetchSlots();
  }, [token]);
  useEffect(() => {
    if (showPastSlots) {
      setFilteredSlots(slots);
    } else {
      const filterSlots = slots.filter(
        (slot) => new Date(slot.startTime) >= now
      );
      setFilteredSlots(filterSlots);
    }
  }, [showPastSlots, slots]);
  useEffect(() => {
    if (selectedSlot) {
      setStartTime(new Date(selectedSlot.startTime));
      setEndTime(new Date(selectedSlot.endTime));
      setMeetingLink(selectedSlot.meetingLink || "");
      setInterviewerEmail(selectedSlot.interviewerEmail);
    }
  }, [selectedSlot]);
  const handleEditSubmit = async () => {
    if (!selectedSlot) return;

    if (validateInputs()) {
      try {
        await updateInterviewSlot({
          slotId: selectedSlot.id,
          startTime: startTime?.toISOString() || "",
          endTime: endTime?.toISOString() || "",
          meetingLink: meetingLink || "",
          interviewerEmail: interviewerEmail,
          notes: "",
          grade: "",
        });
        handleCloseEditModal();
        await fetchSlots();
      } catch (error: any) {
        setError(error.message);
      }
    }
  };
  const validateInputs = (): boolean => {
    if (!startTime || !endTime || !meetingLink?.trim()) {
      setError("All fields are required.");
      return false;
    }
    if (endTime <= startTime) {
      setError("End time must be after start time.");
      return false;
    }
    setError(null);
    return true;
  };
  const fetchSlots = async () => {
    setIsLoading(true);
    try {
      if (!token) return;
      const slotsData = await GetInterviewSlots(roleId, token);
      setSlots(slotsData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching slots:", error);
      setIsLoading(false);
    }
  };

  const handleDeleteSlot = (slot: InterviewSlots) => {
    setSelectedSlot(slot);
    setShowModal(true);
  };
  const handleEditSlot = (slot: InterviewSlots) => {
    setSelectedSlot(slot);
    setShowEditModal(true);
  };
  const handleDeleteConfirm = async (slotId: string) => {
    try {
      if (!token) return;
      await DeleteInterviewSlot(slotId, token);
      setSelectedSlot(null);
      setShowModal(false);
      await fetchSlots();
    } catch {}
  };
  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setStartTime(null);
    setEndTime(null);
    setMeetingLink(null);
    setInterviewerEmail(null);
    setError(null);
    setSelectedSlot(null);
  };
  useClickOutside(editSlotRef, () => setShowEditModal(false));
  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-500 backdrop-blur-sm bg-opacity-50 z-50">
      <div className="relative bg-white p-6  shadow-lg w-full max-w-[80%]  max-h-[80%] overflow-y-auto dark:bg-darkbg">
        <div className="sticky top-0 w-full">
          <div className="flex w-full items-center justify-between">
            <div className="mt-2 text-xl font-bold leading-7 text-gray-700  sm:tracking-tight dark:text-gray-300">
              Interview Slots
            </div>
            <div
              className="mt-2  font-bold  text-gray-700  sm:tracking-tight dark:text-gray-300 transform transition-transform duration-300 hover:scale-110 cursor-pointer"
              onClick={onClose}
            >
              <XCircleIcon className="h-8 w-8 aria:hidden text-red-700 " />
            </div>
          </div>
          <div className="mt-4">
            <ToggleSwitch
              id="student-role"
              label="Show Past Slots?"
              checked={showPastSlots}
              setState={setShowPastSlots}
            />
          </div>
        </div>

        <div className="flex flex-col items-center mt-2">
          {isLoading ? (
            <div>
              <Spinner height="h-10" width="10" />
            </div>
          ) : (
            <div className="grid grid-cols-2 p-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2 sm:gap-4 mt-10">
              {filteredSlots.map((slot) => (
                <div
                  key={slot.id}
                  className={`max-w-60 relative p-2 sm:p-3 border rounded-lg shadow-md transform transition-transform duration-300 ${"bg-white dark:bg-darkbglight hover:bg-blue-100 border-blue-300 hover:scale-105"}`}
                >
                  <div className="flex flex-col items-center justify-between h-full">
                    <div className="text-xs sm:text-sm  inline-block font-semibold text-gray-700 dark:text-gray-300">
                      {new Date(slot.startTime).toLocaleDateString("en-IN", {
                        weekday: "short",
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      })}
                    </div>
                    <div className="text-xs sm:text-sm font-semibold text-gray-700 mt-1 dark:text-gray-300">
                      {new Date(slot.startTime).toLocaleTimeString("en-IN", {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                      {" - "}
                      {new Date(slot.endTime).toLocaleTimeString("en-IN", {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </div>
                    {slot.candidate && (
                      <div className="mt-1 text-xs text-gray-600 dark:text-gray-300 w-full break-words">
                        Candidate:{" "}
                        <Link
                          to={`/roles/${slot.roleId}/candidates/${slot.candidate.id}`}
                          target=""
                          rel="noopener noreferrer"
                          className="text-blue-500 hover:underline"
                        >
                          {slot.candidate.firstName +
                            " " +
                            slot.candidate.lastName}
                        </Link>
                      </div>
                    )}

                    <div className="mt-1 text-xs text-gray-600 dark:text-gray-300">
                      Stage: {slot.roleStatus.name}
                    </div>
                    {slot.meetingLink && (
                      <div className="mt-1 text-xs text-blue-500 hover:underline">
                        <a
                          href={slot.meetingLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Meeting Link
                        </a>
                      </div>
                    )}

                    <div className="flex w-full mt-4 items-center justify-around">
                      <Link
                        to={`/roles/slots/${slot.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="cursor-pointer "
                      >
                        <ChatBubbleBottomCenterTextIcon className="h-5 w-5 aria-hidden:true text-entntblue" />
                      </Link>

                      <div
                        className={` ${!slot.candidateId ? "cursor-pointer" : "cursor-not-allowed"}`}
                        onClick={() => {
                          if (!slot.candidateId) {
                            handleEditSlot(slot);
                          }
                        }}
                      >
                        <PencilSquareIcon
                          className={`h-5 w-5 aria-hidden:true ${slot.candidateId ? "text-blue-300 dark:text-gray-700 " : "text-entntblue"}`}
                        />
                      </div>
                      <div
                        onClick={() => {
                          if (!slot.candidateId) {
                            handleDeleteSlot(slot);
                          }
                        }}
                        className={` ${!slot.candidateId ? "cursor-pointer" : "cursor-not-allowed"}`}
                      >
                        <TrashIcon
                          className={`h-5 w-5 aria-hidden:true ${slot.candidateId ? "text-red-300 dark:text-gray-700 " : "text-red-700"}`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        {selectedSlot && showModal && (
          <ConfirmModal
            type="danger"
            title="Delete Slot"
            open={showModal}
            setOpen={setShowModal}
            description={
              selectedSlot?.candidate ? (
                <span>
                  Are you sure you want to delete the slot for{" "}
                  <strong>{selectedSlot?.role.title + " "}</strong>
                  assigned to{" "}
                  <strong>
                    {" "}
                    {selectedSlot?.candidate.firstName +
                      " " +
                      selectedSlot?.candidate.lastName}
                  </strong>
                  ?
                </span>
              ) : (
                <span>
                  Are you sure you want to delete the slot for{" "}
                  <strong>{selectedSlot?.role.title}</strong>?
                </span>
              )
            }
            onConfirm={() => {
              handleDeleteConfirm(selectedSlot.id);
            }}
          />
        )}
        {showEditModal && selectedSlot && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
            <div
              className="bg-white p-6 rounded-md shadow-lg w-full max-w-lg dark:bg-darkbglight"
              ref={editSlotRef}
            >
              <h3 className="text-lg font-bold mb-4 dark:text-gray-200">
                Edit Interview Slot
              </h3>
              {error && <p className="text-red-500 mb-2">{error}</p>}

              <div className="mb-4">
                <label className="block text-sm  font-medium text-gray-600 mb-1 dark:text-gray-300 ">
                  Start Time <span className="text-red-500">*</span>
                </label>
                <div className="relative">
                  <ReactDatePicker
                    selected={startTime}
                    onChange={(date: Date | null) => setStartTime(date)}
                    showTimeSelect
                    minDate={new Date()}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    className="w-[20rem] p-1  rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 pl-10 dark:bg-darkbglight dark:text-gray-200"
                  />
                  <CalendarDaysIcon className="w-5 h-5 absolute top-1.5 left-2 text-gray-500" />
                </div>
              </div>
              <div className="mb-4 ">
                <label className="block text-sm  font-medium text-gray-600 mb-1 dark:text-gray-300">
                  End Time <span className="text-red-500">*</span>
                </label>
                <div className="relative  ">
                  <ReactDatePicker
                    selected={endTime}
                    onChange={(date: Date | null) => setEndTime(date)}
                    showTimeSelect
                    minDate={new Date()}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    className="w-[20rem] p-1  rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 pl-10 dark:bg-darkbglight dark:text-gray-200"
                  />
                  <CalendarDaysIcon className="w-5 h-5 absolute top-1.5 left-2 text-gray-500" />
                </div>
              </div>

              <label className="block text-sm font-medium text-gray-600 mb-1 dark:text-gray-300">
                Meeting Link <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                value={meetingLink || ""}
                onChange={(e) => setMeetingLink(e.target.value)}
                className="w-full p-2  rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 mb-4 dark:bg-darkbglight dark:text-gray-200"
              />
              <label className="block text-sm font-medium text-gray-600 mb-1 dark:text-gray-300">
                Interviewer Email
              </label>
              <input
                type="text"
                value={interviewerEmail || ""}
                onChange={(e) => setInterviewerEmail(e.target.value)}
                className="w-full p-2  rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 mb-4 dark:bg-darkbglight dark:text-gray-200"
              />
              <div className="flex justify-end mt-4">
                <button
                  type="button"
                  onClick={handleCloseEditModal}
                  className="mr-2 px-4 py-2 bg-gray-200 rounded hover:bg-gray-300 focus:outline-none focus:ring-1 focus:ring-gray-400"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={handleEditSubmit}
                  className="px-4 py-2 bg-blue-600 text-white rounded  hover:bg-blue-700 focus:outline-none focus:ring-1 focus:ring-blue-500"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default InterviewSlotShow;
