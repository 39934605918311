import "./App.css";
import Roles from "./components/Roles";
import { Route, Routes, useLocation } from "react-router-dom";
import RolePage from "./components/Roles/Role";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import CreateRole from "./components/Roles/CreateRole/CreateRole";
import { useEffect, useState } from "react";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import Candidates from "./components/Candidates";
import Dashboard from "./components/Dashboard";
import Calendar from "./components/Calendar";
import CandidatePage from "./components/Candidates/Candidate";
import AllAssignmentsDetails from "./components/AllAssignmentsDetails";
import Assessments from "./components/Assessment/Assessments";
import QuestionsComponent from "./components/Question/Questions";
import AddEditQuestion from "./components/Question/AddEditQuestion";
import AddEditAssessment from "./components/Assessment/AddEditAssessment";
import AddQuestionGroup from "./components/Question/AddQuestionGroup";
import AssessmentQuestions from "./components/Assessment/AssessmentQuestions";
import RankingBoard from "./components/Ranking/RankingBoard";
import Quarantine from "./components/Quarantine/Quarantine";
import Workflows from "./components/Workflows/Workflows";
import AssessmentTemplates from "./components/Template/AssessmentTemplates";
import AddEditAssessmentTemplates from "./components/Template/AddEditAssessmentTemplates";
import LocationBoard from "./components/LocationStats/LocationBoard";
import ActionsLog from "./components/LocationStats/ActionsLog/ActionsLog";
import TemplateLogs from "./components/Template/TemplateLogsComponent";
import InterviewCalendar from "./components/Roles/Calendar";
import InterviewNotes from "./components/Roles/InterviewNotes/InterviewNotes";

function App() {
  const { instance, inProgress } = useMsal();
  const [themeIsDark, setThemeIsDark] = useState<boolean>(false);
  const [showFooter, setShowFooter] = useState<boolean>(true);
  const location = useLocation();

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    const defaultThemeIsDark = Boolean(localStorage.getItem("darkTheme"));
    if (defaultThemeIsDark) setThemeIsDark(true);

  }, []);

  useEffect(() => {
    if (themeIsDark) {
      localStorage.setItem("darkTheme", "true");
      document.body.classList.add("dark");
    } else {
      localStorage.removeItem("darkTheme");
      document.body.classList.remove("dark");
    }
  }, [themeIsDark]);

  useEffect(() => {
    async function handleLogin() {
      try {
        const resToken = await instance.handleRedirectPromise();
        if (resToken !== null) {
          instance.setActiveAccount(resToken.account);
        }
        if (!isAuthenticated && inProgress === InteractionStatus.None) {
          await instance.loginRedirect();
        }
      } catch (er) {
        console.log(er);
      }
    }
    handleLogin();
  }, [isAuthenticated, inProgress, instance]);

  useEffect(() => {
    if (
      location.pathname.match(
        /\/roles\/[a-zA-Z0-9-]{8}-[a-zA-Z0-9-]{4}-[a-zA-Z0-9-]{4}-[a-zA-Z0-9-]{4}-[a-zA-Z0-9-]{12}(?:\/(?:edit|candidates)\/?)?$/
      ) ||
      location.pathname.startsWith("/rankboard") ||
      location.pathname.startsWith("/quarantine") ||
      location.pathname.startsWith("/templates") ||
      location.pathname.startsWith("/questions") ||
      location.pathname.startsWith("/assessments")
    ) {
      setShowFooter(false);
    } else {
      setShowFooter(true);
    }
  }, [location.pathname]);

  return (
    <>
      <NavBar setThemeIsDark={setThemeIsDark} themeIsDark={themeIsDark} />
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/" element={<Roles />} />
        <Route path="/roles" element={<Roles />} />
        <Route path="/roles/:roleId" element={<RolePage />} />
        <Route path="/roles/:roleId/edit" element={<RolePage />} />
        <Route path="/roles/:roleId/candidates" element={<RolePage />} />
        <Route
          path="/roles/:roleId/candidates/:candidateId"
          element={<CandidatePage />}
        />
        <Route path="/roles/create" element={<CreateRole />} />
        <Route path="/roles/:roleId/create" element={<CreateRole />} />
        <Route path="/candidates" element={<Candidates />} />
        <Route path="/candidates/:candidateId" element={<CandidatePage />} />
        <Route path="/roles/calendar" element={<InterviewCalendar/>} />
        <Route path="/roles/slots/:slotId" element={<InterviewNotes/>} />
        <Route path="/assignments" element={<AllAssignmentsDetails />} />
        {/* Assessment Routes */}
        <Route path="/assessments" element={<Assessments />} />
        <Route
          path="/questions"
          element={<QuestionsComponent />}
        />
        <Route
          path="/questions/add"
          element={<AddEditQuestion />}
        />
        <Route path="/assessments/add" element={<AddEditAssessment />} />
        <Route path="/questions/group/add" element={<AddQuestionGroup />} />
        <Route
          path="/questions/edit/:id"
          element={<AddEditQuestion />}
        />
        <Route path="/assessments/edit/:id" element={<AddEditAssessment />} />
        <Route
          path="/assessment/questions/:id"
          element={<AssessmentQuestions />}
        />

        {/* Ranking routes */}
        <Route path="/rankboard" element={<RankingBoard />} />
        <Route path="/rankboard/companies" element={<RankingBoard />} />
        <Route path="/rankboard/universities" element={<RankingBoard />} />

        {/* Quarantine routes */}
        <Route path="/quarantine" element={<Quarantine />} />
        <Route path="/quarantine/companies" element={<Quarantine />} />
        <Route path="/quarantine/universities" element={<Quarantine />} />

        {/* Workflow routes */}
        <Route path="/workflows" element={<Workflows />} />
        {/* Assessment Template Routes */}
        <Route path="/sets" element={<AssessmentTemplates />} />
        <Route path="/sets/create" element={<AddEditAssessmentTemplates />} />
        <Route path="/sets/logs" element={<TemplateLogs />} />
        {/* Location Stats routes */}
        <Route path="/location" element={<LocationBoard />} />
        <Route path="/location/actionLogs" element={<ActionsLog />} />
      </Routes>
      {showFooter && <Footer />}
    </>
  )
}

export default App;
