import React, { useEffect, useState } from "react";
import {
  ICandidate,
  QuarantineCompany,
  QuarantineUniversity,
} from "../../../Models";
import {
  Filters,
  companyUpdateAI,
  deleteQuarantineCompany,
  deleteQuarantineUniversity,
  universityUpdateAI,
  getCandidatesByCompanyUrl,
  getCandidatesByUniversityUrl,
} from "../../../WebCalls";
import Notification from "../../Shared/Notification";
import { Replay } from "@mui/icons-material";
import { isQuarantineCompany } from "../../../Helpers";
import { sort } from "../Quarantine";
import { Spinner } from "../../Shared/Spinner";
import { LinkIcon } from "@heroicons/react/20/solid";
import { LinkedIn } from "@mui/icons-material";
import RejectModal from "../SubComponents/RejectModal";

interface QuarantineProps {
  quarantineData?: any[];
  loading?: boolean;
  token: string;
  selected: string;
  setLoading: (loading: boolean) => void;
  setQuarantineData: (
    data: QuarantineUniversity[] | QuarantineCompany[]
  ) => void;
  fetchQuarantineData: (
    sortType?: string,
    sortDesc?: boolean,
    Filters?: Filters[]
  ) => Promise<void>;
  totalPages: number;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  selectedCompanyLocations: string[];
  setSelectedCompanyLocations: React.Dispatch<React.SetStateAction<string[]>>;
  sortOption: sort | undefined;
  setSortOption: React.Dispatch<React.SetStateAction<sort | undefined>>;
  selectedRows: QuarantineUniversity[];
  setSelectedRows: React.Dispatch<React.SetStateAction<QuarantineUniversity[]>>;
  toggleRow: (data: QuarantineUniversity) => void;
}

const tabs = ["Companies", "Universities"];

const DataGrid: React.FC<QuarantineProps> = ({
  quarantineData,
  loading,
  setLoading,
  setQuarantineData,
  selected,
  selectedRows,
  setSelectedRows,
  selectedCompanyLocations,
  setSelectedCompanyLocations,
  toggleRow,
}) => {
  const [infoUpdateNotify, setInfoUpdateNotify] = useState<boolean>(false);
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [students, setStudents] = useState<ICandidate[]>([]);
  if (loading === true) {
    return (
      <tbody>
        <tr className="h-80">
          <td colSpan={9}>
            <Spinner height="h-10" width="w-10" />
          </td>
        </tr>
      </tbody>
    );
  }

  const toggleRowExpand = async (index: any, data: any) => {
    if (expandedRow === index) {
      setExpandedRow(null);
      setStudents([]); // Clear students when collapsing the row
    } else {
      setExpandedRow(index);

      try {
        let candidates: ICandidate[] = [];
        let furl = data.url;

        // Check which URL (Company or University) to fetch from
        if (selected === tabs[0]) {
          furl = furl.replace(/^(https?:\/\/)?(www\.)?/, "").replace(/\/$/, "");
          candidates = await getCandidatesByCompanyUrl(furl); // Fetch candidates by company URL
        } else {
          candidates = await getCandidatesByUniversityUrl(furl); // Fetch candidates by university URL
        }

        setStudents(candidates); // Set the students data
      } catch (error) {
        console.error("Error fetching candidates:", error);
      }
    }
  };

  const updateInfo = async (id: string) => {
    setIsUpdating(true);
    try {
      if (selected === tabs[0]) {
        const response = await companyUpdateAI(id);
        if (response) {
          const updatedData = quarantineData!.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                linkedIn: response.linkedIn ?? item.linkedIn,
                location: response.location ?? item.location,
                revenue: response.revenue ?? item.revenue,
                stockMarket: response.stockMarket ?? item.stockMarket,
                relevance: response.relevance ?? item.relevance,
                hotStartup: response.hotStartup ?? item.hotStartup,
              };
            }
            return item;
          });
          setQuarantineData(updatedData);
          setInfoUpdateNotify(true);
        } else {
          const message = `Failed to update company AI: ${response}`;
          console.error(message);
        }
      } else {
        const response = await universityUpdateAI(id);
        if (response) {
          const updatedData = quarantineData!.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                linkedIn: response.linkedIn ?? item.linkedIn,
                location: response.location ?? item.location,
                alternateNames: response.alternateNames ?? item.alternateNames,
                package: response.package ?? item.package,
                recruiters: response.recruiters ?? item.recruiters,
                degrees: response.degrees ?? item.degrees,
              };
            }
            return item;
          });
          setQuarantineData(updatedData);
        } else {
          const message = `Failed to update university AI: ${response}`;
          console.error(message);
        }
      }
    } catch (error) {
      console.error("Error updating AI:", error);
    } finally {
      setIsUpdating(false);
    }
  };

  const toggleSelectedOptions = (newId: string) => {
    setSelectedCompanyLocations((prev) => {
      const isAlready = prev.some((id) => id === newId);
      if (isAlready) {
        return prev.filter((id) => id !== newId);
      } else {
        return [...prev, newId];
      }
    });
  };

  return (
    <>
      <Notification
        title="Updation Successful"
        description={`${selected} Updated Successfully.`}
        type="success"
        show={infoUpdateNotify}
        setShow={setInfoUpdateNotify}
      />
      <tbody>
        {quarantineData!.map((data, index) => (
          <React.Fragment key={data.id}>
            <tr
              onClick={() => toggleRowExpand(index, data)}
              className="cursor-pointer hover:bg-gray-50 dark:hover:bg-[#1c2229] border-t border-gray-200"
            >
              <td className="py-2 px-5">
                <input
                  type="checkbox"
                  className="rounded z-10 border-gray-300 text-indigo-600 focus:ring-indigo-600 dark:border-gray-100"
                  checked={selectedRows.includes(data)}
                  onChange={() => {
                    toggleRow(data);
                  }}
                  onClick={(event) => event.stopPropagation()}
                />
              </td>
              <td
                className={`px-4 py-1 font-semibold ${
                  data.flag ? "text-red-500" : "text-gray-700 dark:text-white"
                }`}
              >
                <div className="flex gap-2">
                  {data.linkedIn && (
                    <a
                      href={
                        data.linkedIn?.startsWith("http")
                          ? data.linkedIn
                          : `https:\\${data.linkedIn}`
                      }
                      className="hover:text-blue-500"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <LinkedIn width="1.3rem" />
                    </a>
                  )}
                  <a
                    href={
                      data.url.startsWith("http")
                        ? data.url
                        : `https:\\${data.url}`
                    }
                    className="hover:text-blue-500"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <LinkIcon width="1.3rem" />
                  </a>
                </div>
              </td>
              <td className="px-4 py-1 text-gray-700  dark:text-white font-semibold">
                {data.count}
              </td>
              <td
                className={`px-4 py-1 text-gray-700 flex  flex-row justify-start flex-wrap max-w-[22rem] overflow-x-hidden overflow-y-auto gap-1 dark:text-white font-semibold`}
              >
                {selected === tabs[0] &&
                  data.companyLocations?.map((cl: any) => {
                    return (
                      <span
                        className={`${selectedCompanyLocations.includes(cl.id) ? "border border-black" : "bg-entntblue text-white"} cursor-pointer rounded-md py-0.5 px-1 m-1 cursor-default`}
                        key={cl.Id}
                        onClick={(e) => {
                          e.stopPropagation();

                          const allSelectedExceptThis = data.companyLocations.every(
                              (location: any) =>
                                location.id === cl.id ||
                                selectedCompanyLocations.includes(location.id)
                            );

                          if (allSelectedExceptThis) {
                            return;
                          }
                          toggleSelectedOptions(cl.id);
                        }}
                        title={cl.city.count}
                      >
                        {cl.city?.name}
                      </span>
                    );
                  })}

                {selected === tabs[1] &&
                  data.cities?.map((city: any) => {
                    return (
                      <span
                        className={`${selectedCompanyLocations.includes(city.id) ? "border border-black" : "bg-entntblue text-white"} cursor-pointer rounded-md py-0.5 px-1 m-1 cursor-default`}
                        key={city.id}
                        onClick={(e) => {
                          e.stopPropagation();

                          const allSelectedExceptThis = data.cities.every(
                              (location: any) =>
                                location.id === city.id ||
                                selectedCompanyLocations.includes(location.id)
                            );

                          if (allSelectedExceptThis) {
                            return;
                          }
                          toggleSelectedOptions(city.id);
                        }}
                        title={city.count}
                      >
                        {city.name}
                      </span>
                    );
                  })}
              </td>
              <td className="px-4 py-1 text-gray-700  dark:text-white font-semibold">
                {data.name}
              </td>
            </tr>
            {expandedRow === index && (
              <tr className="border-b border-gray-200">
                <td colSpan={6} className="p-3">
                  <div className="overflow-hidden px-6">
                    <div className="flex flex-col items-start gap-y-4">
                      {selected === tabs[1] && (
                        <div className="w-full">
                          <p className="font-bold">Degrees:</p>
                          <p>{data.degrees}</p>
                        </div>
                      )}
                      {students.length > 0 && (
                        <div className="w-full mt-4">
                          <p className="font-bold">Candidates:</p>
                          <div className="grid grid-cols-1 gap-2 mt-2">
                            {students.map((student: ICandidate) => (
                              <div
                                key={student.id}
                                className="p-2 bg-gray-100 dark:bg-gray-700 rounded-md shadow-md"
                              >
                                <a
                                  href={`/candidates/${student.id}`}
                                  className="hover:underline"
                                  target=""
                                  rel="noreferrer"
                                >
                                  {student.firstName} {student.lastName}
                                </a>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </React.Fragment>
        ))}
      </tbody>
    </>
  );
};

export default DataGrid;
