import React, { useEffect, useState } from "react";
import {  deleteAssessment, deleteTemplate, fetchAllSkills, fetchAssessmentTemplatesWithQuestionnaires, GetRoles, getTemplateSkillMappings, updateRoleAssessment } from "../../WebCalls";
import {  Role, Skill, TemplateWithQuestionnaires } from "../../Models";
import { ArrowDownIcon, ArrowPathIcon, ArrowsUpDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid';
import ConfirmModal from "../Shared/ConfirmModal";
import Notification from "../Shared/Notification";
import NoData from "../Shared/NoData";
import Loading from "../Shared/Loading";
import TemplateRow from "./TemplateRow";
import TemplateRightSideBar from "./Sidebars/TemplateRightSideBar";
import TemplateLeftSideBar from "./Sidebars/TemplateLeftSideBar";
import { debounce } from "../../helpers/constants";

import TemplateLogs from "./TemplateLogsComponent";
interface skillAvg {
    id: string,
    avgMin: number
    avgMax: number
}
export interface SortOptionTemplate {
    field: "difficulty" | "test" | "questions" | "time" | "positive" | "negative" | "results" | "varAvg" | "varDif" | null
    ;
    direction: "default" | "asc" | "desc";
}
const transformFilters = (filters: Record<string, skillAvg[]>) => {
    const nullGuid = "00000000-0000-0000-0000-000000000000";

    const skillIds = filters['Skills'].map(skill => skill.id !== 'all' ? skill.id : nullGuid);
    const roleIds = filters['Roles'].map(role => role.id !== 'all' ? role.id : nullGuid);

    const difficulty = filters['Difficulty'].map(diff => ({
        id: diff.id !== 'all' ? diff.id : nullGuid,
        avgMin: diff.avgMin,
        avgMax: diff.avgMax
    }));

    const results = filters['Results'].map(result => ({
        id: result.id !== 'all' ? result.id : nullGuid,
        avgMin: result.avgMin,
        avgMax: result.avgMax
    }));

    const variation = filters['Variation'].map(variation => ({
        id: variation.id !== 'all' ? variation.id : nullGuid,
        avgMin: variation.avgMin,
        avgMax: variation.avgMax
    }));

    const num_of_Questions = filters['Num_of_Questions'].map(question => ({
        id: question.id !== 'all' ? question.id : nullGuid,
        avgMin: question.avgMin,
        avgMax: question.avgMax
    }));

    const minTime = filters['Time'][0].avgMin;
    const maxTime = filters['Time'][0].avgMax;

    return {
        skillIds: skillIds.filter(id => id !== nullGuid),
        roleIds: roleIds.filter(id => id !== nullGuid),
        difficulty,
        results,
        variation,
        num_of_Questions,
        minTime,
        maxTime
    };
};


function AssessmentTemplates() {
    const [templates, setTemplates] = useState<TemplateWithQuestionnaires[]>([]);
    const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(false);
    const [roles, setRoles] = useState<Role[]>([]);
    const [currentClickedAssessment, setCurrentClickedAssessment] = useState<string>();
    const [currentDeleteId, setcurrentDeleteId] = useState<string>('');
 
    const [isEditable, setIsEditable] = useState<{ editable: boolean, template: TemplateWithQuestionnaires | undefined }>();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [currentSwap, setCurrentSwap] = useState<string>('');
    const [modalInfo, setModalInfo] = useState<{
        title: string, description: string, confirmButtonTitle: string, type: "info" | "danger" | "none", selected: string
    }>({
        title: '',
        description: '',
        confirmButtonTitle: '',
        type: 'none',
        selected: ''
    });
    const [notificationText, setNotificationText] = useState<{ text: string, type: "success" | "error" | "info" }>({
        text: '',
        type: 'success'
    });
    const [toggleNofication, setToggleNofication] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [isLeftSidebarOpen, setIsLeftSidebarOpen] = useState(true);
    const [skills, setSkills] = useState<Skill[]>([]);
    const [sortOption, setSortOption] = useState<SortOptionTemplate>({
        field: null,
        direction: "default",
    });
    const [currentFilters, setCurrentFilters] = useState<Record<string, skillAvg[]>>({
        'Skills': [{ id: 'all', avgMin: 0, avgMax: 100 }],
        'Roles': [{ id: 'all', avgMin: 0, avgMax: 100 }],
        'Difficulty': [{ id: 'all', avgMin: 0, avgMax: 100 }],
        'Results': [{ id: 'all', avgMin: 0, avgMax: 100 }],
        'Variation': [{ id: 'all', avgMin: 0, avgMax: 100 }],
        'Num_of_Questions': [{ id: 'all', avgMin: 0, avgMax: 100 }],
        'Time': [{ id: 'all', avgMin: 0, avgMax: 100 }],
    });
    const fetchTemplatesAndRoles = () => {
        setLoading(true);
        const tempFilters = transformFilters(currentFilters);
        fetchAssessmentTemplatesWithQuestionnaires(sortOption, tempFilters).then((res: TemplateWithQuestionnaires[]) => {
            setTemplates(res);
            setLoading(false);
        });
        GetRoles().then((res) => {
            setRoles(res);
        })
    };
  
    const handleToggleRightSidebar = (isOpen: any) => {
        setIsRightSidebarOpen(isOpen);

    };
    const getAllSkills = async () => {
        await fetchAllSkills().then((res) => {
            setSkills(res);
        })
    }
    useEffect(() => {
        setLoading(true);
        fetchTemplatesAndRoles();
        getAllSkills();
        }, []);


    useEffect(() => {
        fetchTemplatesAndRoles();
    }, [sortOption, currentFilters])

  
    const handleEdit = (assessment: TemplateWithQuestionnaires) => {
        setIsEditable({
            editable: true,
            template: assessment
        });
    };

 
   
    const handleUpdateRoleAssessment = async () => {
        await updateRoleAssessment(currentClickedTemplate, currentClickedRole);
        setCurrentClickedAssessment('')
        setCurrentSwap('')
        setResetDropDowns(true);
        setCurrentClickedRole('');
        setCurrentClickedTemplate('');

    };


    const isEditedToggle = () => {
        setIsEditable({
            editable: false,
            template: undefined
        });
        setTimeout(() => {
            fetchTemplatesAndRoles();
        }, 500)
    }

    const onCofirmModal = async () => {
        setOpenModal(false);
        if (modalInfo.selected === 'clearTemplate') {
            setNotificationText({ text: 'Template is removed from role successfully', type: "success" });
            setToggleNofication(true);
            await handleUpdateRoleAssessment();

        }
        if (modalInfo.selected === 'assignTemplate') {
            setNotificationText({ text: 'Template is assigned to role successfully', type: "success" });
            setToggleNofication(true);
            await handleUpdateRoleAssessment();
        }
        if (modalInfo.selected === "deleteTemplate") {
            await deleteTemplate(currentDeleteId);
            setNotificationText({ text: 'Template is deleted successfully', type: "success" });
        }

        if (modalInfo.selected === "deleteAssessment") {
            await deleteAssessment(currentDeleteId);
            setNotificationText({ text: 'Assessment is deleted successfully', type: "success" });
        }
        fetchTemplatesAndRoles();
        setcurrentDeleteId('');
        setCurrentClickedAssessment('');
        setToggleNofication(true);
    };

    const onCancelModal = () => {
        setOpenModal(false);
        setModalInfo({
            title: '',
            description: '',
            confirmButtonTitle: '',
            type: 'none',
            selected: ''
        });
        setNotificationText({
            text: '',
            type: 'success'
        })
        setCurrentClickedAssessment('');
        setcurrentDeleteId('');
    }

    const [selectedTemplates, setSelectedTemplates] = useState<string[]>([]);
    const [currentClickedRole, setCurrentClickedRole] = useState<string>();
    const [currentClickedTemplate, setCurrentClickedTemplate] = useState<string>();

    const getIsEditable = (template: TemplateWithQuestionnaires) => {
        handleEdit(template);
    }
    const [selectAll, setSelectAll] = useState(false);

    const handleSelect = (questionId: string) => {
        if (selectedTemplates.includes(questionId)) {
            setSelectedTemplates(selectedTemplates.filter((id) => id !== questionId));
        } else {
            setSelectedTemplates([...selectedTemplates, questionId]);
        }
    };

    useEffect(() => {
        if (selectAll) {
            setSelectedTemplates(templates.map((q) => (q.id)));
        } else {
            setSelectedTemplates([]);
        }
    }, [selectAll, templates]);


    const handleSelectAll = () => {
        setSelectAll(!selectAll);
    };

    const handleToggleLeftSidebar = (isOpen: any) => {
        setIsLeftSidebarOpen(isOpen);
    };

    const getTemplateFilters = () => {

    }
    const handleTemplateSearch = debounce(async (e: any) => {
        const val = e.target?.value.trim() ?? '';
        setLoading(true);

    }, 500);
    const handleSortChange = (
        column: "difficulty" | "test" | "questions" | "time" | "positive" | "negative" | "results" | "varAvg" | "varDif"
    ) => {
        setSortOption((prevOption) => {
            const newDirection: SortOptionTemplate["direction"] =
                prevOption.field === column
                    ? prevOption.direction === "asc"
                        ? "desc"
                        : "default"
                    : "asc";

            if (newDirection === "default") {
                return { field: null, direction: "default" };
            }

            return { field: column, direction: newDirection };
        });
    };
    const [currentExpandId, setCurrentExpandId] = useState('');
    const [resetDropdowns, setResetDropDowns] = useState<boolean>(false);
    const [isActionLogOpen, setIsActionLogOpen] = useState<boolean>(false);
    const getCurrentExpandId = (id: string) => {
        setCurrentExpandId(id);
    }

 
    const [isDialogVisible, setIsDialogVisible] = useState(false);

    const handleMouseEnter = () => {
        setIsDialogVisible(true);
    };

    const handleMouseLeave = () => {
        setIsDialogVisible(false);
    };

    const [selectedSort, setSelectedSort] = useState<{ skillId: string; direction: string } | null>(null);

    const handleSort = (skillId: string, direction: string) => {
        setSelectedSort({ skillId, direction });
    };
    const getFilters = (newFilters: Record<string, skillAvg[]>) => {
        setCurrentFilters(newFilters);
    }
    return (
        <div className="bg-white dark:bg-darkbg dark:text-gray-400 w-full text-sm ">
            <div className={`transition-all relative duration-200 ${isLeftSidebarOpen ? "pl-[19rem]" : "pl-0"} ${isRightSidebarOpen ? "pr-[19rem]" : "pl-0"}`}>
                <div className={`${!isLeftSidebarOpen
                    ? " mx-8"
                    : "relative sm:transform "
                    } transition-all duration-200 
                    ease-out`}>

                    {isActionLogOpen ? <>
                        <TemplateLogs />
                    </> : <>
                        {templates && templates.length > 0 && !loading ?
                            <div className="">
                                <div className="relative overflow-x-auto shadow-md sm:rounded-lg min-h-[87vh]">
                                    <div className="relative w-full">

                                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                            <thead className="bg-white dark:bg-darkbglight z-20 border-1 ">
                                                <tr>
                                                    <th scope="col" className=" px-2">
                                                        <input
                                                            type="checkbox"
                                                            checked={selectAll}
                                                            onChange={handleSelectAll}
                                                            className="form-checkbox left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                        />
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="w-[22%] px-6 py-3 text-left text-xs text-gray-900 dark:text-gray-300 relative"
                                                    >
                                                        <div className="flex flex-row">
                                                            Skills
                                                            {/* <ArrowsUpDownIcon className="w-4 h-4 ml-1 text-gray-500 cursor-pointer" /> */}
                                                            {/* Conditionally render the dialog with skills */}
                                                            {/* {isDialogVisible && (
                                                                <div className="absolute left-5 top-5 mt-2 w-48 bg-white dark:bg-darkbglight border border-gray-700 rounded-lg shadow-lg p-4 z-50 ">
                                                                    <ArrowPathIcon height={12} />
                                                                    <ul className="text-sm text-gray-900 dark:text-gray-300 list-disc list-inside ">
                                                                        {Array.from(
                                                                            new Map(
                                                                                templates.flatMap((t: TemplateWithQuestionnaires) =>
                                                                                    t.skills ? t.skills.map((skill) => [skill.id, skill]) : []
                                                                                )
                                                                            ).values()
                                                                        ).map((uniqueSkill) => (
                                                                            <li key={uniqueSkill.id} className="block mt-2">
                                                                                {uniqueSkill.name} -{" "}
                                                                                <span
                                                                                    className={`cursor-pointer p-1 ${selectedSort?.skillId === uniqueSkill.id && selectedSort.direction === "asc" ? "font-bold border border-green-800 bg-green-950 rounded-lg" : ""}`}
                                                                                    onClick={() => handleSort(uniqueSkill.id, "asc")}
                                                                                >
                                                                                    asc
                                                                                </span>{" "}
                                                                                <span
                                                                                    className={`cursor-pointer p-1 ${selectedSort?.skillId === uniqueSkill.id && selectedSort.direction === "desc" ? "font-bold border border-green-800 bg-green-950 rounded-lg" : ""}`}
                                                                                    onClick={() => handleSort(uniqueSkill.id, "desc")}
                                                                                >
                                                                                    desc
                                                                                </span>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            )} */}
                                                        </div>
                                                    </th>
                                                    <th scope="col" className="w-[4%] px-6 py-3 text-left text-xs text-gray-900 dark:text-gray-300">

                                                    </th>
                                                    <th scope="col" className="px-2 py-3 w-[6%]">
                                                        <div className="flex items-center justify-center text-xs text-gray-900 dark:text-gray-300">
                                                            Difficulty
                                                            {sortOption.field === 'difficulty' && sortOption.direction === 'asc' ? (
                                                                <ArrowUpIcon className="w-4 h-4 ml-1 text-gray-500 cursor-pointer" onClick={() => handleSortChange('difficulty')} />
                                                            ) : sortOption.field === 'difficulty' && sortOption.direction === 'desc' ? (
                                                                <ArrowDownIcon className="w-4 h-4 ml-1 text-gray-500 cursor-pointer" onClick={() => handleSortChange('difficulty')} />
                                                            ) : (
                                                                <ArrowsUpDownIcon className="w-4 h-4 ml-1 text-gray-500 cursor-pointer" onClick={() => handleSortChange('difficulty')} />
                                                            )}
                                                        </div>
                                                    </th>
                                                    <th scope="col" className="w-[4%] px-2 py-3 text-center hidden sm:table-cell">
                                                        <div className="flex items-center justify-center text-xs text-gray-900 dark:text-gray-300">
                                                            Tests
                                                            {sortOption.field === 'test' && sortOption.direction === 'asc' ? (
                                                                <ArrowUpIcon className="w-4 h-4 ml-1 text-gray-500 cursor-pointer" onClick={() => handleSortChange('test')} />
                                                            ) : sortOption.field === 'test' && sortOption.direction === 'desc' ? (
                                                                <ArrowDownIcon className="w-4 h-4 ml-1 text-gray-500 cursor-pointer" onClick={() => handleSortChange('test')} />
                                                            ) : (
                                                                <ArrowsUpDownIcon className="w-4 h-4 ml-1 text-gray-500 cursor-pointer" onClick={() => handleSortChange('test')} />
                                                            )}
                                                        </div>
                                                    </th>
                                                    <th scope="col" className="w-[5%] px-2 py-3 text-center hidden sm:table-cell">
                                                        <div className="flex items-center justify-center text-xs text-gray-900 dark:text-gray-300">
                                                            Var Avg
                                                            {sortOption.field === 'varAvg' && sortOption.direction === 'asc' ? (
                                                                <ArrowUpIcon className="w-4 h-4 ml-1 text-gray-500 cursor-pointer" onClick={() => handleSortChange('varAvg')} />
                                                            ) : sortOption.field === 'varAvg' && sortOption.direction === 'desc' ? (
                                                                <ArrowDownIcon className="w-4 h-4 ml-1 text-gray-500 cursor-pointer" onClick={() => handleSortChange('varAvg')} />
                                                            ) : (
                                                                <ArrowsUpDownIcon className="w-4 h-4 ml-1 text-gray-500 cursor-pointer" onClick={() => handleSortChange('varAvg')} />
                                                            )}
                                                        </div>
                                                    </th>
                                                    <th scope="col" className="w-[5%] px-2 py-3 text-center hidden sm:table-cell">
                                                        <div className="flex items-center justify-center text-xs text-gray-900 dark:text-gray-300">
                                                            Var Diff
                                                            {sortOption.field === 'varDif' && sortOption.direction === 'asc' ? (
                                                                <ArrowUpIcon className="w-4 h-4 ml-1 text-gray-500 cursor-pointer" onClick={() => handleSortChange('varDif')} />
                                                            ) : sortOption.field === 'varDif' && sortOption.direction === 'desc' ? (
                                                                <ArrowDownIcon className="w-4 h-4 ml-1 text-gray-500 cursor-pointer" onClick={() => handleSortChange('varDif')} />
                                                            ) : (
                                                                <ArrowsUpDownIcon className="w-4 h-4 ml-1 text-gray-500 cursor-pointer" onClick={() => handleSortChange('varDif')} />
                                                            )}
                                                        </div>
                                                    </th>
                                                    <th scope="col" className="w-[5%] px-2 py-3 text-center hidden sm:table-cell">
                                                        <div className="flex items-center justify-center text-xs text-gray-900 dark:text-gray-300">
                                                            Questions
                                                            {sortOption.field === 'questions' && sortOption.direction === 'asc' ? (
                                                                <ArrowUpIcon className="w-4 h-4 ml-1 text-gray-500 cursor-pointer" onClick={() => handleSortChange('questions')} />
                                                            ) : sortOption.field === 'questions' && sortOption.direction === 'desc' ? (
                                                                <ArrowDownIcon className="w-4 h-4 ml-1 text-gray-500 cursor-pointer" onClick={() => handleSortChange('questions')} />
                                                            ) : (
                                                                <ArrowsUpDownIcon className="w-4 h-4 ml-1 text-gray-500 cursor-pointer" onClick={() => handleSortChange('questions')} />
                                                            )}
                                                        </div>
                                                    </th>
                                                    <th scope="col" className="w-[5%] px-2 py-3 text-center hidden sm:table-cell">
                                                        <div className="flex items-center justify-center text-xs text-gray-900 dark:text-gray-300">
                                                            Time
                                                            {sortOption.field === 'time' && sortOption.direction === 'asc' ? (
                                                                <ArrowUpIcon className="w-4 h-4 ml-1 text-gray-500 cursor-pointer" onClick={() => handleSortChange('time')} />
                                                            ) : sortOption.field === 'time' && sortOption.direction === 'desc' ? (
                                                                <ArrowDownIcon className="w-4 h-4 ml-1 text-gray-500 cursor-pointer" onClick={() => handleSortChange('time')} />
                                                            ) : (
                                                                <ArrowsUpDownIcon className="w-4 h-4 ml-1 text-gray-500 cursor-pointer" onClick={() => handleSortChange('time')} />
                                                            )}
                                                        </div>
                                                    </th>
                                                    <th scope="col" className="w-[5%] px-2 py-3 text-center hidden sm:table-cell">
                                                        <div className="flex items-center justify-center text-xs text-gray-900 dark:text-gray-300">
                                                            Positive
                                                            {sortOption.field === 'positive' && sortOption.direction === 'asc' ? (
                                                                <ArrowUpIcon className="w-4 h-4 ml-1 text-gray-500 cursor-pointer" onClick={() => handleSortChange('positive')} />
                                                            ) : sortOption.field === 'positive' && sortOption.direction === 'desc' ? (
                                                                <ArrowDownIcon className="w-4 h-4 ml-1 text-gray-500 cursor-pointer" onClick={() => handleSortChange('positive')} />
                                                            ) : (
                                                                <ArrowsUpDownIcon className="w-4 h-4 ml-1 text-gray-500 cursor-pointer" onClick={() => handleSortChange('positive')} />
                                                            )}
                                                        </div>
                                                    </th>
                                                    <th scope="col" className="w-[5%] px-2 py-3 text-center hidden sm:table-cell">
                                                        <div className="flex items-center justify-center text-xs text-gray-900 dark:text-gray-300">
                                                            Negative
                                                            {sortOption.field === 'negative' && sortOption.direction === 'asc' ? (
                                                                <ArrowUpIcon className="w-4 h-4 ml-1 text-gray-500 cursor-pointer" onClick={() => handleSortChange('negative')} />
                                                            ) : sortOption.field === 'negative' && sortOption.direction === 'desc' ? (
                                                                <ArrowDownIcon className="w-4 h-4 ml-1 text-gray-500 cursor-pointer" onClick={() => handleSortChange('negative')} />
                                                            ) : (
                                                                <ArrowsUpDownIcon className="w-4 h-4 ml-1 text-gray-500 cursor-pointer" onClick={() => handleSortChange('negative')} />
                                                            )}
                                                        </div>
                                                    </th>

                                                    <th scope="col" className="w-[18%] px-2 py-3  hidden sm:table-cell text-xs text-gray-900 dark:text-gray-300">
                                                        Results
                                                    </th>
                                                    <th scope="col" className="w-[16%] px-2 py-3 text-center text-xs text-gray-900 dark:text-gray-300">
                                                        Actions
                                                    </th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {templates.map((template: TemplateWithQuestionnaires) => (
                                                    <>
                                                        <TemplateRow
                                                            key={template.id}
                                                            template={template}
                                                            roles={roles}
                                                            fetchTemplatesAndRoles={fetchTemplatesAndRoles}
                                                            getIsEditable={getIsEditable}
                                                            selected={selectedTemplates.includes((template.id))}
                                                            handleSelect={() => handleSelect((template.id))}
                                                            getCurrentExpandId={getCurrentExpandId}
                                                        />
                                                        {/* {currentExpandId === template.id &&
                                                        <TemplateAssessmentsRow template={template} />
                                                    } */}
                                                    </>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div >
                            :

                            !loading && templates?.length === 0 ? <div className='' >
                                <NoData title='No Templates Found' description="There are currently no available Templates. Expand the sidebar to start adding templates and generating assessments" />
                            </div>
                                :
                                <>
                                    <Loading />
                                </>
                        }


                    </>}
                </div>
              
            </div>

            <TemplateRightSideBar onToggle={handleToggleRightSidebar} isEditable={isEditable} isEditedToggle={isEditedToggle} fetchTemplatesAndRoles={fetchTemplatesAndRoles} selectedTemplates={selectedTemplates} />
            <TemplateLeftSideBar onToggle={handleToggleLeftSidebar} skills={skills} getTemplateFilters={getTemplateFilters} handleTemplateSearch={handleTemplateSearch} roles={roles} getFilters={getFilters} fetchTemplatesAndRoles={fetchTemplatesAndRoles}/>
            {
                openModal && <ConfirmModal
                    type={modalInfo.type}
                    open={openModal}
                    setOpen={setOpenModal}
                    onConfirm={onCofirmModal}
                    onCancel={onCancelModal}
                    confirmButtonTitle={modalInfo.confirmButtonTitle}
                    description={modalInfo.description}
                    title={modalInfo.title}

                />
            }
            {
                toggleNofication &&
                <Notification show={toggleNofication} setShow={setToggleNofication} title={notificationText.type.charAt(0).toUpperCase() + notificationText.type.slice(1)} description={notificationText.text} type={notificationText.type} />
            }
        </div >
    );
}

export default AssessmentTemplates;
