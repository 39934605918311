import React, { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { deleteAssessment, fetchAllSkills, fetchQuestionGroups, getAllFilterQuestions, getAllQuestionsByQuestionnareId, getAllQuestionsExcludingQuestionnareId, getAssessmentDetailById } from '../../WebCalls';
import { AcademicCapIcon, ListBulletIcon, ClockIcon, CheckBadgeIcon, ChevronDownIcon, ChevronUpIcon, ChevronRightIcon, PencilIcon, TrashIcon, CheckIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { Filter, QuestionGroup, Questions, Skill } from '../../Models';
import Kanban from '../Shared/Kanban/Kanban';
import QuestionSidebar from '../Shared/QuestionSidebar';
import Notification from '../Shared/Notification';
import ConfirmModal from '../Shared/ConfirmModal';

export interface AssessmentTemplateModel {
    id: string,
    name: string,
    description: string,
    time: number,
    isDeleted: false,
    numOfQuestions: number;
    positivePoints: number,
    negativePoints: number,
}
export interface AssessmentDetailsModel {
    title: string;
    description: string;
    baseScore: string;
    time: string;
    exceptionalScore: string;
    numOfQuestions: string;
    lastUpdatedDate: Date;
    assessmentTemplate?: AssessmentTemplateModel;
}
function AssessmentQuestions() {

    const [assessmentDetails, setAssessmentDetails] = useState<AssessmentDetailsModel>({
        title: '',
        description: '',
        baseScore: '',
        time: '',
        exceptionalScore: '',
        numOfQuestions: '',
        lastUpdatedDate: new Date(),
        assessmentTemplate: undefined
    });

    const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
    const [assessmentQuestions, setAssessmentQuestions] = useState<Questions[]>([]);
    const [allQuestions, setAllQuestions] = useState<Questions[]>([]);
    const [questionGroups, setQuestionGroups] = useState<QuestionGroup[]>([]);
    const [editPanel, setEditPanel] = useState<string>('');
    const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
    const [showSuccessNotifcation, setShowSuccessNotifcation] = useState<boolean>(false);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { id } = useParams();

    const getAssessmentDetails = useCallback(async () => {
        if (id) {
            try {
                const res = await getAssessmentDetailById(id);
                setAssessmentDetails(res);
            } catch (error) {
                console.log(error);
            }
        }
    }, [id]);

    const getAssessmentQuestions = useCallback(async () => {
        if (id) {
            try {
                await getAllQuestionsByQuestionnareId(id).then((res) => {
                    setAssessmentQuestions(res);
                })
            } catch (error) {
                console.log(error)
            }
        }
    }, [id]);

    useEffect(() => {
        getAssessmentDetails();
        getAssessmentQuestions();
        getAllQuestions();

        try {
            fetchQuestionGroups().then((res) => setQuestionGroups(res));
        } catch (error) {
            console.log(error)
        }

    }, [getAssessmentDetails, getAssessmentQuestions])

    const handleAssessmentEdit = () => {
        navigate(`/assessments/edit/${id}`)
    }

    const toggleDescriptionExpansion = () => {
        setIsDescriptionExpanded((prev) => !prev);
    };

    const getAllQuestions = async () => {
        try {
            await getAllQuestionsExcludingQuestionnareId().then((res) => {
                setAllQuestions(res.questions)
                setLoading(false);
            })
        } catch (error) {
            console.log(error)
        }
    };

    const filteredAssessmentQuestionGroup = async (currentQuestionGroup: QuestionGroup | null, currentSkills: string[], accuracy: any) => {
        const { min, max } = accuracy;
        console.log(accuracy)

        if (currentQuestionGroup == null && currentSkills.length <= 0 && min === '0' && max === '100') {
            getAllQuestions();
        }
        else {
            try {
                await getAllFilterQuestions(accuracy, currentQuestionGroup?.id ? [currentQuestionGroup?.id] : [], currentSkills, [], []).then((res) => setAllQuestions(res.questions));

            } catch (error) {
                console.log(error)
            }
        }
    }
    const expandEditablePanel = (id: string) => {
        setEditPanel(id)
    }

    const resetEditPanel = () => {
        setEditPanel('');
    }

    const handleAssessmentDelete = () => {
        setConfirmDelete(true);
    }

    const confirmdeleteAssessment = async () => {
        setConfirmDelete(false);
        try {
            deleteAssessment(id);
            setShowSuccessNotifcation(true);
            setTimeout(() => {
                navigate('/assessments')
            }, 2000)
        } catch (error) {
            console.log(error)

        }

    }

    return (
        <div className="relative dark:bg-darkbg">
            {editPanel && (
                <div className="fixed inset-0 bg-opacity-50 bg-gray-300 dark:bg-gray-700 backdrop-filter backdrop-blur-lg z-100" />
            )}
            <div className={`${editPanel ? "pointer-events-none" : ""}`}>
                <header className="bg-gray-100 py-8 dark:bg-darkbglight">
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
                        <div className="min-w-0 flex-1">
                            <nav className="flex" aria-label="Breadcrumb">
                                <ol className="flex items-center space-x-4">
                                    <li>
                                        <div>
                                            <Link
                                                to="/assessments"
                                                className="text-sm font-medium text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                                            >
                                                Assessments
                                            </Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="flex items-center ">
                                            <ChevronRightIcon
                                                className="h-5 w-5 flex-shrink-0 text-gray-400 dark:text-gray-400"
                                                aria-hidden="true"
                                            />
                                            <span className="ml-4 text-sm font-medium text-gray-500 dark:text-gray-400">
                                                {assessmentDetails.title}
                                            </span>
                                        </div>
                                    </li>
                                </ol>
                            </nav>
                            <div className='flex items-center justify-between mb-4'>
                                <h1 className="mt-2 text-2xl font-bold leading-7 text-gray-800 sm:text-3xl sm:tracking-tight dark:text-gray-300">
                                    {assessmentDetails.title}
                                </h1>
                                <div className='flex flex-col sm:flex-row mt-2 align-center justify-center '>
                                    <button className='inline-flex items-center gap-x-1.5 rounded-md bg-white  px-5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mr-2 ' onClick={handleAssessmentEdit}>
                                        <PencilIcon height={15} color='gray' />
                                        Edit
                                    </button>
                                    <div className='mt-2 sm:mt-0'>
                                        {confirmDelete ? <div className='flex  px-5 py-2.5 '>
                                            <CheckIcon height={20} className='cursor-pointer border-0 border-r-2 border-gray-400 pr-2' onClick={confirmdeleteAssessment} color='red' />

                                            <XMarkIcon height={20} className='cursor-pointer pl-2 dark:text-white' onClick={() => setConfirmDelete(false)} />
                                        </div> :
                                            <button className='inline-flex items-center gap-x-1.5 rounded-md bg-red-500 text-white px-5 py-2.5 font-semibold text-sm font-semibol shadow-sm ring-1 ring-inset ring-red-500 hover:bg-red-600' onClick={handleAssessmentDelete}>
                                                <TrashIcon height={15} color='white' />
                                                Delete
                                            </button>}
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-col '>
                                {assessmentDetails.description ? <div className="flex flex-col py-6 " style={{ width: '80%' }}>
                                    <div>
                                        <h4 className="mt-2 text-xs font-bold leading-7 text-gray-900 sm:text-sm sm:tracking-tight dark:text-gray-400 text-left">
                                            Description
                                        </h4>
                                        {assessmentDetails.description.length > 200 ? <div className='text-left text-sm text-gray-500'>
                                            {isDescriptionExpanded
                                                ? assessmentDetails.description
                                                : `${assessmentDetails.description.slice(0, 200)}...`}
                                        </div>
                                            :
                                            <div className='text-left text-sm text-gray-500'>
                                                {assessmentDetails.description}
                                            </div>}
                                    </div>
                                    {
                                        assessmentDetails.description.length > 200 && <button
                                            className="text-blue-500 hover:underline focus:outline-none flex items-center justify-center my-2"
                                            onClick={toggleDescriptionExpansion}
                                        >
                                            {isDescriptionExpanded ? <ChevronUpIcon height={20} color='gray' />
                                                : <ChevronDownIcon height={20} color='gray' />
                                            }
                                        </button>
                                    }
                                </div>
                                    : <div className='py-6'>
                                    </div>}
                                <div className='flex flex-row'>
                                    <div className='flex items-center space-x-1 mx-2 '>
                                        <ListBulletIcon height={20} color='blue' />
                                        <h4 className='text-xs font-bold leading-7 text-gray-900 sm:text-sm sm:tracking-tight dark:text-gray-400 text-left '>{assessmentDetails.numOfQuestions}</h4>
                                    </div>
                                    <div className='flex items-center space-x-1 mx-2 '>
                                        <ClockIcon height={20} color='blue' />
                                        <h4 className='text-xs font-bold leading-7 text-gray-900 sm:text-sm sm:tracking-tight dark:text-gray-400 text-left '>{assessmentDetails.time}</h4>
                                    </div>

                                    <div className='flex items-center space-x-1 mx-2 '>
                                        <CheckBadgeIcon height={20} color='blue' />
                                        <h4 className='text-xs font-bold leading-7 text-gray-900 sm:text-sm sm:tracking-tight dark:text-gray-400 text-left '>{assessmentDetails.baseScore}</h4>
                                    </div>

                                    {assessmentDetails.exceptionalScore && <div className='flex items-center space-x-1 mx-2 '>
                                        <AcademicCapIcon height={20} color='blue' />
                                        <h4 className='text-xs font-bold leading-7 text-gray-900 sm:text-sm sm:tracking-tight dark:text-gray-400 text-left '>{assessmentDetails.exceptionalScore}</h4>
                                    </div>}
                                </div>


                            </div>

                        </div>
                    </div>
                </header>
                <div className={`${!editPanel ? "relative h-full overflow-hidden" : "relative h-full"}`}>
                    <span className={`${editPanel ? "pointer-events-auto" : ""}`}>
                        <QuestionSidebar questionId={editPanel} resetEditPanel={resetEditPanel} />
                    </span>
                    <div className={`${editPanel ? "hidden " : " "}space-y-12 sm:space-y-16 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 `}>
                        <Kanban selectedQuestions={assessmentQuestions} allQuestions={allQuestions} filteredAssessmentQuestionGroup={filteredAssessmentQuestionGroup} questionGroups={questionGroups} expandEditablePanel={expandEditablePanel} assessmentDetails={assessmentDetails} isLoading={loading} />
                    </div>
                </div>
            </div>
            <Notification
                show={showSuccessNotifcation}
                setShow={setShowSuccessNotifcation}
                title="Deleted"
                description="Assessment Deleted Successfully"
                type="success"
            />

        </div >
    )
}

export default AssessmentQuestions
