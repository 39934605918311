import {
    BriefcaseIcon,
    CheckCircleIcon,
    UserIcon,
} from "@heroicons/react/24/outline";

type StepperType = {
    currentStep: number;
};

const Stepper = ({ currentStep }: StepperType) => {
    const steps = [
        {
            step: "Template",
            icon: <UserIcon className="h-2 w-2 rounded-full" />,
        },
        {
            step: "Test",
            icon: <BriefcaseIcon className="h-2 w-2 rounded-full" />,
        },
    ];

    return (
        <div className="flex justify-between w-full mt-2 border-b-2 border-gray-300 pb-2">
            {steps?.map((step, i) => (
                <div
                    key={i}
                    className="relative flex flex-col justify-center items-center w-full"
                >
                    <div
                        className={`w-4 h-4 flex items-center justify-center z-10 relative rounded-full font-medium text-white ${i + 1 < currentStep
                            ? "bg-orange-500"
                            : currentStep === i + 1
                                ? "bg-blue-500 transition-colors delay-200"
                                : "bg-gray-300 text-gray-800"
                            }`}
                    >
                        {i + 1 < currentStep ? <CheckCircleIcon /> : step.icon}
                    </div>
                    <p className="text-[10px]  text-gray-500  dark:text-white">{step.step}</p>
                    {i !== 0 && (
                        <>
                            {/* Background line */}
                            <div
                                className="absolute w-[80%] h-[2px] -left-[40%] top-1/4 bg-gray-300"
                            />
                            {/* Progress line */}
                            <div
                                className={`absolute w-[80%] h-[2px] -left-[40%] top-1/4 transition-all animate-slide-right duration-500 ${i + 1 <= currentStep
                                    ? "bg-orange-500 w-[80%]"
                                    : "bg-gray-500 w-0"
                                    }`}
                            />
                        </>
                    )}
                </div>
            ))}
        </div>
    );
};

export default Stepper;