import { CalendarDaysIcon } from "@heroicons/react/20/solid";
import { ClockIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { RoleStatus } from "../../../Models";
import useClickOutside from "../../../hooks/useClickOutside";

type SlotModalProps = {
  isOpen: boolean;
  errorText: string | null;
  status: RoleStatus[];
  onClose: () => void;
  onSubmit: (
    startTime: Date,
    endTime: Date,
    slotDuration: number,
    breakDuration: number,
    meetingLink: string,
    statusId: string,
    interviewerEmail: string | null
  ) => void;
};

const SlotModal: React.FC<SlotModalProps> = ({
  isOpen,
  errorText,
  status,
  onClose,
  onSubmit,
}) => {
  const [date, setDate] = useState<Date | null>(null);
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null);
  const [slotDuration, setSlotDuration] = useState<number>(0);
  const [breakDuration, setBreakDuration] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);
  const [meetingLink, setMeetingLink] = useState<string | null>(null);
  const [isToday, setIsToday] = useState(false);
  const now = new Date();
  const [selectedStatus, setSelectedStatus] = useState<RoleStatus | null>(null);
  const [interviewerEmail, setInterviewerEmail] = useState<string | null>(null);
  const createInterviewRef = useRef(null);

  const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = e.target.value;
    const selected = status.find((statusItem) => statusItem.id === selectedId);
    setSelectedStatus(selected || null);
  };

  useClickOutside(createInterviewRef, () => onClose());
  useEffect(() => {
    if (errorText != null) {
      setError(errorText);
    }
  }, [errorText]);
  useEffect(() => {
    const now = new Date();
    if (date != null && date.toDateString() === now.toDateString()) {
      setIsToday(true);
    } else {
      setIsToday(false);
    }
  }, [date]);

  const getCombinedDateTime = (
    selectedDate: Date | null,
    selectedTime: Date | null
  ): Date | null => {
    if (!selectedDate || !selectedTime) return null;
    const combined = new Date(selectedDate);
    combined.setHours(selectedTime.getHours(), selectedTime.getMinutes(), 0, 0);
    return combined;
  };

  const validateInputs = (): boolean => {
    const combinedStartTime = getCombinedDateTime(date, startTime);
    const combinedEndTime = getCombinedDateTime(date, endTime);

    if (!combinedStartTime || !combinedEndTime) {
      setError("Please select a valid start time, end time, and date.");
      return false;
    }

    if (combinedEndTime <= combinedStartTime) {
      setError("End time must be after start time.");
      return false;
    }
    if (!selectedStatus) {
      setError("Select a Status.");
      return false;
    }

    const totalMinutes =
      (combinedEndTime.getTime() - combinedStartTime.getTime()) / 60000;
    if (slotDuration === 0) {
      setError("Slot duration cannot be zero.");
      return false;
    }
    if (slotDuration > totalMinutes) {
      setError(
        "Slot duration cannot exceed the difference between start and end times."
      );
      return false;
    }

    if (!meetingLink?.trim()) {
      setError("Meeting Link cannot be empty");
      return false;
    }
    if (interviewerEmail?.trim()) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(interviewerEmail)) {
        setError("Interviewer email is not in correct format.");
        return false;
      }
    }

    setError(null);
    return true;
  };

  const handleSubmit = () => {
    if (validateInputs()) {
      const combinedStartTime = getCombinedDateTime(date, startTime);
      const combinedEndTime = getCombinedDateTime(date, endTime);

      if (
        combinedStartTime &&
        combinedEndTime &&
        meetingLink &&
        selectedStatus
      ) {
        onSubmit(
          combinedStartTime,
          combinedEndTime,
          slotDuration,
          breakDuration,
          meetingLink,
          selectedStatus?.id,
          interviewerEmail
        );

        handleResetFields();
      }
    }
  };
  const handleResetFields = () => {
    setDate(null);
    setStartTime(null);
    setEndTime(null);
    setBreakDuration(0);
    setSlotDuration(0);
    setError(null);
    setIsToday(false);
    setMeetingLink(null);
    setInterviewerEmail(null);
    setSelectedStatus(null);
  };
  const handleClose = () => {
    handleResetFields();
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-500 backdrop-blur-sm bg-opacity-30 z-50 ">
      <div
        className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md dark:bg-darkbg"
        ref={createInterviewRef}
      >
        <h2 className="text-xl font-semibold text-gray-700 mb-4 dark:text-gray-100">
          Create Interview Slots
        </h2>

        {error && <div className="text-red-500 mb-4 text-sm">{error}</div>}

        <div className="mb-4">
          <label
            htmlFor="status"
            className="block text-sm font-medium text-gray-600 mb-2 dark:text-gray-300 "
          >
            Select Stage <span className="text-red-500">*</span>
          </label>
          <select
            id="status"
            value={selectedStatus?.id || ""}
            onChange={handleStatusChange}
            className="w-full p-2  rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 dark:bg-darkbglight dark:text-gray-200"
          >
            <option value="">Select Stage</option>
            {status.map((statusItem) => (
              <option key={statusItem.id} value={statusItem.id}>
                {statusItem.name}
              </option>
            ))}
          </select>
        </div>

        {/* Date Picker */}
        <div className="mb-4">
          <label className="block text-sm  font-medium text-gray-600 mb-1 dark:text-gray-200">
            Select Date <span className="text-red-500">*</span>
          </label>
          <div className="relative">
            <DatePicker
              selected={date}
              onChange={(selectedDate) => {
                setDate(selectedDate as Date);
                setStartTime(null);
                setEndTime(null);
              }}
              minDate={new Date()}
              dateFormat="MMMM d, yyyy"
              className="w-full p-2  rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 pl-10 dark:bg-darkbglight dark:text-gray-200"
            />
            <CalendarDaysIcon className="w-5 h-5 absolute top-2.5 left-2 text-gray-500" />
          </div>
        </div>

        <div className="flex items-center gap-4 mb-4 ">
          <div className="w-1/2">
            <label className="block text-sm font-medium text-gray-600 dark:text-gray-300  mb-1">
              Start Time (IST) <span className="text-red-500">*</span>{" "}
            </label>
            <div className="relative">
              <DatePicker
                selected={startTime}
                onChange={(time) => setStartTime(time as Date)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                minTime={isToday ? new Date() : new Date(now.setHours(0, 0))}
                maxTime={new Date(now.setHours(23, 45))}
                disabled={!date}
                timeCaption="Start"
                dateFormat="h:mm aa"
                className="w-full p-2  rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 pl-10 dark:bg-darkbglight dark:text-gray-200"
              />
              <ClockIcon className="w-5 h-5 absolute top-2.5 left-2 text-gray-500" />
            </div>
          </div>

          <div className="w-1/2">
            <label className="block text-sm font-medium text-gray-600 mb-1 dark:text-gray-300">
              End Time (IST) <span className="text-red-500">*</span>
            </label>
            <div className="relative">
              <DatePicker
                selected={endTime}
                onChange={(time) => setEndTime(time as Date)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                disabled={!date || !startTime}
                minTime={startTime ? startTime : new Date(now.setHours(0, 0))}
                maxTime={new Date(now.setHours(23, 45))}
                timeCaption="End"
                dateFormat="h:mm aa"
                className="w-full p-2  rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 pl-10 dark:bg-darkbglight dark:text-gray-200"
              />
              <ClockIcon className="w-5 h-5 absolute top-2.5 left-2 text-gray-500" />
            </div>
          </div>
        </div>

        <label className="block text-sm font-medium text-gray-600 mb-1 dark:text-gray-300">
          Slot Duration (minutes) <span className="text-red-500">*</span>
        </label>
        <input
          type="number"
          value={slotDuration}
          onChange={(e) => setSlotDuration(Number(e.target.value))}
          className="w-full p-2  rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 mb-4 dark:bg-darkbglight dark:text-gray-200"
        />

        <label className="block text-sm font-medium text-gray-600 mb-1 dark:text-gray-300">
          Break Duration (minutes){" "}
        </label>
        <input
          type="number"
          value={breakDuration}
          onChange={(e) => setBreakDuration(Number(e.target.value))}
          className="w-full p-2  rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 mb-4 dark:bg-darkbglight dark:text-gray-200"
        />
        <label className="block text-sm font-medium text-gray-600 mb-1 dark:text-gray-300">
          Meeting Link <span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          value={meetingLink ? meetingLink : ""}
          onChange={(e) => setMeetingLink(e.target.value)}
          className="w-full p-2  rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 mb-4 dark:bg-darkbglight dark:text-gray-200"
        />
        <label className="block text-sm font-medium text-gray-600 mb-1 dark:text-gray-300">
          Interviewer Email
        </label>
        <input
          type="text"
          value={interviewerEmail ? interviewerEmail : ""}
          onChange={(e) => setInterviewerEmail(e.target.value)}
          className="w-full p-2  rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 mb-4 dark:bg-darkbglight dark:text-gray-200"
        />

        <div className="flex justify-end space-x-4">
          <button
            onClick={handleClose}
            className="px-4 py-2 text-gray-600 bg-gray-200 rounded hover:bg-gray-300 focus:outline-none focus:ring-1 focus:ring-gray-400"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-blue-700 focus:outline-none focus:ring-1 focus:ring-blue-500"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default SlotModal;
