import { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ArrowUturnLeftIcon, CheckCircleIcon, CheckIcon, ExclamationTriangleIcon, MinusCircleIcon, PlusCircleIcon, ShieldExclamationIcon } from '@heroicons/react/20/solid';
import Slider from "rc-slider";
import { Skill, TemplateData, TemplateSkillMapping, TemplateWithQuestionnaires } from '../../../Models';
import { fetchAllSkills, generateAssessmentTemplateTests, getAllFilterQuestions, getAssessmentTemplateThreshold, postAssessmentTemplate, postAssessmentTemplateTests, postTemplateSkillMapping, recalibrateTemplate, UpdateLog } from '../../../WebCalls';
import Loading from '../../Shared/Loading';
import SkillDropdown from '../Dropdown/SkillDropdown';
import Notification from '../../Shared/Notification';
import Stepper from '../Stepper';

export interface Question {
    id: string;
    text: string;
    type: string;
    time: number;
    tags: string;
    questionGroup: string | null;
    answers: any | null;
    candidateAnswers: any | null;
    numOfMaxAttempts: number | null;
    accuracy: number;
}
export interface TestDetails {
    numberOfTests: string;
    baseScore: string;
    time: string | number;
    questionOption: string;  // Can be 'allQuestions' or 'byCandidateAnswers'
    numberOfTimesAnswered: string | number;
}


export default function GenerateTemplateTestModal({
    size, onCancel, isModalClosed, getTemplateTestData, handleAddAssessmentTemplateTest, prevInputFields, getTemplateSkillMappings, isCreating, currentSelectedTemplate, prevSkillMappings, fetchTemplatesAndRoles, assignTest, isRecalibrating, isEditable
}: {
    title: string, content: string, onCancel: Function, isModalClosed: Function, size: string, getTemplateTestData: Function, handleAddAssessmentTemplateTest: Function, currentSelectedTemplate: TemplateWithQuestionnaires | undefined, prevSkillMappings: TemplateSkillMapping[], isCreating: boolean, getTemplateSkillMappings?: Function, prevInputFields?: TestDetails, fetchTemplatesAndRoles: Function, isRecalibrating: boolean, isEditable: boolean, assignTest?: {
        derivatives: boolean, test: boolean

    }
}) {

    const [open, setOpen] = useState(true);
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [selectedSkillMappings, setSelectedSkillMappings] = useState<TemplateSkillMapping[]>([{
        skillId: '',
        noOfQuestionsMin: 0,
        noOfQuestionsMax: 100,
        difficultyMin: 0,
        difficultyMax: 100,
        variationMin: 0,
        variationMax: 100,
        newQuestionPercentageMin: 0,
        newQuestionPercentageMax: 100,
        questionPercentage: 0,
        aboveThresholdPercentage: 0,
        belowThresholdPercentage: 0,
        locked: false
    }])
  
    const [templateData, setTemplateData] = useState<TemplateData>({
        name: '',
        description: '',
        time: '',
        numOfQuestions: '',
        positivePoints: '',
        negativePoints: '',

    });
    const [fetchedAssessments, setFetchedAssessments] = useState<Question[][]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const cancelButtonRef = useRef(null);
    const [allSkills, setAllSkills] = useState<Skill[]>([]);
    const [skillDetails, setSkillDetails] = useState<{ questions: []; totalCount: number; minDifficultyLevel: number, maxDifficultyLevel: number, threshold: number }[] | undefined>([]);
    const [inputFields, setInputFields] = useState<TestDetails>({
        numberOfTests: "",
        baseScore: "",
        time: currentSelectedTemplate?.time || "",
        questionOption: 'allQuestions',
        numberOfTimesAnswered: "-1",
    });
    const [isAssessmentFetched, setIsAssessmentsFetched] = useState<boolean>(false);

    const [notificationText, setNotificationText] = useState<{ text: string, type: "success" | "error" | "info" }>({
        text: '',
        type: 'success'
    });
    const [toggleNofication, setToggleNofication] = useState<boolean>(false);

    useEffect(() => {
        fetchAllSkills().then((res) => {
            setAllSkills(res);
        })
    }, [])
    useEffect(() => {
        if (prevSkillMappings.length > 0) {
            updatePrevSkillMappings();
        }
    }, [prevSkillMappings])

    const handleSkillSelect = (skillId: string, index: number) => {
        getAllFilterQuestions({ min: 0, max: 100 }, [], [skillId], [], []).then((res) => {
            const currentSkillData = [...(skillDetails || [])];
            const questions = res.questions || [];
            let minAccuracy = Math.min(...questions.map((q: any) => q.accuracy));
            let maxAccuracy = Math.max(...questions.map((q: any) => q.accuracy));
            minAccuracy = minAccuracy === Infinity ? 0 : Math.round(minAccuracy);
            maxAccuracy = maxAccuracy === -Infinity ? 100 : Math.round(maxAccuracy);
            let threshold = res.questions.length > 0 && res.questions[0].skills[0].threshold;
            if (currentSkillData) {
                currentSkillData[index] = {
                    ...res,
                    minDifficultyLevel: minAccuracy,
                    maxDifficultyLevel: maxAccuracy,
                    threshold: threshold
                };
                setSkillDetails(currentSkillData);
            }

            setSelectedSkillMappings(prevMappings => {
                const updatedMappings = [...prevMappings];
                updatedMappings[index] = {
                    ...updatedMappings[index],
                    skillId: skillId,
                    noOfQuestionsMin: 1,
                    noOfQuestionsMax: questions.length,
                    difficultyMin: minAccuracy,
                    difficultyMax: maxAccuracy,
                    variationMin: 0,
                    variationMax: 100,
                    questionPercentage: 0,
                    newQuestionPercentageMin: 0,
                    newQuestionPercentageMax: 100,
                    locked: false
                };
                return updatedMappings;
            });
        });
    };

    const updatePrevSkillMappings = () => {
        let tempSkillMappings: TemplateSkillMapping[] = [];
        const currentSkillData = [...(skillDetails || [])];
        prevSkillMappings.map(async (s: TemplateSkillMapping, i) => {
            tempSkillMappings.push({
                skillId: s.skillId,
                noOfQuestionsMin: currentSelectedTemplate ? (s.questionPercentage / 100) * currentSelectedTemplate.numOfQuestions : 0,
                noOfQuestionsMax: currentSelectedTemplate ? (s.questionPercentage / 100) * currentSelectedTemplate.numOfQuestions : 0,
                difficultyMin: s.difficultyMin,
                difficultyMax: s.difficultyMax,
                variationMin: s.variationMin,
                variationMax: s.variationMax,
                questionPercentage: s.questionPercentage,
                newQuestionPercentageMin: s.newQuestionPercentageMin,
                newQuestionPercentageMax: s.newQuestionPercentageMax,
                aboveThresholdPercentage: 0,
                belowThresholdPercentage: 0,
                locked: true
            })
            await getAllFilterQuestions({ min: 0, max: 100 }, [], [s.skillId], [], []).then((res) => {
                const questions = res.questions || [];
                let minAccuracy = Math.min(...questions.map((q: any) => q.accuracy));
                let maxAccuracy = Math.max(...questions.map((q: any) => q.accuracy));
                minAccuracy = minAccuracy === Infinity ? 0 :Math.round(minAccuracy);
                maxAccuracy = maxAccuracy === -Infinity ? 100 : Math.round(maxAccuracy);

                if (currentSkillData) {
                    currentSkillData[i] = {
                        ...res,
                        minDifficultyLevel: minAccuracy,
                        maxDifficultyLevel: maxAccuracy,
                    };
                }
            });
            setSkillDetails(currentSkillData);
        })
        setSelectedSkillMappings(tempSkillMappings)
    }

    useEffect(() => {
        if (open === false) {
            isModalClosed();
            setFetchedAssessments([])
            setIsAssessmentsFetched(false);
        };
    }, [open, isModalClosed]);
    const validateTestData = (): boolean => {
        const { numberOfTests, baseScore, numberOfTimesAnswered, time, questionOption } = inputFields;
        const { name, numOfQuestions, positivePoints, negativePoints } = templateData;

        if (!numberOfTests || !baseScore || !time || !name || name.length === 0 || !numOfQuestions || !positivePoints || !negativePoints) {
            return false;
        }

        if (isNaN(Number(numberOfTests)) || isNaN(Number(baseScore)) || isNaN(Number(time)) || isNaN(Number(numOfQuestions)) || isNaN(Number(positivePoints)) || isNaN(Number(negativePoints))) {
            return false;
        }

        if (questionOption === "byCandidateAnswers") {
            if (!numberOfTimesAnswered || isNaN(Number(numberOfTimesAnswered))) {
                return false;
            }
        }
        return true;
    };
    useEffect(() => {
        if (prevInputFields) {
            setInputFields(prevInputFields);
        }
    }, [prevInputFields])

    const distributeQuestionsEfficiently = async (
        skills: TemplateSkillMapping[],
        numberOfQuestionsPerTest: number
    ) => {
        let minTotal = skills.reduce((sum, skill) => sum + Math.round(skill.noOfQuestionsMin), 0);
        let maxTotal = skills.reduce((sum, skill) => sum + Math.round(skill.noOfQuestionsMax), 0);

        if (numberOfQuestionsPerTest) {
            if ((numberOfQuestionsPerTest < minTotal || numberOfQuestionsPerTest > maxTotal)) {
                setNotificationText({
                    text: `The sum of all minimum range for questions must be equal to ${currentSelectedTemplate ? currentSelectedTemplate.numOfQuestions : templateData?.numOfQuestions}`,
                    type: 'error'
                });
                setToggleNofication(true);
                return;
            }

            let questionDistribution = skills.map(skill => ({
                skillId: skill.skillId,
                noOfQuestions: skill.noOfQuestionsMin,
                belowThresholdQuestions: 0,
                aboveThresholdQuestions: 0
            }));

            let remainingQuestions = numberOfQuestionsPerTest - minTotal;

            while (remainingQuestions > 0) {
                for (let i = 0; i < questionDistribution.length; i++) {
                    if (questionDistribution[i].noOfQuestions < skills[i].noOfQuestionsMax) {
                        questionDistribution[i].noOfQuestions++;
                        remainingQuestions--;
                    }
                    if (remainingQuestions === 0) break;
                }
            }

            const updatedSkills = skills.map((skill, index) => {
                const skillDistribution = questionDistribution[index];
                const totalQuestions = skillDistribution.noOfQuestions;
                const newQuestionPercentageMin = skill.newQuestionPercentageMin;
                const newQuestionPercentageMax = skill.newQuestionPercentageMax;
                const minNewQuestions = Math.ceil(totalQuestions * (newQuestionPercentageMin / 100));
                const maxNewQuestions = Math.floor(totalQuestions * (newQuestionPercentageMax / 100));
                const belowThresholdQuestions = Math.max(minNewQuestions,
                    Math.min(maxNewQuestions, Math.round(totalQuestions * (newQuestionPercentageMin / 100))));

                const aboveThresholdQuestions = totalQuestions - belowThresholdQuestions;

                return {
                    ...skill,
                    questionPercentage: (totalQuestions / numberOfQuestionsPerTest) * 100,
                    belowThresholdPercentage: (belowThresholdQuestions / totalQuestions) * 100,
                    aboveThresholdPercentage: (aboveThresholdQuestions / totalQuestions) * 100,
                    belowThresholdQuestions,
                    aboveThresholdQuestions
                };
            });

            setSelectedSkillMappings(updatedSkills);
            return updatedSkills;
        }
    };
    const handlePageCalls = async (pgNo: number) => {
        let updatedSkillMappings;
        if (currentSelectedTemplate) {
            updatedSkillMappings = await distributeQuestionsEfficiently(selectedSkillMappings, currentSelectedTemplate.numOfQuestions);
        } else {
            updatedSkillMappings = await distributeQuestionsEfficiently(selectedSkillMappings, Number(templateData.numOfQuestions));
        }
        if (pgNo === 0) {
            setPageNumber(1);
        }
        if (pgNo === 1 && validateFields() && updatedSkillMappings) {
            if (currentSelectedTemplate && updatedSkillMappings) {
                await generateAssessmentTemplateTests(currentSelectedTemplate.numOfQuestions, inputFields.numberOfTests, inputFields.numberOfTimesAnswered, updatedSkillMappings).then((res) => {
                    setFetchedAssessments(res);
                    setIsAssessmentsFetched(true);
                    setLoading(false);
                });
            }
            else {
                await generateAssessmentTemplateTests(templateData.numOfQuestions, inputFields.numberOfTests, inputFields.numberOfTimesAnswered, updatedSkillMappings).then((res) => {
                    setFetchedAssessments(res);
                    setIsAssessmentsFetched(true);
                    setLoading(false);
                });
            }

        }
        else if (pgNo === 2) {
            if (currentSelectedTemplate && !assignTest?.derivatives && !assignTest?.test) {
                if (isEditable) {
                    await UpdateLog(currentSelectedTemplate.id);
                }
                await postAssessmentTemplate(templateData).then(async (res1) => {
                    const templateId = res1.id;

                    await postTemplateSkillMapping(templateId, selectedSkillMappings).then(async () => {
                        await postAssessmentTemplateTests(fetchedAssessments, templateId, inputFields.baseScore, inputFields.time);
                        setNotificationText({ text: 'Set Created Successfully', type: 'success' });
                        setToggleNofication(true);
                        setTimeout(async () => {
                            // resetTemplateData(false);
                            fetchTemplatesAndRoles();
                            setSelectedSkillMappings([]);

                        }, 1000);
                        setOpen(false);

                    });
                });
            }
            else if (assignTest?.derivatives && !assignTest?.test) {
                postTemplateSkillMapping(currentSelectedTemplate?.id, selectedSkillMappings).then(() => {
                    postAssessmentTemplateTests(fetchedAssessments, currentSelectedTemplate?.id, inputFields.baseScore, inputFields.time);
                    setNotificationText({ text: 'Set Created Successfully', type: 'success' });
                    setToggleNofication(true);
                    setTimeout(async () => {
                        // resetTemplateData(false);
                        fetchTemplatesAndRoles();
                    }, 1000);

                    setOpen(false);
                });

            }
            else if (!assignTest?.derivatives && assignTest?.test) {
                postAssessmentTemplateTests(fetchedAssessments, currentSelectedTemplate?.id, inputFields.baseScore, inputFields.time);
                setNotificationText({ text: 'Set Created Successfully', type: 'success' });
                setToggleNofication(true);
                setTimeout(async () => {
                    // resetTemplateData(false);
                    fetchTemplatesAndRoles();
                }, 1000);

                setOpen(false);
            }
            else {
                await postAssessmentTemplate(templateData).then((res1) => {
                    const templateId = res1.id;
                    postTemplateSkillMapping(templateId, selectedSkillMappings).then(() => {
                        postAssessmentTemplateTests(fetchedAssessments, templateId, inputFields.baseScore, inputFields.time);
                        setNotificationText({ text: 'Set Created Successfully', type: 'success' });
                        setToggleNofication(true);
                        setTimeout(async () => {
                            // resetTemplateData(false);
                            fetchTemplatesAndRoles();
                        }, 1000);

                        setOpen(false);
                    });
                });
            }
        }
        else if (pgNo === 3) {
            if (currentSelectedTemplate) {
                await recalibrateTemplate(currentSelectedTemplate.id, templateData, fetchedAssessments, selectedSkillMappings, inputFields.baseScore).then(() => { 
                    setTimeout(async () => {
                        fetchTemplatesAndRoles();
                        setSelectedSkillMappings([]);
                    }, 1000)
                    setNotificationText({ text: 'Set Recalibrated Successfully', type: 'success' });
                    setToggleNofication(true);
                    setOpen(false);
                })
            }
        }
    }
    const validateFields = () => {
        const skillIds = selectedSkillMappings.map(mapping => mapping.skillId);
        const hasDuplicates = skillIds.some((skillId, index) => skillIds.indexOf(skillId) !== index);
        if (hasDuplicates) {
            setNotificationText({ text: 'Please remove duplicate skills from the list.', type: 'error' });
            setToggleNofication(true);
            setLoading(false)

            return false;
        }
        return true;
    };
    const handleAddAssessmentSkillMapping = () => {
        setSelectedSkillMappings((prevSkillMappings) => [
            ...prevSkillMappings,
            {
                skillId: '',
                noOfQuestionsMin: 0,
                noOfQuestionsMax: 100,
                difficultyMin: 0,
                difficultyMax: 100,
                variationMin: 0,
                variationMax: 100,
                newQuestionPercentageMin: 0,
                newQuestionPercentageMax: 100,
                questionPercentage: 0,
                aboveThresholdPercentage: 0,
                belowThresholdPercentage: 0,
                locked: false
            }
        ]);
    };
    useEffect(() => {
        setFetchedAssessments([]);
        setIsAssessmentsFetched(false);
    }, [selectedSkillMappings, inputFields, templateData.name, templateData.negativePoints, templateData.numOfQuestions, templateData.positivePoints, templateData.time])

    const handleRemoveAssessmentSkillMapping = (index: number) => {
        setSelectedSkillMappings((prevSkillMappings) =>
            prevSkillMappings.filter((_, i) => i !== index)
        );
        setSkillDetails((skillDetails) =>
            skillDetails?.filter((_, i) => i !== index)
        );
        setFetchedAssessments([])
        setIsAssessmentsFetched(false);
    };
    const handleToggleLock = (index: number) => {
        setSelectedSkillMappings((prevSkillMappings) =>
            prevSkillMappings.map((skill, i) =>
                i === index ? { ...skill, locked: !skill.locked } : skill
            )
        );
    };
    const handleTestDetails = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setInputFields(prev => ({
            ...prev,
            [name]: value === '' ? '' : Number(value)
        }));
    };

    const validateInputFields = () => {
        const { name, description, time, numOfQuestions, positivePoints, negativePoints } = templateData;
        if (
            name.trim() === '' ||
            description.trim() === '' ||
            time === '' ||
            numOfQuestions === '' ||
            positivePoints === '' ||
            negativePoints === ''

        ) {
            return false;
        }
        return true;

    };
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value, type } = e.target;
        setTemplateData(prev => ({
            ...prev,
            [name]: type === 'number' ? Number(value) : value
        }));
    };
    useEffect(() => {
        if (currentSelectedTemplate) {
            setInputFields({
                numberOfTests: currentSelectedTemplate.assessmentQuestionnaires.length>0 ? String(currentSelectedTemplate.assessmentQuestionnaires.length) : "",
                baseScore:currentSelectedTemplate.assessmentQuestionnaires.length>0 ? String(currentSelectedTemplate.assessmentQuestionnaires[0].baseScore) || "60" : "",
                time: currentSelectedTemplate?.time || "",
                questionOption: 'allQuestions',
                numberOfTimesAnswered: "-1",
            })
            setTemplateData({
                name: currentSelectedTemplate.name,
                description: currentSelectedTemplate.description,
                time: currentSelectedTemplate.time,
                numOfQuestions: currentSelectedTemplate.numOfQuestions,
                positivePoints: currentSelectedTemplate.positivePoints,
                negativePoints: currentSelectedTemplate.negativePoints,
            })
        }
    }, [currentSelectedTemplate])
    return (
        <Transition.Root show={open} as={Fragment} >
            <Dialog
                as="div"
                className="relative z-10 dark:bg-entntdarkblue"
                initialFocus={cancelButtonRef}
                onClose={() => {
                    onCancel();
                    setOpen(false);
                }}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-400  bg-opacity-50 dark:bg-opacity-20 transition-opacity " />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto ">
                    <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0 ">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:w-full h-[48rem] flex flex-col dark:bg-darkbg sm:max-w-7xl"

                            >
                                <div className='bg-gray-200 h-12 p-4  dark:bg-darkbglight'>
                                    <h2 className='font-bold text-gray-600 text-sm text-left dark:text-gray-300 flex flex-row justify-between items-center'>
                                        <div>
                                            {isRecalibrating ? "Update Parameters" : "Create Template Tests"}
                                        </div>
                                        {/* <p className='italic text-xs px-4 dark:text-gray-300 font-normal'>
                                            Fill in the fields below to generate tests based on the specified values.
                                        </p> */}
                                    </h2>
                                </div>
                                <div className='flex flex-col flex-grow overflow-hidden'>
                                    {(!currentSelectedTemplate) && <Stepper currentStep={pageNumber + 1} />}
                                    <div className='flex-grow overflow-y-auto '>
                                        {!loading && pageNumber === 1 ? <>
                                            <div className='flex flex-col p-2 flex-grow gap-4 h-full dark:text-white text-[14px]'>
                                                <hr className="border-gray-300 dark:border-gray-800 " />
                                                <div className="flex flex-row items-start p-2 gap-4 mt-2 w-[90%] h-full">
                                                    <label htmlFor="" className=" text-left w-[8%]">
                                                        <small>Description:</small>
                                                        <span className='text-red-700 text-sm'>*</span>
                                                    </label>
                                                    <textarea name="description" id="" className={`block w-[90%] h-full border border-gray-300 rounded-md bg-transparent py-1.5 pl-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 dark:text-gray-300 dark:placeholder:text-gray-600 dark:border-gray-600 `}
                                                        onChange={handleInputChange} value={templateData.description}
                                                        placeholder='e.g Welcome to our online assessment designed to evaluate your skills for this job role. Specific skills tested are JavaScript, React, SQL and general programming concepts. The test consists of 50 multiple-choice questions and is designed to be completed within 40 minutes. This is a timed test, so please ensure you have a stable internet connection and a quiet environment to complete it.'
                                                    />
                                                </div>
                                                <div className='mt-auto p-2'>
                                                    <hr className='pb-2 border-gray-400' />
                                                    <div className='flex justify-between items-center'>
                                                        <div className='flex flex-row items-center gap-2'>
                                                            <button
                                                                type="button"
                                                                onClick={() => {
                                                                    onCancel();
                                                                    setOpen(false);
                                                                }}
                                                                className="mt-3 inline-flex w-full justify-center rounded-md bg-gray-100 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto h-9"
                                                                ref={cancelButtonRef}
                                                            >
                                                                Cancel
                                                            </button>
                                                            {!currentSelectedTemplate && <button
                                                                type="button"
                                                                onClick={() => {
                                                                    setPageNumber(0);
                                                                }}
                                                                className="mt-3  w-full justify-center rounded-md bg-indigo-600 hover:bg-indigo-500 px-3 py-2 text-sm font-semibold shadow-sm  ring-gray-300 sm:mt-0 sm:w-auto h-9 flex flex-row items-center gap-2 text-white"
                                                                ref={cancelButtonRef}
                                                            >
                                                                Prev
                                                                <ArrowUturnLeftIcon height={15} />
                                                            </button>}
                                                        </div>
                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                // onCancel();
                                                                // setOpen(false);
                                                                // handleAddAssessmentTemplateTest();
                                                                // setInputFields(prev => ({
                                                                //     ...prev,
                                                                //     time: templateData.time,
                                                                // }));
                                                                // setPageNumber(1)
                                                                handlePageCalls(2);
                                                                // handlePageCalls(0);
                                                            }}
                                                            disabled={!validateInputFields()}
                                                            className={`rounded-md px-4 h-9 py-2 text-sm font-semibold shadow-sm focus:outline-none text-white cursor-pointer 
                                                                ${validateInputFields() ? ' bg-indigo-600 hover:bg-indigo-500' : 'bg-gray-600 hover:bg-gray-500'}`}
                                                            ref={cancelButtonRef}
                                                        >
                                                            Save
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                        </>
                                            : pageNumber === 0 ?
                                                <div className='flex flex-col h-full p-2'>
                                                    {!isRecalibrating && <>
                                                        <div className='flex flex-row'>
                                                            <div className="flex flex-row items-center p-2 gap-4  w-full max-w-lg text-xs dark:text-gray-300">
                                                                <label htmlFor="" className="w-1/4 text-left"><small>Name: <span className='text-red-700 text-sm'>*</span> </small></label>
                                                                <input type="text" name="name" id="" className="block h-8 w-[60%] border border-gray-300 rounded-md bg-transparent py-1.5 pl-3 text-gray-900 placeholder:text-gray-400 placeholder:text-xs  focus:ring-0 sm:text-sm sm:leading-6 dark:text-gray-300 dark:placeholder:text-gray-500 dark:border-gray-600 " onChange={handleInputChange}
                                                                    value={templateData.name}
                                                                    placeholder='e.g Software Eg'
                                                                />
                                                            </div>

                                                            <div className="flex flex-row items-center p-2 gap-4  w-full max-w-lg text-xs dark:text-gray-300">
                                                                <label htmlFor="" className="w-1/4 text-left"><small>No. of Questions: <span className='text-red-700 text-sm'>*</span> </small></label>
                                                                <input type="number" name="numOfQuestions" id="" className="block h-8 w-[60%] border border-gray-300 rounded-md bg-transparent py-1.5 pl-3 text-gray-900 placeholder:text-gray-400 placeholder:text-xs  focus:ring-0 sm:text-sm sm:leading-6 dark:text-gray-300 dark:placeholder:text-gray-500 dark:border-gray-600 " onChange={handleInputChange}
                                                                    value={templateData.numOfQuestions}
                                                                    placeholder='e.g 50'
                                                                />
                                                            </div>
                                                            <div className="flex flex-row items-center  w-full max-w-lg text-xs dark:text-gray-300 ">

                                                                <div className='w-[50%] flex flex-row items-center gap-2'>
                                                                    <label htmlFor="" className=""><small>+ve Points: <span className='text-red-700 text-sm'>*</span> </small></label>
                                                                    <input type="number" name="positivePoints" id="" className="block h-8  border border-gray-300 rounded-md bg-transparent py-1.5 pl-3 text-gray-900 placeholder:text-gray-400 placeholder:text-xs  focus:ring-0 sm:text-sm sm:leading-6 dark:text-gray-300 dark:placeholder:text-gray-500 dark:border-gray-600 w-24 " onChange={handleInputChange}
                                                                        value={templateData.positivePoints}
                                                                        placeholder='e.g 1'
                                                                    />
                                                                </div>
                                                                <div className='w-[50%] flex flex-row items-center gap-2'>
                                                                    <label htmlFor="" className=""><small>-ve Points: <span className='text-red-700 text-sm'>*</span> </small></label>
                                                                    <input type="number" name="negativePoints" id="" className="block h-8  border border-gray-300 rounded-md bg-transparent py-1.5 pl-3 text-gray-900 placeholder:text-gray-400 placeholder:text-xs  focus:ring-0 sm:text-sm sm:leading-6 dark:text-gray-300 dark:placeholder:text-gray-500 dark:border-gray-600 w-24" onChange={handleInputChange}
                                                                        value={templateData.negativePoints}
                                                                        placeholder='e.g 0.25'
                                                                    />
                                                                </div>

                                                            </div>

                                                        </div>
                                                        <hr className="border-gray-300 dark:border-gray-800 " />
                                                        <div className='flex flex-row'>
                                                            <div className="flex flex-row items-center p-2 gap-4  w-full max-w-lg text-xs dark:text-gray-300">
                                                                <label htmlFor="" className="w-1/4 text-left"><small>Number Of Tests: <span className='text-red-700 text-sm'>*</span> </small></label>
                                                                <input type="number" name="numberOfTests" id="" className="block h-8 w-[60%] border border-gray-300 rounded-md bg-transparent py-1.5 pl-3 text-gray-900 placeholder:text-gray-400 placeholder:text-xs  focus:ring-0 sm:text-sm sm:leading-6 dark:text-gray-300 dark:placeholder:text-gray-500 dark:border-gray-600 " placeholder='Eg. 3' onChange={handleTestDetails}
                                                                    value={inputFields.numberOfTests}
                                                                />
                                                            </div>
                                                            <div className="flex flex-row items-center p-2 gap-4  w-full max-w-lg text-xs dark:text-gray-300">
                                                                <label htmlFor="" className="w-1/4 text-left"><small>Base Score: <span className='text-red-700 text-sm'>*</span></small></label>
                                                                <input type="number" name="baseScore" id="" className="block h-8  w-[60%] border border-gray-300 rounded-md bg-transparent py-1.5 pl-3 text-gray-900 placeholder:text-gray-400 placeholder:text-xs focus:ring-0 sm:text-sm sm:leading-6 dark:text-gray-300 dark:placeholder:text-gray-500 dark:border-gray-600 " placeholder='Eg. 60' onChange={handleTestDetails} value={inputFields.baseScore} />
                                                            </div>

                                                            <div className="flex flex-row items-center p-2 gap-4  w-full max-w-lg text-xs dark:text-gray-300 ">
                                                                <label htmlFor="" className="w-1/4 text-left"><small>Time: <small className='text-[10px]'>(in mins)</small> <span className='text-red-700 text-sm'>*</span></small></label>
                                                                <input type="number" name="time" id="" className="block h-8  w-[60%] border border-gray-300 rounded-md bg-transparent py-1.5 pl-3 text-gray-900 placeholder:text-gray-400  dark:placeholder:text-gray-500 placeholder:text-xs focus:ring-0 sm:text-sm sm:leading-6 dark:text-gray-300 dark:border-gray-600 " placeholder='Eg. 60 (mins)' onChange={(e) => { handleTestDetails(e); handleInputChange(e) }} value={inputFields.time} />
                                                            </div>
                                                        </div>
                                                        <hr className="border-gray-300 dark:border-gray-800 m-2" />
                                                    </>}

                                                    {/* <div className='flex flex-row dark:text-gray-300 text-gray-900 text-xs p-2 h-10'>
                                                    <div className='flex flex-row items-center gap-4 text-xs dark:text-gray-300'>
                                                        <div>Include Questions :</div>
                                                        <div className='flex flex-row gap-2 items-center'>
                                                            <input className='form-checkbox z-[11] bg-transparent left-4 top-1/2 rounded border-gray-300 text-indigo-600 focus:outline-none' type="radio" id="allQuestions" name="questionOption" value="allQuestions" checked={inputFields.questionOption === 'allQuestions'} onChange={handleQuestionOptionChange} />
                                                            <label htmlFor="allQuestions">All Questions</label>
                                                        </div>
                                                        <div className='flex flex-row gap-2  items-center justify-between'>
                                                            <input className='form-checkbox z-[11] bg-transparent left-4 top-1/2 rounded border-gray-300 text-indigo-600 focus:outline-none' type="radio" id="byCandidateAnswers" name="questionOption" value="byCandidateAnswers" checked={inputFields.questionOption === 'byCandidateAnswers'} onChange={handleQuestionOptionChange} />
                                                            <label htmlFor="byCandidateAnswers">By Candidate Answers: </label>
                                                            {inputFields.questionOption === 'byCandidateAnswers' && <input type="number" name="byCandidateAnswers" id="" className="block h-8  border border-gray-300 rounded-md bg-transparent py-1.5 pl-3 text-gray-900 placeholder:text-gray-400  dark:placeholder:text-gray-500 placeholder:text-xs focus:ring-0 sm:text-sm sm:leading-6 dark:text-gray-300 
                                                                dark:border-gray-600 " placeholder='Eg. 300'
                                                                onChange={handleCandidateAnswersChange} value={inputFields.numberOfTimesAnswered}
                                                            />}

                                                        </div>
                                                    </div>
                                                </div> */}

                                                    <div className='px-2 dark:border-gray-700 border-gray-300 dark:text-white flex-grow  max-h-[65%]'>
                                                        <div className='text-xs pb-2'>{isRecalibrating ? "Update Skills:" : "Select Skills:"}</div>
                                                        <div className='h-[100%] overflow-y-auto overflow-hidden'>
                                                            {selectedSkillMappings.map((s: TemplateSkillMapping, index: number) => {
                                                                return <div className='flex flex-row items-center gap-12 pb-4' key={index}>
                                                                    <div className=''>
                                                                        <SkillDropdown skills={allSkills} onSkillSelect={handleSkillSelect} index={index} skillIds={selectedSkillMappings.map((e => e.skillId))} />

                                                                        <div className="text-[10px] text-gray-500 dark:text-gray-300 italic p-2">
                                                                            {skillDetails && skillDetails[index]?.totalCount !== undefined ? (
                                                                                <>

                                                                                    There are {skillDetails[index]?.totalCount} questions available.
                                                                                </>
                                                                            ) : ""}
                                                                        </div>

                                                                    </div>
                                                                    <div className='w-[17%] flex flex-col items-center'>
                                                                        <Slider
                                                                            range
                                                                            max={skillDetails?.[index]?.totalCount ?? 100}
                                                                            min={1}
                                                                            value={[
                                                                                s.noOfQuestionsMin || 0,
                                                                                s.noOfQuestionsMax || (skillDetails?.[index]?.totalCount ?? 100)
                                                                            ]}
                                                                            onChange={(value: any) => {
                                                                                const [min, max] = value as number[];
                                                                                const totalCount = skillDetails?.[index]?.totalCount ?? 100;
                                                                                if (min >= 0 && max <= totalCount) {
                                                                                    setSelectedSkillMappings(prevMappings =>
                                                                                        prevMappings.map((mapping, i) =>
                                                                                            i === index
                                                                                                ? { ...mapping, noOfQuestionsMin: min, noOfQuestionsMax: max }
                                                                                                : mapping
                                                                                        )
                                                                                    );
                                                                                }
                                                                            }}
                                                                            onChangeComplete={(value: any) => {
                                                                                const [min, max] = value as number[];
                                                                                const totalCount = skillDetails?.[index]?.totalCount ?? 100;
                                                                                if (min >= 0 && max <= totalCount) {
                                                                                }
                                                                            }}
                                                                            disabled={s.locked}
                                                                            styles={{
                                                                                track: {
                                                                                    background: "#0D42EC",
                                                                                    fontSize: "10px",
                                                                                },
                                                                                handle: {
                                                                                    borderColor: "#0D42EC",
                                                                                    fontSize: "10px",
                                                                                },
                                                                            }}
                                                                        />
                                                                        <div className='text-[10px] text-gray-500 dark:text-gray-300'>
                                                                            No. of Questions
                                                                        </div>
                                                                    </div><div className='w-[17%] flex flex-col items-center'>
                                                                        <Slider
                                                                            range
                                                                            max={skillDetails ? skillDetails[index]?.maxDifficultyLevel : 100}
                                                                            min={skillDetails ? skillDetails[index]?.minDifficultyLevel : 0}
                                                                            value={[s.difficultyMin, s.difficultyMax]}
                                                                            onChange={(value: any) => {
                                                                                const [min, max] = value as number[];
                                                                                if (min >= 0 && max <= 100) {
                                                                                    setSelectedSkillMappings(prevMappings =>
                                                                                        prevMappings.map((mapping, i) =>
                                                                                            i === index
                                                                                                ? { ...mapping, difficultyMin: min, difficultyMax: max }
                                                                                                : mapping
                                                                                        )
                                                                                    );
                                                                                }
                                                                            }}
                                                                            onChangeComplete={(value: any) => {
                                                                                const [min, max] = value as number[];
                                                                                if (min >= 0 && max <= 100) {

                                                                                }
                                                                            }}
                                                                            disabled={s.locked}
                                                                            styles={{
                                                                                track: {
                                                                                    background: "#0D42EC",
                                                                                    fontSize: "10px"
                                                                                },
                                                                                handle: {
                                                                                    borderColor: "#0D42EC",
                                                                                    fontSize: "10px"
                                                                                },
                                                                            }}

                                                                        />
                                                                        <div className='text-[10px] text-gray-500 dark:text-gray-300'>
                                                                            Difficulty
                                                                        </div>
                                                                    </div>
                                                                    <div className='w-[17%] flex flex-col items-center'>
                                                                        <Slider
                                                                            range
                                                                            max={100}
                                                                            min={0}
                                                                            value={[s.variationMin, s.variationMax]}
                                                                            onChange={(value: any) => {
                                                                                const [min, max] = value as number[];
                                                                                if (min >= 0 && max <= 100) {
                                                                                    setSelectedSkillMappings(prevMappings =>
                                                                                        prevMappings.map((mapping, i) =>
                                                                                            i === index
                                                                                                ? { ...mapping, variationMin: min, variationMax: max }
                                                                                                : mapping
                                                                                        )
                                                                                    );
                                                                                }
                                                                            }}
                                                                            onChangeComplete={(value: any) => {
                                                                                const [min, max] = value as number[];
                                                                                if (min >= 0 && max <= 100) {
                                                                                    // getAccuracy({
                                                                                    //     min: min,
                                                                                    //     max: max,
                                                                                    // });
                                                                                }
                                                                            }}
                                                                            disabled={s.locked}
                                                                            styles={{
                                                                                track: {
                                                                                    background: "#0D42EC",
                                                                                    fontSize: "10px"

                                                                                },
                                                                                handle: {
                                                                                    borderColor: "#0D42EC",
                                                                                    fontSize: "10px"
                                                                                },
                                                                            }}

                                                                        />
                                                                        <div className='text-[10px] text-gray-500 dark:text-gray-300'>
                                                                            Variation
                                                                        </div>
                                                                    </div>
                                                                    <div className='w-[17%] flex flex-col items-center'>
                                                                        <Slider
                                                                            range
                                                                            max={100}
                                                                            min={0}
                                                                            value={[s.newQuestionPercentageMin, s.newQuestionPercentageMax]}
                                                                            onChange={(value: any) => {
                                                                                const [min, max] = value as number[];
                                                                                if (min >= 0 && max <= 100) {
                                                                                    setSelectedSkillMappings(prevMappings =>
                                                                                        prevMappings.map((mapping, i) =>
                                                                                            i === index
                                                                                                ? { ...mapping, newQuestionPercentageMin: min, newQuestionPercentageMax: max }
                                                                                                : mapping
                                                                                        )
                                                                                    );
                                                                                }
                                                                            }}
                                                                            onChangeComplete={(value: any) => {
                                                                                const [min, max] = value as number[];
                                                                                if (min >= 0 && max <= 100) {
                                                                                    // getAccuracy({
                                                                                    //     min: min,
                                                                                    //     max: max,
                                                                                    // });
                                                                                }
                                                                            }}
                                                                            disabled={s.locked}
                                                                            styles={{
                                                                                track: {
                                                                                    background: "#0D42EC",
                                                                                    fontSize: "10px"

                                                                                },
                                                                                handle: {
                                                                                    borderColor: "#0D42EC",
                                                                                    fontSize: "10px"
                                                                                },
                                                                            }}

                                                                        />

                                                                        <div className="text-[10px] text-gray-500 dark:text-gray-300">
                                                                            {skillDetails && skillDetails[index]?.totalCount !== undefined ? (
                                                                                <>

                                                                                    % Of New Questions below {skillDetails[index]?.threshold}
                                                                                </>
                                                                            ) : "Candidate Answer Threshold"}
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex flex-row gap-6 items-center pb-4'>
                                                                        <button
                                                                            className='rounded-md px-2 text-xs py-2 font-semibold shadow-sm focus:outline-none bg-indigo-600 hover:bg-indigo-500 text-white cursor-pointer'
                                                                            onClick={() => handleToggleLock(index)}
                                                                        >
                                                                            {s.locked ? "Unlock" : "Lock"}
                                                                        </button>
                                                                        <MinusCircleIcon color='red' height={20} onClick={() => handleRemoveAssessmentSkillMapping(index)} cursor={"pointer"} />
                                                                    </div>
                                                                </div>
                                                            })}
                                                            <PlusCircleIcon height={20} className='m-1 mt-2 ' color='blue' onClick={handleAddAssessmentSkillMapping} cursor={"pointer"} />
                                                        </div>
                                                    </div>
                                                    <div className='mt-auto p-2'>
                                                        <div className='flex flex-row justify-between text-gray-800 p-2 dark:border-gray-500 border-gray-300 border-y-2 text-xs mb-2 dark:text-white'>
                                                            {isAssessmentFetched ?
                                                                `${fetchedAssessments?.length}/${inputFields.numberOfTests} tests are generated with the specified values.` :
                                                                "Fill in the fields to generate tests based on the specified values."}
                                                        </div>
                                                        <div className='flex justify-between items-center'>
                                                            <div className='flex flex-row gap-2'>
                                                                <button
                                                                    type="button"
                                                                    onClick={() => {
                                                                        onCancel();
                                                                        setOpen(false);
                                                                    }}
                                                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-gray-100 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto h-9"
                                                                    ref={cancelButtonRef}
                                                                >
                                                                    Cancel
                                                                </button>
                                                            </div>
                                                            {fetchedAssessments?.length === 0 ? <button
                                                                type="button"
                                                                onClick={() => {
                                                                    // onCancel();
                                                                    // setOpen(false);
                                                                    // handleAddAssessmentTemplateTest();
                                                                    // setPageNumber(2)
                                                                    handlePageCalls(1);

                                                                }}
                                                                disabled={!validateTestData()}
                                                                className={`rounded-md px-4 h-9 py-2 text-sm font-semibold shadow-sm focus:outline-none text-white cursor-pointer 
                                                                ${validateTestData() ? ' bg-indigo-600 hover:bg-indigo-500' : 'bg-gray-600 hover:bg-gray-500'}`}
                                                                ref={cancelButtonRef}
                                                            >
                                                                Generate
                                                            </button> : <button
                                                                disabled={!validateTestData()}
                                                                className={`rounded-md px-4 h-9 py-2 text-sm font-semibold shadow-sm focus:outline-none text-white cursor-pointer 
                                                                ${validateTestData() && (isAssessmentFetched && fetchedAssessments && fetchedAssessments.length > 0) ? ' bg-indigo-600 hover:bg-indigo-500' : 'bg-gray-600 hover:bg-gray-500'}`}
                                                                ref={cancelButtonRef}
                                                                onClick={() => {
                                                                    if (isAssessmentFetched && fetchedAssessments && fetchedAssessments.length > 0) {
                                                                        if (isRecalibrating) {
                                                                            handlePageCalls(3);
                                                                        }
                                                                        else {
                                                                            setPageNumber(1);
                                                                        }
                                                                    }
                                                                }}
                                                            >
                                                                {isRecalibrating ? "Recalibrate" : "Next"}
                                                            </button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : <></>}
                                    </div>
                                </div>
                                {toggleNofication &&
                                    <Notification show={toggleNofication} setShow={setToggleNofication} title={notificationText.type.charAt(0).toUpperCase() + notificationText.type.slice(1)} description={notificationText.text} type={notificationText.type} />
                                }
                                {loading && <Loading />}
                            </Dialog.Panel>

                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root >
    );
}