import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { QuarantineCompany, QuarantineUniversity } from "../../../Models";
import SearchDropDown from "./searchDropDown";

let debounceTimeout: NodeJS.Timeout;

type props = {
  type: "info" | "danger";
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  description: string;
  dropDownPlaceHolder: string;
  dropDownValues: QuarantineCompany[] | QuarantineUniversity[];
  hideOthers?: boolean;
  selected?: string;
  selectedRows: any[];
  setSelectedRows: React.Dispatch<React.SetStateAction<any[]>>;
  nonSelectedOptions: string[];
  fetchConfirmedData: (name?: string) => Promise<void>;
  deleteInstitute: (quarantineId: string, confirmedId: string) => Promise<void>;
};

const tabs = ["Companies", "Universities"];
export default function RejectModal(props: props) {
  const cancelButtonRef = useRef(null);
  const danger = props.type === "danger";
  const [rejectWithoutSelecting, setRejectWithoutSelecting] =
    useState<boolean>(false);
  const [reject, setReject] = useState<{ [key: string]: string }>({});
  const [rejectSearchQuery, setRejectSearchQuery] = useState<string>("");

  const handleCheckboxChange = (quarantineId:string) => {
    setReject((prev) => ({
        ...prev,
        [quarantineId]: prev[quarantineId] === "00000000-0000-0000-0000-000000000000" ? "" : "00000000-0000-0000-0000-000000000000",
    }));
};
  useEffect(() =>{
    setReject({});
  },[props.selected])

  useEffect(() => {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => {
      props.fetchConfirmedData(rejectSearchQuery);
    }, 1000);

    return () => clearTimeout(debounceTimeout);
  }, [rejectSearchQuery]);

  const totalSearchBoxes = useMemo(() => {
    let result = 0;
    props.selectedRows.forEach((institute) => {
      const list =
        props.selected === tabs[0]
          ? institute.companyLocations
          : institute.cities;
      if (list) {
        list
          .filter(
            (obj: any) => props.nonSelectedOptions.includes(obj.id) === false
          )
          .forEach((obj: any) => {
            result += 1;
          });
      }
    });
    return result;
  }, [reject, props.selectedRows, props.nonSelectedOptions, props.selected]);

  const handleMultiReject = async () => {
    if (reject && Object.keys(reject).length > 0) {
      for (const key of Object.keys(reject)) {
        await props.deleteInstitute(key, reject[key]);
      }
      props.setSelectedRows([]);
    }
  };
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => {
          setReject({});
          props.setOpen(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-400  bg-opacity-50 dark:bg-opacity-20 transition-opacity " />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg ">
                <div className="bg-white px-4 pb-4 pt-5 rounded-lg sm:p-6 sm:pb-4">
                  <div className="flex flex-col sm:items-start">
                    <div className="flex flex-row gap-5">
                      <div
                        className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full ${
                          danger ? "bg-red-100" : "bg-blue-100"
                        } sm:mx-0 sm:h-10 sm:w-10`}
                      >
                        <ExclamationTriangleIcon
                          className={`h-6 w-6 ${
                            danger ? "text-red-600" : "text-blue-600"
                          }`}
                          aria-hidden="true"
                        />
                      </div>
                      <div>
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          {props.title}
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            {props.description}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="mt-3 text-center w-full sm:ml-4 sm:mt-0 sm:text-left">
                      <div
                        className={`mt-4 px-3 w-full max-h-96 ${
                          totalSearchBoxes >= 5 ? "overflow-y-auto" : ""
                        }`}
                      >
                        {props.selectedRows.map((institute) => {
                          const list =
                            props.selected === tabs[0]
                              ? institute.companyLocations
                              : institute.cities;
                          return list
                            ?.filter(
                              (cl: any) =>
                                props.nonSelectedOptions.includes(cl.id) ===
                                false
                            )
                            .map((obj: any) => (
                              <>
                                <SearchDropDown
                                  label={`${institute.name} ${obj.city ? obj.city.name : obj.name}`}
                                  quarantineId={obj.id}
                                  placeHolder={props.dropDownPlaceHolder}
                                  dropDownValues={props.dropDownValues}
                                  hideOthers={props.hideOthers}
                                  setReject={setReject}
                                  currentTab={props.selected}
                                  disable={reject[obj.id]=="00000000-0000-0000-0000-000000000000"}
                                  setRejectSearchQuery={setRejectSearchQuery}
                                />
                                <div className="mt-2">
                              <label className="inline-flex items-center">
                                <input
                                  type="checkbox"
                                  className="cursor-pointer rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 dark:border-gray-100"
                                  checked={reject[obj.id]==="00000000-0000-0000-0000-000000000000"}
                                  onChange={()=>handleCheckboxChange(obj.id)}
                                />
                                <span className="ml-2 text-sm">
                                  Reject without selecting
                                </span>
                              </label>
                            </div>
                              </>
                            ));
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 rounded-lg sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className={`disabled:hover:bg-red-600 inline-flex w-full justify-center rounded-md ${
                      danger ? "bg-red-600" : "bg-blue-600"
                    } px-3 py-2 text-sm font-semibold text-white shadow-sm ${
                      danger ? "hover:bg-red-500" : "hover:bg-entntorange"
                    } sm:ml-3 sm:w-auto ${
                      Object.keys(reject).length !== totalSearchBoxes || 
                      Object.values(reject).some(value => value === "")
                        ? "bg-gray-700 cursor-not-allowed"
                        : ""
                    }`}
                    onClick={handleMultiReject}
                    disabled={
                      Object.keys(reject).length !== totalSearchBoxes || 
                      Object.values(reject).some(value => value === "")
                    }
                  >
                    Confirm
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => {
                      setRejectSearchQuery("");
                      setReject({});
                      props.setOpen(false);
                    }}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}