import { Dispatch, Fragment, SetStateAction, useEffect, useRef, useState } from 'react';
import { Dialog, Popover, Transition } from '@headlessui/react';
import { PlusIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { DragDropContext, Draggable, Droppable, DroppableProvided, DroppableStateSnapshot, DropResult } from 'react-beautiful-dnd';
import { reorder } from '../Roles/CreateRole/CreateRoleStatus';
import { AssessmentCondition, recruitmentProcess } from '../Roles/CreateRole/CreateRoleForm';
import { v4 as uuidv4 } from "uuid";
import { RoleStatusEditPopup } from '../Roles/CreateRole/RoleStatusEditPopup';
import { RoleStatusType } from '../../Helpers';
import TextEditor from '../TextEditor/TextEditor';
import { AssessmentTemplate, RoleWorkflow, TemplateWithQuestionnaires } from '../../Models';
import { fetchAssessmentTemplates, getAllAssessments } from '../../WebCalls';
const draggableCallback = (
    processes: recruitmentProcess[],
    isDraggable: boolean,
    handleRemoveProcess: (id: string) => void,
    setProcesses: Dispatch<SetStateAction<recruitmentProcess[]>>,
    handleTextEditor: (type: string, inputType: string) => void,
    setRecruitmentProcessId: React.Dispatch<React.SetStateAction<string>>,
    allAssessments: TemplateWithQuestionnaires[],

) => {
    return (provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
        <ul
            className="lg:grid mt-10 mb-6 flex-col space-y-4 lg:space-y-0 lg:gap-6 lg:grid-cols-5"
            ref={provided.innerRef}
            {...provided.droppableProps}
        >
            {processes.slice(0, processes.length - 2).map((process, index) => (
                <Draggable
                    index={index}
                    draggableId={process.id}
                    key={process.id}
                    isDragDisabled={!isDraggable}
                >
                    {(provided, snapshot) => (
                        <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`${!isDraggable ? "cursor-pointer" : ""
                                } ${snapshot.isDragging ? "z-50  shadow-lg" : ""}`}
                            style={{
                                ...provided.draggableProps.style,
                                top: snapshot.isDragging ? `${Math.floor(index / 5) * 60 + 150}px` : undefined,
                                bottom: snapshot.isDragging ? "0px" : undefined,
                                left: snapshot.isDragging ? `${(index % 5) * 170 + 30}px` : undefined,
                                right: snapshot.isDragging ? "0px" : undefined,
                            }}
                        >
                            <Popover className="relative flex justify-center ">
                                {({ open }) => (
                                    <>
                                        <Popover.Button
                                            as="div"
                                            className="w-[12rem] lg:col-span-1 flex rounded-md shadow-sm"
                                        >
                                            <div className="flex w-12 bg-entntblue flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white border-gray-400 border dark:text-gray-200">
                                                {index + 1}
                                            </div>
                                            <div
                                                className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t dark:border-gray-400 bg-white dark:bg-darkbglight"
                                            >
                                                <div className="flex-1 truncate px-2 py-2 text-sm">
                                                    <span className="font-medium text-gray-900 dark:text-gray-400">
                                                        {process.title}
                                                    </span>
                                                </div>
                                                {isDraggable && (
                                                    <div className="flex-shrink-0">
                                                        <span
                                                            className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent text-gray-400 hover:text-gray-500"
                                                            onClick={() => {
                                                                handleRemoveProcess(process.id);
                                                                localStorage.removeItem(process.id);
                                                            }}
                                                        >
                                                            <XMarkIcon className="h-5 w-5 cursor-pointer" aria-hidden="true" />
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        </Popover.Button>
                                        <RoleStatusEditPopup
                                            open={open}
                                            setProcesses={setProcesses}
                                            processes={processes}
                                            process={process}
                                            handleTextEditor={handleTextEditor}
                                            setRecruitmentProcessId={setRecruitmentProcessId}
                                            assessments={allAssessments}
                                        />
                                    </>
                                )}
                            </Popover>
                        </li>
                    )}
                </Draggable>
            ))}
            {provided.placeholder}

            <li className="  relative flex justify-center">
                <div className=" flex w-80">
                    <div className=" flex w-12  bg-entntorange flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white border-gray-400 border dark:text-gray-200">
                        {processes.length - 1}
                    </div>
                    <div
                        className={
                            "flex flex-1 truncate items-center justify-between  rounded-r-md border-b border-r border-t dark:border-gray-400 bg-white dark:bg-darkbglight "
                        }
                    >
                        <div className="flex-1  truncate px-2 py-2 text-sm">
                            <span className="font-medium text-gray-900 dark:text-gray-400">
                                Offered/Disqualified
                            </span>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    );
};
export default function WorkFlowModal({ title, content, size, onAdd, onCancel, isModalClosed, prevProcesses, isEditable, prevWorkflow, isView }: { title: string, content: string, size: string, onAdd: Function, onCancel: Function, isModalClosed: Function, prevProcesses: recruitmentProcess[], isEditable: boolean, prevWorkflow: RoleWorkflow | undefined, isView: boolean }) {
    const [open, setOpen] = useState(true);
    const cancelButtonRef = useRef(null);
    const [processes, setProcesses] = useState<recruitmentProcess[]>([
        {
            id: uuidv4(), title: RoleStatusType.rejected, email: '', assessmentTemplateId: null, order: 0, passedEmail: '', rejectionEmail: '',
            submittedEmail: ''
        },
        {
            id: uuidv4(), title: RoleStatusType.hired, email: '', assessmentTemplateId: null, order: 0, passedEmail: '', rejectionEmail: '',
            submittedEmail: ''
        },
    ])
    const [textEditorType, setTextEditorType] = useState("");
    const [showTextEditor, setShowTextEditor] = useState(false);
    const [recruitmentProcessId, setRecruitmentProcessId] = useState("");
    const [jobDescription, setJobDescription] = useState("");
    const [offerEmail, setOfferEmail] = useState("");
    const [rejectionEmail, setRejectionEmail] = useState("");
    const [roleWorkflowName, setRoleWorkflowName] = useState<string>('');
    const [currentProcessValue, setCurrentProcessValue] = useState("");
    const [allAssessments, setallAssessments] = useState<TemplateWithQuestionnaires[]>([]);
    const [inputType, setInputType] = useState<string>("");
    useEffect(() => {
        if (isEditable && prevWorkflow) {
            setProcesses(prevProcesses);
            setRoleWorkflowName(prevWorkflow?.name);
        }
    }, [isEditable])

    useEffect(() => {
        if (open === false) {
            isModalClosed();
        }
    }, [open, isModalClosed]);
    const handleDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const newProcesses = reorder(
            processes,
            result.source.index,
            result.destination.index
        );

        setProcesses([...newProcesses]);
    };

    const handleAddProcess = () => {
        let lastIndex = processes.length - 2;
        setProcesses([
            // ...processes,
            // { id: uuidv4(), title: currentProcessValue, email: "" },
            ...processes.slice(0, lastIndex),
            {
                id: uuidv4(), title: currentProcessValue, email: "Dear {0}, please complete your assessment using the provided link: https://assessments.entnt.in/{1}. {2} - For Interview Slot", 
                assessmentTemplateId: null, order: 0, passedEmail: 'Congratulations {0}, you have passed the assessment with a score of {1}/100!. You have been advanced to the next steps of recruitment.', 
                rejectionEmail: 'Dear {0}, unfortunately, your assessment score of {1}/100 did not meet our requirements',
                submittedEmail: 'Thank you, {0}, for submitting your application.'
            },
            ...processes.slice(lastIndex),
        ]);
        setCurrentProcessValue("");
    };

    useEffect(() => {
        getDefaultStateValue(textEditorType);
    }, [inputType])

    const handleRemoveProcess = (id: string) => {
        const newProcesses = processes.filter((el) => el.id !== id);
        setProcesses(newProcesses);
    };
    const handleTextEditor = (type: string, inputType: string) => {
        setInputType(inputType);
        setTextEditorType(type);
        setShowTextEditor(true);
        window.scrollTo(0, 0);
    };
    const getDefaultStateValue = (textEditorType: string) => {
        const selectedProcess = processes.find(
            (process) => process.id === recruitmentProcessId
        );
        switch (textEditorType) {
            case "Job description":
                return jobDescription;
            case "Offer email":
                return offerEmail;
            case "Rejection email":
                return rejectionEmail;
            case recruitmentProcessId:
                switch (inputType) {
                    case "emailBody":
                        return selectedProcess?.email;
                    case "submittedBody":
                        return selectedProcess?.submittedEmail;
                    case "rejectedBody":
                        return selectedProcess?.rejectionEmail;
                    case "passedBody":
                        return selectedProcess?.passedEmail;
                    default:
                        return ""
                }
            default:
                return "";
        }
    };

    useEffect(() => {
        // getAllAssessments().then((res) => setallAssessments(res));
        fetchAssessmentTemplates().then((res) => setallAssessments(res));
    }, []);

    const hasDuplicateTemplateId = (): boolean => {
        const templateIds = processes.map(process => process.assessmentTemplateId);
        const uniqueIds = new Set(templateIds.filter(id => id !== null)); 
        return uniqueIds.size !== templateIds.filter(id => id !== null).length;
      };

      

    return (
        <>
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-10"
                    initialFocus={cancelButtonRef}
                    onClose={() => {
                        onCancel();
                        setOpen(false);
                    }}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-400  bg-opacity-50 dark:bg-opacity-20 transition-opacity " />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto ">
                        <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0 h-full ">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel
                                    className={`relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full h-[80%]  overflow-y-auto flex flex-col max-w-6xl dark:bg-darkbglight`}
                                >
                                    <h2 className='font-bold text-gray-600 my-4 text-sm text-left pl-4 border-b-2 pb-2 dark:text-white border-gray-500'>
                                        Create Workflow
                                    </h2>
                                    {showTextEditor ? <>
                                        <TextEditor
                                            textEditorType={textEditorType}
                                            setShowTextEditor={setShowTextEditor}
                                            setJobDescription={setJobDescription}
                                            setOfferEmail={setOfferEmail}
                                            setRejectionEmail={setRejectionEmail}
                                            setProcesses={setProcesses}
                                            recruitmentProcessId={recruitmentProcessId}
                                            defaultStateValue={
                                                getDefaultStateValue(textEditorType)
                                            }
                                            inputType={inputType}
                                            currentProcess={processes.find((el) => el.id === textEditorType)}
                                        />
                                    </> : <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 flex-grow dark:bg-darkbglight">
                                        <div className="sm:flex flex-col">
                                            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                <div className="flex space-x-8">
                                                    <div>
                                                        <input type="text" className='flex rounded-md shadow-sm ring-1 ring-inset  focus-within:ring-1 focus-within:ring-inset col-span-2 dark:placeholder:text-gray-600 focus:ring-0 sm:text-sm sm:leading-6 dark:bg-darkbglight dark:text-gray-300'
                                                            placeholder='Workflow Name' value={roleWorkflowName} onChange={(e) => setRoleWorkflowName(e.target.value)} disabled={isView} />
                                                    </div>

                                                    {!isView && <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-1 focus-within:ring-inset focus-within:ring-indigo-600 col-span-2 w-full dark:ring-gray-500 ">
                                                        <input
                                                            type="text"
                                                            name="status-title"
                                                            id="status-title"
                                                            className="block flex-1 border-0 bg-transparent py-1.5 pl-3 text-gray-900 dark:text-gray-300 placeholder:text-gray-400 dark:placeholder:text-gray-600 focus:ring-0 sm:text-sm sm:leading-6"
                                                            placeholder={"Enter the process title here and add the process"}
                                                            onChange={(e) => {
                                                                setCurrentProcessValue(e.target.value);
                                                            }}
                                                            value={currentProcessValue}
                                                            onKeyDown={(e) => {
                                                                if (e.key === "Enter" && currentProcessValue.trim() !== "") {
                                                                    e.preventDefault();
                                                                    handleAddProcess();
                                                                }
                                                            }}
                                                        />
                                                    </div>}
                                                    {!isView && <button
                                                        onClick={handleAddProcess}
                                                        type="button"
                                                        className="text-white cursor-pointer bg-entntblue rounded-md hover:bg-entntorange disabled:bg-gray-300 disabled:cursor-not-allowed"
                                                        disabled={currentProcessValue.trim() === ""}
                                                    >
                                                        <PlusIcon height={34} />
                                                    </button>}
                                                </div>

                                                <div className="hidden lg:block col-span-3">
                                                    <DragDropContext onDragEnd={handleDragEnd}>
                                                        <Droppable droppableId="recruitmentProcess" direction="horizontal">
                                                            {draggableCallback(
                                                                processes,
                                                                !isView ? true : false,
                                                                handleRemoveProcess,
                                                                setProcesses,
                                                                handleTextEditor,
                                                                setRecruitmentProcessId,
                                                                allAssessments
                                                            )}
                                                        </Droppable>
                                                    </DragDropContext>
                                                </div>
                                                <div className="lg:hidden col-span-3">
                                                    <DragDropContext onDragEnd={handleDragEnd}>
                                                        <Droppable droppableId="recruitmentProcess" direction="vertical">
                                                            {draggableCallback(
                                                                processes,
                                                                !isView ? true : false,
                                                                handleRemoveProcess,
                                                                setProcesses,
                                                                handleTextEditor,
                                                                setRecruitmentProcessId,
                                                                allAssessments
                                                            )}
                                                        </Droppable>
                                                    </DragDropContext>
                                                </div>


                                            </div>
                                        </div>
                                    </div>}
                                    {/* Footer Buttons */}
                                    <div className="bg-gray-50 px-4 py-3 flex flex-row items-center justify-between mt-auto dark:bg-darkbglight">
                                        <button
                                            type="button"
                                            onClick={() => {
                                                onCancel();
                                                setOpen(false);
                                            }}
                                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 text-xs py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                            ref={cancelButtonRef}
                                        >
                                            Cancel
                                        </button>
                                        {hasDuplicateTemplateId() && <p className="text-red-500 text-xs">Please select unique assessment templates for each process</p>}
                                        {!isView && <button
                                            type="button"
                                            disabled={!(roleWorkflowName.length > 0 && processes.slice(0, processes.length - 2).every(process => process.email.trim().length > 0 && process.passedEmail.trim().length > 0) && !hasDuplicateTemplateId())}
                                            onClick={() => {
                                                if (!hasDuplicateTemplateId() && roleWorkflowName.length > 0 && processes.slice(0, processes.length - 2).every(process => process.email.trim().length > 0 && process.passedEmail.trim().length > 0) ) {
                                                    if (isEditable) {
                                                        onAdd(prevWorkflow?.id, roleWorkflowName, processes)
                                                    }
                                                    else {
                                                        onAdd(roleWorkflowName, processes);
                                                    }
                                                    setOpen(false);
                                                }
                                            }}
                                            className={`inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm ${!hasDuplicateTemplateId() && roleWorkflowName.length > 0 &&
                                                processes.slice(0, processes.length - 2).every(process => process.email.trim().length > 0 && process.passedEmail.trim().length > 0)
                                                ? "bg-blue-600 text-white"
                                                : "cursor-not-allowed dark:bg-gray-100 bg-gray-100 text-black"
                                                } hover:bg-blue-500 sm:ml-3 sm:w-auto`}
                                        >
                                            {isEditable ? "Update" : "Create"}
                                        </button>}
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>

                </Dialog>
            </Transition.Root>
        </>

    );
}
