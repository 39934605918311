import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import Loading from '../../Shared/Loading';
import { fetchAllSkills, fetchQuestionGroups, getQuestionById, getQuestionSkill, postQuestion, postQuestionGroup, postQuestionSkill, postSkill, putAndPostAssessmentOptions, putAssessmentQuestion } from '../../../WebCalls';
import { Option, Question, QuestionGroup, Skill } from '../../../Models';
import DropdownQuestionGroup from '../../Shared/Dropdowns/DropdownQuestionGroup';
import { ArrowUturnLeftIcon, EllipsisHorizontalCircleIcon, InformationCircleIcon, ListBulletIcon, PlusCircleIcon } from '@heroicons/react/20/solid';
import DropdownQuestionType from '../../Shared/Dropdowns/DropDownQuestionType';
import Skilldropdown from '../Dropdowns/SkillDropdown';
import ReactQuill from 'react-quill';
import { formats, toolbar } from '../../../helpers/constants';
import CheckboxInput from '../../CheckboxInput';
import Notification from '../../Shared/Notification';


export default function QuestionAddEditModal({ size, onCancel, isModalClosed, selectedQuestionId }: { onCancel: Function, isModalClosed: Function, size: string, selectedQuestionId: string }) {

    const [open, setOpen] = useState(true);

    const [loading, setLoading] = useState<boolean>(true);

    const cancelButtonRef = useRef(null);
    useEffect(() => {
        if (open === false) {
            isModalClosed();
        }
    }, [open, isModalClosed]);
    const [selectedQType, setSelectedQType] = useState<Question>({
        id: '1',
        title: 'SINGLE'
    });

    const [qGroups, setQGroups] = useState<Question[]>([])

    const getAllQuestionGroups = useCallback(() => {
        fetchQuestionGroups().then((res) => {
            setQGroups(res);
        });
        if (!selectedQuestionId) {
            setLoading(false);
        }
    }, [])

    useEffect(() => {
        getAllQuestionGroups();
    }, [getAllQuestionGroups]);

    const [selectedQGroup, setSelectedQGroup] = useState<QuestionGroup>()
    const [options, setOptions] = useState<Option[] | undefined>()
    const [text, setText] = useState('');
    const [time, setTime] = useState('');
    const [tags, setTags] = useState('');
    const [selectedSkills, setSelectedSkills] = useState<Skill[]>([]);
    const [skills, setSkills] = useState<Skill[]>([]);

    useEffect(() => {
        if (selectedQuestionId) {
            setLoading(true)
            const getDetails = async () => {
                await getQuestionById(selectedQuestionId).then((res) => {
                    setText(res.text);
                    setTags(res.tags);
                    setTime(res.time);
                    const qType: string = res.type.toUpperCase()
                    const type: Question = {
                        id: (Math.random()).toString(),
                        title: qType
                    };
                    setSelectedQType(type);
                    setOptions(res.answers)
                    const group: QuestionGroup = res.questionGroup;
                    setSelectedQGroup(group)
                })
                await getQuestionSkill(selectedQuestionId).then((res) => {
                    setSelectedSkills(res);
                    setLoading(false)
                });
            }
            getDetails();
        }
    }, [selectedQuestionId]);

    const getAllSkills = () => {
        fetchAllSkills().then((res) => setSkills(res));
    }
    useEffect(() => {
        getAllSkills();
    }, [])
    const setSelectedQuestionGroup = (selected: QuestionGroup | undefined) => {
        setSelectedQGroup(selected)
    }

    const setSelectedQuestionType = (selected: Question) => {
        setSelectedQType(selected);

    }

    const getOptionsfromChild = (options: Option[]) => {
        setOptions(options);
    }
    const [successNotification, setSuccessNotification] = useState(false);
    const [dangerNotification, setDangerNotification] = useState(false);
    const [notifcationMessage, setNotificationMessage] = useState('');

    const handleQuestionSubmit = async (e: any) => {
        e.preventDefault();
        const selectedIsCorrect = options?.filter((o) => { return o.isCorrect === true })
        const optionText = options?.filter((o) => { return o.text.trim() === '' });
        if (optionText && optionText?.length > 0) {
            setNotificationMessage('Option field cannot be empty');
            setDangerNotification(true);
        }
        else if (selectedQType.title === 'MULTIPLE' && selectedIsCorrect && selectedIsCorrect.length <= 1) {
            setNotificationMessage('More than options need to be selected when question type is multiple');
            setDangerNotification(true);
        }
        else if (selectedQType.title === 'SINGLE' && selectedIsCorrect && selectedIsCorrect.length < 1) {
            setNotificationMessage('Atleast one option need to be selected when question type is single');
            setDangerNotification(true);
        }
        else {
            if ((options || selectedQType.title === 'TEXT' || selectedQType.title === 'VIDEO') && text && time && tags) {
                const question = await postQuestion(text, time, tags, selectedQGroup?.id ?? null, selectedQType.title, options as Option[] | undefined);
                if (selectedSkills.length > 0) {
                    postQuestionSkill(question.id, selectedSkills);
                }
                setNotificationMessage('Question Addedd Successfully');
                setSuccessNotification(true);
                setTimeout(() => {
                    isModalClosed();
                }, 500)
            }
        }

    }


    const handleQuestionUpdate = (e: any): void => {
        e.preventDefault();
        const selectedType = selectedQType.title;
        const selectedIsCorrect = options?.filter((o) => { return o.isCorrect === true })
        const optionText = options?.filter((o) => { return o.text.trim() === '' });
        if (optionText && optionText?.length > 0) {
            setNotificationMessage('Option field cannot be empty');
            setDangerNotification(true);
        }
        else if (selectedQType.title === 'MULTIPLE' && selectedIsCorrect && selectedIsCorrect.length <= 1) {
            setNotificationMessage('More than options need to be selected when question type is multiple');
            setDangerNotification(true);
        }
        else if (selectedQType.title === 'SINGLE' && selectedIsCorrect && selectedIsCorrect.length < 1) {
            setNotificationMessage('Atleast one option need to be selected when question type is single');
            setDangerNotification(true);
        }
        else {
            if (options && text && time && tags) {
                putAssessmentQuestion(selectedQuestionId, text, time, selectedType, tags, selectedQGroup);
                putAndPostAssessmentOptions(selectedQuestionId, options);
                postQuestionSkill(selectedQuestionId, selectedSkills);
                setNotificationMessage('Question Updated Successfully');
                setSuccessNotification(true);
                setTimeout(() => {
                    isModalClosed();
                }, 500)
            }
        }
    }

    const openDialog = (type: string) => {
        setIsDialogOpen((prev) => ({
            ...prev,
            [type]: type === 'group' ? !isDialogOpen.group : !isDialogOpen.skill
        }))

    };

    const closeDialog = () => {
        setIsDialogOpen({ skill: false, group: false });
    };

    const handleCreateGroup = async (e: any) => {
        e.preventDefault();
        try {
            await postQuestionGroup(newGroupName).then(() => {
                setNewGroupName('');
                closeDialog();
                setNotificationMessage('Question Group Added');
                setSuccessNotification(true);
                getAllQuestionGroups();
            })
        } catch (error) {
            setNotificationMessage('Unable to Add Question Group');
            setDangerNotification(true);
            setTimeout(() => {
                setNotificationMessage('');
                window.location.reload();
            }, 2000)
        }
    };

    const clearFields = () => {
        setText('');
        setTags('');
        setOptions([]);
        setTime('');
    }

    const onSkillSelect = (currentSelectedSkill: Skill[]) => {
        setSelectedSkills(currentSelectedSkill)
    }

    const handleCreateSkill = async () => {
        await postSkill(newSkillName);
        await getAllSkills();
        closeDialog();
        setNewSkillName({
            id: '',
            name: ''
        });
    }
    const [isDialogOpen, setIsDialogOpen] = useState({ skill: false, group: false });
    const [newGroupName, setNewGroupName] = useState<string>('');
    const [newSkillName, setNewSkillName] = useState<Skill>();
    const [pageNumber, setPageNumber] = useState<number>(1);
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10 dark:bg-entntdarkblue"
                initialFocus={cancelButtonRef}
                onClose={() => {
                    onCancel();
                    setOpen(false);
                }}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-400 bg-opacity-50 dark:bg-opacity-20 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full h-[36rem] flex flex-col dark:bg-darkbg sm:max-w-6xl">
                                <div className='bg-gray-200 h-12 p-4 flex items-center dark:bg-darkbglight'>
                                    <h2 className='font-bold text-gray-600 text-sm text-left dark:text-gray-300'>
                                        {selectedQuestionId ? "Update Question" : "Create Question"}
                                    </h2>
                                </div>

                                {!loading && <>
                                    <div className='h-[1px] mx-4 my-2 mt-6 w-[80%] self-center rounded-xl dark:bg-white flex bg-gray-600 flex-row justify-around items-center'>
                                        <div className={`${pageNumber === 1 ? "bg-entntorange" : "bg-gray-700"} p-1 rounded-lg`}>
                                            <InformationCircleIcon height={18} color='white' title='Question Info' className='cursor-pointer' onClick={() => setPageNumber(1)} />
                                        </div>
                                        {(selectedQType.title === "SINGLE" || selectedQType.title === "MULTIPLE") &&
                                            <div className={`${pageNumber === 2 ? "bg-entntorange" : "bg-gray-700"} p-1 rounded-lg`}>
                                                <EllipsisHorizontalCircleIcon height={18} color='white' title='Question Options' className='cursor-pointer' onClick={() => setPageNumber(2)} />
                                            </div>
                                        }
                                    </div>
                                    <div className='flex flex-col flex-grow overflow-hidden'>
                                        <div className='flex-grow overflow-y-auto px-4 sm:px-6 lg:px-8'>
                                            <form className="mx-auto max-w-7xl w-full">
                                                {pageNumber === 1 && <div className=" p-6 ">
                                                    <div className="flex justify-between pb-8 border-b border-gray-300 dark:border-gray-600">
                                                        <div className='flex items-start flex-col gap-4 lg:flex-row'>
                                                            <div className="cursor-pointer sm:w-fit lg:w-fit mx-2">
                                                                <div className='flex flex-row gap-2 text-xs '>

                                                                    {!selectedQuestionId && <div className='flex flex-row relative marker items-center justify-between mt-4'>
                                                                        {
                                                                            isDialogOpen.group && <div className='border border-gray-300 p-2 h-36 flex flex-col justify-between rounded-md absolute z-10 bg-white  dark:bg-darkbg dark:border-gray-600 text-xs lg:left-6 lg:-bottom-3/3 lg:top-0 right-3 top-16 w-fit'>
                                                                                <div>
                                                                                    <div className=' block sm:text-sm font-medium text-gray-700 w-64 text-left mb-2 dark:text-gray-300 text-sm'>Create Question Group</div>
                                                                                    <div>
                                                                                        <input type="text" className='w-full rounded-md border-gray-300 border p-2 focus:outline-none focus:ring focus:border-indigo-500 dark:bg-transparent dark:border-gray-600 dark:text-gray-400' placeholder='Enter Group Name' onChange={(e) => setNewGroupName(e.target.value)} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='flex flex-row justify-between'>
                                                                                    <button className='text-sm font-semibold text-gray-900 mr-4 bg-gray-300 px-4 py-2 rounded-md' onClick={(e) => { closeDialog(); setNewGroupName('') }}>Cancel</button>
                                                                                    <button className='bg-indigo-600 text-white hover:bg-indigo-500 cursor-pointer rounded-md px-4 py-2 text-sm font-semibold shadow-sm focus:outline-none focus:ring focus:border-indigo-500 ' onClick={handleCreateGroup}>Create </button>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        <PlusCircleIcon height={20} color='blue' onClick={() => openDialog('group')} className={`cursor-pointer ${isDialogOpen.group ? 'origin-center rotate-45 ease-in duration-100' : ''}`}
                                                                        />
                                                                    </div>}
                                                                    <div>
                                                                        <small className='block sm:text-sm font-medium text-gray-700 w-64 text-left dark:text-gray-400 !text-xs'>Question Group <span className='text-red-700'>*</span></small>
                                                                        <DropdownQuestionGroup group={qGroups} setSelectedQuestionGroup={setSelectedQuestionGroup} selectedQGroup={selectedQGroup} />                                                                </div>

                                                                </div>
                                                            </div>
                                                            <div className="cursor-pointer sm:w-fit lg:w-fit mx-2  !text-xs">
                                                                <small className='block sm:text-sm font-medium text-gray-700 w-64 text-left dark:text-gray-400  !text-xs'>Question Type <span className='text-red-700'>*</span></small>
                                                                <div className='flex flex-row gap-2'>
                                                                    <DropdownQuestionType setSelectedQuestionType={setSelectedQuestionType} selectedQType={selectedQType} />

                                                                </div>
                                                            </div>
                                                            <div className="cursor-pointer sm:w-fit lg:w-fit mx-2  text-xs">

                                                                <div className='flex flex-row gap-2'>
                                                                    {!selectedQuestionId && <div className='flex mt-4 flex-row relative marker items-center justify-between'>
                                                                        {
                                                                            isDialogOpen.skill && <div className='border border-gray-300 p-2 h-36 flex flex-col justify-between rounded-md absolute z-10 bg-white  dark:bg-darkbg dark:border-gray-600 text-xs lg:left-6 lg:-bottom-3/3 lg:top-0 right-3 top-16 w-fit'>
                                                                                <div>
                                                                                    <div className=' block sm:text-sm font-medium text-gray-700 w-64 text-left mb-2 dark:text-gray-300 text-sm'>Create Skill </div>
                                                                                    <div>
                                                                                        <input type="text" className='w-full rounded-md border-gray-300 border p-2 focus:outline-none focus:ring focus:border-indigo-500 dark:bg-transparent dark:border-gray-600 dark:text-gray-400' placeholder='Enter Skill Name' onChange={(e) => setNewSkillName({
                                                                                            id: '',
                                                                                            name: e.target.value
                                                                                        })} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='flex flex-row justify-between'>
                                                                                    <button className='text-sm font-semibold text-gray-900 mr-4 bg-gray-300 px-4 py-2 rounded-md' onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        closeDialog(); setNewSkillName({
                                                                                            id: '',
                                                                                            name: ''
                                                                                        })
                                                                                    }}>Cancel</button>
                                                                                    <button className='bg-indigo-600 text-white hover:bg-indigo-500 cursor-pointer rounded-md px-4 py-2 text-sm font-semibold shadow-sm focus:outline-none focus:ring focus:border-indigo-500 ' onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        handleCreateSkill();
                                                                                    }}>Create</button>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        <PlusCircleIcon height={20} color='blue' onClick={() => openDialog('skill')} className={`cursor-pointer ${isDialogOpen.skill ? 'origin-center rotate-45 ease-in duration-100' : ''}`}
                                                                        />

                                                                    </div>}
                                                                    <div>
                                                                        <small className='block sm:text-sm font-medium text-gray-700 w-64 text-left dark:text-gray-400  !text-xs'>Skill Type</small>
                                                                        <Skilldropdown skills={skills} selectedSkills={selectedSkills} onSkillsSelect={onSkillSelect} />
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className='flex flex-col items-start pt-8 py-2 lg:flex-row '>
                                                        <label htmlFor="questionText" className=" text-xs block  font-medium text-gray-700 w-64 text-left py-4 dark:text-gray-400">
                                                            Question Text <span className='text-red-700'>*</span>
                                                        </label>

                                                        <ReactQuill
                                                            value={text}
                                                            onChange={setText}
                                                            formats={formats}
                                                            modules={{
                                                                toolbar: {
                                                                    container: toolbar,
                                                                }
                                                            }}
                                                            className='w-full rounded-md p-1  focus:outline-none focus:ring focus:border-indigo-500 h-fit text-left dark:text-gray-400 !dark:border-gray-800'
                                                            id="questionText"
                                                        />

                                                    </div>


                                                    <div className='flex flex-col items-start py-2 lg:flex-row '>
                                                        <div className=' text-sm font-medium text-gray-700 w-64 text-left  dark:text-gray-400 flex flex-col '>
                                                            <label htmlFor="questionTime" className="text-xs">
                                                                Time <span className='text-red-700 '>*</span>
                                                            </label>
                                                            <label htmlFor="questionTime" className="text-[10px]">
                                                                (In seconds)
                                                            </label>
                                                        </div>
                                                        <input
                                                            id="questionTime"
                                                            name="questionTime"
                                                            type="number"
                                                            className="w-full rounded-md border-gray-300 border p-1 focus:outline-none focus:ring focus:border-indigo-500 dark:bg-transparent dark:text-gray-400 dark:border-gray-600"
                                                            onChange={(e) => setTime(e.target.value)}
                                                            min={0}
                                                            value={time}
                                                            onWheel={(e) => e.currentTarget.blur()}
                                                        />
                                                    </div>
                                                    <div className='flex flex-col items-start py-2 lg:flex-row '>
                                                        <div className=' text-sm font-medium text-gray-700 w-64 text-left  dark:text-gray-400 flex flex-col '>
                                                            <label htmlFor="questionTags" className="text-xs">
                                                                Tags <span className='text-red-700 '>*</span>
                                                            </label>
                                                            <label htmlFor="questionTags" className="text-[10px]">
                                                                (Seperated By commas)
                                                            </label>
                                                        </div>
                                                        <input
                                                            id="questionTags"
                                                            name="questionTags"
                                                            type="text"
                                                            className="w-full rounded-md border-gray-300 border p-1 focus:outline-none focus:ring focus:border-indigo-500 dark:bg-transparent dark:text-gray-400 dark:border-gray-600"
                                                            onChange={(e) => setTags(e.target.value)}
                                                            value={tags}
                                                        />
                                                    </div>
                                                </div>}

                                                {pageNumber === 2 && (selectedQType.title === 'SINGLE' || selectedQType.title === 'MULTIPLE') && (
                                                    <>
                                                        <div className='flex flex-col items-start py-2 lg:flex-row '>
                                                            <CheckboxInput selectedQType={selectedQType} getOptionsfromChild={getOptionsfromChild} selectedOptions={options} isQuestionsModalOpen={open} />
                                                        </div>
                                                    </>
                                                )}
                                            </form>
                                        </div>
                                        <div className="mt-auto p-4 ">
                                            <div className="flex justify-between">
                                                {pageNumber === 1 ? <button
                                                    onClick={() => isModalClosed()}
                                                    type="submit"
                                                    className={` rounded-md px-4 py-2 text-sm font-semibold shadow-sm focus:outline-none focus:ring focus:border-indigo-500 bg-gray-300 text-gray-500  hover:bg-red-500 hover:text-white`}
                                                >
                                                    Cancel
                                                </button> : <button
                                                    onClick={() => setPageNumber(1)}
                                                    type="submit"
                                                    className={`flex flex-row gap-2 items-center rounded-md px-4 py-2 text-sm font-semibold shadow-sm focus:outline-none focus:ring focus:border-indigo-500 bg-gray-300 text-gray-500 hover:bg-gray-500 hover:text-white`}
                                                >
                                                    <ArrowUturnLeftIcon height={15} />
                                                    Back
                                                </button>}
                                                {selectedQuestionId ? (
                                                    <div className='gap-2 flex flex-row'>
                                                        {((selectedQType.title === 'SINGLE' || selectedQType.title === 'MULTIPLE') && pageNumber === 1) && <button
                                                            onClick={() => setPageNumber(2)}
                                                            type="submit"
                                                            className={`rounded-md px-4 py-2 text-sm font-semibold shadow-sm focus:outline-none focus:ring focus:border-indigo-500 ${'bg-indigo-600 text-white hover:bg-indigo-500 cursor-pointer'}`}

                                                        >
                                                            Edit Options

                                                        </button>}

                                                        <button
                                                            onClick={handleQuestionUpdate}
                                                            type="submit"
                                                            className={`rounded-md px-4 py-2 text-sm font-semibold shadow-sm focus:outline-none focus:ring focus:border-indigo-500 ${options && text.length > 0 && time && time.toString().length > 0 && tags.length > 0
                                                                ? 'bg-indigo-600 text-white hover:bg-indigo-500 cursor-pointer'
                                                                : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                                                                }`}
                                                            disabled={!((options || selectedQType.title === 'TEXT' || selectedQType.title === 'VIDEO') && text.length > 0 && time && time.toString().length > 0 && tags.length > 0)}
                                                        >
                                                            Update
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        {pageNumber === 1 && (selectedQType.title === 'SINGLE' || selectedQType.title === 'MULTIPLE') ? <button
                                                            onClick={() => setPageNumber(2)}
                                                            type="submit"
                                                            className={`rounded-md px-4 py-2 text-sm font-semibold shadow-sm focus:outline-none focus:ring focus:border-indigo-500 ${'bg-indigo-600 text-white hover:bg-indigo-500 cursor-pointer'}`}
                                                        >
                                                            Select Options

                                                        </button> : <button
                                                            onClick={handleQuestionSubmit}
                                                            type="submit"
                                                            className={`rounded-md px-4 py-2 text-sm font-semibold shadow-sm focus:outline-none focus:ring focus:border-indigo-500 ${(options || selectedQType.title === 'TEXT' || selectedQType.title === 'VIDEO') && text.length > 0 && time.toString().length > 0 && tags.length > 0
                                                                ? 'bg-indigo-600 text-white hover:bg-indigo-500 cursor-pointer'
                                                                : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                                                                }`}
                                                            disabled={!((options || selectedQType.title === 'TEXT' || selectedQType.title === 'VIDEO') && text.length > 0 && time.toString().length > 0 && tags.length > 0)}
                                                        >
                                                            Submit
                                                        </button>}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </>}
                                <Notification
                                    show={successNotification}
                                    setShow={setSuccessNotification}
                                    title="Success"
                                    description={notifcationMessage}
                                    type="success"
                                />
                                <Notification
                                    show={dangerNotification}
                                    setShow={setDangerNotification}
                                    title="Error"
                                    description={notifcationMessage}
                                    type="error"
                                />
                                {loading && <Loading />}
                            </Dialog.Panel>

                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root >
    );
}