import {
  Candidate,
  CandidateStatus,
  CareerTimelineType,
  Education,
  Experience,
  Role,
  RoleStatusCandidateCount,
} from "../../../Models";
import { useNavigate, useParams } from "react-router-dom";
import GradesChart from "./GradeCharts";
import { RoleStatusType, dateConverter } from "../../../Helpers";
import { CheckIcon, XMarkIcon } from "@heroicons/react/20/solid";
import {
  DocumentDuplicateIcon,
  DocumentPlusIcon,
} from "@heroicons/react/24/outline";
import { CandidateAction } from "./RoleCandidates";
import { useEffect, useMemo, useRef, useState } from "react";
import TimelineModal from "../../Shared/TimelineModal";
import CandidateDetailModal from "./Candidatedetailsmodel";
import {
  getAnsweredPercentage,
  getAssessmentDetailById,
  GetCandidateStatus,
  GetCandidateTimeline,
  GetRole,
  GetRoleStatus,
} from "../../../WebCalls";
import useApiToken from "../../../hooks/useApiToken";
import RecruitmentTimelineModal from "../../Shared/RecruitmentTimelineModal/RecruitmentTimelineModal";
import CareerTimeline from "../../Candidates/CareerTimeline";
import Grade from "./Grade";
import ReAppliedModal from "./ReAppliedModal";
import { NIL } from "uuid";
import StraightTimeline from "../../Candidates/StraightTimerline";
import SkillPieChart from "./SkillPieChart";

function classNames(...classes: string[]): string {
  return classes.filter(Boolean).join(" ");
}
const TimeRemaining = ({
  startTime,
  endTime,
  assessmentTime,
}: {
  startTime: string;
  endTime?: string;
  assessmentTime: number;
}) => {
  const [timeRemaining, setTimeRemaining] = useState<string | null>(null);

  useEffect(() => {
    if (startTime && !endTime && assessmentTime) {
      const st = new Date(startTime).getTime();

      const assessmentDurationInMs = assessmentTime * 60 * 1000;
      const assessmentEndTime = st + assessmentDurationInMs;

      const calculateTimeRemaining = () => {
        const now = Date.now();
        const timeDiff = assessmentEndTime - now;

        if (timeDiff > 0) {
          const minutes = Math.floor(timeDiff / (1000 * 60));
          const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
          setTimeRemaining(`${minutes}:${seconds < 10 ? "0" : ""}${seconds}`);
        } else {
          setTimeRemaining("00:00");
        }
      };

      calculateTimeRemaining();
      const interval = setInterval(calculateTimeRemaining, 1000);
      return () => clearInterval(interval);
    }
  }, [startTime, endTime, assessmentTime]);

  return (
    <>
      <div className="text-white dark:text-gray-800">
        {timeRemaining ? timeRemaining : "Calculating..."}
      </div>
    </>
  );
};

export default function RoleCandidatesList({
  candidates,
  selectedCandidate,
  handleSelectedCandidate,
  handleUpdateCandidates,
  status,
  colomns,
  agebased,
  expbased,
  setskillcol,
  skillcol,
  uniqueSkills
}: {
  candidates: Candidate[];
  selectedCandidate: Candidate[];
  handleSelectedCandidate: (candidate: CandidateAction[]) => void;
  handleUpdateCandidates: (
    candidatesUpdate: CandidateStatus[],
    gradeStatusUpdate?: boolean
  ) => void;
  status: RoleStatusCandidateCount;
  colomns: number;
  agebased: boolean;
  expbased: boolean;
  setskillcol:React.Dispatch<React.SetStateAction<number>>;
  skillcol:number;
  uniqueSkills:string[];
}) {
  const [role, setRole] = useState<Role>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [timelineLoading, setTimelineLoading] = useState(false);
  const [timelineError, setTimelineError] = useState(false);
  const [timelineData, setTimelineData] = useState<CareerTimelineType | null>(
    null
  );

  const [reAppliedModalOpen, setReAppliedModalOpen] = useState(false);
  const [openTimeline, setOpenTimeline] = useState(false);
  const [hoveredCandidate, setHoveredCandidate] = useState<Candidate | null>(
    null
  );
  const [timelineData2, setTimelineData2] = useState<any[]>([]);
  const [candidateStatus, setCandidateStatus] = useState<any[]>([]);

  const [hoverdetails, sethoverdetails] = useState<any>();

  const timeoutIdRef = useRef<number | null>(null);
  const navigate = useNavigate();
  const token = useApiToken();
  const { roleId } = useParams();
  const [showScoreDialog, setShowScoreDialog] = useState(false);
  const [showScoreDetailsId, setShowScoreDetailsId] = useState("");
  const [assessmentTime, setAssessmentTime] = useState<number>();
  const [answeredPercentage, setAnsweredPercentage] = useState<{
    answeredPercentage: number;
    startTime?: string;
    endTime?: string;
    scores?:number[]
  }>();

  useEffect(() => {
    const fetchPercentage = async () => {
      if (!showScoreDialog || !showScoreDetailsId || !roleId) {
        setAnsweredPercentage(undefined);
        return;
      }

      const candidateAssessment = await getAnsweredPercentage(
        showScoreDetailsId,
        roleId
      );
      setAnsweredPercentage(candidateAssessment);
    };

    fetchPercentage();
  }, [showScoreDialog, showScoreDetailsId, roleId]);

  useEffect(() => {
    if (!roleId || !token) return;
    GetRole(roleId, token).then((rl) => {
      setRole(rl);
      if (rl.assessmentQuestionnaireId)
        getAssessmentDetailById(rl.assessmentQuestionnaireId).then((a) => {
          setAssessmentTime(a?.time);
        });
    });
  }, [roleId, token]);

  useEffect(() => {
    if (!role || !token || !openTimeline) return;
    setTimelineData2((prevState) => [
      ...prevState.map((item) => {
        if (Object.keys(item)[0] === "roleCreated") {
          return {
            roleCreated: {
              date: role?.date,
              color: "blue",
              radius: 50,
              text: "Job Posted",
            },
          };
        }
        return item;
      }),
    ]);

    if (hoveredCandidate) {
      GetCandidateStatus(hoveredCandidate.id, token).then((cs) => {
        const sortedStatuses = cs?.sort((a: any, b: any) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          if (dateA < dateB) {
            return -1;
          } else if (dateA > dateB) {
            return 1;
          }
          return a.roleStatus.order - b.roleStatus.order;
        });
        Promise.all(
          sortedStatuses
            ?.filter((s) => s.roleId === roleId)
            ?.map(async (status: CandidateStatus) => {
              const rs = await GetRoleStatus(status.roleStatusId, token);
              return {
                name: rs.name,
                date: status.date,
              };
            })
        ).then((si) => {
          si?.unshift({
            name: "roleCreated",
            date: role.date,
          });
          setCandidateStatus(si);
        });
      });
    }
  }, [role, hoveredCandidate, token, openTimeline]);

  const containsSelectedCandidates = (sc: CandidateAction) => {
    for (var i = 0; i < selectedCandidate.length; i++) {
      if (selectedCandidate[i].id === sc.id) {
        return true;
      }
    }
    return false;
  };

  const getExperienceRanking = (exp: Experience) => {
    let ranking = 0;
    if (role?.rankTypeId === null) role.rankTypeId = NIL;
    exp.companyLocation.company.companyRankings?.forEach((cr) => {
      if (cr.rankTypeId === role?.rankTypeId)
        ranking = cr.rankCategory.rankNumber;
    });
    return ranking;
  };

  const getEducationRanking = (edu: Education) => {
    let ranking = 0;
    if (role?.rankTypeId === null) role.rankTypeId = NIL;
    edu.university.universityRankings?.forEach((ur) => {
      if (ur.rankTypeId === role?.rankTypeId)
        ranking = ur.rankCategory.rankNumber;
    });
    return ranking;
  };
  const getLastKnownPos = (candidate: Candidate) => {
    const lastExperience =
      candidate.experience[candidate.experience.length - 1];
    const lastEducation = candidate.education[candidate.education.length - 1];

    if (candidate.current === null) {
      if (candidate.experience.length !== 0 && lastExperience.relevant) {
        return {
          ranking: getExperienceRanking(lastExperience),
          type: "exp",
          value: lastExperience,
        };
      } else if (candidate.education.length !== 0) {
        return {
          ranking: getEducationRanking(lastEducation),
          type: "edu",
          value: lastEducation,
        };
      }
    } else if (candidate.current === "working") {
      return {
        ranking: getExperienceRanking(lastExperience),
        type: "exp",
        value: lastExperience,
      };
    } else if (candidate.current === "studying") {
      return {
        ranking: getEducationRanking(lastEducation),
        type: "edu",
        value: lastEducation,
      };
    }
  };

  const getLatestStatus = (latestStatusId: string) => {
    const latestStatus = status.roleStatuses.find(
      (rs) => rs.id === latestStatusId
    );
    if (latestStatus) return latestStatus;
  };

  const getColorClassByPercentile = (percentile: any) => {
    if (percentile <= 10) {
      return "bg-[#1E3F66]";
    } else if (percentile <= 25) {
      return "bg-[#2E5984]";
    } else if (percentile <= 50) {
      return "bg-[#528AAE]";
    } else if (percentile <= 75) {
      return "bg-[#73A5C6]";
    } else if (percentile <= 90) {
      return "bg-[#91BAD6]";
    } else {
      return "bg-[#BCD2E8]";
    }
  };

  const getColorClassBySkillScore = (percentile: any) => {
    if (percentile <= 10) {
      return "bg-[#bf8300]";
    } else if (percentile <= 25) {
      return "bg-[#ea9f00]";
    } else if (percentile <= 50) {
      return "bg-[#ffb515]";
    } else if (percentile <= 75) {
      return "bg-[#ffc240]";
    } else if (percentile <= 90) {
      return "bg-[#ffd06a]";
    } else {
      return "bg-[#ffdd95]";
    }
  };

  const handleTimelineClick = async (
    e: React.MouseEvent,
    candidate: Candidate
  ) => {
    e.preventDefault();
    setTimelineLoading(true);
    try {
      if (roleId !== undefined && token !== undefined) {
        const candidateTimeline = await GetCandidateTimeline(
          roleId,
          candidate.id,
          token
        );
        setTimelineData(candidateTimeline);
        setTimelineError(false);
        setTimelineLoading(false);
      }
    } catch (error) {
      console.error("Error fetching candidate timeline:", error);
      setTimelineError(true);
    }
    setIsModalOpen(true);
  };
  const onConfirmTimeline = () => {
    setOpenTimeline(false);
  };
  const showGrades = (candidate: Candidate) => {
    if (candidate.latestStatusId) {
      const isOfferedOrHired =
        getLatestStatus(candidate.latestStatusId)?.name ===
        RoleStatusType.hired ||
        getLatestStatus(candidate.latestStatusId)?.name ===
        RoleStatusType.rejected;

      return isOfferedOrHired
        ? candidate.candidateStatuses.length > 2
        : candidate.candidateStatuses.length > 1;
    } else return false;
  };

  const handleMouseEnter = (id: string) => {
    setShowScoreDialog(true);
    setShowScoreDetailsId(id);
  };

  const handleMouseLeave = () => {
    setShowScoreDialog(false);
    setShowScoreDetailsId("");
  };

  const onScoreMouseEnter = (candidateId:string) =>{
     handleMouseEnter(candidateId)
  }

  const onScoreMouseLeave = () =>{
    handleMouseLeave();
    
  }

  return (
    <>
      <tbody className="divide-y divide-gray-200 bg-white dark:bg-darkbg dark:divide-gray-600">
        {candidates.map((candidate, index) => (
          <tr
            key={candidate.id}
            className={`
            ${containsSelectedCandidates(candidate)
                ? "bg-gray-50 dark:bg-[#1c2229]"
                : ""
              } cursor-pointer hover:bg-gray-50 dark:hover:bg-[#1c2229]`}
            onClick={() => {
              navigate(`/roles/${roleId}/candidates/${candidate.id}`);
            }}
            onMouseEnter={(e) => {
              setHoveredCandidate(candidate);
            }}
            onMouseLeave={(e) => {
              setHoveredCandidate(null);
            }}
          >
            <td
              className="relative px-7 sm:w-12 sm:px-6 cursor-default"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {containsSelectedCandidates(candidate) ? (
                <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
              ) : (
                <>
                  {candidate.latestStatusId &&
                    getLatestStatus(candidate.latestStatusId)?.name ===
                    RoleStatusType.hired && (
                      <div className="rounded-sm absolute left-4 top-1/2 -mt-2 h-4 w-4 flex justify-center items-center bg-green-600 z-10 text-white">
                        <CheckIcon />
                      </div>
                    )}
                  {candidate.latestStatusId &&
                    getLatestStatus(candidate.latestStatusId)?.name ===
                    RoleStatusType.rejected && (
                      <div className="rounded-sm absolute left-4 top-1/2 -mt-2 h-4 w-4 flex justify-center items-center bg-red-600 z-10 text-white">
                        {" "}
                        <XMarkIcon />
                      </div>
                    )}
                </>
              )}

              <input
                type="checkbox"
                className={`z-[11] bg-transparent absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 `}
                value={candidate.email}
                checked={containsSelectedCandidates(candidate)}
                onChange={(e) =>
                  handleSelectedCandidate(
                    e.target.checked
                      ? [...selectedCandidate, candidate]
                      : selectedCandidate.filter((p) => p !== candidate)
                  )
                }
              />
            </td>
            <td
              className={classNames(
                "whitespace-nowrap text-sm font-medium w-[24rem] pr-6",
                containsSelectedCandidates(candidate)
                  ? "text-indigo-600"
                  : "text-gray-900 dark:text-gray-200"
              )}
            >
              <div className="relative flex items-center">
                <div
                  onMouseEnter={(e) => {
                    timeoutIdRef.current = window.setTimeout(() => {
                      handleTimelineClick(e, candidate);
                    }, 500);
                  }}
                  onMouseLeave={(e) => {
                    if (timeoutIdRef.current) {
                      window.clearTimeout(timeoutIdRef.current);
                    }
                    setTimelineData(null);
                    setIsModalOpen(false);
                  }}
                  className="text-wrap"
                >
                  {candidate.firstName} {candidate.lastName}
                  {timelineData?.candidateId === candidate.id && (
                    <TimelineModal
                      open={isModalOpen}
                      setOpen={setIsModalOpen}
                      content={timelineData}
                      loading={timelineLoading}
                      timelineError={timelineError}
                      timeline={
                        timelineData?.timeline.length <= 3 ? (
                          <StraightTimeline timeline={timelineData?.timeline} />
                        ) : (
                          <CareerTimeline
                            timeline={timelineData?.timeline || []}
                          />
                        )
                      }
                    />
                  )}
                </div>
                <div className="ml-2 relative">
                  {candidate.reApply && (
                    <span
                      className="text-red-600 font-bold"
                      onMouseEnter={(e) => {
                        timeoutIdRef.current = window.setTimeout(() => {
                          setReAppliedModalOpen(true);
                        }, 500);
                      }}
                      onMouseLeave={(e) => {
                        if (timeoutIdRef.current) {
                          window.clearTimeout(timeoutIdRef.current);
                        }
                        setReAppliedModalOpen(false);
                      }}
                    >
                      {candidate.reApply === candidate.email ? (
                        <DocumentPlusIcon className="h-5" />
                      ) : (
                        <DocumentDuplicateIcon className="h-5" />
                      )}
                    </span>
                  )}
                  {hoveredCandidate &&
                    hoveredCandidate.id === candidate.id &&
                    reAppliedModalOpen &&
                    candidate.reApply && (
                      <ReAppliedModal
                        reApply={candidate.reApply}
                        email={candidate.email}
                      />
                    )}
                </div>
              </div>
            </td>
            <td className="whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
              <div className="flex">
                <span
                  className={`${candidate.workLocation2City ? "max-w-20 truncate" : ""
                    }`}
                  title={
                    candidate.workLocation1City.name +
                    (candidate.workLocation2City
                      ? ", " + candidate.workLocation2City.name
                      : "")
                  }
                >
                  {candidate.workLocation1City.name}
                </span>
              </div>
            </td>

            <td
              className="whitespace-nowrap text-sm text-gray-500 dark:text-gray-300"
              onMouseEnter={(e) => {
                timeoutIdRef.current = window.setTimeout(() => {
                  setOpenTimeline(true);
                }, 500);
              }}
              onMouseLeave={(e) => {
                if (timeoutIdRef.current) {
                  window.clearTimeout(timeoutIdRef.current);
                }
                setOpenTimeline(false);
              }}
            >
              <span
                className={`relative items-center justify-center h-11 w-11  inline-flex p-2 text-white text-xs font-bold rounded-full ${getColorClassByPercentile(candidate.agePercentile)}`}
              >
                {candidate.age > 100 ? "-" : Math.floor(candidate.age)}
                {hoveredCandidate &&
                  openTimeline &&
                  hoveredCandidate.id === candidate.id && (
                    <RecruitmentTimelineModal
                      openTimeline={openTimeline}
                      setOpenTimeline={setOpenTimeline}
                      onConfirmTimeline={onConfirmTimeline}
                      title=""
                      description=""
                      timelineData={timelineData2}
                      role={role}
                      status={status}
                      candidateStatus={candidateStatus}
                      hoveredCandidate={hoveredCandidate}
                    />
                  )}
              </span>
            </td>
            <td className="whitespace-nowrap text-sm text-gray-700 dark:text-gray-300">
              <div
                className={`flex items-center justify-center h-12 w-12 rounded-full border border-white ${getColorClassByPercentile(100 - candidate.expPercentileforsameage)}`}
              >
                <div
                  className={`flex items-center justify-center h-8 w-8 text-xs font-bold rounded-full border border-white 
    ${candidate.experiencePercentileMonths < 25 ? "text-gray-700" : "text-white"} 
    ${getColorClassByPercentile(100 - candidate.experiencePercentileMonths)}`}
                >
                  {candidate.experienceInMonths}
                </div>
              </div>
            </td>

            {/* <td className=" whitespace-nowrap text-sm text-gray-500 dark:text-gray-300 ">
              <div className="text-sm text-gray-700 dark:text-gray-300">
                <div
                  className={`${expbased
                      ? `flex items-center justify-center h-12 w-12 rounded-full border border-gray-200 ${getColorClassByPercentile(100 - candidate.combinePercentileBasedEXP)}`
                      : ""
                    }`}
                >
                  <div
                    className={`${agebased
                        ? `flex items-center justify-center h-12 w-12 rounded-full border border-gray-200 ${getColorClassByPercentile(100 - candidate.combinedPercentileBasedAge)}`
                        : ""
                      }`}
                  >
                    <span
                      className={`flex items-center justify-center h-8 w-8 text-white text-xs font-bold rounded-full border border-white ${getColorClassByPercentile(100 - candidate.combinedGradePercentile)}`}
                      onMouseEnter={() => sethoverdetails(candidate)}
                      onMouseLeave={() => sethoverdetails(null)}
                    >
                      {candidate.combinedGrade.toFixed(1)}
                    </span>
                  </div>
                </div>
              </div>
              <div>
                {hoverdetails && hoverdetails.id === candidate.id && (
                  <CandidateDetailModal
                    isOpen={true}
                    candidate={hoverdetails}
                  />
                )}
              </div>
            </td> */}
            {/* {(colomns === 1 || colomns === 2) && (
              <td className=" whitespace-nowrap text-sm text-gray-500 dark:text-gray-300 ">
                <div
                  className={`${expbased
                      ? `flex items-center justify-center h-12 w-12 rounded-full border border-gray-200 ${getColorClassByPercentile(100 - candidate.educationPercentileBasedEXP)}`
                      : ""
                    }`}
                >
                  <div
                    className={`${agebased
                        ? `flex items-center justify-center h-12 w-12 rounded-full border border-gray-200 ${getColorClassByPercentile(100 - candidate.educationPercentileBasedAge)}`
                        : ""
                      }`}
                  >
                    <div className="">
                      <span
                        className={`flex items-center justify-center h-8 w-8 text-white text-xs font-bold rounded-full border border-gray-200 ${getColorClassByPercentile(candidate.educationPercentileSameRole)}`}
                      >
                        {candidate.totalEducationGrade.toFixed(1)}
                      </span>
                    </div>
                  </div>
                </div>
              </td>
            )} */}

            {/* {colomns === 2 && (
              <td className=" whitespace-nowrap text-sm text-gray-500 dark:text-gray-300 ">
                <div
                  className={`${expbased
                      ? `flex items-center justify-center h-12 w-12 rounded-full border border-gray-200 ${getColorClassByPercentile(100 - candidate.experiencePercentileBasedEXP)}`
                      : ""
                    }`}
                >
                  <div
                    className={`${agebased
                        ? `flex items-center justify-center h-12 w-12 rounded-full border border-gray-200 ${getColorClassByPercentile(100 - candidate.experiencePercentileBasedAge)}`
                        : ""
                      }`}
                  >
                    <div className="">
                      <span
                        className={`flex items-center justify-center h-8 w-8 text-white text-xs font-bold rounded-full border border-gray-200 ${getColorClassByPercentile(100 - candidate.experiencePercentileSameRole)}`}
                      >
                        {candidate.totalExperienceGrade.toFixed(1)}
                      </span>
                    </div>
                  </div>
                </div>
              </td>
            )} */}

            {/* <td className="text-sm text-gray-700 dark:text-gray-300">
              <div
                className={`${expbased
                    ? `flex items-center justify-center h-12 w-12 rounded-full border border-gray-200 ${getColorClassByPercentile(candidate.gapPercentileBasedEXP)}`
                    : ""
                  }`}
              >
                <div
                  className={`${agebased
                      ? `flex items-center justify-center h-12 w-12 rounded-full border border-gray-200  ${getColorClassByPercentile(candidate.gapmonthspercentileAge)}`
                      : ""
                    }`}
                >
                  <div
                    className={`flex items-center justify-center h-8 w-8 text-white text-xs font-bold rounded-full border border-white ${getColorClassByPercentile(candidate.gappercentile)}`}
                  >
                    {candidate.totalGapMonths}
                  </div>
                </div>
              </div>
            </td> */}

            {/* <td className=" whitespace-nowrap  dark:text-gray-300 flex items-center justify-center h-11 w-11  text-white text-xs font-bold rounded-full border-2 border-black my-[5.5px]">
              <GradesChart
                educationGrades={candidate.educationGrades}
                experienceGrades={candidate.experienceGrades}
              />
            </td> */}
              <td className=" whitespace-nowrap text-sm text-gray-500 dark:text-gray-300 ">
              <div
                className={`${
                  expbased
                    ? `flex items-center justify-center h-12 w-12 rounded-full  ${getColorClassByPercentile(100 - candidate.combinePercentileBasedEXP)}`
                    : ""
                }`}
              >
                <div
                  className={`${
                    agebased
                      ? `flex items-center justify-center h-12 w-12 rounded-full  ${getColorClassByPercentile(100 - candidate.combinedPercentileBasedAge)}`
                      : ""
                  }`}
                >
                  <span
                    className={`flex items-center justify-center h-8 w-8 text-white text-xs font-bold rounded-full border border-white ${getColorClassByPercentile(100 - candidate.combinedGradePercentile)}`}
                    onMouseEnter={() => sethoverdetails(candidate)}
                    onMouseLeave={() => sethoverdetails(null)}
                  >
                    {candidate.combinedGrade.toFixed(1)}
                  </span>
                </div>
              </div>
              <div>
                {hoverdetails && hoverdetails.id === candidate.id && (
                  <CandidateDetailModal
                    isOpen={true}
                    candidate={hoverdetails}
                  />
                )}
              </div>
            </td>
 
            {colomns === 1 && (
              <td className=" whitespace-nowrap  py-2 text-sm text-gray-500 dark:text-gray-300 ">
                <div
                  className={`${
                    expbased
                      ? `flex items-center justify-center h-12 w-12 rounded-full  ${getColorClassByPercentile(100 - candidate.educationPercentileBasedEXP)}`
                      : ""
                  }`}
                >
                  <div
                    className={`${
                      agebased
                        ? `flex items-center justify-center h-12 w-12 rounded-full ${getColorClassByPercentile(100 - candidate.educationPercentileBasedAge)}`
                        : ""
                    }`}
                  >
                    <div className="">
                      <span
                        className={`flex items-center justify-center h-8 w-8 text-white text-xs font-bold rounded-full border border-gray-200 ${getColorClassByPercentile(candidate.educationPercentileSameRole)}`}
                      >
                        {candidate.totalEducationGrade.toFixed(1)}
                      </span>
                    </div>
                  </div>
                </div>
              </td>
            )}
 
            {colomns == 1 && (
              <td className=" whitespace-nowrap py-2 text-sm text-gray-500 dark:text-gray-300 ">
                <div
                  className={`${
                    expbased
                      ? `flex items-center justify-center h-12 w-12 rounded-full  ${getColorClassByPercentile(100 - candidate.experiencePercentileBasedEXP)}`
                      : ""
                  }`}
                >
                  <div
                    className={`${
                      agebased
                        ? `flex items-center justify-center h-12 w-12 rounded-full  ${getColorClassByPercentile(100 - candidate.experiencePercentileBasedAge)}`
                        : ""
                    }`}
                  >
                    <div className="">
                      <span
                        className={`flex items-center justify-center h-8 w-8 text-white text-xs font-bold rounded-full border border-gray-200 ${getColorClassByPercentile(100 - candidate.experiencePercentileSameRole)}`}
                      >
                        {candidate.totalExperienceGrade.toFixed(1)}
                      </span>
                    </div>
                  </div>
                </div>
              </td>
            )}
 
            {colomns == 1 && (
              <td className=" py-2 text-sm text-gray-700 dark:text-gray-300">
                <div
                  className={`${
                    expbased
                      ? `flex items-center justify-center h-12 w-12 rounded-full  ${getColorClassByPercentile(candidate.gapPercentileBasedEXP)}`
                      : ""
                  }`}
                >
                  <div
                    className={`${
                      agebased
                        ? `flex items-center justify-center h-12 w-12 rounded-full  ${getColorClassByPercentile(candidate.gapmonthspercentileAge)}`
                        : ""
                    }`}
                  >
                    <div
                      className={`flex items-center justify-center h-8 w-8 text-white text-xs font-bold rounded-full border border-white ${getColorClassByPercentile(candidate.gappercentile)}`}
                    >
                      {candidate.totalGapMonths}
                    </div>
                  </div>
                </div>
              </td>
            )}
 
            {colomns == 1 && (
              <td className=" whitespace-nowrap  ml-4 mt-[22px]  text-sm text-gray-700   dark:text-gray-300 flex items-center justify-center h-12 w-12  text-white text-xs font-bold rounded-full border-2 border-black">
                <GradesChart
                  educationGrades={candidate.educationGrades}
                  experienceGrades={candidate.experienceGrades}
                />
              </td>
            )}

            <td className="whitespace-nowrap py-2 text-sm text-gray-500 dark:text-gray-300 "
            >
              <div className="w-16 h-16 mb-3">
                {candidate.score!==null ? (
                  <>
                  <SkillPieChart
                    avgSkillScores={candidate.avgSkillScores}
                    score={candidate.score}
                    percentile={candidate.scorePercentile}
                    candidateId={candidate.id}
                    onScoreMouseEnter={onScoreMouseEnter}
                    onMouseLeave={onScoreMouseLeave}
                  />

              <div className="relative items-center justify-center h-11 w-11  inline-flex p-2 text-white text-xs font-bold rounded-full">
                {showScoreDialog &&
                  candidate.id === showScoreDetailsId &&
                  answeredPercentage && (
                    <div
                      className="absolute top-4 left-0 z-10 p-2 bg-white shadow-lg border dark:border-gray-600 border-gray-300 rounded-lg dark:bg-darkbglight text-gray-500 dark:text-gray-300"
                      onClick={(e) => e.preventDefault()}
                    >
                      <div className="flex flex-col  gap-4 h-fit p-2">
                        <div className="flex flex-row gap-4">
                          <div className="flex flex-col items-center">
                            <div className="text-xs">Start Time</div>
                            <div className="p-1 bg-green-400 rounded-lg dark:text-black text-white">
                              {answeredPercentage.startTime
                                ? dateConverter(
                                  answeredPercentage.startTime,
                                  true
                                )
                                : "TBD"}
                            </div>
                          </div>
                          {answeredPercentage.endTime ? (
                            <div className="flex flex-col items-center">
                              <div className="text-xs ">End Time</div>
                              <div className="p-1 bg-green-400 rounded-lg dark:text-black text-white">
                                {answeredPercentage.endTime
                                  ? dateConverter(
                                    answeredPercentage.endTime,
                                    true
                                  )
                                  : "TBD"}
                              </div>
                            </div>
                          ) : (
                            answeredPercentage.startTime &&
                            !answeredPercentage.endTime && (
                              <div className="flex flex-col items-center">
                                <div className="text-xs ">Time Remaining</div>
                                <div className={`p-1 bg-orange-400 rounded-lg`}>
                                  {candidate && assessmentTime && (
                                    <TimeRemaining
                                      endTime={answeredPercentage.endTime}
                                      startTime={answeredPercentage.startTime}
                                      assessmentTime={assessmentTime}
                                    />
                                  )}
                                </div>
                              </div>
                            )
                          )}
                        </div>
                        <div>
                          {answeredPercentage.startTime &&
                            answeredPercentage.endTime && (
                              <div className="flex flex-row items-center gap-2">
                                Test ended in -{" "}
                                {(() => {
                                  const startTime = new Date(
                                    answeredPercentage.startTime
                                  );
                                  const endTime = new Date(
                                    answeredPercentage.endTime
                                  );
                                  const timeDiffInMs =
                                    endTime.getTime() - startTime.getTime();
                                  const minutes = Math.floor(
                                    timeDiffInMs / (1000 * 60)
                                  );
                                  const seconds = Math.floor(
                                    (timeDiffInMs % (1000 * 60)) / 1000
                                  );
                                  if (assessmentTime) {
                                    const assessmentDurationInMs =
                                      assessmentTime * 60 * 1000;
                                    return (
                                      <div
                                        className={`${timeDiffInMs < assessmentDurationInMs / 2 ? "bg-red-400" : "bg-green-400"} w-fit text-white p-1 rounded-lg dark:text-gray-800`}
                                      >
                                        {`${minutes}:${seconds < 10 ? "0" : ""}${seconds} mins`}
                                      </div>
                                    );
                                  }
                                  return (
                                    <div
                                      className={`bg-gray-400 w-fit text-white p-1 rounded-lg`}
                                    >
                                      {`${minutes}:${seconds < 10 ? "0" : ""}${seconds} mins`}
                                    </div>
                                  );
                                })()}
                              </div>
                            )}
                        </div>

                        {answeredPercentage.endTime && (
                          <div>
                            Answered Questions:{" "}
                            {answeredPercentage &&
                              answeredPercentage.answeredPercentage.toFixed(2)}
                            %
                          </div>
                        )}
                        {answeredPercentage.scores && answeredPercentage.scores.length>0 && (
                          <div>
                            <div className="flex flex-row items-center gap-2">
                              <div>Scores:</div>
                              <div className="flex flex-row gap-2 w-fit">
                                {answeredPercentage.scores.map((score, index) => (
                                  <div>
                                      {score && <div
                                      key={index}
                                      className={` bg-green-400 p-2 rounded-lg dark:text-gray-600 text-white h-fit w-fit text-center`}
                                    >
                                      {score} 
                                    </div>}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  </div>
                  </>
                ) : (
                  "-"
                )}
              </div>
            </td>
            {skillcol === 1 &&
              uniqueSkills.map((skill, index) => {
                const skillData = candidate.avgSkillScores.find(
                  (s) => s.skillName === skill
                );
                return (
                  <td
                  key={index}
                  className="whitespace-nowrap py-2 text-sm text-gray-500 dark:text-gray-300"
                >
                  {skillData ? (
                    <div className="py-2 text-sm text-gray-700 dark:text-gray-300">
                      <div
                       // className={`flex items-center justify-center h-12 w-12 rounded-full ${getColorClassBySkillScore(skillData.historicalPercentile)}`}
                      >
                        <div
                          className={`flex items-center justify-center h-12 w-12 inline-flex p-2 text-white text-xs font-bold rounded-full ${getColorClassBySkillScore(skillData.percentile)}`}
                        >
                          {skillData.skillScore.toFixed(1)}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </td>
               
                );
              })}
            {/* <td
              className="whitespace-nowrap text-sm text-gray-500 dark:text-gray-300"
              onMouseEnter={() => handleMouseEnter(candidate.id)}
              onMouseLeave={handleMouseLeave}
            >
              <span
                className={`relative items-center justify-center h-11 w-11  inline-flex p-2 text-white text-xs font-bold rounded-full ${getColorClassBySkillScore(candidate.scorePercentile)}`}
              >
                {candidate.score !== null ? candidate.score : "-"}
                
              </span>
            </td> */}

            <td className="whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
              {showGrades(candidate) ? (
                <Grade
                  handleUpdateCandidates={handleUpdateCandidates}
                  roleStatuses={status.roleStatuses}
                  candidate={candidate}
                />
              ) : (
                <span className="text-xs">N/A</span>
              )}
            </td>
            {/* <td
              className={`pl-4 whitespace-nowrap py-2 text-sm text-gray-500 dark:text-gray-300`}
            >
              {candidate.assignmentSubmission ? (
                <a
                  href={candidate.assignmentSubmission.assignmentLink}
                  target="blank"
                  className="font-medium text-entntblue hover:text-entntorange"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  GitHub
                </a>
              ) : (
                "-"
              )}
            </td> */}
            {/* <td className="whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
              {candidate.yearsOfExperience === 0
                ? candidate.expectedGraduationDate
                  ? dateConverter(candidate.expectedGraduationDate)
                  : 0 + " years"
                : candidate.yearsOfExperience + " years"}
            </td> */}
            {/* <td className="whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
              <div className="flex relative">
                <span
                  style={
                    getLastKnownPos(candidate) !== undefined
                      ? getLastKnownPos(candidate)?.type === "edu"
                        ? getStyleForUniversity(
                          getLastKnownPos(candidate)?.ranking ?? 0,
                          (getLastKnownPos(candidate)?.value as Education)
                            .postGrad
                        )
                        : getStyleForCompany(
                          getLastKnownPos(candidate)?.ranking ?? 0
                        )
                      : undefined
                  }
                  className={`px-2 py-[2px] rounded-md text-xs truncate max-w-40`}
                >
                  {getLastKnownPos(candidate) !== undefined &&
                    (getLastKnownPos(candidate)?.type === "edu"
                      ? (getLastKnownPos(candidate)?.value as Education)
                        .university.name
                      : (getLastKnownPos(candidate)?.value as Experience)
                        .companyLocation.company.name)}
                </span>
              </div>
            </td> */}
            {/* <td className="whitespace-nowrap text-sm text-gray-500">
              {getLastKnownPos(candidate) !== undefined &&
              getLastKnownPos(candidate)?.type === "edu"
                ? (getLastKnownPos(candidate)?.value as Education).period
                : getExpTime(getLastKnownPos(candidate)?.value as Experience)}
            </td> */}
            <td className="whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
              {candidate.expectedGraduationDate
                ? dateConverter(candidate.expectedGraduationDate)
                : (candidate.noticePeriod ?? 0) + " mon"}
            </td>
            {/* <td className="whitespace-nowrap text-sm text-gray-500">
              <p className="text-sm text-gray-500 dark:text-gray-400">
                {candidate.candidateStatuses[
                  candidate.candidateStatuses.length - 1
                ]?.date &&
                  dateConverter(
                    candidate.candidateStatuses[
                      candidate.candidateStatuses.length - 1
                    ].date
                  )}
              </p>
            </td> */}
          </tr>
        ))}
      </tbody>
    </>
  );
}
