import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { GetRoles } from "../WebCalls";
import { Role } from "../Models";
import RoleCard from "./Roles/RoleCard";
import NoData from "./Shared/NoData";
import { Spinner } from "./Shared/Spinner";
import { clearLocalStorageExceptDark } from "./TextEditor/textEditorConfig";
import { CalendarDaysIcon } from "@heroicons/react/24/outline";

export default function Roles() {
  const [roles, setRoles] = useState<Role[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    GetRoles().then((roles) => {
      roles.sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      );
      setRoles(roles);
      setLoading(false);
    });
  }, []);

  return (
    <>
      <header className="bg-gray-100 py-8 dark:bg-darkbglight ">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex items-center justify-between flex-wrap">
          <h1 className="mt-2 text-2xl font-bold leading-7 text-gray-800 sm:text-3xl sm:tracking-tight dark:text-gray-300">
            Job Postings
          </h1>
          <div className="flex items-center">
          <Link to="/roles/calendar" className="mt-3 sm:ml-4 sm:mt-0">
            <div className="flex items-center px-3 py-2 rounded-md  font-semibold text-gray-800 dark:text-gray-300 ">
              <CalendarDaysIcon className="h-6 w-6 aria-hidden:true"/>
              <div className="pl-2 ">
              Calendar
              </div>
            </div>
          </Link>
          <Link to="/roles/create" className="mt-3 sm:ml-4 sm:mt-0">
            <button
              type="button"
              className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-entntorange focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => clearLocalStorageExceptDark()}
            >
              Create new job
            </button>
          </Link>
          </div>
        </div>
      </header>
      {loading ? (
        <div className="h-[50vh] flex items-center justify-center">
          <Spinner height="h-14" width="h-14" />
        </div>
      ) : roles.length === 0 ? (
        <NoData
          title="No Roles"
          description="
      There are currently no available roles. Click 'Create new job' to start adding roles."
        />
      ) : (
        roles.map((role) => <RoleCard role={role} key={role.id} />)
      )}
    </>
  );
}
