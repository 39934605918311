import { useEffect, useState } from "react";
import { ChevronDoubleRightIcon, ChevronDoubleLeftIcon} from "@heroicons/react/20/solid";
import { deleteMultipleTemplates, fetchAllSkills, getTemplateSkillMappings } from "../../../WebCalls";
import { Skill, TemplateData, TemplateSkillMapping, TemplateWithQuestionnaires } from "../../../Models";
import Notification from "../../Shared/Notification";
import GenerateTemplateTestModal, {TestDetails } from "../Modals/GenerateTemplateTestModal";
import ConfirmModal from "../../Shared/ConfirmModal";




const TemplateRightSideBar = ({ onToggle, isEditable, isEditedToggle, fetchTemplatesAndRoles, selectedTemplates }: { onToggle: Function, isEditable: { editable: boolean, template: TemplateWithQuestionnaires | undefined } | undefined, isEditedToggle: Function, fetchTemplatesAndRoles: Function, selectedTemplates: string[] }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
   
    const [allSkills, setAllSkills] = useState<Skill[]>([]);
    const [templateData, setTemplateData] = useState<TemplateData>({
        name: '',
        description: '',
        time: '',
        numOfQuestions: '',
        positivePoints: '',
        negativePoints: '',

    });
    const [notificationText, setNotificationText] = useState<{ text: string, type: "success" | "error" | "info" }>({
        text: '',
        type: 'success'
    });
    const [openModal, setOpenModal] = useState<boolean>(false);

    const [toggleNofication, setToggleNofication] = useState<boolean>(false);
    const [currentSelectedTemplate, setCurrentSelectedTemplate] = useState<TemplateWithQuestionnaires>();

    const [prevSkillMappings, setPrevSkillMappings] = useState<TemplateSkillMapping[]>([]);
    const [isGenerateTestModalOpen, setIsGenerateTestModalOpen] = useState(false);
    const [templateTestData, setTemplateTestData] = useState<TestDetails>({
        numberOfTests: "",
        baseScore: "",
        time: currentSelectedTemplate?.time || "",
        questionOption: 'allQuestions',
        numberOfTimesAnswered: "-1",
    });

    const [skillMappings, setSkillMappings] = useState<TemplateSkillMapping[]>([]);
    useEffect(() => {
        fetchAllSkills().then((res) => {
            setAllSkills(res);
        })
    }, [])
    useEffect(() => {
        if (isEditable?.editable && isEditable.template) {
            fetchPrevSkillMappings();
            setIsSidebarOpen(true);
            onToggle(true);
            const { name, description, time, positivePoints, negativePoints, numOfQuestions } = isEditable.template
            setTemplateData((prev) => ({ ...prev, name: name, description: description, negativePoints: negativePoints, positivePoints: positivePoints, time: time, numOfQuestions: numOfQuestions }));
            setTemplateTestData((prevData) => ({
                ...prevData,
                time: time
            }));
            setCurrentSelectedTemplate(isEditable.template);
            setIsGenerateTestModalOpen(true);
        }
    }, [isEditable])


    const handleToggle = () => {
        setIsSidebarOpen(!isSidebarOpen);
        onToggle(!isSidebarOpen);
    };



    const fetchPrevSkillMappings = async () => {
        if (isEditable?.template) {
            const mappings: TemplateSkillMapping[] = [];
            getTemplateSkillMappings(isEditable.template?.id).then((res: TemplateSkillMapping[]) => {
                res.map((r: TemplateSkillMapping) => {
                    const temp: TemplateSkillMapping = {
                        difficultyMax: r.difficultyMax,
                        difficultyMin: r.difficultyMin,
                        variationMax: r.variationMax,
                        variationMin: r.variationMin,
                        noOfQuestionsMin: 0,
                        noOfQuestionsMax: 100,
                        questionPercentage: r.questionPercentage,
                        newQuestionPercentageMin: r.newQuestionPercentageMin,
                        newQuestionPercentageMax: r.newQuestionPercentageMax,
                        aboveThresholdPercentage: 0,
                        belowThresholdPercentage: 0,
                        skillId: r.skillId,
                        locked: true
                    }
                    mappings.push(temp);

                })
                setPrevSkillMappings(mappings);
                setSkillMappings(mappings);
            })
        }
    }

    const isTemplateModalClosed = () => {
        setIsGenerateTestModalOpen(false);
    }
    const cancelAdd = () => {
        setIsGenerateTestModalOpen(false);
    };
    const getTemplateTestData = (inputFields: TestDetails) => {
        setTemplateTestData(inputFields);
    };
    const handleAddAssessmentTemplateTest = async () => {

    }

    const getTempSkillMappings = (currSkillMappings: TemplateSkillMapping[]) => {
        setSkillMappings(currSkillMappings);
    }
   
    const handleTemplatesDelete = async () => {
        setOpenModal(true);
    }

    const onCofirmModal = async () => {
        await deleteMultipleTemplates(selectedTemplates).then(() => {
            setNotificationText({ text: "Templates Deleted Successfully", type: "success" });
            setToggleNofication(true);
            setOpenModal(false);
            fetchTemplatesAndRoles();
        });
      
    }

    const onCancelModal = () => {
        setOpenModal(false);
    }
    return (
        <aside
            className={`select-none fixed z-10 top-[4.1rem] block h-[calc(99vh-4rem)] right-0 shadow-md border dark:border-gray-500  border-gray-200 transition-transform transform duration-200 w-[19rem] ${isSidebarOpen ? "translate-x-0" : "translate-x-[100%]"} flex flex-col`}
        >

            <div className=" bg-gray-100 thin-scroll overflow-y-auto dark:bg-darkbg  w-full flex flex-col">
                <div className="min-w-0 px-4 pt-2 pb-1 dark:bg-darkbglight">
                    <div className="text-gray-900 dark:text-gray-300 flex items-center space-x-2 justify-center ">
                        <h3 className="text-center text-lg font-semibold leading-7 sm:text-base sm:tracking-tight">
                            Assessment Template Actions
                        </h3>
                    </div>

                </div>
            </div>
            <div
                className={`absolute top-10 ${isSidebarOpen ? "-left-2 shadow-md" : "-left-4 shadow-xl"} h-16 border border-gray-300 rounded-md flex items-center justify-center cursor-pointer bg-white dark:bg-darkbglight dark:border-gray-500 dark:text-gray-300`}
                onClick={handleToggle}
            >
                {isSidebarOpen ? <ChevronDoubleRightIcon width={16} /> : <ChevronDoubleLeftIcon width={16} />}
            </div>
            <div className="h-full bg-white thin-scroll overflow-y-auto dark:bg-darkbg w-full flex flex-col items-center p-2 gap-4 mt-8">

                <div className="border-b-2 pb-4 w-full flex flex-row items-center border-gray-600 justify-center">
                    <button
                        className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 font-semibold text-white shadow-sm hover:bg-entntorange focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 text-xs"
                        onClick={() => {// if (validateFields(true)) {
                            setIsGenerateTestModalOpen(true);
                            // submitTemplate();
                            // }
                        }}
                    >
                        Create Test Set +
                    </button>
                </div>
                {selectedTemplates.length > 0 && <div className="border-b-2 border-gray-600  pb-4 w-full flex flex-row items-center justify-center">
                    <button
                        className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 font-semibold text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 text-xs"
                        onClick={() => {
                            handleTemplatesDelete();
                        }}
                    >
                        Delete Selected Templates : {selectedTemplates.length}
                    </button>
                </div>
                }

               
            </div>
            {isGenerateTestModalOpen && (
                <GenerateTemplateTestModal
                    title=""
                    content=""
                    onCancel={cancelAdd}
                    isModalClosed={isTemplateModalClosed}
                    size="small"
                    getTemplateTestData={getTemplateTestData}
                    handleAddAssessmentTemplateTest={handleAddAssessmentTemplateTest}
                    currentSelectedTemplate={currentSelectedTemplate}
                    prevSkillMappings={skillMappings && !isEditable?.editable ? skillMappings : (prevSkillMappings && isEditable?.editable) ? prevSkillMappings : []}
                    isCreating={true}
                    getTemplateSkillMappings={getTempSkillMappings}
                    prevInputFields={templateTestData}
                    fetchTemplatesAndRoles={fetchTemplatesAndRoles}
                    isRecalibrating={false}
                    isEditable={isEditable?.editable ?? false}
                />
            )
            }
            {toggleNofication &&
                <Notification show={toggleNofication} setShow={setToggleNofication} title={notificationText.type.charAt(0).toUpperCase() + notificationText.type.slice(1)} description={notificationText.text} type={notificationText.type} />
            }
            
              {openModal && <ConfirmModal
                            type={"danger"}
                            open={openModal}
                            setOpen={setOpenModal}
                            onConfirm={onCofirmModal}
                            onCancel={onCancelModal}
                            confirmButtonTitle={"Delete"}
                            description={"Are you sure you want to delete the selected templates?"}
                            title={"Delete Templates"}
            
                        />
                        }
        </aside>

    );
};

export default TemplateRightSideBar;
