import {
  AutoAdvanceCondition,
  Candidate,
  CandidateComment,
  CandidateStatus,
  City,
  Role,
  RoleStatus,
  RoleStatusCandidateCount,
  Option,
  QuestionGroup,
  Category,
  CategoryFull,
  TemplateData,
  Skill,
  FilterRanking,
} from "./Models";
import { recruitmentProcess } from "./components/Roles/CreateRole/CreateRoleForm";
import { SortOptionTemplate } from "./components/Template/AssessmentTemplates";
import { Question } from "./components/Template/Modals/GenerateTemplateTestModal";
import {
  LocationStats,
  State,
  LocationActionsLog,
  ICandidate,
  InterviewSlots,
} from "./Models";
import { graphConfig } from "./authProvider";
import { ITotalCounts } from "./components/LocationStats/Sidebar/LocationLeftSidebar";

export type paginationData = {
  totalCount: number;
  pageSize: number;
  currentPage: number;
  totalPages: number;
};

const APIURL = "https://entntcareersapi.azurewebsites.net/api";
// const APIURL = "https://localhost:7154/api";

export async function GetRoles() {
  const res = await fetch(`${APIURL}/Roles`);
  const roles: Role[] = await res.json();
  return roles;
}

export async function GetRole(id: string, token: string) {
  const res = await fetch(`${APIURL}/Roles/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const role: Role = await res.json();
  return role;
}

export async function CreateRole(body: Role, token: string) {
  const res = await fetch(`${APIURL}/Roles`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  const role: Role = await res.json();
  return role;
}

export async function DeleteRole(roleId: string, token: string) {
  await fetch(`${APIURL}/Roles/${roleId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function UpdateRole(
  roleId: string,
  body: { propertyName: string; propertyValue: string }[],
  token: string
) {
  await fetch(`${APIURL}/Roles/${roleId}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
}
export async function PutUpdateRole(roleId: string, body: Role, token: string) {
  await fetch(`${APIURL}/Roles/${roleId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
}

export async function GetCandidateForRole(
  roleId: string,
  token: string,
  filterQuery = "",
  pageNumber = 1,
  searchQuery = "",
  sortQuery = ""
) {
  const res = await fetch(
    `${APIURL}/Roles/${roleId}/Candidates?pageNumber=${pageNumber}&pageSize=${100}&filterQuery=${filterQuery}&searchQuery=${searchQuery}&orderBy=${sortQuery}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  const candidates: {
    candidates: Candidate[];
    paginationMetadata: paginationData;
  } = await res.json();

  return candidates;
}
export async function GetCandidate(candidateId: string, token: string) {
  const res = await fetch(`${APIURL}/Candidates/${candidateId}/full`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const candidate: Candidate = await res.json();
  return candidate;
}

export async function GetRoleForCandidate(candidateId: string, token: string) {
  const res = await fetch(`${APIURL}/Candidates/${candidateId}/roles`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const role: Role[] = await res.json();
  return role;
}

export async function GetProfilePhoto(token: string, personId?: string) {
  const res = await fetch(
    personId
      ? `https://graph.microsoft.com/v1.0/users/${personId}/photo/$value`
      : graphConfig.graphGetPhotoSmall,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  let photo: Blob | undefined;
  if (res.status === 200) photo = await res.blob();
  return photo;
}

export async function GetStatusForRole(roleId: string, token: string) {
  const res = await fetch(`${APIURL}/Roles/${roleId}/Status`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const roleStatus: RoleStatusCandidateCount = await res.json();
  return roleStatus;
}

export async function GetRoleStatus(roleStatusId: string, token: string) {
  const res = await fetch(`${APIURL}/Roles/Status/${roleStatusId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const roleStatus: RoleStatus = await res.json();
  return roleStatus;
}
export async function UpdateRoleStatus(
  roleStatusId: string,
  token: string,
  body: RoleStatus
) {
  const res = await fetch(`${APIURL}/Roles/Status/${roleStatusId}`, {
    body: JSON.stringify(body),
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  const json: RoleStatus = await res.json();
  return json;
}
export async function GetCandidateStatus(candidateId: string, token: string) {
  const res = await fetch(`${APIURL}/Candidates/${candidateId}/Status`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const CandidateStatus: CandidateStatus[] = await res.json();
  return CandidateStatus;
}

export async function CreateCandidateStatus(
  token: string,
  body: CandidateStatus
) {
  const res = await fetch(`${APIURL}/Candidates/Status`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  const CandidateStatus: CandidateStatus = await res.json();
  return CandidateStatus;
}

export async function CreateMultipleCandidateStatus(
  token: string,
  body: CandidateStatus[]
) {
  const res = await fetch(`${APIURL}/Candidates/Status/multiple`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  const CandidateStatuses: CandidateStatus[] = await res.json();
  return CandidateStatuses;
}

export async function GetCandidateComments(candidateId: string, token: string) {
  const res = await fetch(`${APIURL}/Candidates/${candidateId}/comment`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const CandidateComments: CandidateComment[] = await res.json();
  return CandidateComments;
}

export async function GetUserName(personId: string, token: string) {
  const res = await fetch(
    `https://graph.microsoft.com/v1.0/users/${personId}?$select=displayName`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  const username = await res.json();
  return username;
}

export async function CreateCandidateComment(
  body: CandidateComment,
  token: string
) {
  const res = await fetch(`${APIURL}/Candidates/comment`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  const newCandidateComment: CandidateComment = await res.json();
  return newCandidateComment;
}

export async function DeleteComment(id: string, token: string) {
  await fetch(`${APIURL}/Candidates/comment/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function PostRoleStatus(body: RoleStatus, token: string) {
  await fetch(`${APIURL}/Roles/Status`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
}

export async function PostAutoAdvanceCondition(
  body: AutoAdvanceCondition,
  token: string
) {
  await fetch(`${APIURL}/Roles/Status/autoAdvanceCondition`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
}

export async function DeleteAutoAdvanceCondition(id: string, token: string) {
  await fetch(`${APIURL}/Roles/Status/autoAdvanceCondition/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function GetCities() {
  const res = await fetch(`${APIURL}/MasterData/Cities`);
  const cities: City[] = await res.json();
  return cities;
}
export async function GetMajorCities() {
  const res = await fetch(`${APIURL}/MasterData/Cities/majorCities`);
  const cities: City[] = await res.json();
  return cities;
}
export async function GetallSubCities() {
  const res = await fetch(`${APIURL}/MasterData/Cities/subCities`);
  const cities: City[] = await res.json();
  return cities;
}

export async function GetCandidateTimeline(
  roleId: string,
  candidateId: string,
  token: string
) {
  const res = await fetch(
    `${APIURL}/Candidates/${roleId}/candidate/${candidateId}/timeline`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const timeline = await res.json();
  return timeline;
}

export async function GetCandidateCv(candidateId: string, token: string) {
  const res = await fetch(`${APIURL}/Candidates/candidate/${candidateId}/cv`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const cvBlob = await res.blob();
  return cvBlob;
}

export async function submitReview(submissionData: any) {
  const response = await fetch(`${APIURL}/Assignments/review`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(submissionData),
  });

  if (!response.ok) {
    throw new Error("Failed to submit assignment");
  }
}

export async function fetchComments(candidateId: string, roleId: string) {
  try {
    const response = await fetch(
      `${APIURL}/Assignments/GetReview?candidateId=${candidateId}&roleId=${roleId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch comments");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(`Failed to fetch comments:`);
  }
}

export async function GetCandidateData(candidateId: any, roleId: any) {
  try {
    const response = await fetch(
      `${APIURL}/Assignments/candidateData?candidateId=${candidateId}&roleId=${roleId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to check assignment submission");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(`Failed to check assignment submission:`);
  }
}

export async function GetAssignmentData() {
  try {
    const response = await fetch(`${APIURL}/Assignments/Getdata`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(
        `Failed to load data: ${response.status} ${response.statusText}`
      );
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error(`Failed to load data: ${error}`);
    throw new Error(`Failed to load data`);
  }
}

export const fetchQuestionGroups = async () => {
  const res = await fetch(`${APIURL}/AssessmentQuestionGroup`);
  const json = res.json();
  return json;
};

export const getAllAssessments = async () => {
  const res = await fetch(`${APIURL}/AssessmentQuestionnaires`);
  const json = res.json();
  return json;
};

export const putAssessmentQuestion = async (
  id: string | undefined,
  text: string,
  time: string,
  type: string,
  tags: string,
  questionGroupId: QuestionGroup | undefined
) => {
  await fetch(`${APIURL}/AssessmentQuestion/${id}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id: id,
      text: text,
      time: time,
      type: type.toLowerCase(),
      tags: tags,
      questionGroupId: questionGroupId?.id ?? null,
    }),
  });
};

export const getQuestionById = async (qId: string) => {
  const res = await fetch(`${APIURL}/AssessmentQuestion/questions/${qId}`);
  const json = res.json();
  return json;
};

export const postAssessment = async (assessment: any) => {
  const res = await fetch(`${APIURL}/AssessmentQuestionnaires`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(assessment),
  });
  const json = await res.json();
  return json;
};
export const deleteAssessment = async (id: string | undefined) => {
  await fetch(`${APIURL}/AssessmentQuestionnaires/${id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  return;
};
export const postQuestion = async (
  text: string,
  time: string,
  tags: string,
  selectedQGroupId: string | null,
  qType: string,
  options: Option[] | undefined
) => {
  const res = await fetch(`${APIURL}/AssessmentQuestion`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      text,
      time: parseInt(time),
      tags,
      questionGroupId: selectedQGroupId,
      type: qType.toLowerCase(),
    }),
  });
  const json = await res.json();
  const { id } = json;

  if (options)
    for (let i of options) {
      await fetch(`${APIURL}/AssessmentQuestionAnswer`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          questionId: id,
          text: i.text,
          isCorrect: i.isCorrect,
        }),
      });
    }
  return json;
};

export const getAllQuestions = async (
  currentPage?: number,
  searchQuery = ""
) => {
  if (currentPage) {
    const res = await fetch(
      `${APIURL}/AssessmentQuestion?pageNumber=${currentPage}&searchQuery=${searchQuery}`
    );
    const json = res.json();
    return json;
  } else {
    const res = await fetch(
      `${APIURL}/AssessmentQuestion?searchQuery=${searchQuery}`
    );
    const json = res.json();
    return json;
  }
};

export const getAllFilterQuestions = async (
  accuracy: { min: number; max: number },
  qGids?: string[],
  selectedSkills?: string[],
  selectedAssessments?: string[],
  selectedType?: string[],
  currentPage?: number,
  searchQuery = "",
  sortOptions: { field: string; direction: "asc" | "desc" | "default" }[] = []
) => {
  const { min, max } = accuracy;

  let url = `${APIURL}/AssessmentQuestion/filtered`;

  let queryParams = `?MinAcc=${min}&MaxAcc=${max}`;

  if (currentPage) {
    queryParams += `&pageNumber=${currentPage}`;
  }

  if (searchQuery) {
    queryParams += `&searchQuery=${searchQuery}`;
  }

  const textSort = sortOptions.find((option) => option.field === "text");
  const accuracySort = sortOptions.find(
    (option) => option.field === "accuracy"
  );

  queryParams += `&sortByText=${textSort?.direction || "default"}`;
  queryParams += `&sortByAccuracy=${accuracySort?.direction || "default"}`;

  url += queryParams;

  const body = {
    questionGroupIds: qGids,
    skillIds: selectedSkills,
    assessmentIds: selectedAssessments,
    Type: selectedType,
    sortOptions: sortOptions.filter((option) => option.direction !== "default"),
  };

  const res = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });

  const json = await res.json();
  return json;
};

export const getAssessmentDetailById = async (id: string) => {
  const res = await fetch(`${APIURL}/AssessmentQuestionnaires/${id}`);
  const json = res.json();
  return json;
};

export const updateAssessmentDetailsById = async (assessmentDetails: any) => {
  await fetch(`${APIURL}/AssessmentQuestionnaires`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(assessmentDetails),
  });
  return;
};

export const getAllQuestionsByQuestionnareId = async (id: string) => {
  const res = await fetch(`${APIURL}/AssessmentQuestionMapping/${id}`);
  const json = res.json();
  return json;
};

export const getAllQuestionsExcludingQuestionnareId = async () => {
  const res = await fetch(`${APIURL}/AssessmentQuestion`);
  const json = res.json();
  return json;
};

export const postQuestionGroup = async (title: string) => {
  await fetch(`${APIURL}/AssessmentQuestionGroup`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ title }),
  });
  return;
};

export const addQuestionsToAssessment = async (assessmentQuestions: any) => {
  await fetch(`${APIURL}/AssessmentQuestionMapping/add-multiple`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(assessmentQuestions),
  });
  return;
};

export const removeQuestionsFromAssessment = async (
  assessmentQuestions: any
) => {
  await fetch(`${APIURL}/AssessmentQuestionMapping/delete-multiple`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(assessmentQuestions),
  });
  return;
};

export const putAndPostAssessmentOptions = async (
  Qid: string | undefined,
  options: Option[]
) => {
  let updatedOptions: Option[] = [];
  let newOptions: any[] = [];
  const resolvedOptions = await Promise.all(options);
  resolvedOptions.forEach((option) => {
    const { id, ...rest } = option;

    if (option.id.toLocaleString().length > 5) {
      updatedOptions.push(option);
    } else {
      newOptions.push({ ...rest, questionId: Qid });
    }
  });
  if (updatedOptions.length > 0) {
    await fetch(`${APIURL}/AssessmentQuestionAnswer/put-multiple`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedOptions),
    });
  }

  if (newOptions.length > 0) {
    await fetch(`${APIURL}/AssessmentQuestionAnswer/post-multiple`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newOptions),
    });
  }
};

export const deleteAssessmentOptions = async (option: Option) => {
  await fetch(`${APIURL}/AssessmentQuestionAnswer`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(option),
  });
};

export const deleteQuestions = async (questions: string[]) => {
  await fetch(`${APIURL}/AssessmentQuestion`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(questions),
  });
};

export const fetchAllSkills = async () => {
  const res = await fetch(`${APIURL}/Skill`);
  const json = await res.json();
  return json;
};

export const getRoleWithAutoAdvanceConditions = async (id: String) => {
  const res = await fetch(`${APIURL}/Roles/${id}/Status`);
  const json = res.json();
  return json;
};

export const postAudioQuestion = async (
  questionId: string,
  questionData: string
) => {
  const res = await fetch(`${APIURL}/AudioQuestions`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ questionId, questionData }),
  });
  const json = await res.json();
  return json;
};

export const deleteAudioQuestion = async (questionId: string) => {
  await fetch(`${APIURL}/AudioQuestions/${questionId}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

export const postCandidateSpecificQuestionnaire = async (
  candidateIds: string[],
  assessmentQuestionnaireId: string,
  roleId: string
) => {
  await fetch(
    `${APIURL}/CandidateSpecificQuestionnaires?assessmentQuestionnaireId=${assessmentQuestionnaireId}&RoleId=${roleId}`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(candidateIds),
    }
  );
};

export const getCandidateVideoAnswers = async (
  cIds: string[]
): Promise<any[]> => {
  const res = await fetch(
    `${APIURL}/CandidateMediaAnswer/Answers/Multiple/Video`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(cIds),
    }
  );
  const json = await res.json();
  return json;
};

export async function GradeCandidate(
  candidateStatus: CandidateStatus,
  token: string
) {
  try {
    const response = await fetch(`${APIURL}/Candidates/Status/Grade`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(candidateStatus),
    });
    if (!response.ok) {
      throw new Error("Failed to grade candidate");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(`Something went wrong`);
  }
}
//Ranking calls
export const getRankType = async (token: string) => {
  const res = await fetch(`${APIURL}/Ranking/RankType`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const json: { id: string; name: string }[] = await res.json();
  return json;
};

export const getCompaniesByRankType = async (
  rankTypeId: string,
  pageNumber: number = 1,
  pageSize: number = 100,
  sortBy: string = "",
  sortDesc: boolean = false,
  search: string = "",
  filterQuery: string = "",
  filters: FilterRanking[] = []
) => {
  const baseUrl = `${APIURL}/Ranking/Companies/${rankTypeId}`;
  const url = new URL(baseUrl);

  url.searchParams.append("pageNumber", pageNumber.toString());
  url.searchParams.append("pageSize", pageSize.toString());
  url.searchParams.append("sortBy", sortBy);
  url.searchParams.append("sortDesc", sortDesc.toString());
  url.searchParams.append("search", search);
  url.searchParams.append("filterQuery", filterQuery);
  let json;
  try {
    const res = await fetch(url.toString(), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(filters),
    });
    json = await res.json();
  } catch (ex) {
    console.error(ex);
  }
  return json;
};

export const getUniversitiesByRankType = async (
  rankTypeId: string,
  pageNumber: number = 1,
  pageSize: number = 15,
  sortBy: string = "",
  sortDesc: boolean = false,
  search: string = "",
  filterQuery: string = "",
  filters: FilterRanking[] = []
) => {
  const baseUrl = `${APIURL}/Ranking/Unis/${rankTypeId}`;
  const url = new URL(baseUrl);

  url.searchParams.append("pageNumber", pageNumber.toString());
  url.searchParams.append("pageSize", pageSize.toString());
  url.searchParams.append("sortBy", sortBy);
  url.searchParams.append("sortDesc", sortDesc.toString());
  url.searchParams.append("search", search);
  url.searchParams.append("filterQuery", filterQuery);
  console.log(filters);
  let json;
  try {
    const res = await fetch(url.toString(), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(filters),
    });
    json = await res.json();
  } catch (ex) {
    console.error(ex);
  }

  return json;
};

export const postNewRankType = async (rankType: string, token: string) => {
  try {
    const res = await fetch(`${APIURL}/Ranking/RankType`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ name: rankType }),
    });
    if (!res.ok) {
      throw new Error(`HTTP error status: ${res.status}`);
    }
  } catch (err) {
    return {
      error: true,
      message: "Rank type already exist",
    };
  }
};

export async function deleteRankType(rankTypeId: string, token: string) {
  await fetch(`${APIURL}/Ranking/RankType/${rankTypeId}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export const generateUniversityRankings = async (
  rankTypeId: string,
  generateRank: { ids?: string[] },
  token: string
) => {
  const res = await fetch(
    `${APIURL}/AI/GenerateRanks/University/${rankTypeId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(generateRank),
    }
  );

  const json = await res.json();
  return json;
};

export const generateCompanyRankings = async (
  rankTypeId: string,
  generateRank: { ids?: string[] },
  token: string
) => {
  const res = await fetch(`${APIURL}/AI/GenerateRanks/Company/${rankTypeId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(generateRank),
  });

  const json = await res.json();
  return json;
};

export const updateRanking = async (
  tab: string,
  rankTypeId: string | undefined,
  id: string,
  categoryId: string,
  token: string
) => {
  if (tab === "Companies") {
    const res = await fetch(`${APIURL}/Ranking/Company`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        companyId: id,
        rankTypeId: rankTypeId,
        rankCategoryId: categoryId,
      }),
    });
    if (!res.ok) {
      const er = await res.text();
      throw new Error(er);
    }
  } else {
    const res = await fetch(`${APIURL}/Ranking/University`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        universityId: id,
        rankTypeId: rankTypeId,
        rankCategoryId: categoryId,
      }),
    });
    if (!res.ok) {
      const er = await res.text();
      throw new Error(er);
    }
  }
};

export const getCompanyRankingCategories = async (
  rankTypeId: string,
  token: string
) => {
  const res = await fetch(`${APIURL}/Ranking/Company/${rankTypeId}/Category`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const json: CategoryFull[] = await res.json();
  return json;
};

export const getUniversityRankingCategories = async (
  rankTypeId: string,
  token: string
) => {
  const res = await fetch(
    `${APIURL}/Ranking/University/${rankTypeId}/Category`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const json: CategoryFull[] = await res.json();
  return json;
};

export const postRankingCategories = async (
  rankingCategory: Category,
  token: string
) => {
  const res = await fetch(`${APIURL}/Ranking/Category`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(rankingCategory),
  });
  const json: CategoryFull = await res.json();
  return json;
};

export const updateRankingCategories = async (
  id: string,
  rankingCategory: Category,
  token: string
) => {
  const res = await fetch(`${APIURL}/Ranking/Category/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(rankingCategory),
  });
  if (res.ok) {
    const json: CategoryFull = await res.json();
    return json;
  } else {
    const er = await res.text();
    throw new Error(er);
  }
};

export const deleteRankingCategories = async (id: string, token: string) => {
  await fetch(`${APIURL}/Ranking/Category/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

//Quarantine calls
export interface Filters {
  FilterType: string;
  MinCount?: number;
  MaxCount?: number;
  Locations?: (string | number | boolean | null)[];
}
export const fetchQuarantineCompanies = async (
  token: string,
  pageNumber: number = 1,
  pageSize: number = 15,
  search: string | null = null,
  sortBy: string | null = null,
  sortDesc: boolean = false,
  filters: Filters[] = []
) => {
  const baseUrl = `${APIURL}/Quarantine/QuarantineCompanies`;
  const url = new URL(baseUrl);

  url.searchParams.append("pageNumber", pageNumber.toString());
  url.searchParams.append("pageSize", pageSize.toString());
  if (search) url.searchParams.append("search", search);
  if (sortBy) url.searchParams.append("sortBy", sortBy);
  url.searchParams.append("sortDesc", sortDesc.toString());

  const payload =
    filters?.length > 0
      ? JSON.stringify(filters)
      : JSON.stringify([
          {
            FilterType: "count",
            MinCount: 0,
            MaxCount: 100,
          },
        ]);

  try {
    const res = await fetch(url.toString(), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: payload,
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const obj = await res.json();
    return obj;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const fetchQuarantineUniversities = async (
  token: string,
  pageNumber: number = 1,
  pageSize: number = 15,
  search: string | null = null,
  sortBy: string | null = null,
  sortDesc: boolean = false,
  filters: Filters[] = []
) => {
  const baseUrl = `${APIURL}/Quarantine/QuarantineUnis`;
  const url = new URL(baseUrl);

  url.searchParams.append("pageNumber", pageNumber.toString());
  url.searchParams.append("pageSize", pageSize.toString());
  if (search) url.searchParams.append("search", search);
  if (sortBy) url.searchParams.append("sortBy", sortBy);
  url.searchParams.append("sortDesc", sortDesc.toString());

  const payload =
    filters?.length > 0
      ? JSON.stringify(filters)
      : JSON.stringify([
          {
            FilterType: "count",
            MinCount: 0,
            MaxCount: 100,
          },
        ]);

  try {
    const res = await fetch(url.toString(), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: payload,
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    const obj = await res.json();
    return obj;
  } catch (error) {
    console.error("Error fetching quarantine universities:", error);
    throw error;
  }
};

export const confirmQuarantineUniversity = async (
  token: string,
  id: string,
  universityData: object
) => {
  const url = `${APIURL}/Quarantine/QuarantineUniversity/${id}`;
  try {
    const res = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(universityData),
    });
    if (!res.ok) {
      const errorMsg = await res.text();
      throw new Error(`Request failed with status ${res.status}: ${errorMsg}`);
    }
    return "University Updated Successfully.";
  } catch (err) {
    console.error("Error updating university:", err);
  }
};

export const confirmQuarantineCompany = async (
  token: string,
  id: string,
  companyData: object
) => {
  const url = `${APIURL}/Quarantine/QuarantineCompany/${id}`;
  try {
    const res = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(companyData),
    });
    if (!res.ok) {
      const errorMsg = await res.text();
      throw new Error(`Request failed with status ${res.status}: ${errorMsg}`);
    }
    return "Company Updated Successfully.";
  } catch (err) {
    console.error("Error updating Company:", err);
  }
};

export const deleteQuarantineUniversity = async (
  token: string,
  oldId: string,
  newId: string
) => {
  try {
    const QuarantineUniversityId = oldId;
    const ConfirmedUniversityId = newId;
    const response = await fetch(
      `${APIURL}/Quarantine/QuarantineUniversity/${QuarantineUniversityId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(ConfirmedUniversityId),
      }
    );

    if (response.ok) {
      const data = await response.text();
      return data;
    } else {
      throw new Error("Failed to delete university");
    }
  } catch (error) {
    console.error("Error:", error);
  }
};

export const deleteQuarantineCompany = async (
  token: string,
  oldId: string,
  newId: string
) => {
  try {
    const QuarantineCompanyId = oldId;
    const ConfirmedCompanyId = newId;
    const response = await fetch(
      `${APIURL}/Quarantine/QuarantineCompany/${QuarantineCompanyId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(ConfirmedCompanyId),
      }
    );

    if (response.ok) {
      const data = await response.text();
      return data;
    } else {
      throw new Error("Failed to delete Company");
    }
  } catch (error) {
    console.error("Error:", error);
  }
};

export const fetchConfirmedUniversities = async (
  search = "",
  pageNumber = 1,
  pageSize = 15,
  urlSearch = ""
) => {
  const res = await fetch(
    `${APIURL}/MasterData/ConfirmedUniversities?pageNumber=${pageNumber}&pageSize=${pageSize}&search=${search}&urlSearch=${urlSearch}`
  );
  const data = await res.json();
  return data;
};

export const fetchActionsLog = async () => {
  const res = await fetch(`${APIURL}/Quarantine/ActionsLog`);
  const data = await res.json();
  return data;
};

export const revertConfirm = async (actionId: string) => {
  try {
    const response = await fetch(
      `${APIURL}/Quarantine/ActionsLog/RevertConfirm`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(actionId),
      }
    );
    if (response.ok) {
      const data = await response.text();
      return data;
    } else {
      throw new Error("Failed to Revert Action.");
    }
  } catch (error) {
    console.error("Error:", error);
  }
};

export const revertReject = async (actionId: string) => {
  try {
    const response = await fetch(
      `${APIURL}/Quarantine/ActionsLog/RevertReject`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(actionId),
      }
    );
    if (response.ok) {
      const data = await response.text();
      return data;
    } else {
      throw new Error("Failed to Revert Action.");
    }
  } catch (error) {
    console.error("Error:", error);
  }
};

export const companyUpdateAI = async (Id: string) => {
  const response = await fetch(`${APIURL}/MasterData/Company`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(Id),
  });
  try {
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error();
    }
  } catch (error) {
    console.error("Error:", error);
  }
};

export const universityUpdateAI = async (Id: string) => {
  const response = await fetch(`${APIURL}/MasterData/University`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(Id),
  });
  try {
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error("Failed to Update University.");
    }
  } catch (error) {
    console.error("Error:", error);
  }
};

export const fetchAssessmentTemplates = async () => {
  const res = await fetch(`${APIURL}/AssessmentTemplate`);
  const json = res.json();
  return json;
};

export const fetchAssessmentTemplatesWithQuestionnaires = async (
  sortOptions: SortOptionTemplate,
  newFilters: any
) => {
  const res = await fetch(
    `${APIURL}/AssessmentTemplate/Questionnaires?sortField=${sortOptions.field}&sortDirection=${sortOptions.direction}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newFilters),
    }
  );
  const json = res.json();
  return json;
};

export const postAssessmentTemplate = async (
  assessmentTemplate: TemplateData
) => {
  const res = await fetch(`${APIURL}/AssessmentTemplate`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(assessmentTemplate),
  });
  const json = res.json();
  return json;
};

export const getAssessmentTemplateThreshold = async (
  templateId: string | undefined,
  numberOfTimesAnswered: number | undefined
) => {
  if (templateId) {
    const res = await fetch(
      `${APIURL}/AssessmentTemplate/generate/${templateId}?numberOfTimesAnswered=${numberOfTimesAnswered}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const json = res.json();
    return json;
  }
};

export const generateAssessmentTemplateTests = async (
  numOfQuestions: string | number,
  numberOfTests: number | string,
  numberOfTimesAnswered: number | string,
  updatedSkillMappings: any
) => {
  if (updatedSkillMappings) {
    const res = await fetch(
      `${APIURL}/AssessmentTemplate/Generate/?noOfTests=${numberOfTests}&numOfQuestions=${numOfQuestions}&numberOfTimesAnswered=${numberOfTimesAnswered}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedSkillMappings),
      }
    );
    const json = res.json();
    return json;
  }
};

export const postTemplateSkillMapping = async (
  templateId: string | undefined,
  skillMappings: {
    skillId: string;
    difficultyMin: number;
    difficultyMax: number;
    variationMin: number;
    variationMax: number;
    questionPercentage: number;
  }[]
) => {
  if (templateId) {
    const res = await fetch(
      `${APIURL}/AssessmentTemplate/TempalteSkillMapping/${templateId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(skillMappings),
      }
    );
    const json = res.json();
    return json;
  }
};

export const updateRoleAssessment = async (
  templateId: string | undefined,
  roleId: string | undefined
) => {
  if (roleId) {
    await fetch(`${APIURL}/Roles/Update/Assessment/${roleId}/${templateId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return;
  }
};

export const updateTemplate = async (
  templateData: TemplateData,
  templateId: string | undefined
) => {
  const template = {
    id: templateId,
    name: templateData.name,
    description: templateData.description,
    time: templateData.time,
    positivePoints: templateData.positivePoints,
    negativePoints: templateData.negativePoints,
  };
  await fetch(`${APIURL}/AssessmentTemplate`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(template),
  });
  return;
};

export const deleteTemplate = async (id: string) => {
  await fetch(`${APIURL}/AssessmentTemplate/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
  return;
};

export const fetchCompanyLocations = async (companyName?: string) => {
  const response = await fetch(
    `${APIURL}/Quarantine/CompanyLocations?${companyName ? `companyName=${companyName}&` : ""}pageNumber=${1}&pageSize=${500}`
  );
  try {
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error("Failed to Fetch CompanyLocations.");
    }
  } catch (error) {
    console.error("Error:", error);
  }
};
export const getAssessmentsByFilters = async (
  difficulty: any,
  templateIds?: string[],
  selectedSkills?: string[],
  currentPage?: number,
  searchQuery = ""
) => {
  const { min, max } = difficulty;

  let url = `${APIURL}/AssessmentQuestionnaires/filtered`;

  if (currentPage) {
    url += `?pageNumber=${currentPage}&searchQuery=${searchQuery}`;
  } else if (searchQuery) {
    url += `?searchQuery=${searchQuery}`;
  }

  const res = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      templateIds: templateIds,
      skillIds: selectedSkills,
      minDifficulty: min,
      maxDifficulty: max,
    }),
  });
  const json = await res.json();
  return json;
};
export const postAssessmentTemplateTests = async (
  assessments: Question[][] | undefined,
  templateId: string | undefined,
  baseScore: string,
  time: string | number
) => {
  await fetch(
    `${APIURL}/AssessmentTemplate/AssignAssessments/${templateId}?baseScore=${baseScore}&time=${time}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(assessments),
    }
  );
  return;
};

export const createAssessmentCopy = async (
  assessmentId: string,
  templateId: string | undefined,
  minAccuracy: number,
  maxAccuracy: number,
  minVariation: number,
  maxVariation: number
) => {
  const res = await fetch(
    `${APIURL}/AssessmentTemplate/generate/Test/Copy/${assessmentId}?templateId=${templateId}&minAccuracy=${minAccuracy}&MaxAccuracy=${maxAccuracy}&minVariation=${minVariation}&maxVariation=${maxVariation}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const json = res.json();
  return json;
};
export const getQuestionSkill = async (questionId: string) => {
  const res = await fetch(
    `${APIURL}/QuestionSkillMappingControllers/${questionId}`
  );
  const json = await res.json();
  return json;
};

export const postQuestionSkill = async (
  questionId: string | undefined,
  skills: Skill[]
) => {
  const skillIds = await skills.map((s) => s.id);
  await fetch(
    `${APIURL}/QuestionSkillMappingControllers/Update/${questionId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(skillIds),
    }
  );
  return;
};

export const postSkill = async (skill: Skill | undefined) => {
  if (skill)
    await fetch(`${APIURL}/Skill`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name: skill.name }),
    });
  return;
};

export async function GetLocationStats(
  locationFilterQuery: string,
  candidateFilterQuery: string,
  universityFilterQuery: string,
  companyFilterQuery: string,
  sortQuery: string,
  searchQuery: string,
  currentPage: number,
  currentTab: string,
  token: string | undefined,
  univRankTypeId: string,
  compRankTypeId: string,
  atTheTime: number
) {
  const res = await fetch(
    `${APIURL}/Location/LocationStats?filterQuery=${locationFilterQuery}&candidateFilterQuery=${candidateFilterQuery}&universityFilterQuery=${universityFilterQuery}&companyFilterQuery=${companyFilterQuery}&orderBy=${sortQuery}&searchQuery=${searchQuery}&pageNumber=${currentPage}&pageSize=${50}&searchBy=${currentTab}&univRankTypeId=${univRankTypeId}&compRankTypeId=${compRankTypeId}&locationType=${atTheTime}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  const locationStats: {
    locations: LocationStats[];
    paginationMetadata: paginationData;
  } = await res.json();

  return locationStats;
}
export async function GetLocationStatsGeoJson(
  locationFilterQuery: string,
  candidateFilterQuery: string,
  universityFilterQuery: string,
  companyFilterQuery: string,
  sortQuery: string,
  searchQuery: string,
  currentPage: number,
  currentTab: string,
  createMapBy: string | undefined,
  token: string | undefined,
  univRankTypeId: string,
  compRankTypeId: string,
  atTheTime: number
) {
  const res = await fetch(
    `${APIURL}/Location/LocationStatsGeoJson?filterQuery=${locationFilterQuery}&candidateFilterQuery=${candidateFilterQuery}&universityFilterQuery=${universityFilterQuery}&companyFilterQuery=${companyFilterQuery}&orderBy=${sortQuery}&searchQuery=${searchQuery}&pageNumber=${currentPage}&pageSize=${30}&searchBy=${currentTab}&createBy=${createMapBy}&univRankTypeId=${univRankTypeId}&compRankTypeId=${compRankTypeId}&locationType=${atTheTime}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!res.ok) {
    throw new Error("Failed to fetch GeoJSON data");
  }

  const geoJsonData = await res.json();

  return geoJsonData;
}
export async function GetSubCities(
  locationFilterQuery: string,
  candidateFilterQuery: string,
  universityFilterQuery: string,
  companyFilterQuery: string,
  sortQuery: string,
  searchQuery: string,
  token: string | undefined,
  univRankTypeId: string,
  compRankTypeId: string,
  atTheTime: number,
  locationId: string
) {
  const res = await fetch(
    `${APIURL}/Location/LocationStats/City?filterQuery=${locationFilterQuery}&candidateFilterQuery=${candidateFilterQuery}&universityFilterQuery=${universityFilterQuery}&companyFilterQuery=${companyFilterQuery}&orderBy=${sortQuery}&searchQuery=${searchQuery}&univRankTypeId=${univRankTypeId}&compRankTypeId=${compRankTypeId}&locationType=${atTheTime}&cityId=${locationId}&searchBy=${"city"}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!res.ok) {
    throw new Error("Failed to fetch sub-cities");
  }

  const subCities: LocationStats[] = await res.json();
  return subCities;
}
export async function GetStates() {
  const res = await fetch(`${APIURL}/MasterData/States`);
  const cities: State[] = await res.json();
  return cities;
}
export async function MakeSubcity(
  cityIds: string[],
  majorCityId: string,
  token: string | undefined
) {
  const res = await fetch(
    `${APIURL}/Location/makeSubCity?majorCityId=${majorCityId}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(cityIds),
    }
  );

  return;
}
export async function MakeMajorCity(
  cityIds: string[],
  token: string | undefined
) {
  const res = await fetch(`${APIURL}/Location/makeMajorCity`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(cityIds),
  });

  return;
}
export async function GetTotalCounts(
  roleIds: string[] | undefined,
  token: string | undefined
) {
  const queryParams = new URLSearchParams();

  if (roleIds) {
    roleIds.forEach((id) => queryParams.append("roleIds", id));
  }

  const res = await fetch(
    `${APIURL}/Location/TotalCounts?${queryParams.toString()}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!res.ok) {
    throw new Error(`Error fetching total counts: ${res.statusText}`);
  }

  const counts: ITotalCounts = await res.json();
  return counts;
}
export async function fetchLocationActionsLog(token: string | undefined) {
  try {
    const res = await fetch(`${APIURL}/Location/ActionLogs`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      throw new Error("Failed to fetch action logs");
    }
    const actionLogs: LocationActionsLog[] = await res.json();
    return actionLogs;
  } catch (error) {
    console.error(error);
    return [];
  }
}
export const GetCandidateApplies = async (roleId: string, email: string) => {
  const res = await fetch(`${APIURL}/Candidates/${roleId}/Applies/${email}`);
  const candidates: { name: string; email: string }[] = await res.json();
  return candidates;
};

export const updateCandidateMetrics = async (roleId: string) => {
  const url = `${APIURL}/Candidates/Metrics/${roleId}`;
  try {
    await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (err) {
    console.error("Error updating metrics:", err);
  }
};

export const getUngradedRoles = async () => {
  const res = await fetch(`${APIURL}/Candidates/ungradedroles`);
  const json: string[] = await res.json();
  return json;
};

export const getRoleWorkflows = async () => {
  const res = await fetch(`${APIURL}/RoleWorkflow`);
  const json = await res.json();
  return json;
};

export const getWorkFlowStatuses = async (roleWorkflowId: string) => {
  const res = await fetch(`${APIURL}/RoleWorkflow/${roleWorkflowId}/statuses`);
  const json = await res.json();
  return json;
};

export const postWorkflowAndStatuses = async (
  workFlowName: string,
  process: recruitmentProcess[]
) => {
  await fetch(`${APIURL}/RoleWorkflow`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      workFlowName,
      roleWorkflowStatuses: process,
    }),
  });
};

export const AssignQuestionsToTemplate = async (
  tId: string,
  questionIds: string[]
) => {
  await fetch(`${APIURL}/AssessmentQuestion/Assign/${tId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(questionIds),
  });
  return;
};

export const updateWorkflowAndStatuses = async (
  workflowId: string,
  workFlowName: string,
  process: recruitmentProcess[]
) => {
  try {
    await fetch(
      `${APIURL}/RoleWorkflow/${workflowId}?workflowName=${workFlowName}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(process),
      }
    );
  } catch (err) {
    console.error("Error updating workflow:", err);
  }
};
export const getAnsweredPercentage = async (cId: string, roleId: string) => {
  const res = await fetch(
    `${APIURL}/Candidates/${cId}/AnsweredPercentage/${roleId}`
  );
  const json: {
    answeredPercentage: number;
    startTime?: string;
    endTime?: string;
  } = await res.json();
  return json;
};

export const deleteWorkflow = async (workflowid: string) => {
  await fetch(`${APIURL}/RoleWorkflow/${workflowid}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const RemoveQuestionsFromTemplate = async (
  tId: string,
  questionIds: string[]
) => {
  await fetch(`${APIURL}/AssessmentQuestion/Templates/Remove/${tId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(questionIds),
  });
  return;
};

export const RemoveQuestionsFromAssessment = async (
  aId: string,
  questionIds: string[]
) => {
  await fetch(`${APIURL}/AssessmentQuestion/Assessments/Remove/${aId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(questionIds),
  });
  return;
};

export const getAllQuestionsFromTemplate = async (tId: string) => {
  const res = await fetch(`${APIURL}/AssessmentTemplate/${tId}/questions`);
  const json = await res.json();
  return json;
};

export const getTemplateSkillMappings = async (
  templateId: string | undefined
) => {
  const res = await fetch(
    `${APIURL}/AssessmentTemplate/SkillMappings/${templateId}`
  );
  const json = res.json();
  return json;
};

export const deleteMultipleTemplates = async (templateIds: string[]) => {
  await fetch(`${APIURL}/AssessmentTemplate/Multiple`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(templateIds),
  });
};

export const getSkillAnalytics = async (skillId: string) => {
  const res = await fetch(`${APIURL}/Skill/Stats?skillId=${skillId}`);
  const json = res.json();
  return json;
};

export const updateSkillThreshold = async (
  skillId: string,
  thresholdVal: number
) => {
  await fetch(`${APIURL}/Skill/Threshold/${skillId}/${thresholdVal}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  return;
};

export const GenerateAndRecalibrateTemplate = async (
  templateId: string,
  updatedSkillMappings: any,
  assessmentQuestions: any
) => {
  const res = await fetch(
    `${APIURL}/AssessmentTemplate/Generate/Recalibrate/${templateId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        generatedAssessmentData: assessmentQuestions,
        skillMappings: updatedSkillMappings,
      }),
    }
  );
  const json = await res.json();
  return json;
};

export const recalibrateTemplate = async (
  prevTempId: string,
  assessmentTemplate: TemplateData,
  assessmentData: Question[][] | undefined,
  skillMappings: {
    skillId: string;
    difficultyMin: number;
    difficultyMax: number;
    variationMin: number;
    variationMax: number;
    questionPercentage: number;
  }[],
  baseScore: string
) => {
  await fetch(`${APIURL}/AssessmentTemplate/Recalibrate/${prevTempId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      assessmentTemplate,
      assessmentData,
      skillMappings,
      baseScore,
    }),
  });
  return;
};

export const UpdateLog = async (templateId: string) => {
  await fetch(`${APIURL}/AssessmentTemplate/${templateId}/UpdateLog`);
  return;
};
export const postTag = async (
  tagName: string | undefined,
  rankTypeId: string,
  isCompany: boolean
) => {
  try {
    if (tagName !== undefined) {
      const response = await fetch(`${APIURL}/Tag?rankTypeId=${rankTypeId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: tagName,
          isCompany,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        return {
          error: true,
          message: errorData.message || "Failed to create tag",
        };
      }

      return {
        error: false,
      };
    }
  } catch (ex) {
    console.log(ex);
  }
  return;
};

export const getTags = async (rankTypeId: string, isCompany: boolean) => {
  let json;
  try {
    let res;
    if (isCompany) {
      res = await fetch(`${APIURL}/Tag/Company?rankTypeId=${rankTypeId}`);
    } else {
      res = await fetch(`${APIURL}/Tag/University?rankTypeId=${rankTypeId}`);
    }
    json = await res.json();
  } catch (ex) {
    console.log(ex);
  }

  return json;
};

export const deleteTag = async (tagId: any) => {
  try {
    const response = await fetch(`${APIURL}/Tag/${tagId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      return {
        error: true,
        message: errorData.message || "Failed to delete tag",
      };
    }

    return { error: false };
  } catch (ex) {
    console.log(ex);
  }
};

export const assignTagCompanies = async (tagId: any, companyId: string) => {
  try {
    const response = await fetch(`${APIURL}/Tag/Company/Assign`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tagId,
        companyId,
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      return {
        error: true,
        message: errorData.message || "Failed to assign tag",
      };
    }

    return { error: false };
  } catch (ex) {
    console.error(ex);
  }
};

export const assignTagUniversities = async (
  tagId: any,
  universityId: string
) => {
  try {
    const response = await fetch(`${APIURL}/Tag/University/Assign`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tagId,
        universityId,
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      return {
        error: true,
        message: errorData.message || "Failed to assign tag",
      };
    }

    return { error: false };
  } catch (ex) {
    console.error(ex);
  }
};

export const deallocateTag = async (
  entityId: string,
  tagId: string,
  isCompany: boolean
) => {
  try {
    if (isCompany) {
      await fetch(
        `${APIURL}/Tag/Company/Remove?companyId=${entityId}&tagId=${tagId}`
      );
    } else {
      await fetch(
        `${APIURL}/Tag/University/Remove?universityId=${entityId}&tagId=${tagId}`
      );
    }
  } catch (ex) {
    console.log(ex);
  }
};

export const editTag = async (Id: string, name: string) => {
  try {
    fetch(`${APIURL}/Tag/Edit`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Id,
        name,
      }),
    });
  } catch (ex) {}
};

export const refreshRoleCandidateCache = async (roleId: string) => {
  await fetch(`${APIURL}/Roles/${roleId}/Cache/Refresh`);
};

export const getTemplateLogs = async () => {
  const res = await fetch(`${APIURL}/AssessmentTemplate/Logs`);
  const json = res.json();
  return json;
};
export const restoreTemplate = async (id: string) => {
  await fetch(`${APIURL}/AssessmentTemplate/${id}/Restore`);
  return;
};

export const getCandidatesByCompanyUrl = async (url: string) => {
  try {
    const res = await fetch(`${APIURL}/Quarantine/GetCandidateByCompanyUrl`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(url),
    });

    if (!res.ok) {
      throw new Error(`Error: ${res.statusText}`);
    }

    const candidates: ICandidate[] = await res.json();
    return candidates;
  } catch (error) {
    console.error("Error fetching candidates by company URL:", error);
    return [];
  }
};

export const getCandidatesByUniversityUrl = async (url: string) => {
  try {
    const res = await fetch(
      `${APIURL}/Quarantine/GetCandidateByUniversityUrl`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(url),
      }
    );

    if (!res.ok) {
      throw new Error(`Error: ${res.statusText}`);
    }

    const candidates: ICandidate[] = await res.json();
    return candidates;
  } catch (error) {
    console.error("Error fetching candidates by university URL:", error);
    return [];
  }
};
export const createSlots = async (
  startTime: string,
  endTime: string,
  slotDurationMinutes: number,
  breakDurationMinutes: number | null,
  roleId: string | undefined,
  meetingLink: string,
  roleStatusId: string,
  interviewerEmail: string | null,
  token: string
) => {
  var errorText = null;
  try {
    const slotRequest = {
      startTime,
      endTime,
      slotDurationMinutes,
      breakDurationMinutes,
      roleId,
      meetingLink,
      roleStatusId,
      interviewerEmail,
    };

    const response = await fetch(`${APIURL}/Roles/createSlots`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(slotRequest),
    });

    if (!response.ok) {
      errorText = await response.text();
      throw new Error(errorText);
    }
    const data = await response.text();

    return data;
  } catch (error) {
    if (errorText != null) {
      throw new Error(errorText);
    }
    throw new Error("Something Went Wrong. Please Try Again.");
  }
};
export const GetInterviewSlots = async (
  roleId: string | null | undefined = null,
  token: string
) => {
  const url = roleId
    ? `${APIURL}/Roles/interviewSlots?roleId=${roleId}`
    : `${APIURL}/Roles/interviewSlots`;
  const res = await fetch(`${url}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const slots: InterviewSlots[] = await res.json();
  return slots;
};
export const GetInterviewSlotById = async (id: string) => {
  const url = `${APIURL}/Roles/interviewSlots/${id}`;
  const res = await fetch(url);
  const slots: InterviewSlots = await res.json();
  return slots;
};

export const DeleteInterviewSlot = async (slotId: string, token: string) => {
  try {
    const response = await fetch(`${APIURL}/Roles/deleteSlot/${slotId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.ok) {
    } else {
      console.error("Failed to delete the slot:", response.status);
    }
  } catch (error) {
    console.error("Error occurred while deleting the slot:", error);
  }
};
export const checkAvailableSlots = async (roleId: string | undefined) => {
  try {
    const response = await fetch(
      `${APIURL}/Roles/checkAvailableSlots?roleId=${roleId}`
    );
    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching available slots:", error);
    throw error;
  }
};
export const updateInterviewSlot = async (updatedSlot: {
  slotId: string;
  startTime: string;
  endTime: string;
  meetingLink: string;
  interviewerEmail: string | null;
  notes: string | null;
  grade: string | null;
}) => {
  var errorText = null;
  const filteredSlot = Object.fromEntries(
    Object.entries(updatedSlot).filter(
      ([_, value]) => value !== null && value !== ""
    )
  );
  try {
    const response = await fetch(`${APIURL}/Roles/updateInterviewSlot`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(filteredSlot),
    });
    if (!response.ok) {
      errorText = await response.text();
      throw new Error("Failed to update the slot");
    }

    return await response.text();
  } catch (error) {
    if (errorText) {
      throw new Error(errorText);
    }
    console.error("Error updating slot:", error);
    throw error;
  }
};
export const GetCandidateScore = async (id: string, token: string) => {
  const res = await fetch(`${APIURL}/Candidates/${id}/score`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const result = await res.json();
  return result;
};
export const GetCandidateInterviewNotes = async (
  candidateId: string,
  roleId: string,
  token: string
) => {
  const res = await fetch(
    `${APIURL}/Roles/interviewSlots/candidate?candidateId=${candidateId}&roleId=${roleId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!res.ok) {
    return;
  }
  const result = await res.json();
  return result;
};

export const GetCandidateInterviews = async (
  candidateId: string,
  roleId: string,
  token: string
) => {
  const res = await fetch(
    `${APIURL}/Candidates/${candidateId}/Interview/${roleId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!res.ok) {
    return;
  }
  const result = await res.json();
  return result;
};

export const CalculateAccuracy = async () => {
  await fetch(`${APIURL}/AssessmentQuestion/GenerateAccuracy`);
  return;
};
