import React, { useEffect, useState } from "react";
import {
  fetchActionsLog,
  revertConfirm,
  revertReject,
} from "../../../WebCalls";
import Loading from "../../Shared/Loading";
import {
  CalendarDaysIcon,
  ChevronRightIcon,
  MapPinIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import { CheckCircle, Recycling } from "@mui/icons-material";
import Notification from "../../Shared/Notification";
import ConfirmModal from "../../Shared/ConfirmModal";

interface ActionsProps {
  selected?: string;
  loading?: boolean;
  setLoading?: (loading: boolean) => void;
  setShowActions: (loading: boolean) => void;
}
function Actions({
  selected,
  loading,
  setLoading,
  setShowActions,
}: ActionsProps) {
  const [actions, setActions] = useState<any[]>();
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [confirmNotification, setConfirmNotification] =
    useState<boolean>(false);
  const [revertAction, setRevertAction] = useState<any>();

  const fetchActions = async () => {
    setLoading && setLoading(true);

    const data = await fetchActionsLog();
    setActions(data);

    setLoading && setLoading(false);
  };

  useEffect(() => {
    fetchActions();
  }, []);

  async function revertActionConfirmed() {
    try {
      if (revertAction && revertAction.type === "confirm") {
        const res = await revertConfirm(revertAction.id);
        if (res) {
          setConfirmNotification(true);
          setShowConfirmModal(false);
          await fetchActions();
        }
      } else if (revertAction && revertAction.type === "reject") {
        const res = await revertReject(revertAction.id);
        if (res) {
          setConfirmNotification(true);
          setShowConfirmModal(false);
          await fetchActions();
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  function handleRevertButton(action: any) {
    setShowConfirmModal(true);
    setRevertAction(action);
  }

  if (loading) {
    return <Loading />;
  }

  if (!actions || actions.length === 0) {
    return (
      <div className="bg-blue-100 rounded-lg py-8 mt-8 dark:bg-darkbglight font-bold items-center text-center dark:text-gray-400">
        <h1>Actions Log is Empty</h1>
      </div>
    );
  }

  const filteredActions =
    selected === "Companies"
      ? actions.filter((action) => action.isCompany === true)
      : actions.filter((action) => action.isCompany === false);

  return (
    <div>
      <ConfirmModal
        type="info"
        open={showConfirmModal}
        setOpen={setShowConfirmModal}
        onConfirm={revertActionConfirmed}
        title="Revert Action"
        description="Are you sure you want to Revert this action?"
      />
      <header className="bg-gray-50 py-8 dark:bg-darkbglight">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
          <div className="min-w-0 flex-1">
            <nav className="flex" aria-label="Breadcrumb">
              <ol className="flex items-center space-x-4">
                <li>
                  <div>
                    <Link
                      onClick={() => setShowActions(false)}
                      to={`/quarantine/${selected?.toLowerCase()}`}
                      className="text-sm font-medium text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                    >
                      {selected}
                    </Link>
                  </div>
                </li>
                <li>
                  <div className="flex items-center">
                    <ChevronRightIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400 dark:text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-4 text-sm font-medium text-gray-500 dark:text-gray-400">
                      Actions Log
                    </span>
                  </div>
                </li>
              </ol>
            </nav>
            <h1 className="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:tracking-tight dark:text-gray-300">
              {selected} Actions Log
            </h1>
          </div>
        </div>
      </header>
      <ul className="relative before:content-[''] before:absolute before:top-0 before:left-[7rem] before:z-[-10] before:w-[2px] before:h-full before:bg-gray-300 before:-translate-x-1/2">
        {filteredActions.map((action) => (
          <li
            className="flex flex-row items-center justify-around px-8 py-2 my-2"
            key={action.Id}
          >
            <div className="flex flex-col items-center bg-white">
              {action.type === "confirm" ? (
                <CheckCircle
                  fontSize="large"
                  className="text-entntblue text-4xl"
                />
              ) : (
                <XMarkIcon className="w-[1.9rem] bg-red-600 text-white rounded-full" />
              )}
              <div className="flex items-center text-[0.85rem] text-gray-500">
                {new Date(action.actionDate).toLocaleString("en-US", {
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                })}
              </div>
            </div>
            <div className="px-4 py-2 grid grid-cols-6 sm:flex-1 max-w-[80%] border-b border-gray-300">
              <div className="col-span-2">
                <h1
                  id="message-heading"
                  className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-300"
                >
                  {selected === "Companies"
                    ? `${action.company.name}`
                    : `${action.university.name}`}
                </h1>
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  <MapPinIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-entntblue"
                    aria-hidden="true"
                  />
                  {selected === "Companies"
                    ? `${action.company.city?.name}`
                    : `${action.university.city?.name}`}
                </div>
              </div>
              <div className="col-span-3 text-sm">
                {action.type === "confirm" ? (
                  <>
                    Confirmed Quarantine{" "}
                    {selected === "Companies" ? "Company" : "University"}{" "}
                    <span className="text-entntblue">
                      {selected === "Companies"
                        ? `${action.company?.name} (${action.company.city.name})`
                        : `${action.university?.name} (${action.university?.city.name})`}
                    </span>
                    .
                  </>
                ) : (
                  <>
                    Rejected Quarantine{" "}
                    {selected === "Companies" ? "Company" : "University"}{" "}
                    <span className="text-entntblue">
                      {selected === "Companies"
                        ? `${action.company?.name} (${action.company.city.name})`
                        : `${action.university?.name} (${action.university?.city.name})`}
                    </span>{" "}
                    {action.rejectedWithCompany == null &&
                    action.rejectedWithUniversity == null ? (
                      <span>Directly</span>
                    ) : (
                      <>
                      <span>and Replaced with{" "}</span> 
                      <span className="text-entntblue" title={selected === "Companies"
                          ? `${action.rejectedWithCompany?.city.name}`
                          : `${action.rejectedWithUniversity?.city.name}`}>
                        {selected === "Companies"
                          ? `${action.rejectedWithCompany?.name} (${action.rejectedWithCompany?.city.name})`
                          : `${action.rejectedWithUniversity?.name} (${action.rejectedWithUniversity?.city.name})`}
                      </span>
                      </>
                    )}
                    .
                  </>
                )}
                <div className="mt-2 flex items-center text-sm text-gray-500 ">
                  <CalendarDaysIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-entntblue"
                    aria-hidden="true"
                  />
                  {action.actionDate.split("T")[0]}
                </div>
              </div>
              <div>
                <div
                  onClick={() => handleRevertButton(action)}
                  className="cursor-pointer inline-flex gap-[0.5rem] text-sm justify-center items-center py-[0.4rem] px-[1rem] rounded-md text-white bg-entntblue"
                >
                  Revert
                  <Recycling fontSize="small" />
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
      <Notification
        title="Action Reverted Successfully"
        description={`${selected} Confirmed Successfully.`}
        type="success"
        show={confirmNotification}
        setShow={setConfirmNotification}
      />
    </div>
  );
}

export default Actions;
