import {
  CandidateStatus,
  QuarantineCompany,
  QuarantineUniversity,
  RoleStatus,
} from "./Models";
import { GetCandidateCv } from "./WebCalls";

export const RoleStatusType: {
  rejected: string;
  hired: string;
  applied: string;
} = {
  applied: "Applied",
  rejected: "Disqualified",
  hired: "Offered",
};
export const dateConverter = (date: string, withTime?: boolean): string => {
  const options: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
    year: "numeric",
  };
  if (withTime) {
    options.hour = "numeric";
    options.minute = "numeric";
    options.hour12 = true;
  }
  const convertedDate = new Date(date).toLocaleDateString("en-US", options);
  return convertedDate;
};

export const getAvatarName = (username: string): string => {
  const splitUsername = username.split(" ");
  return (
    splitUsername[0][0] + splitUsername[splitUsername.length - 1][0]
  ).toUpperCase();
};

export function getColorShade(rankNumber: number, university = false) {
  const maxQualityScore = 5;

  const darkOrange = { r: 255, g: 140, b: 0 };
  const lightOrange = { r: 255, g: 230, b: 200 };

  const darkBlue = { r: 0, g: 0, b: 139 };
  const lightBlue = { r: 173, g: 216, b: 230 };

  const darkYellow = { r: 204, g: 204, b: 0 };
  const lightYellow = { r: 255, g: 255, b: 224 };

  const factor = (rankNumber - 1) / maxQualityScore;

  function interpolateColor(
    darkColor: { r: number; g: number; b: number },
    lightColor: { r: number; g: number; b: number }
  ) {
    const r = Math.round(darkColor.r + factor * (lightColor.r - darkColor.r));
    const g = Math.round(darkColor.g + factor * (lightColor.g - darkColor.g));
    const b = Math.round(darkColor.b + factor * (lightColor.b - darkColor.b));
    return `rgb(${r}, ${g}, ${b})`;
  }

  if (university) {
    const postGrad = interpolateColor(darkYellow, lightYellow);
    const grad = interpolateColor(darkOrange, lightOrange);
    return { postGrad, grad };
  } else {
    return interpolateColor(darkBlue, lightBlue);
  }
}

export const getStyleForUniversity = (ranking: number, postGrad: boolean) => {
  let color;
  let background;
  const shade = getColorShade(ranking, true) as {
    postGrad: string;
    grad: string;
  };
  if (ranking === 0) {
    color = "white";
    background = "#A9A9A9";
  } else if (ranking === 1 || ranking === 2) {
    color = "white";
    background = postGrad ? shade.postGrad : shade.grad;
  } else {
    color = "#4B5563";
    background = postGrad ? shade.postGrad : shade.grad;
  }

  return { background, color };
};

export const getStyleForCompany = (ranking: number) => {
  let color;
  let background;

  if (ranking === 0) {
    color = "white";
    background = "#A9A9A9";
  } else if (ranking <=3) {
    color = "white";
    background = getColorShade(ranking, false) as string;
  } else {
    color = "#4B5563";
    background = getColorShade(ranking, false) as string;
  }

  return { background, color };
};

export function isQuarantineCompany(
  item: QuarantineUniversity | QuarantineCompany
): item is QuarantineCompany {
  return (item as QuarantineCompany).companyId !== undefined;
}

export const getNextStatus = (
  roleStatuses: RoleStatus[],
  candidateStatuses: CandidateStatus[]
) => {
  if (!roleStatuses || roleStatuses.length === 0) return;
  let hiredOrRejected = false;
  const latestStatus = roleStatuses.find(
    (s) =>
      s.id === candidateStatuses[candidateStatuses.length - 1]?.roleStatusId
  );
  let nextStatusIndex = 0;
  if (latestStatus) {
    hiredOrRejected =
      latestStatus.name === RoleStatusType.hired ||
      latestStatus.name === RoleStatusType.rejected;
    if (hiredOrRejected) return;
 
    nextStatusIndex =
      roleStatuses.findIndex((rs) => rs.order === latestStatus.order) + 1;
  }
  if (nextStatusIndex > roleStatuses.length - 1) return;
 
  const nextStatusHiredOrRejected =
    roleStatuses[nextStatusIndex].name === RoleStatusType.rejected ||
    roleStatuses[nextStatusIndex].name === RoleStatusType.hired;
 
  const containsHiredAndRejected =
    roleStatuses.some((rs) => rs.name === RoleStatusType.hired) &&
    roleStatuses.some((rs) => rs.name === RoleStatusType.rejected);
  return nextStatusHiredOrRejected ||
    (containsHiredAndRejected && nextStatusIndex < roleStatuses.length - 2)
    ? [
        roleStatuses[nextStatusIndex].name === RoleStatusType.rejected
          ? roleStatuses[nextStatusIndex + 1].id
          : roleStatuses[nextStatusIndex].id,
        roleStatuses.find((rs) => rs.name === RoleStatusType.rejected)?.id,
      ]
    : [roleStatuses[nextStatusIndex].id];
};

export function areArraysEqual<T extends Record<string, any>>(
  arr1: T[],
  arr2: T[]
): boolean {
  if (arr1.length !== arr2.length) return false;

  return arr1.every((obj1, index) => {
    const obj2 = arr2[index];

    return areObjectsEqual(obj1, obj2);
  });
}

export function areObjectsEqual<T>(obj1: T, obj2: T): boolean {
  if (
    typeof obj1 !== "object" ||
    typeof obj2 !== "object" ||
    obj1 === null ||
    obj2 === null
  ) {
    return obj1 === obj2;
  }

  if (obj1 === null || obj2 === null) return false;

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) return false;

  for (const key of keys1) {
    const val1 = (obj1 as Record<string, any>)[key];
    const val2 = (obj2 as Record<string, any>)[key];

    if (!areObjectsEqual(val1, val2)) {
      return false;
    }
  }

  return true;
}

export async function downloadCv(
  token: string,
  candidateId: string,
  candidateName: string
) {
  try {
    const cvBlob = await GetCandidateCv(candidateId, token);

    const arrayBuffer = await cvBlob.arrayBuffer();
    const byteArray = new Uint8Array(arrayBuffer);

    let extension = "";

    const pdfSignature = [0x25, 0x50, 0x44, 0x46];
    const isPdf = pdfSignature.every((byte, i) => byte === byteArray[i]);

    const docxSignature = [0x50, 0x4b];
    const isDocx = docxSignature.every((byte, i) => byte === byteArray[i]);

    if (isPdf) {
      extension = "pdf";
    } else if (isDocx) {
      extension = "docx";
    } else {
      throw new Error("Unsupported file format");
    }

    const blobUrl = window.URL.createObjectURL(cvBlob);
    const downloadLink = document.createElement("a");
    downloadLink.href = blobUrl;

    downloadLink.download = `resume_${candidateName}.${extension}`;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    window.URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error("Error handling CV:", error);
  }
}
