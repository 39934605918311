import { useState } from "react";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import {
  CalendarDaysIcon,
  EllipsisVerticalIcon,
  MapPinIcon,
  ShieldExclamationIcon,
} from "@heroicons/react/20/solid";
import { Role } from "../../Models";
import { Link, useNavigate } from "react-router-dom";
import { DeleteRole, UpdateRole } from "../../WebCalls";
import ConfirmModal from "../Shared/ConfirmModal";
import useApiToken from "../../hooks/useApiToken";
import { dateConverter } from "../../Helpers";
import { clearLocalStorageExceptDark } from "../TextEditor/textEditorConfig";
import { careerDomain } from "../../helpers/constants";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function RoleCard({ role }: { role: Role }) {
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false);
  const [jobStatusModalIsOpen, setJobStatusModalIsOpen] =
    useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(role.isActive);
  const token = useApiToken();
  const navigate = useNavigate();

  const deleteOnConfirm = async () => {
    if (!token) return;
    await DeleteRole(role.id, token);
    window.location.reload();
    setDeleteModalIsOpen(false);
  };

  const closeOnConfirm = async () => {
    if (!token) return;
    const body = [
      {
        propertyName: "IsActive",
        propertyValue: String(!isActive),
      },
    ];
    await UpdateRole(role.id, body, token);
    setIsActive(!isActive);
    setJobStatusModalIsOpen(false);
  };
  return (
    <>
      <div className="border-b border-gray-200 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8  dark:border-gray-800">
        <div
          className="sm:flex sm:items-baseline sm:justify-between hover:bg-gradient-to-r from-white via-gray-50 to-white pl-2 py-4 rounded-sm dark:from-darkbg dark:via-darkbglight dark:to-darkbg cursor-pointer "
          onClick={() => {
            navigate(`/roles/${role.id}`);
          }}
        >
          <div className=" grid grid-cols-3 sm:w-full sm:flex-1">
            <div className="col-span-2">
              <h1
                id="message-heading"
                className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-300"
              >
                <span className="flex flex-row  items-center gap-2">{role.title} {isActive && role.isEmailStopped && <ExclamationTriangleIcon color="orange" height={20} title="One or more assessment set is out of bounds. Email has been stopped! Please Recalibrate the Set" />}</span>

              </h1>
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <MapPinIcon
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-entntblue"
                  aria-hidden="true"
                />
                {role.location}
              </div>
            </div>
            <div>
              <h1
                id="message-heading"
                className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-400"
              >
                <span> Created on</span>
              </h1>
              <div className="mt-2 flex items-center text-sm text-gray-500 ">
                <CalendarDaysIcon
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-entntblue"
                  aria-hidden="true"
                />
                {dateConverter(role.date)}
              </div>
            </div>
          </div>

          <div className="mt-4 mb-0 flex items-center justify-between sm:ml-6 sm:mt-0 sm:flex-shrink-0 sm:justify-start">
            {isActive ? (
              <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                Open
              </span>
            ) : (
              <span className="inline-flex items-center rounded-full bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
                Close
              </span>
            )}

            <Menu as="div" className="relative ml-3 inline-block text-left">
              <div>
                <Menu.Button
                  className="-my-2 flex items-center rounded-full bg-transparent  text-gray-400 hover:text-gray-600 dark:hover:text-gray-200"
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                  }}
                >
                  <span className="sr-only">Open options</span>
                  <EllipsisVerticalIcon
                    className="h-5 w-5"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-darkbglight"
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                  }}
                >
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to={`/roles/${role.id}/edit`}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700 dark:text-gray-400",
                            "flex justify-between px-4 py-2 text-sm"
                          )}
                          onClick={(e) => {
                            e.stopPropagation();
                            clearLocalStorageExceptDark();
                          }}
                        >
                          <span>Edit</span>
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          type="button"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700 dark:text-gray-400",
                            "flex w-full justify-between px-4 py-2 text-sm"
                          )}
                          onClick={(e) => {
                            e.stopPropagation();
                            setJobStatusModalIsOpen(true);
                          }}
                        >
                          <span>{isActive ? "Close" : "Open"}</span>
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          type="button"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700 dark:text-gray-400",
                            "flex w-full justify-between px-4 py-2 text-sm"
                          )}
                          onClick={(e) => {
                            e.stopPropagation();
                            setDeleteModalIsOpen(true);
                          }}
                        >
                          <span>Delete</span>
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to={`/roles/${role.id}/create`}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700 dark:text-gray-400",
                            "flex w-full justify-between px-4 py-2 text-sm"
                          )}
                          onClick={(e) => {
                            e.stopPropagation();
                            clearLocalStorageExceptDark();
                          }}
                        >
                          <span>Create a copy</span>
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700 dark:text-gray-400",
                            "flex w-full justify-between px-4 py-2 text-sm"
                          )}
                          onClick={() =>
                            navigator.clipboard.writeText(
                              `${careerDomain}/${role.id}/overview`
                            )
                          }
                        >
                          <span>Copy role url</span>
                        </div>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
      <ConfirmModal
        open={deleteModalIsOpen}
        setOpen={setDeleteModalIsOpen}
        onConfirm={deleteOnConfirm}
        type="danger"
        title="Delete job"
        description=" Are you sure you want to delete this job? All of the
        data will be permanently removed. This action cannot
        be undone."
      />
      <ConfirmModal
        open={jobStatusModalIsOpen}
        setOpen={setJobStatusModalIsOpen}
        onConfirm={closeOnConfirm}
        type="info"
        title="Change Job status"
        description={`Are you sure you want make this job ${isActive ? "inactive" : "active"
          }?`}
      />
    </>
  );
}
