import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GetCandidateInterviews } from "../../WebCalls";
import useApiToken from "../../hooks/useApiToken";
import { InterviewSlots } from "../../Models";

export default function Interviews() {
  const [interviews, setInterviews] = useState<InterviewSlots[]>([]);
  const { candidateId, roleId } = useParams();
  const token = useApiToken();
  useEffect(() => {
    if (!candidateId || !token || !roleId) {
      return;
    }
    const fetchSlotDetails = async () => {
      try {
        const slot = await GetCandidateInterviews(candidateId, roleId, token);
        setInterviews(slot);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchSlotDetails();
  }, [candidateId, token]);

  return (
    <div
      className={
        "py-3 px-4 bg-white rounded-md z-[11] border border-gray-200  h-fit dark:bg-darkbglight dark:border-gray-500 mt-4"
      }
    >
      <h2
        id="timeline-title"
        className="text-lg font-medium text-gray-900 dark:text-gray-300"
      >
        Interviews
      </h2>
      {interviews &&
        interviews.map((interview) => (
          <div
            className="p-4 bg-blue-100  dark:bg-slate-900 rounded-lg mb-6 space-y-1 mt-2"
            key={interview.id}
          >
            <p className="text-gray-600 dark:text-gray-400  ">
              <strong className="text-gray-600 dark:text-gray-400">
                Start Time:
              </strong>{" "}
              {new Date(interview.startTime).toLocaleDateString("en-IN", {
                hour: "2-digit",
                minute: "2-digit",
                weekday: "short",
                day: "2-digit",
                month: "short",
                year: "numeric",
              })}
            </p>
            <p className="text-gray-600 dark:text-gray-400">
              <strong className="text-gray-600 dark:text-gray-400">
                End Time:
              </strong>{" "}
              {new Date(interview.endTime).toLocaleDateString("en-IN", {
                hour: "2-digit",
                minute: "2-digit",
                weekday: "short",
                day: "2-digit",
                month: "short",
                year: "numeric",
              })}
            </p>
            <p className="text-gray-600 dark:text-gray-400">
              <strong className="text-gray-600 dark:text-gray-400">
                Interviewer Email:
              </strong>{" "}
              {interview.interviewerEmail}
            </p>
            <p className="text-gray-600 dark:text-gray-400">
              <strong className="text-gray-600 dark:text-gray-400">
                Stage :
              </strong>{" "}
              {interview.roleStatus.name}
            </p>

            {interview.meetingLink && (
              <p className="text-gray-600 dark:text-gray-400">
                <strong className="text-gray-600 dark:text-gray-400">
                  Meeting Link:
                </strong>{" "}
                <a
                  href={interview.meetingLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 underline"
                >
                  Join Meeting
                </a>
              </p>
            )}
          </div>
        ))}
    </div>
  );
}
