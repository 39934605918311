import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Loading from '../../Shared/Loading';
import Notification from '../../Shared/Notification';
import { getSkillAnalytics, updateSkillThreshold } from '../../../WebCalls';
import CircularProgress from '@mui/joy/CircularProgress';

interface SkillAnalytics {
    skillId: string,
    skillName: string,
    skillThreshold: number,
    totalAnsweredCorrectly: number,
    totalAnsweredIncorrectly: number,
    totalUnanswered: number,
    successPercentage: number,
    overallSuccessPercentage: number,
    averageAccuracy: number,
    questionCount: number
}

export default function SkillAnalyticsModal({ size, isOpen, onCancel, isModalConfirm, selectedSkillId }: { onCancel: Function, isOpen: boolean, isModalConfirm: Function, size: string, selectedSkillId: string | undefined }) {

    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen])

    const [loading, setLoading] = useState<boolean>(true);

    const cancelButtonRef = useRef(null);
    const [successNotification, setSuccessNotification] = useState(false);
    const [dangerNotification, setDangerNotification] = useState(false);
    const [notifcationMessage, setNotificationMessage] = useState('');
    const [SkillAnalyticsData, setSkillAnalyticsData] = useState<SkillAnalytics>()
    const [skillThreshold, setSkillThreshold] = useState<number | undefined>(SkillAnalyticsData?.skillThreshold);


    useEffect(() => {
        fetchSkillData();
    }, [selectedSkillId])


    const fetchSkillData = async () => {
        if (selectedSkillId) {
            await getSkillAnalytics(selectedSkillId).then((res: SkillAnalytics) => {
                setSkillAnalyticsData(res);
                setLoading(false);
                setSkillThreshold(res.skillThreshold);
            })
        }
    }

    const handleThresholdUpdate = () => {
        if (skillThreshold && selectedSkillId) {
            updateSkillThreshold(selectedSkillId, skillThreshold).then(() => {
                fetchSkillData();
            })
        }
    }
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10 dark:bg-entntdarkblue"
                initialFocus={cancelButtonRef}
                onClose={() => {
                    onCancel();
                    setOpen(false);
                }}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-400 bg-opacity-50 dark:bg-opacity-20 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full h-fit flex flex-col dark:bg-darkbg sm:max-w-6xl">
                                <div className='bg-gray-200 h-12 p-4 flex items-center dark:bg-darkbglight'>
                                    <h2 className='font-bold text-gray-600 text-sm text-left dark:text-gray-300'>
                                        Skill Analytics
                                    </h2>
                                </div>

                                <div className='p-4 flex flex-col gap-4'>
                                    <div className='flex flex-row items-center gap-5'>
                                        <div className='text-[40px] dark:text-white'>{SkillAnalyticsData?.skillName}</div>
                                        <div className='text-[16px] dark:text-white underline underline-offset-1'>{SkillAnalyticsData?.questionCount} Questions</div>
                                    </div>
                                    <div className='flex flex-row justify-evenly mt-10'>
                                        <div className='flex flex-col items-center'>
                                            <CircularProgress size="lg" determinate value={100}
                                                color='success'
                                            >
                                                <span className='dark:text-white'>

                                                    {SkillAnalyticsData?.totalAnsweredCorrectly}
                                                </span>
                                            </CircularProgress>
                                            <span className='text-xs mt-2 dark:text-white'>Answered Correctly</span>
                                        </div>
                                        <div className='flex flex-col items-center '>
                                            <CircularProgress size="lg" determinate value={100}
                                                color='danger'
                                            >
                                                <span className='dark:text-white'>
                                                    {SkillAnalyticsData?.totalAnsweredIncorrectly}
                                                </span>
                                            </CircularProgress>
                                            <span className='text-xs mt-2 dark:text-white'>Answered Incorrectly</span>
                                        </div>
                                        <div className='flex flex-col items-center'>
                                            <CircularProgress size="lg" determinate value={100}
                                                color='warning'
                                            >
                                                <span className='dark:text-white'>
                                                    {SkillAnalyticsData?.totalUnanswered}
                                                </span>
                                            </CircularProgress>
                                            <span className='text-xs mt-2 dark:text-white'>Unanswered</span>
                                        </div>
                                        <div className='flex flex-col items-center'>
                                            <CircularProgress size="lg" determinate
                                                value={parseFloat(SkillAnalyticsData?.overallSuccessPercentage.toFixed(2) || "0")}
                                                color='success'
                                            >
                                                <span className='dark:text-white'>
                                                    {SkillAnalyticsData?.overallSuccessPercentage}
                                                </span>
                                            </CircularProgress>
                                            <span className='text-xs mt-2 dark:text-white'>Success Percentage </span>
                                        </div>
                                        <div className='flex flex-col items-center'>
                                            <CircularProgress size="lg" determinate value={SkillAnalyticsData?.averageAccuracy}
                                                color={`${SkillAnalyticsData?.averageAccuracy && SkillAnalyticsData?.averageAccuracy <= 50 ? "success" : SkillAnalyticsData?.averageAccuracy && SkillAnalyticsData?.averageAccuracy <= 80 ? "warning" : "danger"}`}
                                            >
                                                <span className='dark:text-white'>
                                                    {SkillAnalyticsData?.averageAccuracy}
                                                </span>
                                            </CircularProgress>
                                            <span className='text-xs mt-2 dark:text-white'>Average Difficulty </span>
                                        </div>
                                    </div>
                                    <div className='flex flex-row mt-5 gap-3 dark:text-white items-center '>
                                        <label htmlFor="" className='text-xs'>Skill Threshold Value:</label>
                                        <input type="number" name="threshold" id="" className="block h-8  border border-gray-300 rounded-md bg-transparent py-1.5 pl-3 text-gray-900 placeholder:text-gray-400 placeholder:text-xs  focus:ring-0 sm:text-sm sm:leading-6 dark:text-gray-300 dark:placeholder:text-gray-500 dark:border-gray-600 " placeholder="e.g 50" value={skillThreshold}
                                            onChange={(e) => {
                                                const value = parseFloat(e.target.value);
                                                setSkillThreshold(isNaN(value) ? undefined : value);
                                            }} />

                                        <button
                                            className={`mt-3 inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold  shadow-sm ring-1 ring-inset  sm:mt-0 sm:w-auto h-9 ${skillThreshold === undefined || (skillThreshold === SkillAnalyticsData?.skillThreshold) ? "bg-gray-600 text-black" : "bg-indigo-600 text-white hover:bg-entntorange"} `}
                                            disabled={skillThreshold === SkillAnalyticsData?.skillThreshold}
                                            onClick={() => {
                                                // isModalConfirm();
                                                handleThresholdUpdate();
                                                // setOpen(false);
                                            }}>
                                            Update
                                        </button>
                                    </div>
                                </div>
                                <div className='flex flex-row items-center justify-between p-2'>
                                    <button className='mt-3 inline-flex w-full justify-center rounded-md bg-gray-100 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto h-9'
                                        onClick={() => {
                                            onCancel();
                                            setOpen(false);
                                        }}>
                                        Close
                                    </button>
                                    {/* <button
                                        className='mt-3 inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold  shadow-sm ring-1 ring-inset  sm:mt-0 sm:w-auto h-9 bg-indigo-600 text-white hover:bg-entntorange'
                                        onClick={() => {
                                            isModalConfirm(); // Confirm the action when clicked
                                            setOpen(false);   // Close modal after confirmation
                                        }}>
                                        Confirm
                                    </button> */}
                                </div>

                                <Notification
                                    show={successNotification}
                                    setShow={setSuccessNotification}
                                    title="Success"
                                    description={notifcationMessage}
                                    type="success"
                                />
                                <Notification
                                    show={dangerNotification}
                                    setShow={setDangerNotification}
                                    title="Error"
                                    description={notifcationMessage}
                                    type="error"
                                />
                                {loading && <Loading />}
                            </Dialog.Panel>


                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root >
    );
}