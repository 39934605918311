import { useEffect, useRef, useState } from "react";
import { Candidate, RoleStatus } from "../../../Models";
import { checkAvailableSlots } from "../../../WebCalls";
import { CandidateAction } from "./RoleCandidates";
import { ArrowLongRightIcon } from "@heroicons/react/20/solid";
import { useParams } from "react-router-dom";
type Props = {
  candidatesWithAction: CandidateAction[];
  handleCandidateWithAction: (candidate: Candidate[]) => void;
  roleStatuses: RoleStatus[];
  setSelectedCandidate: React.Dispatch<React.SetStateAction<CandidateAction[]>>;
  bulkActionIsDisqualify: boolean;
  handleBulkConfirmButtonDisabled: (query: boolean) => void;
};
export type AvailableSlot = {
  roleStatusId: string;
  roleStatusName: string;
  adjustedCount: number;
  selectedCount: number | null;
};
export default function BulkActionModalDescription({
  candidatesWithAction,
  handleCandidateWithAction,
  roleStatuses,
  bulkActionIsDisqualify,
  handleBulkConfirmButtonDisabled,
}: Props) {
  const actionIndex = bulkActionIsDisqualify ? 1 : 0;
  const [availableSlots, setAvailableSlots] = useState<AvailableSlot[]>([]);
  const isInitialLoad = useRef(true);
  const { roleId } = useParams();

  useEffect(() => {
    const fetchAvailableSlots = async () => {
      try {
        const slots = await checkAvailableSlots(roleId); 
        setAvailableSlots(slots);
      } catch (error) {
        console.error("Error fetching available slots");
        handleBulkConfirmButtonDisabled(true);
      }
    };

    fetchAvailableSlots();
  }, []);
  useEffect(() => {
    if (availableSlots.length > 0) {
      if (isInitialLoad.current) {
        const updatedCandidates = candidatesWithAction.map((sc) => {
          const statusName = getStatusName(sc);
          const actionIndex = bulkActionIsDisqualify ? 1 : 0;
          if (
            statusName?.toLowerCase().includes("interview") &&
            availableSlots.some(
              (slot) =>
                slot.roleStatusId === sc.actions?.[actionIndex]?.roleStatusId
            )
            && sc.actions![actionIndex].email ===true
          ) {
            sc.actions![actionIndex].slots = true; 
          }
          return sc;
        });

        handleCandidateWithAction(updatedCandidates);
        updateSelectedSlotsCount(availableSlots, updatedCandidates);
        isInitialLoad.current = false;
      }
    } else {
      handleBulkConfirmButtonDisabled(false);
    }
  }, [availableSlots, candidatesWithAction, ]);
  const updateSelectedSlotsCount = (
    slots: AvailableSlot[],
    updatedCandidates: CandidateAction[] = candidatesWithAction
  ) => {
    const updatedSlots = slots.map((slot) => {
      const count = updatedCandidates.filter((candidate) => {
        const nextStatus = candidate.actions?.[bulkActionIsDisqualify ? 1 : 0];
        return (
          nextStatus &&
          nextStatus.slots &&
          nextStatus.roleStatusId === slot.roleStatusId
        );
      }).length;

      if (
        slot.roleStatusName.toLowerCase().includes("interview") &&
        count > slot.adjustedCount
      ) {
        
        handleBulkConfirmButtonDisabled(true);
      } else {
       
        handleBulkConfirmButtonDisabled(false);
      }

      return {
        ...slot,
        selectedCount: count,
      };
    });

    setAvailableSlots(updatedSlots);
  };

  const handleToggleEmail = (id: string) => {
    const updatedCandidates = candidatesWithAction.map((sc) => {
      if (id === sc.id && sc.actions) {
        sc.actions[actionIndex] = {
          ...sc.actions[actionIndex],
          email: !sc.actions[actionIndex].email,
          slots: !sc.actions[actionIndex].email === false ? false: sc.actions[actionIndex].slots,
        };

      }
      return sc;
    });
    updateSelectedSlotsCount(availableSlots,updatedCandidates);
    handleCandidateWithAction(updatedCandidates);
  };
  const handleToggleSlots = (id: string) => {
    const updatedCandidates = candidatesWithAction.map((sc) => {
      if (id === sc.id && sc.actions) {
        sc.actions[actionIndex] = {
          ...sc.actions[actionIndex],
          slots:sc.actions[actionIndex].email === true?  !sc.actions[actionIndex].slots:false,
        };
      }
      return sc;
    });
    handleCandidateWithAction(updatedCandidates);
    updateSelectedSlotsCount(availableSlots, updatedCandidates);
  };


  const getStatusName = (candidate: CandidateAction) => {
    if (!candidate.actions) return;
    const action = candidate.actions[actionIndex];
    const roleStatus = roleStatuses.find((rs) => rs.id === action.roleStatusId);
    return roleStatus ? roleStatus.name : "";
  };

  return (
    <>
      <div className="mt-2">
        <h3 className="font-semibold text-base">Available Slots</h3>
        {availableSlots.length > 0 ? (
          <ul>
            {availableSlots.map((slot) => (
              <li key={slot.roleStatusId} className="text-sm">
                {slot.roleStatusName}: {slot.adjustedCount} Available |
                Selected:{" "}
                <span
                  className={
                    (slot.selectedCount ? slot.selectedCount : 0) >
                    slot.adjustedCount
                      ? "text-red-500"
                      : ""
                  }
                >
                  {slot.selectedCount}
                </span>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-sm text-gray-500">No available slots found.</p>
        )}
      </div>
      <div className="flex justify-end items-center space-x-4 text-gray-700 pr-3 py-[1px] mb-1">
        {/* <span></span> */}
        <span className="font-medium text-sm ">Slots</span>
        <span className="font-medium text-sm">Email</span>
      </div>
      <ul className="h-fit max-h-[20rem] min-w-80 overflow-scroll thin-scroll py-1">
        {candidatesWithAction.map((sc) => (
          <li
            key={sc.id}
            className="flex justify-between items-center space-x-4 text-gray-700 pr-3 py-[1px] "
          >
            {sc.actions && (
              <>
                <span className="flex items-end text-sm">
                  {`${sc.firstName} ${sc.lastName}`}
                  <ArrowLongRightIcon className="h-5 px-2" />
                  {getStatusName(sc)}
                </span>
                <div className="flex items-end">
                  <input
                    type="checkbox"
                    className="h-4 w-4 mr-10 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    checked={sc.actions[actionIndex].slots }
                    onChange={() => {
                      handleToggleSlots(sc.id);
                    }}
                    disabled={availableSlots.length > 0 ? false : true}
                  />
                  <input
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    checked={sc.actions[actionIndex].email}
                    onChange={() => {
                      handleToggleEmail(sc.id);
                    }}
                  />
                </div>
              </>
            )}
          </li>
        ))}
      </ul>
    </>
  );
}
