import { ChevronRightIcon } from '@heroicons/react/20/solid'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { AssessmentTemplate } from '../../Models';
import { fetchAllSkills } from '../../WebCalls';
import { InputField } from '../Shared/InputFields';

function AddEditAssessmentTemplates() {

    const { id } = useParams();

    const [templateData, setTemplateData] = useState<AssessmentTemplate>({
        id: "",
        name: "",
        description: "",
        time: "",
        isDeleted: false,
        numOfQuestions: 0,
    })
    const [name, setName] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [time, setTime] = useState<string>('')

    const [skills, setSkills] = useState([]);

    useEffect(() => {
        getAllSkils();

    }, [])

    const getAllSkils = async () => {
        await fetchAllSkills().then((res) => setSkills(res));

    }
    const navigate = useNavigate();

    const formFields = [
        {
            id: 1,
            label: "Name",
            name: "name",
            inputId: "name",
            placeholder: "Software Engineer Test 1",
            autoComplete: "name",
            value: name,
            setValue: setName,
        },
        {
            id: 2,
            label: "Description",
            name: "description",
            inputId: "description",
            placeholder: "Description",
            autoComplete: "description",
            value: description,
            setValue: setDescription,
        },
        {
            id: 3,
            label: "Time",
            name: "Time",
            inputId: "Time",
            placeholder: "e.g. 50 ",
            autoComplete: "Time",
            value: time,
            setValue: setTime,
        },
    ];
    return (
        <div>
            <div>
                <header className="bg-gray-50 py-8 dark:bg-darkbglight">
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
                        <div className="min-w-0 flex-1">
                            <nav className="flex" aria-label="Breadcrumb">
                                <ol className="flex items-center space-x-4">
                                    <li>

                                        <div>
                                            <Link
                                                to="/templates"
                                                className="text-sm font-medium text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                                            >
                                                Templates
                                            </Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="flex items-center">
                                            <ChevronRightIcon
                                                className="h-5 w-5 flex-shrink-0 text-gray-400 dark:text-gray-400"
                                                aria-hidden="true"
                                            />
                                            {
                                                id ? <span className="ml-4 text-sm font-medium text-gray-500 dark:text-gray-400">
                                                    Edit
                                                </span>
                                                    : <span className="ml-4 text-sm font-medium text-gray-500 dark:text-gray-400">
                                                        Create
                                                    </span>
                                            }
                                        </div>
                                    </li>
                                </ol>
                            </nav>
                            {
                                id ? <>
                                    <div className='flex flex-row justify-between align-center text-center'>
                                        <div className=''>
                                            <h1 className='mt-2 text-2xl font-bold leading-7 text-gray-900 sm:text-4xl sm:tracking-tight  text-left dark:text-gray-300'>{templateData?.name}</h1>
                                            <h4 className='mt-2 text-xs font-bold leading-7 text-gray-900 sm:text-sm sm:tracking-tight dark:text-gray-300 text-left'>Num of Questions: {templateData?.time}</h4>
                                        </div>
                                        <div className='flex flex-col align-center justify-center'>
                                            <button className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-xs sm:text-base md:text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800' onClick={() => navigate(`/assessment/questions/${id}`)}>
                                                Assessment Questions
                                            </button>

                                        </div>
                                    </div>
                                </> : <h1 className="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:text-4xl sm:tracking-tight  text-left dark:text-gray-300">
                                    Create Assessment Template
                                </h1>
                            }
                        </div>
                    </div>
                </header>
            </div>
            <form action="">
                <div className="space-y-12 sm:space-y-16 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
                    <div>
                        <div className="mt-4 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0 dark:border-gray-800  dark:sm:divide-gray-800">
                            {formFields.map((field) => (
                                <InputField
                                    key={field.id}
                                    label={field.label}
                                    name={field.name}
                                    inputId={field.inputId}
                                    placeholder={field.placeholder}
                                    autoComplete={field.autoComplete}
                                    value={field.value}
                                    setValue={field.setValue}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default AddEditAssessmentTemplates
