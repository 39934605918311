import React, { useCallback, useEffect, useState } from 'react';
import { deleteAssessment,getAssessmentsByFilters } from '../../WebCalls';
import {  AssessmentQuestionnaire, Skill } from '../../Models';
import Loading from '../Shared/Loading';
import ConfirmModal from '../Shared/ConfirmModal';
import Notification from '../Shared/Notification';
import NoData from '../Shared/NoData';
import AssessmentLeftSideBar from './Sidebars/AssessmentLeftSidebar';
import { debounce } from '../../helpers/constants';
import AssessmentRow from './AssessmentRow';
import AssessmentRightSideBar from './Sidebars/AssessmentRightSideBar';
import PaginationComponent from '../Shared/PaginationComponent';
export type CurrentFilters = {
    selectedFilters: Record<string, string[]>;
    currentAccuracy: { min: number; max: number };
    searchQueryString: string;
};

function Assessments() {
    const [assessments, setAssessments] = useState<AssessmentQuestionnaire[]>([]);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [selectedAssessment, setSelectedAssessment] = useState<string | undefined>()
    const [loading, setLoading] = useState<boolean>(true)
    const [assessmentDeleteNotification, setAssessmentDeleteNotification] =
        useState(false);
    const [isLeftSidebarOpen, setIsLeftSidebarOpen] = useState(true);
    const [selectedAssessments, setSelectedAssessments] = useState<string[]>([]);
    const [selectAll, setSelectAll] = useState(false);
    const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(false);
    const [selectedQuestions, setSelectedQuestions] = useState<string[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const itemsPerPage = 15;
    const [totalCount, setTotalCount] = useState<number>();
    const [currentFilters, setCurrentFilters] = useState<CurrentFilters>({
        selectedFilters: {
            Question_Groups: ["all"],
            Skills: ["all"],
            Assessments: ["all"],
            Type: ["all"],
        },
        currentAccuracy: { min: 0, max: 100 },
        searchQueryString: '',
    });;

    const handleSelectAll = () => {
        setSelectAll(!selectAll);
    };
   
    const confirmDelete = () => {
        setIsDeleteModalOpen(false)
        try {
            deleteAssessment(selectedAssessment);
            setSelectedAssessment('');
            setAssessmentDeleteNotification(true);
            setTimeout(() => {
                window.location.reload();
            }, 2000)

        } catch (error) {
            console.log(error);
        }


    };
   
    const getQuestionFilters = async (selectedFilters: Record<string, string[]>, currentAccuracy: { min: number, max: number }, searchQueryString: string) => {
        setLoading(true);
        await debouncedGetQuestionFilters(selectedFilters, currentAccuracy, searchQueryString, currentPage);
        setCurrentFilters({
            selectedFilters,
            currentAccuracy,
            searchQueryString,
        })

    }

    const debouncedGetQuestionFilters = useCallback(
        debounce(
            async (
                selectedFilters: Record<string, string[]>,
                currentDifficulty: { min: number; max: number },
                searchQueryString: string,
            ) => {
                const skills = selectedFilters?.Skills.filter((q) => q !== "all")
                const templates = selectedFilters?.Templates.filter((q) => q !== "all")
                await getAssessmentsByFilters(currentDifficulty, templates, skills, currentPage, searchQueryString).then((res) => {
                    if (res.totalCount > 0 && res?.questions.length === 0) {
                        const totalPages = Math.ceil(res.totalCount / itemsPerPage);
                        if (currentPage > totalPages) {
                            setCurrentPage(totalPages);
                        }
                    }
                    else {
                        setAssessments(res?.questions)
                    }
                    setTotalCount(res.totalCount)
                    setLoading(false);
                })
            },
            500
        ),
        [currentPage]
    );


    const handleAssessmentSearch = debounce(async (e: any) => {
        const val = e.target?.value.trim() ?? '';

    }, 500);
    const handleToggleLeftSidebar = (isOpen: any) => {
        setIsLeftSidebarOpen(isOpen);
    };

    const handleSelect = (questionId: string) => {
        if (selectedAssessments.includes(questionId)) {
            setSelectedAssessments(selectedAssessments.filter((id) => id !== questionId));
        } else {
            setSelectedAssessments([...selectedAssessments, questionId]);
        }
    };
    const getSelectedEditAssessment = (questionId: string) => {

    }

    const handleToggleRightSidebar = (isOpen: any) => {
        setIsRightSidebarOpen(isOpen);
    };

    useEffect(() => {
        if (selectAll) {
            setSelectedAssessments(assessments.map((q) => (q.id)));
        } else {
            setSelectedAssessments([]);
        }
    }, [selectAll, assessments]);

    const getSelectedQuestions = (questions: string[]) => {
        setSelectedQuestions(questions);
    }


    const [expandedAssessmentId, setExpandedAssessmentId] = useState<string | null>(null);

    const handleExpand = (assessmentId: string | null) => {
        setExpandedAssessmentId(prevId => prevId === assessmentId ? null : assessmentId);
    };

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };
    return (
        <div className=' bg-white dark:bg-darkbg dark:text-gray-400'>
            <div className={`transition-all duration-200 ${isLeftSidebarOpen ? "pl-[19rem]" : "pl-0"} ${isRightSidebarOpen ? "pr-[19rem]" : "pl-0"}`}>

                <div className={`${!isLeftSidebarOpen
                    ? " mx-8"
                    : "relative sm:transform "
                    } transition-all duration-200 
ease-out`}>
                    {(assessments.length > 0) ? <div>
                        {(!loading && assessments && assessments?.length > 0) ?
                            <div>
                                <div className="relative overflow-x-hidden overflow-y-auto shadow-md sm:rounded-lg">
                                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                        <thead className="bg-white dark:bg-darkbglight z-20  before:absolute before:content-[''] before:w-full before:h-full before:-z-10 ">
                                            <tr className=''>
                                                <th scope="col" className="px-4 pt-2">
                                                    <input
                                                        type="checkbox"
                                                        checked={selectAll}
                                                        onChange={handleSelectAll}
                                                        className="form-checkbox  left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                    />
                                                </th>
                                                <th scope="col" className=" py-3 w-[35%]">
                                                    Title
                                                </th>
                                                <th scope="col" className=" py-3 w-[10%] hidden sm:table-cell">
                                                    <div className="flex items-center justify-center">
                                                        Time
                                                    </div>
                                                </th>
                                                <th scope="col" className=" py-3 w-[10%] hidden sm:table-cell">
                                                    <div className="flex items-center justify-center">
                                                        No. of Ques
                                                    </div>
                                                </th>
                                                <th scope="col" className=" py-3 w-[40%] hidden sm:table-cell">
                                                    <div className="flex items-center justify-center">
                                                        Skills
                                                    </div>
                                                </th>
                                                <th scope="col" className="px-6 py-3 w-[5%]">
                                                    {/* Empty for the modal (Ellipsis icon) */}
                                                </th>
                                                {/* {selectedAssessments.length > 0 && (
                                                    <div className="absolute top-0 flex h-10 items-center space-x-3 sm:left-12 z-30 bg-white dark:bg-darkbglight">
                                                        <button
                                                            type="button"
                                                            className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-red-500 disabled:cursor-not-allowed disabled:text-gray-300 disabled:hover:bg-white hover:text-white hover:border-red-500"
                                                            onClick={() => {
                                                                handlAssessmentsToDelete();
                                                            }}
                                                        >
                                                            Delete Assessments
                                                        </button>
                                                    </div>)} */}

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {assessments.map((q) => (
                                                <AssessmentRow
                                                    key={q.id}
                                                    assessment={q}
                                                    selected={selectedAssessments.includes((q.id))}
                                                    handleSelect={() => handleSelect((q.id))}
                                                    getSelectedEditAssessment={getSelectedEditAssessment}
                                                    getSelectedQuestions={getSelectedQuestions}
                                                    expanded={expandedAssessmentId === q.id}
                                                    onExpand={() => handleExpand(q.id)}
                                                    
                                                />
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                            </div >
                            : !loading && assessments?.length === 0 ? <div className='' >
                                <NoData title='No Questions Found' description="There are currently no available Questions. Click 'Add Question' to start adding Questions for the Assessment." />
                            </div>
                                :
                                <>
                                    <Loading />
                                </>
                        }
                    </div> : !loading && assessments.length === 0 ? <div className='' >
                        <NoData title='No Assessment Found' description="There are currently no available Assessments. Click 'Add Assessment' to start adding Assessments for the role." />
                    </div>
                        :
                        <>
                            <Loading />
                        </>
                    }
                    <ConfirmModal
                        open={isDeleteModalOpen}
                        setOpen={setIsDeleteModalOpen}
                        onConfirm={confirmDelete}
                        type="danger"
                        title="Delete Assessment"
                        description=" Are you sure you want to delete this Assessment? All of the
                        data will be permanently removed. This action cannot
                        be undone."
                    />

                    <Notification
                        show={assessmentDeleteNotification}
                        setShow={setAssessmentDeleteNotification}
                        title="Deleted"
                        description="Assessment Deleted Successfully"
                        type="success"
                    />

                </div>
                <AssessmentLeftSideBar onToggle={handleToggleLeftSidebar} handleAssessmentSearch={handleAssessmentSearch} getQuestionFilters={getQuestionFilters} currentPage={currentPage}/>
                <AssessmentRightSideBar onToggleRightSidebar={handleToggleRightSidebar} selectedQuestions={selectedQuestions} selectedAssessments={selectedAssessments} assessments={assessments} expandedAssessmentId={expandedAssessmentId} getQuestionFilters={getQuestionFilters}/>
              
            </div>
            {(totalCount !== undefined && totalCount >= 15 && assessments && assessments.length > 0) && (
                    <div className={` ${isLeftSidebarOpen ? "pl-[19rem]" : "pl-0"} ${isRightSidebarOpen ? "pr-[19rem]" : "pl-0"}`}>
                        <PaginationComponent
                            currentPage={currentPage}
                            totalPages={Math.ceil((totalCount || 0) / 15)}
                            handlePageChange={handlePageChange}
                        />
                        
                    </div>
                )}
        </div>
    );
}

export default Assessments;
