import DropdownQuestionGroup from '../Shared/Dropdowns/DropdownQuestionGroup'
import { useCallback, useEffect, useState } from 'react';
import { fetchAllSkills, fetchQuestionGroups, getQuestionById, getQuestionSkill, postQuestion, postQuestionGroup, postQuestionSkill, postSkill, putAndPostAssessmentOptions, putAssessmentQuestion } from '../../WebCalls';
import { Option, Question, QuestionGroup, Skill } from '../../Models';
import DropdownQuestionType from '../Shared/Dropdowns/DropDownQuestionType';
import CheckboxInput from '../CheckboxInput';
import { ChevronRightIcon, PlusCircleIcon } from '@heroicons/react/20/solid';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { formats, toolbar } from '../../helpers/constants';
import Notification from '../Shared/Notification';
import Skilldropdown from './Dropdowns/SkillDropdown';

export default function AddEditQuestion() {
    const [selectedQType, setSelectedQType] = useState<Question>({
        id: '1',
        title: 'SINGLE'
    });
    const [selectedQGroup, setSelectedQGroup] = useState<QuestionGroup>()
    const [options, setOptions] = useState<Option[] | undefined>()
    const [text, setText] = useState('');
    const [time, setTime] = useState('');
    const [tags, setTags] = useState('');
    const [skills, setSkills] = useState<Skill[]>([]);
    const [isDialogOpen, setIsDialogOpen] = useState({ skill: false, group: false });
    const [newGroupName, setNewGroupName] = useState<string>('');
    const [newSkillName, setNewSkillName] = useState<Skill>();
    const [successNotification, setSuccessNotification] = useState(false);
    const [dangerNotification, setDangerNotification] = useState(false);
    const [notifcationMessage, setNotificationMessage] = useState('');
    const [selectedSkills, setSelectedSkills] = useState<Skill[]>([]);
    const [questionGroups, setQuestionGroups] = useState<QuestionGroup[]>([]);
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            const getDetails = async () => {
                await getQuestionById(id).then((res) => {
                    setText(res.text);
                    setTags(res.tags);
                    setTime(res.time);
                    const qType: string = res.type.toUpperCase()
                    const type: Question = {
                        id: (Math.random()).toString(),
                        title: qType
                    };
                    setSelectedQType(type);
                    setOptions(res.answers)
                    const group: QuestionGroup = res.questionGroup;
                    setSelectedQGroup(group)
                })
                await getQuestionSkill(id).then((res) => {
                    setSelectedSkills(res);
                });
            }
            getDetails();
        }
    }, [id]);

    const getAllQuestionGroups = useCallback(() => {
        fetchQuestionGroups().then((res) => {
            setQuestionGroups(res);
        });
    }, [])
    useEffect(() => {
        getAllQuestionGroups();
    }, [getAllQuestionGroups]);
    const getAllSkills = () => {
        fetchAllSkills().then((res) => setSkills(res));
    }
    useEffect(() => {
        getAllSkills();
    }, [])

    const setSelectedQuestionGroup = (selected: QuestionGroup | undefined) => {
        setSelectedQGroup(selected)
    }

    const setSelectedQuestionType = (selected: Question) => {
        setSelectedQType(selected);

    }

    const getOptionsfromChild = (options: Option[]) => {
        setOptions(options);
    }

    const handleQuestionSubmit = async (e: any) => {
        e.preventDefault();
        const selectedIsCorrect = options?.filter((o) => { return o.isCorrect === true })
        const optionText = options?.filter((o) => { return o.text.trim() === '' });
        if (optionText && optionText?.length > 0) {
            setNotificationMessage('Option field cannot be empty');
            setDangerNotification(true);
        }
        else if (selectedQType.title === 'MULTIPLE' && selectedIsCorrect && selectedIsCorrect.length <= 1) {
            setNotificationMessage('More than options need to be selected when question type is multiple');
            setDangerNotification(true);
        }
        else if (selectedQType.title === 'SINGLE' && selectedIsCorrect && selectedIsCorrect.length < 1) {
            setNotificationMessage('Atleast one option need to be selected when question type is single');
            setDangerNotification(true);
        }
        else {
            if (selectedQGroup?.id && (options || selectedQType.title === 'TEXT' || selectedQType.title === 'VIDEO') && text && time && tags) {
                const question = await postQuestion(text, time, tags, selectedQGroup?.id, selectedQType.title, options as Option[] | undefined);
                if (selectedSkills.length > 0) {
                    postQuestionSkill(question.id, selectedSkills);
                }
                setNotificationMessage('Question Addedd Successfully');
                setSuccessNotification(true);
                setTimeout(() => {
                    setNotificationMessage('');
                    navigate('/questions');
                }, 2000)
            }
        }

    }


    const handleQuestionUpdate = (e: any): void => {
        e.preventDefault();
        const selectedType = selectedQType.title;
        const selectedIsCorrect = options?.filter((o) => { return o.isCorrect === true })
        const optionText = options?.filter((o) => { return o.text.trim() === '' });
        if (optionText && optionText?.length > 0) {
            setNotificationMessage('Option field cannot be empty');
            setDangerNotification(true);
        }
        else if (selectedQType.title === 'MULTIPLE' && selectedIsCorrect && selectedIsCorrect.length <= 1) {
            setNotificationMessage('More than options need to be selected when question type is multiple');
            setDangerNotification(true);
        }
        else if (selectedQType.title === 'SINGLE' && selectedIsCorrect && selectedIsCorrect.length < 1) {
            setNotificationMessage('Atleast one option need to be selected when question type is single');
            setDangerNotification(true);
        }
        else {
            if (selectedQGroup?.id && options && text && time && tags) {
                putAssessmentQuestion(id, text, time, selectedType, tags, selectedQGroup);
                putAndPostAssessmentOptions(id, options);
                postQuestionSkill(id, selectedSkills);
                setNotificationMessage('Question Updated Successfully');
                setSuccessNotification(true);
                setTimeout(() => {
                    setNotificationMessage('');
                    navigate('/questions');
                }, 2000)
            }
        }
    }


    const openDialog = (type: string) => {
        setIsDialogOpen((prev) => ({
            ...prev,
            [type]: type === 'group' ? !isDialogOpen.group : !isDialogOpen.skill
        }))

    };

    const closeDialog = () => {
        setIsDialogOpen({ skill: false, group: false });
    };

    const handleCreate = async (e: any) => {
        e.preventDefault();
        try {
            await postQuestionGroup(newGroupName).then(() => {
                setNewGroupName('');
                closeDialog();
                setNotificationMessage('Question Group Added');
                setSuccessNotification(true);
                setTimeout(() => {
                    setNotificationMessage('');
                    getAllQuestionGroups();
                }, 2000)

            })
        } catch (error) {
            setNotificationMessage('Unable to Add Question Group');
            setDangerNotification(true);
            setTimeout(() => {
                setNotificationMessage('');
                getAllQuestionGroups();
            }, 2000)
        }
    };

    const clearFields = () => {
        setText('');
        setTags('');
        setOptions([]);
        setTime('');
    }

    const onSkillSelect = (currentSelectedSkill: Skill[]) => {
        setSelectedSkills(currentSelectedSkill)
    }

    const handleCreateSkill = async () => {
        await postSkill(newSkillName);
        await getAllSkills();
        closeDialog();
        setNewSkillName({
            id: '',
            name: ''
        });
    }

    return (
        <>
            <header className="bg-gray-100 py-8 dark:bg-darkbglight">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
                    <div className="min-w-0 flex-1">
                        <nav className="flex" aria-label="Breadcrumb">
                            <ol className="flex items-center space-x-4">
                                <li>
                                    <div>
                                        <Link
                                            to="/questions"
                                            className="text-sm font-medium text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                                        >
                                            Questions
                                        </Link>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex items-center">
                                        <ChevronRightIcon
                                            className="h-5 w-5 flex-shrink-0 text-gray-400 dark:text-gray-400"
                                            aria-hidden="true"
                                        />
                                        <span className="ml-4 text-sm font-medium text-gray-500 dark:text-gray-400">
                                            {id ? 'Edit ' : 'Create '}
                                        </span>
                                    </div>
                                </li>
                            </ol>
                        </nav>
                        <h1 className="mt-2 text-2xl font-bold leading-7 text-gray-800 sm:text-3xl sm:tracking-tight dark:text-gray-300">
                            {id ? 'Edit Question' : 'Create New Question'}
                        </h1>
                    </div>
                </div>
            </header>
            <form className="space-y-12 sm:space-y-16 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
                <div className=" p-6 ">
                    <div className="flex justify-between py-14 border-b border-gray-300 dark:border-gray-600">
                        <div className='flex items-start flex-col gap-8 lg:flex-row'>
                            <div className="cursor-pointer sm:w-fit lg:w-fit mx-2">
                                <small className='block sm:text-sm font-medium text-gray-700 w-64 text-left dark:text-gray-400'>Question Group *</small>
                                <div className='flex flex-row gap-2 text-xs '>
                                    <DropdownQuestionGroup group={questionGroups} setSelectedQuestionGroup={setSelectedQuestionGroup} selectedQGroup={selectedQGroup} />
                                    {!id && <div className='flex flex-row relative marker items-center justify-between '>
                                        {
                                            isDialogOpen.group && <div className='border border-gray-300 p-2 h-36 flex flex-col justify-between rounded-md absolute z-10 bg-white  dark:bg-darkbg dark:border-gray-600 text-xs lg:left-6 lg:-bottom-3/3 lg:top-0 right-3 top-16 w-fit'>
                                                <div>
                                                    <div className=' block sm:text-sm font-medium text-gray-700 w-64 text-left mb-2 dark:text-gray-300 text-sm'>Create Question Group</div>
                                                    <div>
                                                        <input type="text" className='w-full rounded-md border-gray-300 border p-2 focus:outline-none focus:ring focus:border-indigo-500 dark:bg-transparent dark:border-gray-600 dark:text-gray-400' placeholder='Enter Group Name' onChange={(e) => setNewGroupName(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className='flex flex-row justify-between'>
                                                    <button className='text-sm font-semibold text-gray-900 mr-4 bg-gray-300 px-4 py-2 rounded-md' onClick={(e) => { closeDialog(); setNewGroupName('') }}>Cancel</button>
                                                    <button className='bg-indigo-600 text-white hover:bg-indigo-500 cursor-pointer rounded-md px-4 py-2 text-sm font-semibold shadow-sm focus:outline-none focus:ring focus:border-indigo-500 ' onClick={handleCreate}>Create </button>
                                                </div>
                                            </div>
                                        }
                                        <PlusCircleIcon height={20} color='blue' onClick={() => openDialog('group')} className={`cursor-pointer ${isDialogOpen.group ? 'origin-center rotate-45 ease-in duration-100' : ''}`}
                                        />
                                    </div>}
                                </div>
                            </div>
                            <div className="cursor-pointer sm:w-fit lg:w-fit mx-2">
                                <small className='block sm:text-sm font-medium text-gray-700 w-64 text-left dark:text-gray-400'>Question Type *</small>
                                <DropdownQuestionType setSelectedQuestionType={setSelectedQuestionType} selectedQType={selectedQType} />
                            </div>
                            <div className="cursor-pointer sm:w-fit lg:w-fit mx-2">
                                <small className='block sm:text-sm font-medium text-gray-700 w-64 text-left dark:text-gray-400'>Skill Type</small>
                                <div className='flex flex-row gap-2'>
                                    <Skilldropdown skills={skills} selectedSkills={selectedSkills} onSkillsSelect={onSkillSelect} />
                                    {!id && <div className='flex flex-row relative marker items-center justify-between'>
                                        {
                                            isDialogOpen.skill && <div className='border border-gray-300 p-2 h-36 flex flex-col justify-between rounded-md absolute z-10 bg-white  dark:bg-darkbg dark:border-gray-600 text-xs lg:left-6 lg:-bottom-3/3 lg:top-0 right-3 top-16 w-fit'>
                                                <div>
                                                    <div className=' block sm:text-sm font-medium text-gray-700 w-64 text-left mb-2 dark:text-gray-300 text-sm'>Create Skill </div>
                                                    <div>
                                                        <input type="text" className='w-full rounded-md border-gray-300 border p-2 focus:outline-none focus:ring focus:border-indigo-500 dark:bg-transparent dark:border-gray-600 dark:text-gray-400' placeholder='Enter Skill Name' onChange={(e) => setNewSkillName({
                                                            id: '',
                                                            name: e.target.value
                                                        })} />
                                                    </div>
                                                </div>
                                                <div className='flex flex-row justify-between'>
                                                    <button className='text-sm font-semibold text-gray-900 mr-4 bg-gray-300 px-4 py-2 rounded-md' onClick={(e) => {
                                                        e.preventDefault();
                                                        closeDialog(); setNewSkillName({
                                                            id: '',
                                                            name: ''
                                                        })
                                                    }}>Cancel</button>
                                                    <button className='bg-indigo-600 text-white hover:bg-indigo-500 cursor-pointer rounded-md px-4 py-2 text-sm font-semibold shadow-sm focus:outline-none focus:ring focus:border-indigo-500 ' onClick={(e) => {
                                                        e.preventDefault();
                                                        handleCreateSkill();
                                                    }}>Create</button>
                                                </div>
                                            </div>
                                        }
                                        <PlusCircleIcon height={20} color='blue' onClick={() => openDialog('skill')} className={`cursor-pointer ${isDialogOpen.skill ? 'origin-center rotate-45 ease-in duration-100' : ''}`}
                                        />
                                    </div>}
                                </div>
                            </div>

                        </div>


                    </div>
                    <div className='flex flex-col items-start py-10 border-b border-gray-300 lg:flex-row dark:border-gray-600'>
                        <label htmlFor="questionText" className="block text-sm font-medium text-gray-700 w-64 text-left py-4 dark:text-gray-400">
                            Question Text *
                        </label>

                        <ReactQuill
                            value={text}
                            onChange={setText}
                            formats={formats}
                            modules={{
                                toolbar: {
                                    container: toolbar,
                                }
                            }}
                            className='w-full rounded-md p-2 focus:outline-none focus:ring focus:border-indigo-500 h-fit text-left dark:text-gray-400 dark:border-gray-600'
                            id="questionText"
                        />

                    </div>

                    {(selectedQType.title === 'SINGLE' || selectedQType.title === 'MULTIPLE') && (
                        <CheckboxInput selectedQType={selectedQType} getOptionsfromChild={getOptionsfromChild} selectedOptions={options} isQuestionsModalOpen={false} />
                    )}

                    <div className='flex flex-col items-start py-14 border-b border-gray-300 lg:flex-row dark:border-gray-600'>
                        <label htmlFor="questionTime" className="block text-sm font-medium text-gray-700 w-64 text-left py-4 dark:bg-transparent dark:text-gray-400">
                            Time <small>(In seconds) </small>  *
                        </label>
                        <input
                            id="questionTime"
                            name="questionTime"
                            type="number"
                            className="w-full rounded-md border-gray-300 border p-2 focus:outline-none focus:ring focus:border-indigo-500 dark:bg-transparent dark:text-gray-400 dark:border-gray-600"
                            onChange={(e) => setTime(e.target.value)}
                            min={0}
                            value={time}
                            onWheel={(e) => e.currentTarget.blur()}
                        />
                    </div>
                    <div className='flex flex-col items-start py-14 border-b border-gray-300 lg:flex-row dark:border-gray-600'>
                        <label htmlFor="questionTags" className="block text-sm font-medium text-gray-700 w-64 text-left py-4 dark:text-gray-400">
                            Tags *
                        </label>
                        <input
                            id="questionTags"
                            name="questionTags"
                            type="text"
                            className="w-full rounded-md border-gray-300 border p-2 focus:outline-none focus:ring focus:border-indigo-500 dark:bg-transparent dark:text-gray-400 dark:border-gray-600"
                            onChange={(e) => setTags(e.target.value)}
                            value={tags}
                        />
                    </div>
                </div>

                <div className="mt-8 flex justify-end">
                    <button type="button" className="text-sm font-semibold text-gray-900 mr-4  dark:text-gray-100" onClick={clearFields}>
                        Clear
                    </button>
                    {
                        id ? <>
                            <button
                                onClick={handleQuestionUpdate}
                                type="submit"
                                className={`rounded-md px-4 py-2 text-sm font-semibold shadow-sm focus:outline-none focus:ring focus:border-indigo-500 ${selectedQGroup?.id && options && text.length > 0 && time && time.toString().length > 0 && tags.length > 0
                                    ? 'bg-indigo-600 text-white hover:bg-indigo-500 cursor-pointer'
                                    : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                                    }`}
                                disabled={!(selectedQGroup?.id && (options || selectedQType.title === 'TEXT' || selectedQType.title === 'VIDEO') && text.length > 0 && time && time.toString().length > 0 && tags.length > 0)}
                            >
                                Update
                            </button>

                        </> : <>
                            <button
                                onClick={handleQuestionSubmit}
                                type="submit"
                                className={`rounded-md px-4 py-2 text-sm font-semibold shadow-sm focus:outline-none focus:ring focus:border-indigo-500 ${selectedQGroup?.id && (options || selectedQType.title === 'TEXT' || selectedQType.title === 'VIDEO') && text.length > 0 && time.toString().length > 0 && tags.length > 0
                                    ? 'bg-indigo-600 text-white hover:bg-indigo-500 cursor-pointer'
                                    : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                                    }`}
                                disabled={!(selectedQGroup?.id && (options || selectedQType.title === 'TEXT' || selectedQType.title === 'VIDEO') && text.length > 0 && time.toString().length > 0 && tags.length > 0)}
                            >
                                Submit
                            </button>

                        </>
                    }
                </div>
            </form>
            <Notification
                show={successNotification}
                setShow={setSuccessNotification}
                title="Success"
                description={notifcationMessage}
                type="success"
            />
            <Notification
                show={dangerNotification}
                setShow={setDangerNotification}
                title="Error"
                description={notifcationMessage}
                type="error"
            />
        </>
    );
}
