import React, { useEffect } from 'react'
import { Fragment, useState } from 'react'
import { Transition, Listbox } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { QuestionGroup } from '../../../Models'

function DropdownQuestionGroup({ group, setSelectedQuestionGroup, selectedQGroup, clearQuestionGroup, type }: { group: any[], setSelectedQuestionGroup: (q: QuestionGroup | undefined) => void, selectedQGroup?: QuestionGroup | undefined, clearQuestionGroup?: boolean, type?: string }) {
  const [selected, setSelected] = useState({
    id: '',
    title: "Select Question Group"
  })

  useEffect(() => {
    if (selectedQGroup) {
      setSelectedQuestionGroup(selectedQGroup);
      setSelected(selectedQGroup)
    }
    if (clearQuestionGroup) {
      setSelected({
        id: '',
        title: "Filter By Question Group"
      })
      setSelectedQuestionGroup(undefined);
    }
  }, [selectedQGroup, clearQuestionGroup, setSelectedQuestionGroup])



  const handleSelect = (q: any) => {
    setSelected(q);
    setSelectedQuestionGroup(q)
  }
  return (
    <div className={`${type === 'modal' ? " mr-4" : "w-60"} w-60 `}>

      <Listbox value={selected.title}>
        <div className="relative mt-1 ">
          <Listbox.Button className={`relative !text-xs w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left border border-gray-300 focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm ${type === 'modal' ? "!bg-[#0D42EC] text-white mr-3 font-semibold" : "dark:bg-transparent dark:border-gray-600"} `}>
            <span className={`${type === 'modal' ? "" : " dark:text-gray-300"} block truncate`}>{selected.title}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className=" z-10 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm custom-scroll-small dark:bg-darkbglight ">
              {group.map((q) => (
                <Listbox.Option
                  onClick={() => handleSelect(q)}
                  key={q.id}
                  className={({ active }) =>
                    ` cursor-default !text-xs select-none py-2 grid grid-cols-5 dark:text-gray-300 hover:bg-blue-200 dark:hover:bg-blue-600 dark:hover:text-white ${active ? 'bg-blue-100 text-blue-900 dark:text-white  dark:bg-blue-600' : 'text-gray-900 '
                    }`
                  }
                  value={q.title}
                >
                  {({ selected }) => (
                    <>
                      {selected ? (
                        <span className=" col-span-1 !text-xs inset-y-0 left-0 flex items-center pl-3 text-blue-600 text-left dark:text-gray-300">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : <span className='col-span-1'>

                      </span>}
                      <span

                        className={`block truncate !text-xs text-left col-span-4 ${selected ? 'font-medium' : 'font-normal'
                          }`}
                      >
                        {q.title}
                      </span>

                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  )
}

export default DropdownQuestionGroup
