import React from "react";
import { Pie } from "react-chartjs-2";
import { SkillScore } from "../../../Models";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
 
ChartJS.register(ArcElement, Tooltip, Legend);
 
interface SkillPieChartProps {
  avgSkillScores: SkillScore[];
  score: number;
  percentile: number;
  candidateId:string;
  onScoreMouseEnter:Function;
  onMouseLeave : Function;
}
 
const SkillPieChart: React.FC<SkillPieChartProps> = ({
  avgSkillScores,
  score,
  percentile,
  candidateId,
  onScoreMouseEnter,
  onMouseLeave
}) => {
  const data = {
   
    datasets: [
      {
        data: Array(avgSkillScores.length).fill(1),
        backgroundColor: avgSkillScores.map((skill) => {
          const percentile = skill.percentile;
 
          if ( percentile>0 && percentile <= 10) {
            return "#bf8300";
          } else if (percentile>10 && percentile <= 25) {
            return "#ea9f00";
          } else if (percentile>25 && percentile <= 50) {
            return "#ffb515";
          } else if (percentile>50 && percentile <= 75) {
            return "#ffc240";
          } else if (percentile>75 && percentile <= 90) {
            return "#ffd06a";
          } else {
            return "#ffdd95";
          }
        }),
 
        borderWidth: 2,
      },
    ],
  };
 
  const getColorClassBySkillScore = (percentile: any) => {
    if (percentile <= 10) {
      return "bg-[#bf8300]";
    } else if (percentile <= 25) {
      return "bg-[#ea9f00]";
    } else if (percentile <= 50) {
      return "bg-[#ffb515]";
    } else if (percentile <= 75) {
      return "bg-[#ffc240]";
    } else if (percentile <= 90) {
      return "bg-[#ffd06a]";
    } else {
      return "bg-[#ffdd95]";
    }
  };
 
  const options = {
    plugins: {
      tooltip: {
        enabled: false,
        external: function (context: any) {
          const tooltipModel = context.tooltip;
   
   
          if (!tooltipModel || tooltipModel.opacity === 0) {
            const tooltipEl = document.getElementById("custom-tooltip");
            if (tooltipEl) {
              tooltipEl.style.opacity = "0";
            }
            return;
          }
   
          let tooltipEl = document.getElementById("custom-tooltip");
          if (!tooltipEl) {
            tooltipEl = document.createElement("div");
            tooltipEl.id = "custom-tooltip";
            tooltipEl.style.position = "absolute";
            tooltipEl.style.backgroundColor = "rgba(0, 0, 0, 0.7)";
            tooltipEl.style.color = "#fff";
            tooltipEl.style.padding = "5px";
            tooltipEl.style.borderRadius = "5px";
            tooltipEl.style.pointerEvents = "none";
            tooltipEl.style.zIndex = "100";
            tooltipEl.style.fontSize="12px"
            document.body.appendChild(tooltipEl);
          }
   
 
          const skillName = avgSkillScores[tooltipModel.dataPoints[0].dataIndex].skillName + ' - ' + avgSkillScores[tooltipModel.dataPoints[0].dataIndex].skillScore.toFixed(1) ;
          tooltipEl.textContent = skillName;
 
          const position = context.chart.canvas.getBoundingClientRect();
          tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + "px";
          tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - 30 + "px";
          tooltipEl.style.opacity = "1";
        },
      },
    },
  };
 
 
  return (
    <div
      style={{
        display: "flex",
        position: "relative",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
       
      }}
   
    >
     
      <div
        className={`absolute flex items-center justify-center h-8 w-8 text-white text-xs font-bold rounded-full mt-[11px] border-2 ${getColorClassBySkillScore(percentile)}`}
        style={{ zIndex: 0 }}
        onMouseEnter={() => {
            onScoreMouseEnter(candidateId)
        }}
        onMouseLeave={() => {
            onMouseLeave()
        }}
      >
        {score}
      </div>
      <Pie style={{ }} data={data} options={options}/>
    </div>
  );
};
 
export default SkillPieChart;