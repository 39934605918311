import { useState } from "react";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import {
    CalendarDaysIcon,
    EllipsisVerticalIcon,
    MapPinIcon,
} from "@heroicons/react/20/solid";
import { RoleWorkflow } from "../../Models";
import { deleteWorkflow, getWorkFlowStatuses, updateWorkflowAndStatuses } from "../../WebCalls";
import ConfirmModal from "../Shared/ConfirmModal";
import { dateConverter } from "../../Helpers";
import WorkFlowModal from "./WokflowModal";
import { recruitmentProcess } from "../Roles/CreateRole/CreateRoleForm";
import Notification from "../Shared/Notification";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

export default function WorkflowCard({ workflow, fetchWorkflows }: { workflow: RoleWorkflow, fetchWorkflows: Function }) {
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [editable, setIsEditable] = useState<boolean>(false);
    const [prevProcesses, setPrevProcesses] = useState<recruitmentProcess[]>([]);
    const [isView, setIsView] = useState<boolean>(true);
    const [notificationText, setNotificationText] = useState<{ text: string, type: "success" | "error" | "info" }>({
        text: '',
        type: 'success'
    });
    const [toggleNofication, setToggleNofication] = useState<boolean>(false);


    const confirmEdit = async (id: string, workflowName: string, process: recruitmentProcess[]) => {
        const updatedProcesses = process.map((process, index) => ({
            ...process,
            order: index + 1,
        }));

        await updateWorkflowAndStatuses(id, workflowName, updatedProcesses)
        fetchWorkflows();
        setNotificationText({ text: 'Workflow has been updated!', type: "success" });
        setToggleNofication(true);
    };
    const confirmReset = () => {
        setIsModalOpen(false);
        setIsView(false);
        setNotificationText({ text: '', type: "success" });
        setToggleNofication(false);
    };

    const deleteOnConfirm = async () => {
        await deleteWorkflow(workflow.id).then(() => {
            setNotificationText({ text: 'Workflow has been deleted!', type: "success" });
            setToggleNofication(true);
        })
        setDeleteModalIsOpen(false);
        fetchWorkflows();
    };

    const handleEdit = async () => {
        await getWorkFlowStatuses(workflow.id).then((res) => {
            let tempPrevProcess: recruitmentProcess[] = []
            res.map((w: recruitmentProcess) => {
                tempPrevProcess.push({
                    id: w.id,
                    title: w.title,
                    email: w.email,
                    order: w.order,
                    passedEmail: w.passedEmail ?? '',
                    submittedEmail: w.submittedEmail ?? '',
                    rejectionEmail: w.rejectionEmail ?? '',
                    assessmentTemplateId: w.assessmentTemplateId ?? null
                })
            })
            setPrevProcesses(tempPrevProcess);
        })
        setIsModalOpen(true);
        setIsEditable(true);
    }
    return (
        <>
            <div className="border-b border-gray-200 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8  dark:border-gray-800">
                <div
                    className="sm:flex sm:items-baseline sm:justify-between hover:bg-gradient-to-r from-white via-gray-50 to-white pl-2 py-4 rounded-sm dark:from-darkbg dark:via-darkbglight dark:to-darkbg cursor-pointer "
                    onClick={() => {
                        setIsModalOpen(true);
                        setIsEditable(true);
                        handleEdit();
                        setIsView(true);
                    }}
                >
                    <div className=" grid grid-cols-3 sm:w-full sm:flex-1">
                        <div className="col-span-2">
                            <h1
                                id="message-heading"
                                className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-300"
                            >
                                <span>{workflow.name}</span>
                            </h1>
                            <div className="mt-2 flex items-center text-sm text-gray-500">
                                <MapPinIcon
                                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-entntblue"
                                    aria-hidden="true"
                                />
                                {/* {role.location} */}
                            </div>
                        </div>
                        <div>
                            <h1
                                id="message-heading"
                                className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-400"
                            >
                                <span> Updated on</span>
                            </h1>
                            <div className="mt-2 flex items-center text-sm text-gray-500 ">
                                <CalendarDaysIcon
                                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-entntblue"
                                    aria-hidden="true"
                                />
                                {dateConverter(workflow.date)}
                            </div>

                        </div>
                    </div>

                    <div className="mt-4 mb-0 flex items-center justify-between sm:ml-6 sm:mt-0 sm:flex-shrink-0 sm:justify-start">
                        {/* {isActive ? (
                            <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                Open
                            </span>
                        ) : (
                            <span className="inline-flex items-center rounded-full bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
                                Close
                            </span>
                        )} */}

                        <Menu as="div" className="relative ml-3 inline-block text-left">
                            <div>
                                <Menu.Button
                                    className="-my-2 flex items-center rounded-full bg-transparent  text-gray-400 hover:text-gray-600 dark:hover:text-gray-200"
                                    onClick={(e: React.MouseEvent) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    <span className="sr-only">Open options</span>
                                    <EllipsisVerticalIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                    />
                                </Menu.Button>
                            </div>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items
                                    className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-darkbglight"
                                    onClick={(e: React.MouseEvent) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    <div className="py-1">
                                        <Menu.Item>
                                            {({ active }) => (

                                                <button
                                                    type="button"
                                                    className={classNames(
                                                        active
                                                            ? "bg-gray-100 text-gray-900"
                                                            : "text-gray-700 dark:text-gray-400",
                                                        "flex w-full justify-between px-4 py-2 text-sm"
                                                    )}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setIsView(false);
                                                        handleEdit();
                                                    }}
                                                >
                                                    <span>Edit</span>
                                                </button>
                                            )}
                                        </Menu.Item>
                                        {/* <Menu.Item>
                                            {({ active }) => (
                                                <button
                                                    type="button"
                                                    className={classNames(
                                                        active
                                                            ? "bg-gray-100 text-gray-900"
                                                            : "text-gray-700 dark:text-gray-400",
                                                        "flex w-full justify-between px-4 py-2 text-sm"
                                                    )}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setJobStatusModalIsOpen(true);
                                                    }}
                                                >
                                                    <span>{isActive ? "Close" : "Open"}</span>
                                                </button>
                                            )}
                                        </Menu.Item> */}
                                        <Menu.Item>
                                            {({ active }) => (
                                                <button
                                                    type="button"
                                                    className={classNames(
                                                        active
                                                            ? "bg-gray-100 text-gray-900"
                                                            : "text-gray-700 dark:text-gray-400",
                                                        "flex w-full justify-between px-4 py-2 text-sm"
                                                    )}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setDeleteModalIsOpen(true);
                                                    }}
                                                >
                                                    <span>Delete</span>
                                                </button>
                                            )}
                                        </Menu.Item>
                                        {/* <Menu.Item>
                                            {({ active }) => (
                                                <Link
                                                    to={`/roles/${role.id}/create`}
                                                    className={classNames(
                                                        active
                                                            ? "bg-gray-100 text-gray-900"
                                                            : "text-gray-700 dark:text-gray-400",
                                                        "flex w-full justify-between px-4 py-2 text-sm"
                                                    )}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        clearLocalStorageExceptDark();
                                                    }}
                                                >
                                                    <span>Create a copy</span>
                                                </Link>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <div
                                                    className={classNames(
                                                        active
                                                            ? "bg-gray-100 text-gray-900"
                                                            : "text-gray-700 dark:text-gray-400",
                                                        "flex w-full justify-between px-4 py-2 text-sm"
                                                    )}
                                                    onClick={() =>
                                                        navigator.clipboard.writeText(
                                                            `${careerDomain}/${role.id}/overview`
                                                        )
                                                    }
                                                >
                                                    <span>Copy role url</span>
                                                </div>
                                            )}
                                        </Menu.Item> */}
                                    </div>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </div>
                </div>
            </div>

            {/* <ConfirmModal
                open={jobStatusModalIsOpen}
                setOpen={setJobStatusModalIsOpen}
                onConfirm={closeOnConfirm}
                type="info"
                title="Change Job status"
                description={`Are you sure you want make this job ${isActive ? "inactive" : "active"
                    }?`}
            /> */}
            {isModalOpen && prevProcesses.length > 0 &&
                <WorkFlowModal
                    title={""}
                    content={""}
                    size={"large"}
                    onAdd={confirmEdit}
                    onCancel={confirmReset}
                    isModalClosed={confirmReset}
                    prevProcesses={prevProcesses}
                    isEditable={editable}
                    prevWorkflow={workflow}
                    isView={isView}
                />

            }
            {
                toggleNofication &&
                <Notification show={toggleNofication} setShow={setToggleNofication} title={notificationText.type.charAt(0).toUpperCase() + notificationText.type.slice(1)} description={notificationText.text} type={notificationText.type} />
            }
            <ConfirmModal
                open={deleteModalIsOpen}
                setOpen={setDeleteModalIsOpen}
                onConfirm={deleteOnConfirm}
                type="danger"
                title="Delete workflow"
                description=" Are you sure you want to delete this workflow? All of the
        data will be permanently removed. This action cannot
        be undone."
            />
        </>
    );
}
