import React, { useState } from "react";
import { AssessmentDropdown } from "./CreateProcessAutomation";
import { AssessmentCondition } from "./CreateRoleForm";
import { RoleWorkflow } from "../../../Models";
import { Combobox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

const AllWorkflowsDropDown = ({
    allWorkflows,
    selectedWorkFlow,
    setWorkflows,
    setSelectedWorkflow,
}: {
    allWorkflows: RoleWorkflow[];
    selectedWorkFlow: RoleWorkflow | null;
    setWorkflows: React.Dispatch<React.SetStateAction<RoleWorkflow[]>>;
    setSelectedWorkflow: React.Dispatch<
        React.SetStateAction<RoleWorkflow | null>
    >;
}) => {
    // const handleAssessmentSelection = (
    //     newValue: React.SetStateAction<AssessmentCondition | null>
    // ) => {
    //     setSelectedAssessment((prevState) =>
    //         typeof newValue === "function" ? newValue(prevState) : newValue
    //     );
    //     if (typeof newValue !== "function" && newValue) {
    //         setAssessments([newValue]);
    //     } else {
    //         setAssessments([]);
    //     }
    // };
    const [query, setQuery] = useState("");

    const filteredWorkflows =
        query === ""
            ? allWorkflows
            : allWorkflows.filter((workflows) => {
                return workflows.name.toLowerCase().includes(query.toLowerCase());
            });

    const displayValue = (): string => {
        return selectedWorkFlow ? selectedWorkFlow.name : "";
    };
    const handleWorkFlowSelect = (workFlow: RoleWorkflow) => {
        setSelectedWorkflow(workFlow);
    }


    return (
        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
            <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 dark:text-gray-400">
                Recruitment Workflow <span className="text-red-500">*</span>
            </label>
            <div className="mt-2 sm:col-span-2 sm:mt-0">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300  dark:ring-gray-500 focus-within:ring-1 focus-within:ring-inset focus-within:ring-indigo-600"></div>
                {/* <WorkflowDropdown
                    assessments={allAssessments}
                    selectedAssessment={selectedAssessment}
                    setSelectedAssessment={handleAssessmentSelection}
                    placeholder=" Select From Workflows"
                /> */}
                <Combobox
                    as="div"
                    value={selectedWorkFlow}
                    onChange={setSelectedWorkflow}
                >
                    <div className="relative">
                        <Combobox.Input
                            className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-transparent dark:text-gray-300  dark:ring-gray-500"
                            placeholder={"Select From Workflows"}
                            onChange={(event) => setQuery(event.target.value)}
                            displayValue={displayValue}
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                            <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </Combobox.Button>
                        {filteredWorkflows.length > 0 && (
                            <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm dark:bg-darkbglight">
                                {filteredWorkflows.map((process: RoleWorkflow) => (
                                    <Combobox.Option
                                        onClick={() => handleWorkFlowSelect(process)}
                                        key={process.id}
                                        value={process}
                                        className={({ active }) =>
                                            classNames(
                                                "relative cursor-default select-none py-2 pl-3 pr-9",
                                                active
                                                    ? "bg-entntblue text-white"
                                                    : "text-gray-900 dark:text-gray-300"
                                            )
                                        }
                                    >
                                        {({ active, selected }) => (
                                            <>
                                                <span
                                                    className={classNames(
                                                        "block truncate",
                                                        selected ? "font-semibold" : ""
                                                    )}
                                                >

                                                    {process?.name ?? "Untitled"}
                                                </span>

                                                {selected && (
                                                    <span
                                                        className={classNames(
                                                            "absolute inset-y-0 right-0 flex items-center pr-4",
                                                            active ? "text-white" : "text-entntblue"
                                                        )}
                                                    >
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                )}
                                            </>
                                        )}
                                    </Combobox.Option>
                                ))}
                            </Combobox.Options>
                        )}
                    </div>
                </Combobox>
            </div>
        </div>
    );
};

export default AllWorkflowsDropDown;