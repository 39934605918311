import { useState, Fragment } from "react";
import { PlusIcon } from "@heroicons/react/20/solid";
import { postTag } from "../../../../WebCalls";
import { PopUp } from "./RankingSideBarBody";

interface Props {
    tab: string;
    refreshTags: () => void;
    rankType: string;
    setNotificationPopUp: (notificationObject: PopUp) => void;
    setShow: (show: boolean) => void;
}

export const TagManagement = ({
    tab,
    setNotificationPopUp,
    refreshTags,
    rankType,
    setShow
}: Props) => {
    const [inputTag, setInputTag] = useState<string>("");
    
    const handlePostTag = async () => {
        if(inputTag === "") return;
        try{
          let res;
          if(tab === "Companies"){
            res = await postTag(inputTag, rankType, true);
          }
          else{
            res = await postTag(inputTag, rankType, false);
          }
    
          if(res && res.error){
            setNotificationPopUp({
              title: "Failed",
              description: res.message,
              type: "error"
            });
          }
          else{
            setNotificationPopUp({
              title: "Success",
              description: "Tag is successfully created.",
              type: "success"
            });
          }
          setInputTag("");
          setShow(true);
          refreshTags();
        }
        catch(ex){
          console.log(ex);
        }
    }

    const handleTagInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputTag(event.target.value);
    };
    

  return (
    <>
     <div className="my-6">
       <div className="w-full px-6 flex gap-2 items-center">
            <input
              type="text"
              placeholder="Add new tag"
              value={inputTag}
              className="x1 rounded-lg bg-white py-2 pl-10 pr-3 text-left border border-gray-300 focus:outline-none focus-visible:border-indigo-500 dark:text-gray-400 dark:border-gray-600 dark:bg-transparent focus-visible:ring-offset-blue-100 sm:text-sm"
              onChange={(e) => handleTagInput(e)}
            />
          <button onClick={handlePostTag}>
            <PlusIcon className="h-8 w-8 border-2 bg-entntblue hover:bg-gray-400 text-gray-300 rounded-lg cursor-pointer"/>
          </button>
        </div>
      </div>
    </>
  )
};


