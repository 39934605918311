import { useState, useEffect, Fragment, useMemo, useRef} from "react";
import { Disclosure } from "@headlessui/react";
import { TrashIcon, ChevronUpDownIcon, XMarkIcon, ArrowPathIcon, PencilSquareIcon, CheckCircleIcon } from "@heroicons/react/20/solid";
import { assignTagCompanies, assignTagUniversities, deleteRankType, deleteTag, editTag } from "../../../../WebCalls";
import ConfirmModal from "../../../Shared/ConfirmModal";
import { PopUp, Tags } from "./RankingSideBarBody";
import useApiToken from "../../../../hooks/useApiToken";

interface filterOption {
    value: string | number | boolean | null;
    label: string;
    checked: boolean;
    extraData?: number;
};

interface ITagFilter{
    id: string;
    name: string;
    options: filterOption[];
    filteredOptions: filterOption[];
};

const initialTagFilter: ITagFilter = {
    id: "tagFilter",
    name: "Tag",
    options: [],
    filteredOptions: [],
};
  
interface DeleteType {
    id: string,
    type: string
}

interface Props {
    applyFilters: (filter: any) => void;
    selectedColumns: string[];
    tab: string;
    setSelectedColumns: (columns: string[]) => void;
    getData: (obj: any) => void;
    fetchAndSetRankType: () => Promise<void>;
    setNotificationPopUp: (popUp: PopUp) => void;
    setShow: (show: boolean) => void;
    refreshTags: () => void;
    tags: Tags[];
}

const TagFilter = ({
    applyFilters,
    selectedColumns,
    tab,
    setSelectedColumns,
    getData,
    fetchAndSetRankType,
    setNotificationPopUp,
    setShow,
    refreshTags,
    tags
}: Props) => {
    const [tagFilter, setTagFilter] =   useState<ITagFilter>(initialTagFilter);
    const [tagSearch, setTagSearch] = useState(""); 
    const [deleteRankTagType, setDeleteRankTagType] = useState<DeleteType>();
    // const [editTagNameFlag, setEditTagNameFlag] = useState<boolean>(false);
    const [editTagFlags, setEditTagFlags] = useState<Record<string, boolean>>({});
    const tagEditRef = useRef<HTMLInputElement>(null);
     
    const [deleteRankTypeModal, setDeleteRankTypeModal] = useState(false);
    const token = useApiToken();

    const filteredTags = useMemo(() => {
      const filteredTag = tagSearch
        ? tags.filter((t) =>
            t.name.toLowerCase().startsWith(tagSearch.toLowerCase())
          )
        : tags;
    
      return tagFilter.options.filter((tf) =>
        filteredTag.some((ft) => ft.id === tf.value)
      );
  }, [tagSearch, tags, tagFilter.options]);

  useEffect(() => {
    setTagFilter((prev) => ({ ...prev, filteredOptions: filteredTags }));
  }, [filteredTags]);

    const setFilterForTag = () => {
      const updatedFilterOption = tags.map((c:any) => ({
        checked: false,
        value: c.id,
        label: c.name,
        extraData: c.count ?? undefined
      }));
  
      const typeFilter = {
        ...initialTagFilter,
        options: updatedFilterOption,
      };
      setTagFilter(typeFilter);
    }

    useEffect(() => {
      setFilterForTag();
    }, [ tags, tab ]);


    const handleTagFilterChange = async (changedOption: filterOption) => {

        const updatedOptions = tagFilter.options.map((option) => {
          if (option.value === changedOption.value) {
            return { ...option, checked: !option.checked };
          }
          return option;
        });
        const updatedFilteredOptions = tagFilter.filteredOptions.map((fo) => {
          if (fo.value === changedOption.value) {
            return { ...fo, checked: !fo.checked };
          }
          return fo;
        });
    
        setTagFilter((prev) => ({
          ...prev,
          options: updatedOptions,
          filteredOptions: updatedFilteredOptions,
        }));
        applyFilters([
          {
            FilterType: "tag",
            Tags: updatedOptions.filter((uo) => uo.checked).map(uo => uo.value)
          }
        ])
        
    };

    const handleAssignTag = async (tagId: any) => {
        try{
            let res:any;
            if(tab === "Companies"){
            await Promise.all(
                selectedColumns.map( async (id) => {
                res = await assignTagCompanies(tagId, id);
            }));
            }
            else{
            await Promise.all(
                selectedColumns.map( async (id) => {
                res = await assignTagUniversities(tagId, id);
            }));
            }
            if(res && res?.error){
            setNotificationPopUp({
                title: "Tag assign failed",
                description: res.message,
                type: "error"
            });
        }
        else{
            setNotificationPopUp({
            title: "Tag assigned",
            description: res?.message,
            type: "success"
            });
        }
        setShow(true);
        refreshTags();
        getData({pageChange: false, reload: true});
        }
        catch(ex){
            console.log(ex);
        }
    }

    const handleDeleteRankType = async () => {
      if (!token || !deleteRankTagType) return;
  
      if(deleteRankTagType.type === "tag"){
        const res = await deleteTag(deleteRankTagType.id);
        if(res && res.error){
          setNotificationPopUp({
            title: "Error",
            description: res.message,
            type: "error"
          });
        }
        else{
          setNotificationPopUp({
            title: "Success",
            description: "Tag is successfully deleted.",
            type: "success"
          });
        }
        setShow(true);
        refreshTags();
        applyFilters([]);
        // getData({pageChange: false, reload: true});
      }
      else{
        await deleteRankType(deleteRankTagType.id, token);
        fetchAndSetRankType();
      }
      setDeleteRankTypeModal(false);
    };


    const handleEditTag = async (tagId: any, editedTagName: string) => {
      try{
        await editTag(tagId, editedTagName);
        var updatedFilteredOptions = tagFilter.filteredOptions.map((tag) => {
          if(tag.value === tagId){
            return { ...tag, label: editedTagName }
          }
          return tag;
        });

        var updatedOptions = tagFilter.options.map((tag) => {
          if(tag.value === tagId){
            return { ...tag, label: editedTagName }
          }
          return tag;
        });

        setTagFilter({...tagFilter, filteredOptions: updatedFilteredOptions, options: updatedOptions});
        applyFilters([]);
      }
      catch(ex){
        console.log(ex);
      }
    }

  return (
    <>
        <Disclosure
        as="div"
        className="border-b border-gray-200 py-4 dark:border-gray-500"
        >
        {({ open, close }) => (
          <div>
            <div className="relative">
              <h3 className="-my-4 flow-root">
                <Disclosure.Button className="flex w-full items-center py-3 justify-between text-sm text-gray-400 hover:text-gray-500">
                  <span className="font-medium text-gray-900 dark:text-gray-300">
                    {tagFilter.name}
                  </span>
                  <span className="ml-3 flex items-center">
                    <div className="relative w-full cursor-default overflow-hidden rounded-md bg-white text-left ring-1 ring-gray-300 sm:text-sm dark:ring-gray-500">
                      <input
                        type="text"
                        value={tagSearch}
                        placeholder="Search tags"
                        className="w-full border-none py-1 pl-3 pr-10 text-sm text-gray-900 focus:ring-0 placeholder:text-gray-400 dark:text-gray-300 dark:bg-darkbg"
                        onChange={(event) =>
                          setTagSearch(event.target.value)
                        }
                      />
                      <div className="absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                          className="h-5 w-5 text-gray-400 dark:text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </span>
                </Disclosure.Button>
              </h3>

              <Disclosure.Panel
                className={`dark:bg-darkbglight dark:border-gray-500 shadow-sm pl-1 py-1 h-fit absolute bg-white rounded-md z-50 border border-gray-200 bottom-[2.6rem] right-[1rem]`}
              >
                <div className="max-h-[10.5rem] min-w-[11.8rem] px-2 py-1 overflow-auto thin-scroll bg-transparent z-10">
                  <div className="space-y-2">
                    <div className="pb-2 flex justify-between items-center">
                      <p className="text-entntblue text-sm font-medium">Tags</p>
                      <button onClick={refreshTags}>
                        <ArrowPathIcon className="h-4 w-4 text-gray-600 dark:text-gray-300 "/>
                      </button>
                    </div>
                    {tagFilter.filteredOptions.map((option:any, optionIdx) => {
                      return (
                        <div key={optionIdx} className="flex justify-between gap-3 items-center border-b-2 dark:border-gray-400 pb-2">
                          <div  className="flex items-center">
                            {
                              option.extraData ? (<input
                                id={`tag-filter-${optionIdx}`}
                                type={"checkbox"}
                                onChange={() => {
                                  handleTagFilterChange(option);
                                }}
                                checked={option.checked}
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                              />) : <></>
                              
                            }

                            <label
                              id={`tag-filter-${optionIdx}`}
                              className="ml-3 text-sm text-gray-600 flex justify-between w-full pr-2 dark:text-gray-400"
                            >
                              {
                                !editTagFlags[option.value] ? (<span>{option.label}</span>) : (<input 
                                  type="text"
                                  className="h-4 rounded border-gray-300  focus:ring-indigo-500 text-black text-xs w-24"
                                  defaultValue={option.label}
                                  ref={tagEditRef}
                                 />)
                              }
                            </label>
                          </div>
                          <div className="flex gap-4 items-center">
                            {
                              selectedColumns.length > 0 && 
                              <button 
                                className="text-xs hover:text-entntblue text-gray-500" 
                                onClick={() => handleAssignTag(option.value)}
                              >
                                (ASSIGN)
                              </button>
                            }
                            <button onClick={() => {
                              setEditTagFlags((prev) => ({
                                ...prev,
                                [option.value]: !prev[option.value], // Toggle edit state for the specific tag
                              }));
                              if(tagEditRef.current && editTagFlags[option.value]){
                                 handleEditTag(option.value, tagEditRef.current.value);
                              }
                            }}>
                              {
                                editTagFlags[option.value] ? <CheckCircleIcon className="h-4 w-4 text-gray-500 hover:text-black"/> : <PencilSquareIcon className="h-3 w-3 text-gray-500 hover:text-black"/>
                              }
                            </button>
                            <button onClick={() => {
                                setDeleteRankTagType({id: option.value as string, type: "tag"});
                                setDeleteRankTypeModal(true);
                            }}>
                              <TrashIcon className="h-3 w-3 cursor-pointer  dark:text-gray-300 hover:text-red-500"/>
                            </button>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </Disclosure.Panel>
            </div>
            {open && (
              <div
                className="fixed inset-0 z-20"
                onClick={() => {
                  close();
                }}
              ></div>
            )}
            <div className="z-10 text-xs flex flex-wrap box-border mt-2">
              {tagFilter.options.map((o, index) =>
                o.checked ? (
                  <span
                    key={o.label + index}
                    className="flex items-center bg-blue-100 text-entntblue py-1 px-2 mr-2 mt-2 rounded-full relative"
                  >
                    {o.label}
                    <XMarkIcon
                      onClick={() => {
                        handleTagFilterChange(o);
                      }}
                      className="h-4 w-4 cursor-pointer text-gray-500 ml-1 hover:text-gray-600"
                    />
                  </span>
                ) : (
                  <Fragment key={index}></Fragment>
                )
              )}
            </div>
          </div>
        )}
      </Disclosure>
      <ConfirmModal
        open={deleteRankTypeModal}
        setOpen={setDeleteRankTypeModal}
        onConfirm={handleDeleteRankType}
        type="danger"
        title="Delete Tag"
        description={`Are you sure? Tag will be removed from all assigned ${tab === "Companies" ? "companies" : "universities"}.`}
      />
    </>
  )
}

export default TagFilter
