import React, { useState } from 'react';
import { ChevronDoubleRightIcon, ChevronDoubleLeftIcon, PlusIcon, ListBulletIcon, MinusCircleIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import AssessmentDropdown from '../Dropdowns/AssessmentDropdown';
import { AssessmentQuestionnaire, Skill, TemplateWithQuestionnaires } from '../../../Models';
import { fetchAssessmentTemplatesWithQuestionnaires, getAllAssessments, getAllQuestionsFromTemplate, AssignQuestionsToTemplate, getAllQuestionsByQuestionnareId, addQuestionsToAssessment, removeQuestionsFromAssessment, RemoveQuestionsFromTemplate, fetchAssessmentTemplates } from '../../../WebCalls';
import TemplatesDropdown from '../Dropdowns/TemplatesDropdown';
import { Menu } from '@headlessui/react';
import ConfirmModal from '../../Shared/ConfirmModal';
import { CurrentFilters } from '../Questions';
import Notification from '../../Shared/Notification';
import SkillDropdown from '../../Template/Dropdown/SkillDropdown';
import { ArrowRightCircleIcon } from '@heroicons/react/24/outline';
import SkillAnalyticsModal from '../Modals/SkillAnalyticsModal';
interface QuestionRightSideBarProps {
    onToggle: (isOpen: boolean) => void;
    toggleModal: Function;
    selectedQuestions: string[];
    handleQuestionsDelete: Function;
    getQuestionFilters: Function,
    currentFilters: CurrentFilters,
    skills: Skill[]
}

const QuestionRightSideBar: React.FC<QuestionRightSideBarProps> = ({ onToggle, toggleModal, selectedQuestions, handleQuestionsDelete, getQuestionFilters, currentFilters, skills }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [assessments, setAssessments] = useState<AssessmentQuestionnaire[]>([]);
    const [isAssessmentDropdownOpen, setIsAssessmentDropdownOpen] = useState(false);
    const [isTemplateDropdownOpen, setIsTemplateDropdownOpen] = useState(false);
    const [templates, setTemplates] = useState<TemplateWithQuestionnaires[]>([]);
    const [selectedAssessments, setSelectedAssessments] = useState<string[]>([]);
    const [selectedTemplate, setSelectedTemplate] = useState<string>('');
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [assignType, setAssignType] = useState<"add" | "delete">("add");
    const [modalInfo, setModalInfo] = useState<{
        title: string, description: string, confirmButtonTitle: string, type: "info" | "danger" | "none", selected: string
    }>({
        title: '',
        description: '',
        confirmButtonTitle: '',
        type: 'none',
        selected: ''
    });
    const [notificationText, setNotificationText] = useState<{ text: string, type: "success" | "error" | "info" }>({
        text: '',
        type: 'success'
    });
    const [toggleNofication, setToggleNofication] = useState<boolean>(false);
    const [selectedSkill, setSelectedSkill] = useState<Skill | undefined>();

    const handleToggle = () => {
        setIsSidebarOpen(!isSidebarOpen);
        onToggle(!isSidebarOpen);
    };

    const onAssessmentSelect = (assessments: AssessmentQuestionnaire[]) => {
        const assessmentIds = assessments.map((a) => a.id);
        setSelectedAssessments(assessmentIds);
    };
    const onTemplateSelect = (templateId: string) => {
        setSelectedTemplate(templateId);
    };

    const handleAssessmentDropdown = async () => {
        await getAllAssessments().then((res) => {
            setAssessments(res);
            setIsTemplateDropdownOpen(false);
            setIsAssessmentDropdownOpen(true);
        });
    };
    const resetSelectedTemplate = () => {
        setSelectedTemplate('');
    }
    const handleTemplateDropdown = async () => {
        await fetchAssessmentTemplates().then((res) => {
            setTemplates(res);
            setIsAssessmentDropdownOpen(false);
            setIsTemplateDropdownOpen(true);
        });
    };

    const closeDropdowns = () => {
        setIsAssessmentDropdownOpen(false);
        setIsTemplateDropdownOpen(false);
    };

    const handleAddQuestionsToTemplate = async () => {
        getAllQuestionsFromTemplate(selectedTemplate).then((res) => {
            const questions = res;
            const duplicates = questions.length > 0 && questions?.filter((question: any) => selectedQuestions.includes(question.id));
            if (duplicates.length > 0) {
                setModalInfo({
                    title: 'Assign Questions',
                    description: `There are ${duplicates.length} questions already in the selected template, Are you sure you want to add it? Duplicate Questions will not be added again.`,
                    confirmButtonTitle: 'Confirm',
                    type: 'danger',
                    selected: ''
                })
                setOpenModal(true);
            } else {
                setModalInfo({
                    title: 'Assign Questions',
                    description: `Are you sure you want to add these questions to the template?`,
                    confirmButtonTitle: 'Confirm',
                    type: 'info',
                    selected: ''
                })
                setOpenModal(true);
            }
        }).catch((err) => {

        })
    }
    const onCofirmModal = async () => {
        setOpenModal(false);
        if (isTemplateDropdownOpen) {
            if (assignType === "add") {
                await AssignQuestionsToTemplate(selectedTemplate, selectedQuestions);
                setSelectedTemplate('');
            }
            else if (assignType === "delete") {
                await RemoveQuestionsFromTemplate(selectedTemplate, selectedQuestions);
            }
        }
        else if (isAssessmentDropdownOpen) {
            if (assignType === "add") {
                const assessmentQuestions = selectedAssessments.flatMap((questionnaireId) =>
                    selectedQuestions.map((questionId) => ({
                        QuestionId: questionId,
                        QuestionnaireId: questionnaireId
                    }))
                );
                try {
                    await addQuestionsToAssessment(assessmentQuestions);
                    setSelectedAssessments([]);
                } catch (error) {
                    console.error("Error adding questions to assessment:", error);
                }
            }
            else if (assignType === "delete") {
                const assessmentQuestions = selectedAssessments.flatMap((questionnaireId) =>
                    selectedQuestions.map((questionId) => ({
                        QuestionId: questionId,
                        QuestionnaireId: questionnaireId
                    }))
                );
                await removeQuestionsFromAssessment(assessmentQuestions);
                setSelectedAssessments([]);
            }
        }
        else {
            handleQuestionsDelete();

        }
        setNotificationText({
            text: "Questions Updated Successfully",
            type: "success"
        })
        setToggleNofication(true);
        setIsAssessmentDropdownOpen(false);
        setIsTemplateDropdownOpen(false);
        setAssignType("add");
        getQuestionFilters(currentFilters.selectedFilters, currentFilters.currentAccuracy, currentFilters.searchQueryString);
    }
    const onCancelModal = () => {
        setOpenModal(false);
        setModalInfo({
            title: '',
            description: '',
            confirmButtonTitle: '',
            type: 'none',
            selected: ''
        });

    }
    const isAssessmentModalClosed = () => {

    }
    const handleAddQuestionsToAssessment = async () => {
        try {
            const allQuestionsFromAssessments = await Promise.all(
                selectedAssessments.map(async (id) => {
                    const questions = await getAllQuestionsByQuestionnareId(id);
                    return questions;
                })
            );
            const allQuestions = allQuestionsFromAssessments.flat();
            const duplicates = allQuestions.filter((question: any) =>
                selectedQuestions.includes(question.id)
            );

            if (duplicates.length > 0) {
                setModalInfo({
                    title: 'Assign Questions',
                    description: `There are ${duplicates.length} questions already in the selected assessments, Are you sure you want to add it? Duplicate Questions will not be added again.`,
                    confirmButtonTitle: 'Confirm',
                    type: 'danger',
                    selected: ''
                })
                setOpenModal(true);
            } else {
                setModalInfo({
                    title: 'Assign Questions',
                    description: `Are you sure you want to add these questions to the selected assessments?`,
                    confirmButtonTitle: 'Confirm',
                    type: 'info',
                    selected: ''
                })
                setOpenModal(true);
            }

        } catch (error) {
            console.error("Error fetching questions:", error);
        }
    };

    const handleDeleteQuestionsToAssessment = () => {
        // setIsAssessmentDropdownOpen(false);
        setModalInfo({
            title: 'Remove Questions',
            description: `Are you sure you want to delete ${selectedQuestions.length} questions from the selected assessments?`,
            confirmButtonTitle: 'Confirm',
            type: 'danger',
            selected: ''
        })
        setOpenModal(true);

    }

    const handleDeleteQuestionsToTemplate = () => {
        // setIsTemplateDropdownOpen(false)
        setModalInfo({
            title: 'Remove Questions',
            description: `Are you sure you want to delete ${selectedQuestions.length} question from the selected template?`,
            confirmButtonTitle: 'Confirm',
            type: 'danger',
            selected: ''
        })
        setOpenModal(true);
    }

    const handleQuestionsDeleteModal = () => {
        setModalInfo({
            title: 'Delete Questions',
            description: `Are you sure you want to delete selected ${selectedQuestions.length} questions? Questions assinged to the assessments will be removed as well. `,
            confirmButtonTitle: 'Confirm',
            type: 'danger',
            selected: ''
        })
        setOpenModal(true);
    }

    const onSkillSelect = (skillId: string, index: number) => {
        let selected = skills.find((S) => S.id === skillId);
        if (selected) {
            setSelectedSkill(selected);
        }
        setIsSkillModalOpen(false);
    }

    const [isSkillModalOpen, setIsSkillModalOpen] = useState(false);

    const handleSkillModalConfirm = () => {
        setIsSkillModalOpen(false)
    }

    const handleSkillModalClose = () => {
        setIsSkillModalOpen(false)
    }


    return (
        <aside
            className={`select-none fixed z-10 top-[4.1rem] block h-[calc(99vh-4rem)] right-0 shadow-md border border-gray-500 transition-transform transform duration-200 w-[19rem] ${isSidebarOpen ? "translate-x-0" : "translate-x-[100%]"}`}
        >
            <div className="mx-auto bg-gray-100 dark:bg-darkbglight">
                <div className="min-w-0 px-4 pt-2 pb-1">
                    <div className="text-gray-900 dark:text-gray-300 flex items-center space-x-2 justify-center">
                        <h3 className="text-center text-lg font-semibold leading-7 sm:text-base sm:tracking-tight">
                            Question Actions
                        </h3>
                    </div>
                </div>
            </div>
            <div
                className={`absolute top-10 ${isSidebarOpen ? "-left-2 shadow-md" : "-left-4 shadow-xl"} h-16 border border-gray-300 rounded-md flex items-center justify-center cursor-pointer bg-white dark:bg-darkbglight dark:border-gray-500 dark:text-gray-300`}
                onClick={handleToggle}
            >
                {isSidebarOpen ? <ChevronDoubleRightIcon width={16} /> : <ChevronDoubleLeftIcon width={16} />}
            </div>
            <div className="pb-12 h-full bg-white thin-scroll overflow-y-auto dark:bg-darkbg w-full flex flex-col items-center mt-2">
                <div className='w-full flex flex-col items-center mt-8 pb-8 border-b-2 border-gray-600'>
                    <button className='items-center rounded-md bg-entntblue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-entntorange focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:hover:bg-entntblue disabled:cursor-not-allowed sm:ml-3 w-[70%] flex flex-row justify-between' onClick={() => toggleModal()}>Add Question <PlusIcon height={20} /> </button>
                </div>
                <div className='w-full flex flex-col items-center mt-8 pb-8 border-b-2 border-gray-600'>
                    <div className='w-full flex flex-col items-center'>
                        <div className='items-center rounded-md bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:hover:bg-entntblue disabled:cursor-not-allowed sm:ml-3 w-[70%] flex flex-row justify-between'> ({selectedQuestions.length}) Selected Questions <ListBulletIcon height={20} /> </div>
                    </div>
                    {selectedQuestions.length > 0 && (
                        <div className='flex flex-col items-center justify-between gap-2 w-[70%] ml-2'>
                            <button className='items-center rounded-md bg-entntblue px-3 py-2 font-semibold text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:hover:bg-entntblue disabled:cursor-not-allowed w-full flex flex-row justify-center mt-2 text-xs' onClick={handleQuestionsDeleteModal}>
                                Delete
                            </button>

                            <Menu as="div" className="relative inline-block text-left w-full mt-2">
                                <div>
                                    <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-entntblue px-3 py-2 text-xs font-semibold text-white shadow-sm hover:bg-entntorange">
                                        Update to
                                        <ChevronDownIcon className="-mr-1 h-5 w-5 text-white" aria-hidden="true" />
                                    </Menu.Button>
                                </div>

                                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="py-1">
                                        <Menu.Item>
                                            {({ active }) => (
                                                <div
                                                    className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} block px-4 py-2 text-sm`}
                                                    onClick={handleAssessmentDropdown}
                                                >
                                                    Assessments
                                                </div>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <div
                                                    className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} block px-4 py-2 text-sm`}
                                                    onClick={handleTemplateDropdown}
                                                >
                                                    Template
                                                </div>
                                            )}
                                        </Menu.Item>
                                    </div>
                                </Menu.Items>
                            </Menu>
                        </div>
                    )}

                    <div className='flex flex-row items-center justify-between gap-2 '>
                        {isAssessmentDropdownOpen && (
                            <div>
                                <div className='ml-6 mt-2 flex flex-row items-center justify-between gap-2 '>
                                    <AssessmentDropdown assessments={assessments} onAssessmentSelect={onAssessmentSelect} />
                                    <div>
                                        <MinusCircleIcon height={20} className='cursor-pointer' onClick={() => setIsAssessmentDropdownOpen(false)} />
                                    </div>

                                </div>
                                <div className='flex flex-row items-center justify-evenly mt-8'>
                                    <button className='items-center rounded-md bg-entntblue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-entntorange focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:hover:bg-entntblue disabled:cursor-not-allowed sm:ml-3  flex flex-row justify-between' onClick={() => {
                                        if (selectedAssessments.length > 0) {
                                            setAssignType("delete");
                                            handleDeleteQuestionsToAssessment();
                                        }
                                    }} >Delete</button>
                                    <button className={`items-center rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:hover:bg-entntblue disabled:cursor-not-allowed sm:ml-3  flex flex-row justify-between ${selectedAssessments.length > 0 ? "bg-entntblue hover:bg-entntorange" : "bg-gray-500"}`} onClick={() => {
                                        if (selectedAssessments.length > 0) {
                                            setAssignType("add");
                                            handleAddQuestionsToAssessment();
                                        }
                                    }}>Save</button>
                                </div>
                            </div>
                        )}
                        {isTemplateDropdownOpen && (
                            <div>
                                <div className='ml-6 mt-2 flex flex-row items-center justify-between gap-2 '>
                                    <TemplatesDropdown templates={templates} onTemplateSelect={onTemplateSelect} resetSelectedTemplate/>
                                    <div>
                                        <MinusCircleIcon height={20} className='cursor-pointer' onClick={() => setIsTemplateDropdownOpen(false)} />
                                    </div>
                                </div>
                                <div className='flex flex-row items-center justify-evenly mt-8'>
                                    <button className='items-center rounded-md bg-entntblue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-entntorange focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:hover:bg-entntblue disabled:cursor-not-allowed sm:ml-3  flex flex-row justify-between' onClick={() => {
                                        if (selectedTemplate.length > 0) {
                                            setAssignType("delete");
                                            handleDeleteQuestionsToTemplate();
                                        }
                                    }}>Delete</button>
                                    <button className={`items-center rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:hover:bg-entntblue disabled:cursor-not-allowed sm:ml-3  flex flex-row justify-between ${selectedTemplate.length > 0 ? "bg-entntblue hover:bg-entntorange" : "bg-gray-500"}`}
                                        onClick={() => {
                                            if (selectedTemplate.length > 0) {
                                                setAssignType("add");
                                                handleAddQuestionsToTemplate()
                                            }
                                        }}>Save</button>
                                </div>
                            </div>
                        )}

                    </div>
                </div>
                <div className='w-full flex flex-col items-center mt-8 pb-8 border-b-2 border-gray-600 gap-8'>
                    Skill Analytics
                    <SkillDropdown skills={skills} onSkillSelect={onSkillSelect} index={-1} skillIds={skills.map((s) => s.id)} />
                    {selectedSkill &&
                        <button className='items-center rounded-md bg-entntblue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-entntorange focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:hover:bg-entntblue disabled:cursor-not-allowed sm:ml-3 w-[70%] flex flex-row justify-between' onClick={() => { setIsSkillModalOpen(true) }}>View Analytics <ArrowRightCircleIcon height={20} /> </button>
                    }
                </div>
            </div>
            <ConfirmModal
                type={modalInfo.type}
                open={openModal}
                setOpen={setOpenModal}
                onConfirm={onCofirmModal}
                onCancel={onCancelModal}
                confirmButtonTitle={modalInfo.confirmButtonTitle}
                description={modalInfo.description}
                title={modalInfo.title}
            />
            {toggleNofication &&
                <Notification show={toggleNofication} setShow={setToggleNofication} title={notificationText.type.charAt(0).toUpperCase() + notificationText.type.slice(1)} description={notificationText.text} type={notificationText.type} />
            }
            {isSkillModalOpen && <SkillAnalyticsModal
                selectedSkillId={selectedSkill?.id}
                isModalConfirm={handleSkillModalConfirm}
                isOpen={isSkillModalOpen}
                onCancel={handleSkillModalClose}
                size={'large'}
            />}

        </aside >
    );
};

export default QuestionRightSideBar;
