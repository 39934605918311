import React, { useState, useEffect } from "react";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import { GetInterviewSlots } from "../../WebCalls";
import ConfirmModal from "../Shared/ConfirmModal";
import Notification from "../Shared/Notification";
import { Spinner } from "../Shared/Spinner";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import useApiToken from "../../hooks/useApiToken";

const localizer = momentLocalizer(moment);
moment.updateLocale("en", {
  week: {
    dow: 1,
  },
});
interface CalendarEvent {
  id: string;
  title: string;
  start: Date;
  end: Date;
  candidateId: string | null;
  meetingLink: string | null;
  roleId: string | null;
  roleName: string | null;
  candidateName: string | null;
}

const InterviewCalendar = () => {
  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const [confirmation, setConfirmation] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | null>(
    null
  );
  const [errorNotification, setErrorNotification] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const token = useApiToken();
  useEffect(() => {
    if(!token) return ;
    fetchSlots();
  }, [token]);

  const fetchSlots = async () => {
    setIsLoading(true);
    try {
      if(!token )return;
      const slots = await GetInterviewSlots(null,token);
      const mappedEvents = slots.map((slot) => ({
        id: slot.id,
        title: slot.candidateId
          ? `Interview with ${slot.candidate?.firstName} for ${slot.role?.title}`
          : `Open Slot for ${slot.role?.title}`,
        start: new Date(slot.startTime),
        end: new Date(slot.endTime),
        candidateId: slot.candidateId,
        meetingLink: slot.meetingLink,
        roleId: slot.roleId,
        roleName: slot.role.title,
        candidateName: slot.candidate
          ? `${slot.candidate.firstName} ${slot.candidate.lastName}`
          : null,
      }));
      setEvents(mappedEvents);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleEventClick = (event: CalendarEvent) => {
    if (event.meetingLink) {
      setSelectedEvent(event);
      setConfirmation(true);
    } else {
      setErrorNotification(true);
    }
  };

  const handleJoinMeeting = () => {
    
    if (selectedEvent?.meetingLink) {
      window.open(selectedEvent.meetingLink, "_blank",'width=800,height=800,left=800,top=0');
    }

    if (selectedEvent?.id) {
      const route = `/roles/slots/${selectedEvent.id}`;
      navigate(route);
    }
    setConfirmation(false);
  };

  const eventStyleGetter = (event: CalendarEvent) => {
    const backgroundColor = event.candidateId ? "#4CAF50" : "#2196F3";
    return {
      style: {
        backgroundColor,
        color: "white",
        borderRadius: "8px",
        padding: "5px",
        cursor: "pointer",
      },
    };
  };

  const dayPropGetter = (date: Date) => {
    const isWeekend = date.getDay() === 0 || date.getDay() === 6;
    return {
      style: {
        backgroundColor: isWeekend ? "#ffb3b3" : "",
        border: "1px solid #cfd8dc",
        color: isWeekend ? "#b30000" : "",
      },
    };
  };

  return (
    <div>
      <header className="bg-gray-50 dark:bg-darkbglight py-8">
        <div className="mx-8 max-w-7xl px-4 sm:px-6 lg:px-8 flex items-center justify-between flex-wrap">
          <h1 className="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:tracking-tight dark:text-gray-300">
            Calendar
          </h1>
        </div>
      </header>
      {isLoading ? (
        <div className="flex justify-center items-center h-80">
          <Spinner height="h-12" width="w-12" />
        </div>
      ) : (
        <div className="calendar-container">
          <BigCalendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{ height: "80vh" }}
            eventPropGetter={eventStyleGetter}
            onSelectEvent={handleEventClick}
            className="ml-4 mr-4 rounded-lg shadow-lg dark:text-white dark:bg-darkbglight"
            defaultView="month"
            popup
            dayPropGetter={dayPropGetter}
          />
        </div>
      )}

      {selectedEvent && (
        <ConfirmModal
          type="info"
          title="Are you sure you want to join this meeting?"
          open={confirmation}
          setOpen={setConfirmation}
          description={
            <div>
              <p className="mt-2 text-sm font-semibold">
                Event:{" "}
                {selectedEvent.candidateId ? (
                  <>
                    Interview with{" "}
                    <Link
                      to={`/roles/${selectedEvent.roleId}/candidates/${selectedEvent.candidateId}`}
                      className="text-blue-500 text-base hover:underline"
                    >
                      {selectedEvent.candidateName}
                    </Link>{" "}
                    for{" "}
                    <Link
                      to={`/roles/${selectedEvent.roleId}`}
                      className="text-blue-500 text-base hover:underline"
                    >
                      {selectedEvent.roleName}
                    </Link>
                  </>
                ) : (
                  <>
                    Open Slot for{" "}
                    <Link
                      to={`/roles/${selectedEvent.roleId}`}
                      className="text-blue-500  text-base hover:underline"
                    >
                      {selectedEvent.roleName}
                    </Link>
                  </>
                )}
              </p>
              <p className="mt-2 text-sm">
                Time:{" "}
                {`${new Date(selectedEvent.start).toLocaleDateString("en-IN", {
                  weekday: "short",
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
                })} at ${new Date(selectedEvent.start).toLocaleTimeString(
                  "en-IN",
                  {
                    hour: "2-digit",
                    minute: "2-digit",
                  }
                )} - ${new Date(selectedEvent.end).toLocaleTimeString("en-IN", {
                  hour: "2-digit",
                  minute: "2-digit",
                })}`}
              </p>
            </div>
          }
          confirmButtonTitle="Join"
          onConfirm={handleJoinMeeting}
          onCancel={() => setConfirmation(false)}
        />
      )}
      <Notification
        title="No Meeting Link"
        description="No meeting link is assigned to this slot."
        type="info"
        show={errorNotification}
        setShow={setErrorNotification}
      />
    </div>
  );
};

export default InterviewCalendar;
