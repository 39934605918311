import { useEffect, useMemo, useState } from "react";
import { NIL, v4 as uuidv4 } from "uuid";
import { useLocation, useParams } from "react-router-dom";
import { convertFromHTML } from "draft-js";

import {
  CreateRole,
  DeleteAutoAdvanceCondition,
  PostAutoAdvanceCondition,
  PostRoleStatus,
  PutUpdateRole,
  UpdateRoleStatus,
  fetchAssessmentTemplates,
  fetchAssessmentTemplatesWithQuestionnaires,
  getAllAssessments,
  getRankType,
  getRoleWorkflows,
  getWorkFlowStatuses,
} from "../../../WebCalls";
import Notification from "../../Shared/Notification";
import useApiToken from "../../../hooks/useApiToken";
import CreateRoleStatus from "./CreateRoleStatus";
import CreateProcessAutomation from "./CreateProcessAutomation";
import {
  areArraysEqual,
  areObjectsEqual,
  RoleStatusType,
} from "../../../Helpers";
import { AutoAdvanceCondition, Role, RoleStatus, RoleWorkflow, AssessmentTemplate } from "../../../Models";
import TextEditor from "../../TextEditor/TextEditor";
import { TextEditorFields } from "../../Shared/TextEditorFields";
import { InputField } from "../../Shared/InputFields";
import { clearLocalStorageExceptDark } from "../../TextEditor/textEditorConfig";
import RankingType from "./RankingType";
import ToggleSwitch from "../../Shared/ToggleSwitch";
import AllWorkflowsDropDown from "./AllWorkflowsDropDown";
import SearchDropDown from "../../Quarantine/SubComponents/searchDropDown";
import { ArrowPathIcon, CalendarDaysIcon } from "@heroicons/react/24/outline";
import ReactDatePicker from "react-datepicker";
import DepartmentDropdown from "./DepartmentDropdown";

export type recruitmentProcess = {
  id: string;
  title: string;
  email: string;
  order: number,
  passedEmail: string,
  submittedEmail: string,
  rejectionEmail: string,
  assessmentTemplateId: string | null;
};
export type AssessmentCondition = {
  id: string;
  name: string;
};

const defaultRankType = {
  id: NIL,
  name: "Overall",
};

const getProcessAndAutoAdvanceCondition = (
  roleStatuses: RoleStatus[] | undefined
) => {
  const orderedRoleStatus = roleStatuses?.sort((a, b) => a.order - b.order);
  const autoAdvanceConditions: AutoAdvanceCondition[] = [];

  const processesForRole: recruitmentProcess[] = [];
  orderedRoleStatus?.forEach((rs) => {
    rs.autoAdvanceConditions?.forEach((aac) => {
      autoAdvanceConditions.push(aac);
    });
    processesForRole.push({ id: rs.id, title: rs.name, email: rs.email, assessmentTemplateId: rs.assessmentTemplateId, order: rs.order, passedEmail: rs.passedEmail, submittedEmail: rs.submittedEmail, rejectionEmail: rs.rejectionEmail });
  });
  return { autoAdvanceConditions, processesForRole };
};

export default function CreateRoleForm({
  role,
  roleStatuses,
}: {
  role: Role | undefined;
  roleStatuses: RoleStatus[] | undefined;
}) {
  const [title, setTitle] = useState("");
  const [employmentType, setEmploymentType] = useState("");
  const [location, setLocation] = useState("");
  const [salary, setSalary] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [offerEmail, setOfferEmail] = useState("");
  const [rejectionEmail, setRejectionEmail] = useState("");
  const [assessmentLink, setAssessmentLink] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [processes, setProcesses] = useState<recruitmentProcess[]>([]);
  const [selectedRankType, setSelectedRankType] = useState(defaultRankType);
  const [rankTypes, setRankTypes] = useState<{ id: string; name: string }[]>(
    []
  );
  const [processAutomations, setProcessAutomations] = useState<
    AutoAdvanceCondition[]
  >([]);

  const [textEditorType, setTextEditorType] = useState("");
  const [showTextEditor, setShowTextEditor] = useState(false);
  const [recruitmentProcessId, setRecruitmentProcessId] = useState("");
  const roleId = useParams();

  const [allAssessments, setallAssessments] = useState<AssessmentTemplate[]>(
    []
  );
  const [assessments, setAssessments] = useState<AssessmentTemplate[]>([]);
  const [selectedAssessment, setSelectedAssessment] = useState<AssessmentCondition | null>(null);
  const [isStudentRole, setIsStudentRole] = useState(false);
  const [isOnCampus, setIsOnCampus] = useState(false);
  const [roleWorkflows, setRoleWorkflows] = useState<RoleWorkflow[]>([]);
  const [selectedWorkFlow, setSelectedWorkFlow] = useState<RoleWorkflow | null>(null);
  const [inputType, setInputType] = useState<string>("");
  // const [selectedAssessment, setSelectedAssessment] = useState<AssessmentTemplate | null>(null);
  const [department, setDepartment] = useState<any>();
  const [openDate,setOpenDate] = useState<Date| null>(null);
  const [closeDate,setCloseDate] = useState<Date|null>(null);
  const newRoleId = uuidv4();
  const token = useApiToken();

  const url = useLocation();

  const isEditRole = url.pathname.includes("edit");

  const formFields = [
    {
      id: 1,
      label: "Job title",
      name: "jobTitle",
      inputId: "jobTitle",
      placeholder: "e.g. Junior Software Engineer",
      autoComplete: "jobTitle",
      value: title,
      setValue: setTitle,
    },
    {
      id: 2,
      label: "Employment type",
      name: "employmentType",
      inputId: "employmentType",
      placeholder: "e.g. Full time, Part time, Internship",
      autoComplete: "employmentType",
      value: employmentType,
      setValue: setEmploymentType,
    },
    {
      id: 3,
      label: "Location",
      name: "location",
      inputId: "location",
      placeholder: "e.g. Remote",
      autoComplete: "location",
      value: location,
      setValue: setLocation,
    },
    {
      id: 4,
      label: "Salary",
      name: "salary",
      inputId: "salary",
      placeholder: "e.g. US$950-US$1,300 per month",
      autoComplete: "salary",
      value: salary,
      setValue: setSalary,
    },
  ];

  const textEditorFields = [
    {
      id: 1,
      label: "Job description",
      state: jobDescription,
      setTextEditorType,
      setShowTextEditor,
    },
    {
      id: 3,
      label: "Offer email",
      state: offerEmail,
      setTextEditorType,
      setShowTextEditor,
    },
    {
      id: 4,
      label: "Rejection email",
      state: rejectionEmail,
      setTextEditorType,
      setShowTextEditor,
    },
  ];

  const { processesForRole, autoAdvanceConditions } = useMemo(() => {
    return getProcessAndAutoAdvanceCondition(roleStatuses);
  }, [roleStatuses]);

  useEffect(() => {
    if (!token) return;
    getRankType(token).then((data) => {
      data.unshift(defaultRankType);
      setRankTypes(data);
    });
  }, [token]);
  //Filling the form with role data
  useEffect(() => {
    if (!role) return;
    setTitle(role.title);
    setEmploymentType(role.employmentType);
    setLocation(role.location);
    setSalary(role.salary);
    setJobDescription(role.description);
    setIsStudentRole(role.isStudent);
    setIsOnCampus(role.isOnCampus);
    setDepartment({ id: "3", name: role.department });
    role.openDate && setOpenDate(new Date(role.openDate));
    role.closeDate && setCloseDate(new Date(role.closeDate));

    const assessment = allAssessments.find(
      (a) => a.id === role.assessmentQuestionnaireId
    );
    if (assessment) {
      setSelectedAssessment(assessment);
      setAssessments([assessment]);
    }

    const ranking = rankTypes.find((rt) => rt.id === role.rankTypeId);
    if (ranking) setSelectedRankType(ranking);

    if (!roleStatuses) return;

    roleStatuses.forEach((rs) => {
      if (rs.name === RoleStatusType.hired) setOfferEmail(rs.email ?? "");
      if (rs.name === RoleStatusType.rejected)
        setRejectionEmail(rs.email ?? "");
    });

    //this code is for creating the new ids and mapping them correctly
    if (!isEditRole) {
      const processesForRoleWithNewId = processesForRole.map((pr) => {
        const newId = uuidv4();
        autoAdvanceConditions.forEach((aac) => {
          if (aac.autoAdvanceStatusId === pr.id)
            aac.autoAdvanceStatusId = newId;
          if (aac.startStatusId === pr.id) aac.startStatusId = newId;
        });
        return { ...pr, id: newId };
      });
      autoAdvanceConditions.forEach((aac) => {
        aac.id = uuidv4();
      });
      setProcesses(processesForRoleWithNewId);
    } else {
      setProcesses(processesForRole);
    }
    setProcessAutomations(autoAdvanceConditions);
  }, [
    role,
    roleStatuses,
    rankTypes,
    allAssessments,
    isEditRole,
    processesForRole,
    autoAdvanceConditions,
  ]);
  const isFormValid = () => {
    return (
      title.trim() !== "" &&
      employmentType.trim() !== "" &&
      location.trim() !== "" &&
      salary.trim() !== "" &&
      convertFromHTML(jobDescription).contentBlocks.length !== 0 &&
      convertFromHTML(offerEmail).contentBlocks.length !== 0 &&
      convertFromHTML(rejectionEmail).contentBlocks.length !== 0 &&
      processes.length > 0
    );
  };

  const handleSaveClick = (e: React.FormEvent) => {
    e.preventDefault();
    if (!token) return;
    const formData: Role = {
      id: newRoleId,
      title: title.trim(),
      employmentType: employmentType.trim(),
      location: location.trim(),
      salary: salary.trim(),
      description: jobDescription.trim(),
      isActive: false,
      rankTypeId: selectedRankType.id,
      assessmentQuestionnaireId: selectedAssessment?.id,
      date: new Date().toISOString(),
      isStudent: isStudentRole,
      isOnCampus: isOnCampus,
      // assessmentTemplate: selectedAssessment
      department: department.name ?? "",
      openDate,
      closeDate
    };
    CreateRole(formData, token).then((res) => {
      setTitle("");
      setEmploymentType("");
      setLocation("");
      setSalary("");
      setJobDescription("");
      setOfferEmail("");
      setSelectedRankType(defaultRankType);
      setRejectionEmail("");
      setIsStudentRole(false);
      setIsOnCampus(false);
      setDepartment(undefined);
      setOpenDate(null);
      setCloseDate(null);

      const roleStatuses: RoleStatus[] = processes.map((process, index) => {
        var email = null;
        if (process.title === RoleStatusType.hired) {
          email = offerEmail;
        } else if (process.title === RoleStatusType.rejected) {
          email = rejectionEmail;
        } else {
          email = process.email;
        }

        return {
          id: process.id,
          name: process.title,
          email: email,
          roleId: newRoleId,
          order: index + 1,
          assessmentTemplateId: process.assessmentTemplateId,
          passedEmail: process.passedEmail,
          rejectionEmail: process.rejectionEmail,
          submittedEmail: process.submittedEmail,
        };
      });

      roleStatuses[roleStatuses.length - 1] = {
        ...roleStatuses[roleStatuses.length - 1],
        order: roleStatuses.length - 1,
      };

      Promise.all(
        roleStatuses.map(async (roleStatus) => {
          await PostRoleStatus(roleStatus, token);
        })
      );
      Promise.all(
        processAutomations.map(async (pa) => {
          await PostAutoAdvanceCondition(pa, token);
        })
      );
      setShowNotification(true);
      setProcessAutomations([]);
      // setProcesses([
      //   { id: uuidv4(), title: RoleStatusType.rejected, email: rejectionEmail },
      //   { id: uuidv4(), title: RoleStatusType.hired, email: offerEmail },
      // ]);
      clearLocalStorageExceptDark();
      setTitle("");
      setEmploymentType("");
      setLocation("");
      setSalary("");
      setAssessmentLink("");
      setJobDescription("");
      setOfferEmail("");
      setRejectionEmail("");
      setSelectedWorkFlow(null);
      setProcesses([]);
    });
  };

  const handleUpdateClick = async (e: React.FormEvent) => {
    if (!token || !role) return;
    e.preventDefault();

    const formData: Role = {
      id: role.id,
      title: title.trim(),
      employmentType: employmentType.trim(),
      location: location.trim(),
      salary: salary.trim(),
      description: jobDescription.trim(),
      isActive: role.isActive,
      rankTypeId: selectedRankType.id,
      assessmentQuestionnaireId: selectedAssessment?.id,
      date: role.date,
      isStudent: isStudentRole,
      isOnCampus: isOnCampus,
      department: department.name,
      openDate:openDate?.toISOString(),
      closeDate:closeDate?.toISOString(),
    };

    await PutUpdateRole(role.id, formData, token);
    const statusUpdatePromises = processes.map(async (p) => {
      const prev = processesForRole.find((pr) => pr.id === p.id);
      if (prev && !areObjectsEqual(prev, p)) {
        const update = roleStatuses?.find((rs) => rs.id === p.id);
        if (update) {
          return UpdateRoleStatus(p.id, token, {
            id: p.id,
            email: p.email,
            roleId: update.roleId,
            order: update.order,
            name: p.title,
            assessmentTemplateId: p.assessmentTemplateId,
            passedEmail: p.passedEmail,
            rejectionEmail: p.rejectionEmail,
            submittedEmail: p.submittedEmail,
          });
        }
      }
    });
    await Promise.all(statusUpdatePromises);

    const processAutomationsToDelete = autoAdvanceConditions.filter(
      (aac) => !processAutomations.some((pa) => pa.id === aac.id)
    );
    const processAutomationsToAdd = processAutomations.filter(
      (pa) => !autoAdvanceConditions.some((aac) => aac.id === pa.id)
    );
    await Promise.all(
      processAutomationsToDelete.map(async (pa) => {
        await DeleteAutoAdvanceCondition(pa.id, token);
      })
    );
    await Promise.all(
      processAutomationsToAdd.map(async (pa) => {
        await PostAutoAdvanceCondition(pa, token);
      })
    );

    setShowNotification(true);
    setTimeout(() => {
      window.location.reload();
    }, 500);

    clearLocalStorageExceptDark();
  };

  const isFormChanged = () => {
    if (!role) return;
    const automationIsSame = areArraysEqual(
      processAutomations,
      autoAdvanceConditions
    );

    const processIsSame = areArraysEqual(processesForRole, processes);

    const formIsChanged =
      title.trim() !== role.title ||
      employmentType.trim() !== role.employmentType ||
      location.trim() !== role.location ||
      salary.trim() !== role.salary ||
      jobDescription.trim() !== role.description ||
      selectedRankType.id !== role.rankTypeId ||
      selectedAssessment?.id !== role.assessmentQuestionnaireId ||
      !automationIsSame ||
      !processIsSame;

    return formIsChanged;
  };

  const handleTextEditor = (type: string, inputType: string) => {
    setInputType(inputType);
    setTextEditorType(type);
    setShowTextEditor(true);
    window.scrollTo(0, 0);
  };

  const getDefaultStateValue = (textEditorType: string) => {
    const selectedProcess = processes.find(
      (process) => process.id === recruitmentProcessId
    );
    switch (textEditorType) {
      case "Job description":
        return jobDescription;
      case "Offer email":
        return offerEmail;
      case "Rejection email":
        return rejectionEmail;
      case recruitmentProcessId:
        switch (inputType) {
          case "emailBody":
            return selectedProcess?.email;
          case "submittedBody":
            return selectedProcess?.submittedEmail;
          case "rejectedBody":
            return selectedProcess?.rejectionEmail;
          case "passedBody":
            return selectedProcess?.passedEmail;
          default:
            setInputType('');
            return ""
        }
      default:
        setInputType('');
        return "";
    }
  };

  useEffect(() => {
    getRoleWorkflows().then((res) => setRoleWorkflows(res));
    fetchAssessmentTemplates().then((res) => setAssessments(res));
  }, []);

  useEffect(() => {
    if (selectedWorkFlow) {
      getWorkFlowStatuses(selectedWorkFlow?.id).then((res: recruitmentProcess[]) => {
        let recProcess: recruitmentProcess[] = [];
        res.map((r) => {
          recProcess.push({
            id: uuidv4(),
            title: r.title,
            email: r.email,
            order: r.order,
            assessmentTemplateId: r.assessmentTemplateId,
            passedEmail: r.passedEmail ?? '',
            rejectionEmail: r.rejectionEmail ?? '',
            submittedEmail: r.submittedEmail ?? '',
          });
        });
        setProcesses(recProcess);
      });
    }
  }, [selectedWorkFlow])
  return (
    <>
      {!showTextEditor ? (
        <form>
          <div className="space-y-12 sm:space-y-16 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
            <div>
              {!isEditRole && (
                <p className="mt-6 max-w-2xl text-sm leading-6 text-gray-600 dark:text-gray-300">
                  This jobs you create are not active by default.
                </p>
              )}
              <div>
                <div className="mt-4 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0 dark:border-gray-800  dark:sm:divide-gray-800">
                  <div className="flex sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                    <h3 className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-400">
                      Department <span className="text-red-500"> *</span>
                    </h3>
                      <DepartmentDropdown
                        placeHolder="Select department"
                        dropDownValues={[
                          { id: "1", name: "IT" },
                          { id: "2", name: "Shipping" },
                        ]}
                        currentValue={department}
                        setCurrentValue={setDepartment}
                        query=""
                        setQuery={() => {}}
                        dropDownOnly
                      />
                  </div>
                  {formFields.map((field) => (
                    <InputField
                      key={field.id}
                      label={field.label}
                      name={field.name}
                      inputId={field.inputId}
                      placeholder={field.placeholder}
                      autoComplete={field.autoComplete}
                      value={field.value}
                      setValue={field.setValue}
                    />
                  ))}

                  <div className="flex sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6 text-sm font-medium leading-6 text-gray-900 dark:text-gray-400">
                    <label htmlFor="student-role">Hiring students?</label>
                    <ToggleSwitch
                      id="student-role"
                      // label="Hiring students?"
                      checked={isStudentRole}
                      setState={setIsStudentRole}
                    />
                  </div>
                  <div className="flex sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6 text-sm font-medium leading-6 text-gray-900 dark:text-gray-400">
                    <label htmlFor="oncampus">
                      Partner institution hiring?
                    </label>

                    <ToggleSwitch
                      id="oncampus"
                      // label="Partner institution hiring?"
                      checked={isOnCampus}
                      setState={setIsOnCampus}
                    />
                  </div>
                  {!isEditRole && <AllWorkflowsDropDown
                    allWorkflows={roleWorkflows}
                    setWorkflows={setRoleWorkflows}
                    selectedWorkFlow={selectedWorkFlow}
                    setSelectedWorkflow={setSelectedWorkFlow}
                  />}
                  <CreateRoleStatus
                    processes={processes}
                    setProcesses={setProcesses}
                    handleTextEditor={handleTextEditor}
                    setRecruitmentProcessId={setRecruitmentProcessId}
                  />
                  {textEditorFields.map((field) => (
                    <div
                      key={field.id}
                      className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6"
                      onClick={() => setInputType('')}
                    >
                      <TextEditorFields
                        label={field.label}
                        state={field.state}
                        setTextEditorType={field.setTextEditorType}
                        setShowTextEditor={field.setShowTextEditor}
                      />
                    </div>
                  ))}
                  <RankingType
                    selected={selectedRankType}
                    setSelected={setSelectedRankType}
                    rankTypes={rankTypes}
                  />
                  <CreateProcessAutomation
                    processes={processes}
                    processAutomations={processAutomations}
                    setProcessAutomations={setProcessAutomations}
                    assessments={assessments}
                    allAssessments={allAssessments}
                  />
                 <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6 sm:pt-12">
                    <label
                      // htmlFor="openDate"
                      className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 dark:text-gray-400"
                    >
                      Open Date
                    </label>
                    <div className="mt-2 sm:col-span-2 sm:mt-0">
                    <div className="mb-4 flex flex-row items-center gap-5">

                        <div className="relative">
                          <ReactDatePicker
                            id="openDate"
                            selected={openDate}
                            onChange={(date: Date | null) => {setOpenDate(date)}}
                            showTimeSelect
                            minDate={new Date()}
                            dateFormat="MMMM d, yyyy h:mm aa"
                            className="w-[16rem] p-1 text-sm rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 pl-10 dark:bg-darkbg dark:text-gray-200"
                          />
                          <CalendarDaysIcon className="w-5 h-5 absolute top-1.5 left-2 text-gray-500" />
                        </div>
                        <ArrowPathIcon height={18} className='dark:text-gray-200 text-gray-800 cursor-pointer' onClick={()=>{
                          setOpenDate(null)
                        }}/>
                      </div>
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6 sm:pt-12">
                    <label
                      // htmlFor="closeDate"
                      className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 dark:text-gray-400"
                    >
                      Close Date
                    </label>
                    <div className="mt-2 sm:col-span-2 sm:mt-0">
                      <div className="mb-4 flex flex-row items-center gap-5">
                        <div className="relative">
                          <ReactDatePicker
                            id="closeDate"
                            selected={closeDate}
                            onChange={(date: Date | null) => {setCloseDate(date)}}
                            showTimeSelect
                            minDate={new Date()}
                            dateFormat="MMMM d, yyyy h:mm aa"
                            className="w-[16rem] p-1 text-sm rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 pl-10 dark:bg-darkbg dark:text-gray-200"
                          />
                          <CalendarDaysIcon className="w-5 h-5 absolute top-1.5 left-2 text-gray-500" />
                        </div>
                        <ArrowPathIcon height={18} className='dark:text-gray-200 text-gray-800 cursor-pointer' onClick={()=>{
                          setCloseDate(null)
                        }}/>
                      </div>
                    </div>
                  </div>
                  {/* <div className='flex flex-col items-start py-10 border-b border-gray-300 lg:flex-row dark:border-gray-600'>
                        <label htmlFor="startTime" className="block text-sm font-medium text-gray-700 w-64 text-left mb-4 dark:text-gray-400">
                            Open Date
                        </label>
                        <div className="flex items-center w-full gap-2">
                            <input
                                type="date"
                                // onChange={handleChangeDate}
                                className='rounded-md border-gray-300 border p-2 focus:outline-none focus:ring focus:border-indigo-500 dark:bg-transparent dark:text-gray-400 dark:border-gray-600'
                            />
                            <input type="time" name="" id="" className=' rounded-md border-gray-300 border p-2 focus:outline-none focus:ring focus:border-indigo-500 dark:bg-transparent dark:text-gray-400 dark:border-gray-600 ' 
                            />
                            <ArrowPathIcon height={18} className='dark:text-gray-200 text-gray-800 cursor-pointer' />
                        </div>
                    </div> */}
                </div>
              </div>
              {isEditRole ? (
                <div className="mt-6 pb-8 flex items-center justify-end">
                  <button
                    type="button"
                    className={`inline-flex justify-center rounded-md bg-entntblue px-5 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-entntblue ${!(isFormValid() && isFormChanged())
                      ? "disabled:bg-gray-300 cursor-not-allowed"
                      : "hover:bg-entntorange"
                      }`}
                    onClick={handleUpdateClick}
                    disabled={!(isFormValid() && isFormChanged())}
                  >
                    Update
                  </button>
                </div>
              ) : (
                <div className="mt-6 flex items-center justify-end gap-x-6">
                  <button
                    type="button"
                    className={`inline-flex justify-center rounded-md bg-entntblue px-5 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-entntblue ${!isFormValid()
                      ? "disabled:bg-gray-300 cursor-not-allowed"
                      : "hover:bg-entntorange"
                      }`}
                    onClick={handleSaveClick}
                    disabled={!isFormValid()}
                  >
                    Create
                  </button>
                </div>
              )}
            </div>
          </div>
        </form>
      ) : (
        <TextEditor
          textEditorType={textEditorType}
          setShowTextEditor={setShowTextEditor}
          setJobDescription={setJobDescription}
          setOfferEmail={setOfferEmail}
          setRejectionEmail={setRejectionEmail}
          setProcesses={setProcesses}
          recruitmentProcessId={recruitmentProcessId}
          defaultStateValue={
            roleId?.roleId && getDefaultStateValue(textEditorType)
          }
          inputType={inputType}
          currentProcess={processes.find((el) => el.id === textEditorType)}
        />
      )}
      <Notification
        show={showNotification}
        setShow={setShowNotification}
        title={`${isEditRole ? "Updated" : "Created"} Successfully`}
        description={`Role has been ${isEditRole ? "updated" : "created"} successfully`}
        type="success"
      />
    </>
  );
}
