import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import React, { Fragment, useState } from 'react';
import { AssessmentQuestionnaire } from '../../../Models';

interface AssessmentDropdownProps {
    assessments: AssessmentQuestionnaire[];
    onAssessmentSelect: (selectedAssessments: AssessmentQuestionnaire[]) => void;
}

const AssessmentDropdown: React.FC<AssessmentDropdownProps> = ({ assessments, onAssessmentSelect }) => {
    const [selected, setSelected] = useState<AssessmentQuestionnaire[]>([]);

    const handleAssessmentSelect = (assessment: AssessmentQuestionnaire) => {
        const newSelected = selected.includes(assessment)
            ? selected.filter(item => item.id !== assessment.id)
            : [...selected, assessment];
        setSelected(newSelected);
        onAssessmentSelect(newSelected);
    };

    return (
        <div className="w-48">
            <Listbox value={selected} onChange={setSelected} multiple>
                <div className="relative mt-1">
                    <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left border border-gray-300 focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm dark:bg-transparent dark:border-gray-600 text-xs">
                        <span className="block truncate dark:text-gray-300 text-xs">
                            {selected.length === 0
                                ? "Select Assessments"
                                : `${selected.length} selected`}
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </span>
                    </Listbox.Button>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options className="z-10 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm custom-scroll-small dark:bg-darkbglight">
                            {assessments.map((q) => (
                                <Listbox.Option
                                    key={q.id}
                                    className={({ active }) =>
                                        `cursor-default select-none py-2 grid grid-cols-5 dark:text-gray-300 hover:bg-blue-200 dark:hover:bg-blue-600 dark:hover:text-white text-xs ${active ? 'bg-blue-100 text-blue-900 dark:text-white dark:bg-blue-600' : 'text-gray-900'
                                        }`
                                    }
                                    value={q}
                                    onClick={() => handleAssessmentSelect(q)}
                                >
                                    {({ selected }) => (
                                        <>
                                            <span className="col-span-1 inset-y-0 left-0 flex items-center pl-3 text-blue-600 text-left dark:text-gray-300">
                                                {selected && <CheckIcon className="h-3 w-3" aria-hidden="true" />}
                                            </span>
                                            <span
                                                className={`block truncate text-left col-span-4 ${selected ? 'font-medium' : 'font-normal'
                                                    }`}
                                            >
                                                {q?.title}
                                            </span>
                                        </>
                                    )}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Transition>
                </div>
            </Listbox>
        </div>
    );
};

export default AssessmentDropdown;