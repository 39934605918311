import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { Filter, Question, QuestionGroup, Questions, Skill } from '../../../Models';
import { TagIcon, ClockIcon, QuestionMarkCircleIcon, MinusCircleIcon, ChevronDownIcon, ChevronUpIcon, PencilSquareIcon, CheckIcon, ArrowPathIcon, ListBulletIcon } from '@heroicons/react/20/solid';
import { useParams } from 'react-router-dom';
import { addQuestionsToAssessment, AssignQuestionsToTemplate, fetchAllSkills, removeQuestionsFromAssessment, RemoveQuestionsFromTemplate } from '../../../WebCalls';
import AddQuestionModal from '../Modals/AddQuestionsModal';
import { convert } from 'html-to-text';
import parse from 'html-react-parser'
import Loading from '../Loading';
import ConfirmModal from '../ConfirmModal';
import Notification from '../Notification';
import QuestionsFilter from '../Filter/QuestionsFilter';
import { AssessmentDetailsModel } from '../../Assessment/AssessmentQuestions';


function Kanban({ allQuestions, selectedQuestions, filteredAssessmentQuestionGroup, questionGroups, expandEditablePanel, assessmentDetails, isLoading }: { questionGroups: QuestionGroup[] | undefined, allQuestions: Questions[], selectedQuestions: Questions[], filteredAssessmentQuestionGroup: Function, expandEditablePanel: Function, assessmentDetails: AssessmentDetailsModel, isLoading: boolean }) {

    const [currentSelectedQuestions, setCurrentSelectedQuestions] = useState<Questions[]>(selectedQuestions)
    const [currentAllQuestions, setCurrentAllQuestions] = useState<Questions[]>(allQuestions);
    const [addedQuestions, setAddedQuestions] = useState<Questions[]>([]);
    const [removedQuestions, setRemovedQuestions] = useState<Questions[]>([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedQuestionGroup, setSelectedQuestionGroup] = useState<QuestionGroup | null>(null);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [selectedExpanded, setSelectedExpanded] = useState<string>('');
    const [assessmentNotification, setAssessmentNotification] = useState(false);
    const [filters, setfilters] = useState<Filter[]>([]);
    const [skills, setSkills] = useState<Skill[]>([]);
    const [selectedSkills, setSelectedSkills] = useState<string[]>([]);
    const [accuracy, setAccuracy] = useState({
        min: 0,
        max: 100
    });
    const [checkboxSelectedQuestions, setCheckboxSelectedQuestions] = useState<Questions[]>([]);
    const [checkboxAllQuestions, setCheckboxAllQuestions] = useState<Questions[]>([]);
    const [modalInfo, setModalInfo] = useState<{
        title: string, description: string, confirmButtonTitle: string, type: "info" | "danger" | "none", selected: string
    }>({
        title: '',
        description: '',
        confirmButtonTitle: '',
        type: 'none',
        selected: ''
    });
    const [confirmType, setConfirmType] = useState<"add" | "remove" | "reset">("add");

    const { id } = useParams();
    useEffect(() => {
        fetchAllSkills().then((res) => {
            setSkills(res);
        });
    }, [])


    useEffect(() => {
        initialFilter();
    }, [questionGroups, skills]);


    useEffect(() => {
        filteredAssessmentQuestionGroup(selectedQuestionGroup, selectedSkills, accuracy);
    }, [accuracy])

    const initialFilter = async () => {
        if (questionGroups && skills) {
            const skillOptions = skills.map((s: any) => {
                return { value: s, label: s, checked: false };
            })
            const groupOptions = questionGroups?.map((q) => {
                return { value: q as any, label: q as any, checked: false };

            })
            setfilters([
                {
                    id: 'skill',
                    name: 'Skills',
                    options: skillOptions,
                    type: "checkbox"
                },
                {
                    id: 'group',
                    name: 'Question Groups',
                    options: groupOptions,
                    type: "radio"
                },
                {
                    id: 'accuracy',
                    name: 'Accuracy',
                    options: [],
                    type: "radio"
                }
            ]);
        }
    }


    const setAllQuestions = () => {
        setCurrentAllQuestions(allQuestions.filter((question) => (!selectedQuestions.some((selectedQuestion) => selectedQuestion.id === question.id) && (!addedQuestions.some((selectedQuestion) => selectedQuestion.id === question.id)))))

    }

    useEffect(() => {
        setAllQuestions();
    }, [allQuestions, selectedQuestions]);

    useEffect(() => {
        setCurrentSelectedQuestions(selectedQuestions)
    }, [selectedQuestions])


    const onDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }
        const sourceDroppableId = result.source.droppableId;
        const destinationDroppableId = result.destination.droppableId;

        let updatedAllQuestions = [...currentAllQuestions];
        let updatedSelectedQuestions = currentSelectedQuestions ? [...currentSelectedQuestions] : [];
        if (sourceDroppableId === 'selectedQuestions' && destinationDroppableId === "allQuestions") {
            const [movedItem] = updatedSelectedQuestions?.splice(result.source.index, 1);
            updatedAllQuestions.splice(result.destination.index, 0, movedItem);
        }
        else if (sourceDroppableId === 'allQuestions' && destinationDroppableId === 'selectedQuestions') {
            const [movedItem] = updatedAllQuestions.splice(result.source.index, 1);
            updatedSelectedQuestions.splice(result.destination.index, 0, movedItem);
        }
        else if (sourceDroppableId === destinationDroppableId) {
            if (sourceDroppableId === 'selectedQuestions') {
                const movedQuestion = updatedSelectedQuestions.splice(result.source.index, 1)[0];
                updatedSelectedQuestions.splice(result.destination.index, 0, movedQuestion);
            }
            else if (sourceDroppableId === 'allQuestions') {
                const movedQuestion = updatedAllQuestions.splice(result.source.index, 1)[0];
                updatedAllQuestions.splice(result.destination.index, 0, movedQuestion)
            }
        }
        setCurrentAllQuestions(updatedAllQuestions);
        setCurrentSelectedQuestions(updatedSelectedQuestions);


        const addedQuestions = updatedSelectedQuestions.filter(
            (question) => !selectedQuestions?.some((q) => q.id === question.id)
        );
        setAddedQuestions(addedQuestions);

        const removedQuestions = selectedQuestions?.filter(
            (question) => !updatedSelectedQuestions.some((q) => q.id === question.id)
        );
        setRemovedQuestions(removedQuestions);
    };

    const handleResetQuestions = async () => {
        setConfirmType("reset");
        setModalInfo({
            title: 'Reset Questions',
            description: 'Are you sure you want to reset all the changes?',
            confirmButtonTitle: 'Confirm',
            type: 'danger',
            selected: ''
        })
        setIsConfirmModalOpen(true);
    }

    const handleQuestionSearch = (e: any) => {
        setSearchQuery(e.target.value);
        if (e.target.value === '' && e.target.value.trim() === '' && addedQuestions?.length === 0 && removedQuestions?.length === 0) {
            setAllQuestions()
        }
        else {
            //removedQuestions?.length !== 0 ? removedQuestions : allQuestions
            const filtered = (allQuestions)?.filter((question: Questions) =>
                (question.text.toLowerCase().includes(e.target.value.toLowerCase()) || question.tags.toLowerCase().includes(e.target.value.toLowerCase()) || question.type.toLowerCase().includes(e.target.value.toLowerCase()) || (question.time && question.time.toString().toLowerCase().includes(e.target.value.toLowerCase()))) && !addedQuestions?.some((q) => q.id === question.id) && !selectedQuestions?.some((q) => q.id === question.id)
            );
            setCurrentAllQuestions(filtered);
        }
    }
    const handleQuestionGroupSelection = (assessment: QuestionGroup) => {
        setSelectedQuestionGroup(assessment);
        filteredAssessmentQuestionGroup(assessment)
        setIsDropdownOpen(false);
        setSearchQuery('');
    };

    const handleClearFilter = () => {
        if (addedQuestions.length > 0) {
            setCurrentAllQuestions(allQuestions.filter((question) => {
                return !addedQuestions?.some((q) => q.id === question.id)
            }));
        }
        else {
            setAllQuestions()
        }
        setSearchQuery('');
        setSelectedQuestionGroup(null);
        initialFilter();
        filteredAssessmentQuestionGroup(null, [], accuracy);
        setIsDropdownOpen(false);
        setSelectedSkills([]);
        setAccuracy({
            min: 0,
            max: 100
        })
        setIsDropdownOpen(false);
    }

    const handleAddQuestionsToSelectedByGroup = () => {

        const currentFilteredQuestions = currentAllQuestions.filter(
            (question) => !currentSelectedQuestions.some((selectedQuestion) => selectedQuestion.id === question.id));

        const renderedQuestions = [
            ...currentSelectedQuestions,
            ...currentFilteredQuestions

        ];
        const renderedAddeddQuestions = [...addedQuestions, ...currentAllQuestions.filter(
            (question) => !currentSelectedQuestions.some((selectedQuestion) => selectedQuestion.id === question.id)
        )];


        if (currentFilteredQuestions.length > 0) {
            setCurrentSelectedQuestions(renderedQuestions);
        }
        setAddedQuestions(renderedAddeddQuestions);
        setCurrentAllQuestions([]);
    }


    const handleSaveQuestionsToAssessment = () => {
        setIsModalOpen(true);
    }

    const confirmAdd = async (currentAddedQuestions: Questions[], currentRemovedQuestions: Questions[]) => {
        const addedArray = currentAddedQuestions.map((q) => {
            return {
                questionId: q.id,
                questionnaireId: id,
            }
        });
        const removedArray = currentRemovedQuestions.map((q) => {
            return {
                questionId: q.id,
                questionnaireId: id,
            }
        })

        try {
            if (addedArray.length > 0) {
                await addQuestionsToAssessment(addedArray)
            }

            if (removedArray.length > 0) {
                await removeQuestionsFromAssessment(removedArray)
            }
            setAssessmentNotification(true);
            setTimeout(() => {
                window.location.reload();
            }, 2000)

        } catch (error) {
            console.log(error)
        }
    };
    const confirmModal = async () => {
        if (confirmType === "reset") {
            setIsModalOpen(false);
            setAllQuestions();
            setCurrentSelectedQuestions(selectedQuestions);
            setAddedQuestions([]);
            setRemovedQuestions([]);
            setSearchQuery('');
            setSelectedQuestionGroup(null);
            filteredAssessmentQuestionGroup(null, [], accuracy);
            initialFilter();
            setSelectedSkills([]);
            window.location.reload();
        }
        else if (confirmType === "add") {
            if (assessmentDetails.assessmentTemplate?.id) {
                const questionIds: string[] = checkboxAllQuestions.map(question => question.id);
                await AssignQuestionsToTemplate(assessmentDetails.assessmentTemplate?.id, questionIds)
            }
            setAssessmentNotification(true);
            setTimeout(() => {
                window.location.reload();
            }, 2000)
            setIsConfirmModalOpen(false);
        }
        else if (confirmType === "remove") {
            if (assessmentDetails.assessmentTemplate?.id) {
                const questionIds: string[] = checkboxSelectedQuestions.map(question => question.id);
                await RemoveQuestionsFromTemplate(assessmentDetails.assessmentTemplate?.id, questionIds)
            }

            setAssessmentNotification(true);
            setTimeout(() => {
                window.location.reload();
            }, 2000)
            setIsConfirmModalOpen(false);

        }
    };

    const cancelAdd = () => {
        setIsModalOpen(false);

    };

    const isModalClosed = () => {
        setConfirmType("reset")
        setIsModalOpen(false);

    }

    const handleFilter = (filter: Filter[]) => {
        let selectedSkills: string[] = [];
        let currentSelectedGroup: QuestionGroup | undefined = undefined;
        filter.forEach((e: Filter) => {
            if (e.id === 'skill') {
                e.options.forEach((a: any) => {
                    if (a.checked === true) {
                        selectedSkills.push(a.value.id)
                    }
                })
            } else if (e.id === 'group') {
                e.options.forEach((a: any) => {
                    if (a.checked === true) {
                        currentSelectedGroup = {
                            id: a.value.id,
                            title: a.value.title
                        }
                        setSelectedQuestionGroup(currentSelectedGroup)
                    }
                });
                setSearchQuery('');
            }
        });
        filteredAssessmentQuestionGroup(currentSelectedGroup, selectedSkills, accuracy);
        setSelectedSkills(selectedSkills)
    };

    const getAccuracy = (value: any) => {
        setAccuracy(value);
    };

    const handleCheckboxChangeForCurrent = (question: Questions) => {
        setCheckboxSelectedQuestions(prevSelected => {
            const isAlreadySelected = prevSelected.some(q => q.id === question.id);

            if (isAlreadySelected) {
                return prevSelected.filter(q => q.id !== question.id);
            } else {
                return [...prevSelected, question];
            }
        });
    };

    const handleCheckboxChangeForAll = (question: Questions) => {
        setCheckboxAllQuestions(prevSelected => {
            const isAlreadySelected = prevSelected.some(q => q.id === question.id);

            if (isAlreadySelected) {
                return prevSelected.filter(q => q.id !== question.id);
            } else {
                return [...prevSelected, question];
            }
        });
    };
    const [showRemoveDropdown, setShowRemoveDropdown] = useState(false);
    const [showAddDropdown, setShowAddDropdown] = useState(false);

    const removeDropdownRef = useRef<HTMLDivElement>(null);
    const addDropdownRef = useRef<HTMLDivElement>(null);

    const toggleRemoveDropdown = () => setShowRemoveDropdown(!showRemoveDropdown);
    const toggleAddDropdown = () => setShowAddDropdown(!showAddDropdown);
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (removeDropdownRef.current && !removeDropdownRef.current.contains(event.target as Node)) {
                setShowRemoveDropdown(false);
            }
            if (addDropdownRef.current && !addDropdownRef.current.contains(event.target as Node)) {
                setShowAddDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleResetChecked = (type: string) => {
        if (type === "selected") {
            setCheckboxSelectedQuestions([]);
        }
        if (type === "all") {
            setCheckboxAllQuestions([]);
        }
    }

    const handleQuestionsAssignToTemplate = async () => {
        setConfirmType("add");
        setModalInfo({
            title: 'Add questions to template',
            description: 'Are you sure you want to add the selected questions from the current template?',
            confirmButtonTitle: 'Confirm',
            type: 'info',
            selected: ''
        })
        setIsConfirmModalOpen(true);
    }

    const handleQuestionRemoveFromTemplate = async () => {
        setConfirmType("remove");
        setModalInfo({
            title: 'Remove questions from template',
            description: 'Are you sure you want to remove the selected questions from the current template?',
            confirmButtonTitle: 'Confirm',
            type: 'danger',
            selected: ''
        })
        setIsConfirmModalOpen(true);
    }

    return (
        <div className='flex flex-col h-full mt-8'>
            <div className='flex justify-end flex-col'>
                <div className='flex flex-row justify-end items-center'>
                    <input type="text" placeholder='Search Question' className='rounded-md border-gray-500 border sm:px-5 sm:py-2 me-2 focus:outline-none focus:ring focus:border-indigo-500 w-60 self-centerbg-transparent  text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 dark:text-gray-100 dark:bg-transparent dark:placeholder:text-gray-600 px-6 py-3 ' onChange={handleQuestionSearch} value={searchQuery} />
                    <div className="flex items-center justify-center">

                        {selectedQuestionGroup && <button className='py-2.5 px-5 me-2  text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700' onClick={handleAddQuestionsToSelectedByGroup}>Add All {selectedQuestionGroup.title} Group Questions</button>}
                        <QuestionsFilter filters={filters} handleFilter={handleFilter} getAccuracy={getAccuracy} accuracy={accuracy} />
                    </div>
                    {
                        (selectedQuestionGroup || searchQuery.length > 0 || selectedSkills.length > 0 || (accuracy.min !== 0 || accuracy.max !== 100)) && <div className='flex flex-col justify-center items-center ml-2 py-2 me-2 ' onClick={handleClearFilter}>
                            <ArrowPathIcon height={20} color='gray' cursor={'pointer'} />
                        </div>
                    }
                    <div className="relative inline-block text-left">
                        {isDropdownOpen && (
                            <div className="z-10 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1  block ring-black ring-opacity-5 focus:outline-none dark:bg-darkbglight">
                                <ul className="py-2 text-sm text-gray-700 dark:text-gray-200 text-center" aria-labelledby="assessmentDropdownButton">
                                    {questionGroups?.map((questionGroup) => (
                                        <li key={questionGroup.id}>
                                            {
                                                selectedQuestionGroup?.id === questionGroup.id ? <button
                                                    className="px-4 py-2 hover:bg-blue-200 dark:hover:bg-blue-600 dark:hover:text-white w-full font-medium flex flex-row justify-start"
                                                    onClick={() => handleQuestionGroupSelection(questionGroup)}
                                                >
                                                    <CheckIcon className="h-5 w-5 mr-2" aria-hidden="true" color='blue' />

                                                    {questionGroup.title}
                                                </button>
                                                    :
                                                    <button
                                                        className=" px-4 py-2 hover:bg-blue-200 dark:hover:bg-blue-600 dark:hover:text-white w-full font-normal	flex flex-row justify-start	"
                                                        onClick={() => handleQuestionGroupSelection(questionGroup)}
                                                    >
                                                        <div className='h-5 w-5 mr-2'>

                                                        </div>
                                                        {questionGroup.title}
                                                    </button>
                                            }
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </div >
                {
                    selectedQuestionGroup && <small className='text-right px-4 text-gray-500 mt-2'>
                        Questions are not droppable. You need to add all questions from the group.
                    </small>
                }
                <div className='flex flex-row justify-end mt-8'>
                    {(addedQuestions.length > 0 || removedQuestions.length > 0) &&
                        <>
                            <button className='focus:outline-none text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800' onClick={handleSaveQuestionsToAssessment} >Save Changes</button>

                            <button onClick={handleResetQuestions} className='focus:outline-none text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900'>
                                Reset All Questions
                            </button>
                        </>
                    }
                </div >
                <div className='flex flex-row justify-between'>
                    <div className='relative' ref={removeDropdownRef}>
                        {checkboxSelectedQuestions.length > 0 && <div className='flex flex-row gap-2 items-center'>
                            <button
                                onClick={toggleRemoveDropdown}
                                className='text-[10px] focus:outline-none text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900'>
                                <ListBulletIcon height={20} color='white' />
                            </button>
                            <ArrowPathIcon height={20} color='gray' className=' mb-2' cursor={"pointer"} title='Reset Selected' onClick={() => {
                                handleResetChecked("selected");
                                setShowRemoveDropdown(false);
                            }} />

                        </div>}
                        {showRemoveDropdown && (
                            <div className='absolute z-10 w-48 bg-white rounded divide-y divide-gray-100 shadow'>
                                <ul className='py-1 text-sm text-gray-700'>
                                    <li>
                                        <button className='w-full text-left px-4 py-2 hover:bg-red-200' onClick={() => setIsModalOpen(true)}>
                                            Remove Questions From Assessment
                                        </button>
                                    </li>
                                    {assessmentDetails?.assessmentTemplate && (
                                        <li>
                                            <button className='w-full text-left px-4 py-2 hover:bg-red-200' onClick={handleQuestionRemoveFromTemplate}>
                                                Remove Questions From Template {assessmentDetails.assessmentTemplate?.name}
                                            </button>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        )}
                    </div>
                    <div className='relative' ref={addDropdownRef}>
                        {
                            checkboxAllQuestions.length > 0 && <div className='flex flex-row gap-2 justify-center items-center'>
                                <button
                                    onClick={toggleAddDropdown}
                                    className='text-[10px] focus:outline-none text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 '>
                                    <ListBulletIcon height={20} color='white' />
                                </button>
                                <ArrowPathIcon height={20} color='gray' className=' mb-2' cursor={"pointer"} title='Reset Selected' onClick={() => {
                                    handleResetChecked("all")
                                    setShowAddDropdown(false);
                                }} />

                            </div>
                        }
                        {showAddDropdown && (
                            <div className='absolute z-10 w-48 bg-white rounded divide-y divide-gray-100 shadow'>
                                <ul className='py-1 text-sm text-gray-700'>
                                    <li>
                                        <button className='w-full text-left px-4 py-2 hover:bg-green-200' onClick={() => setIsModalOpen(true)}>
                                            Add Questions To Assessment
                                        </button>
                                    </li>
                                    {assessmentDetails?.assessmentTemplate && (
                                        <li>
                                            <button className='w-full text-left px-4 py-2 hover:bg-green-200' onClick={() => handleQuestionsAssignToTemplate()}>
                                                Add Questions To Template {assessmentDetails.assessmentTemplate?.name}
                                            </button>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>

            </div >

            {(currentSelectedQuestions.length < 0 && currentAllQuestions.length < 0) ? <><Loading /></> : <div className="flex flex-between justify-between overflow-hidden my-10 ">
                <DragDropContext onDragEnd={onDragEnd}>
                    <div className='flex w-screen'>
                        <div className='border rounded border-gray mx-2 flex-1 max-w-1/2 h-fit	min-h-4	p-4 bg-gray-50 dark:bg-transparent dark:border-gray-700'>
                            <h2 className="text-lg font-semibold mb-6 sticky top-0 bg-white p-4 text-center dark:bg-darkbglight dark:text-gray-200 rounded-md">Selected Questions</h2>

                            <Droppable droppableId="selectedQuestions" direction="vertical" isDropDisabled={selectedQuestionGroup ? true : false}>
                                {(provided) => (
                                    <div className="overflow-y-scroll max-h-screen min-h-32 min-w-96" ref={provided.innerRef} {...provided.droppableProps}>
                                        {!isLoading && currentSelectedQuestions?.map((question, index) => (
                                            <Draggable key={question.id} draggableId={question.id} index={index}>
                                                {(provided, snapshot) => (
                                                    <div
                                                        className="mb-2 p-2 rounded text-gray bg-white border border-gray-300 dark:border-gray-800 dark:bg-darkbglight dark:text-gray-50"
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}

                                                    >

                                                        <div className='flex flex-col text-left p-5 text-gray-600'>

                                                            {(isExpanded && selectedExpanded === question.id) ?
                                                                <>
                                                                    <div className='flex flex-row justify-between dark:text-gray-300'>
                                                                        <div className='w-4/5 flex flex-row'>
                                                                            <input type="checkbox" name="" id="" className='mr-3 form-checkbox h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
                                                                                checked={checkboxSelectedQuestions.some(q => q.id === question.id)}
                                                                                onChange={() => handleCheckboxChangeForCurrent(question)}
                                                                            />
                                                                            <small className=''>{parse(question.text)}</small>
                                                                        </div>
                                                                        <PencilSquareIcon className='cursor-pointer' width={20} height={20} onClick={() => {
                                                                            expandEditablePanel(question.id);
                                                                        }} />
                                                                    </div >
                                                                    <ChevronUpIcon height={30} onClick={() => { setIsExpanded(false); setSelectedExpanded('') }} className='cursor-pointer' />
                                                                </>
                                                                : question.text.length > 200 ?
                                                                    <>

                                                                        <div className='flex flex-row justify-between align-center dark:text-gray-300'>
                                                                            <div className='w-5/6 flex flex-row'>
                                                                                <input type="checkbox" name="" id="" className='mr-3 form-checkbox h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
                                                                                    checked={checkboxSelectedQuestions.some(q => q.id === question.id)}
                                                                                    onChange={() => handleCheckboxChangeForCurrent(question)}
                                                                                />
                                                                                <small className=' '>{question.text.length > 200 ? `${convert(question.text.slice(0, 200))}...` : <>{parse(question.text)}</>}</small>
                                                                            </div>


                                                                            <PencilSquareIcon className='cursor-pointer' width={20} height={20} onClick={() => {
                                                                                expandEditablePanel(question.id);
                                                                            }} />

                                                                        </div >
                                                                        <ChevronDownIcon height={30} onClick={() => { setIsExpanded(true); setSelectedExpanded(question.id) }} className='cursor-pointer' /></>
                                                                    : <>
                                                                        <div className='flex flex-row justify-between align-center dark:text-gray-300'>
                                                                            <div className='w-4/5 flex flex-row'>
                                                                                <input type="checkbox" name="" id="" className='mr-3 form-checkbox h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
                                                                                    checked={checkboxSelectedQuestions.some(q => q.id === question.id)}
                                                                                    onChange={() => handleCheckboxChangeForCurrent(question)}
                                                                                />
                                                                                <small className=' w-4/5'>{parse(question.text)}</small>
                                                                            </div>

                                                                            <PencilSquareIcon className='cursor-pointer' width={20} height={20} onClick={() => {
                                                                                expandEditablePanel(question.id);
                                                                            }} />

                                                                        </div >

                                                                    </>
                                                            }
                                                            <div className='flex flex- text-left mt-2 gap-3'>
                                                                <div className='border-gray-400   p-2 flex rounded-lg	 items-center space-x-3 mx-2 gap-2 border text-xs font-medium leading-7 text-gray-900 sm:text-sm sm:tracking-tight dark:text-gray-400 text-left '>
                                                                    <QuestionMarkCircleIcon color='blue' height={20} className='space-x-2 ' />
                                                                    {question.type.toUpperCase()}</div>

                                                                <div className='border-gray-400  border rounded-lg	 p-2  flex items-center space-x-3 mx-2 gap-2 text-xs font-medium leading-7 text-gray-900 sm:text-sm sm:tracking-tight dark:text-gray-400 text-left '>
                                                                    <ClockIcon color='blue' height={20} className='space-x-2 ' />
                                                                    {question.time}</div>
                                                                <div className='border-gray-400  border p-2 rounded-lg	 flex items-center space-x-3 mx-2 gap-2 text-xs font-medium leading-7 text-gray-900 sm:text-sm sm:tracking-tight dark:text-gray-400 text-left '>
                                                                    <TagIcon color='blue' height={20} className='space-x-2 ' />
                                                                    {question.tags}</div>
                                                                <div>
                                                                    <div className='border-gray-400   p-2 flex rounded-lg	 items-center space-x-3 mx-2 gap-2 border text-xs font-medium leading-7 text-gray-900 sm:text-sm sm:tracking-tight dark:text-gray-400 text-left'>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="#FFD700" strokeWidth="2" >
                                                                            <circle cx="12" cy="12" r="10" />
                                                                            <circle cx="12" cy="12" r="6" />
                                                                            <circle cx="12" cy="12" r="2" />
                                                                            <line x1="8" y1="12" x2="14" y2="12" />
                                                                        </svg>
                                                                        {question.accuracy !== null && question.accuracy !== undefined && (question.type !== "video" && question.type !== "audio" && question.type !== "text") ? (
                                                                            <> {question.accuracy.toFixed(2)} ﹪ </>
                                                                        ) : "Not Applicable"}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div >
                                                )}
                                            </Draggable >
                                        ))}
                                        {isLoading && <Loading />}
                                        {provided.placeholder}
                                    </div >
                                )}
                            </Droppable >
                        </div >
                        <div className='border rounded border-gray mx-2 flex-1 max-w-1/2 h-fit	min-h-4	p-4 bg-gray-50 dark:bg-transparent dark:border-gray-700'>
                            <h2 className="text-lg font-semibold mb-6 sticky top-0 bg-white p-4 text-center dark:bg-darkbglight dark:text-gray-200 rounded-md">  {selectedQuestionGroup ? selectedQuestionGroup.title + " Group Questions" : "All Questions"}</h2>
                            <Droppable droppableId="allQuestions" direction="vertical" >
                                {(provided) => (
                                    <div className="overflow-y-scroll max-h-screen min-h-32 min-w-96" ref={provided.innerRef} {...provided.droppableProps}>
                                        {!isLoading && currentAllQuestions?.map((question, index) => (
                                            <Draggable key={question.id} draggableId={question.id} index={index}>
                                                {(provided) => (
                                                    <div
                                                        className="mb-2 p-2 rounded text-gray bg-white border border-gray-300 dark:border-gray-800 dark:bg-darkbglight dark:text-gray-50"
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <div className='flex flex-col text-left p-5 text-gray-600'>
                                                            {(isExpanded && selectedExpanded === question.id) ?
                                                                <>
                                                                    <div className='flex flex-row justify-between dark:text-gray-300'>
                                                                        <div className='w-4/5 flex flex-row'>
                                                                            <input type="checkbox" name="" id="" className='mr-3 form-checkbox h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
                                                                                checked={checkboxAllQuestions.some(q => q.id === question.id)}
                                                                                onChange={() => handleCheckboxChangeForAll(question)}
                                                                            />
                                                                            <small className=' w-4/5'>{parse(question.text)}</small>
                                                                        </div>
                                                                        <PencilSquareIcon className='cursor-pointer' width={20} height={20} onClick={() => {
                                                                            expandEditablePanel(question.id);
                                                                        }} />
                                                                    </div >
                                                                    <ChevronUpIcon height={30} onClick={() => { setIsExpanded(false); setSelectedExpanded('') }} className='cursor-pointer' />
                                                                </>
                                                                : question.text.length > 200 ?
                                                                    <>
                                                                        <div className='flex flex-row justify-between align-center  dark:text-gray-300 '>
                                                                            <div className='w-4/5 flex flex-row'>
                                                                                <input type="checkbox" name="" id="" className='mr-3 form-checkbox h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
                                                                                    checked={checkboxAllQuestions.some(q => q.id === question.id)}
                                                                                    onChange={() => handleCheckboxChangeForAll(question)}
                                                                                />
                                                                                <small className=' w-5/6'>{question.text.length > 200 ?
                                                                                    `${convert(question.text.slice(0, 200))}...`
                                                                                    : <>{parse(question.text)}</>}</small>
                                                                            </div>


                                                                            <PencilSquareIcon className='cursor-pointer' width={20} height={20} onClick={() => {
                                                                                expandEditablePanel(question.id);
                                                                            }} />

                                                                        </div >
                                                                        <ChevronDownIcon height={30} onClick={() => { setIsExpanded(true); setSelectedExpanded(question.id) }} className='cursor-pointer' /></>
                                                                    : <>
                                                                        <div className='flex flex-row justify-between align-center  dark:text-gray-300'>
                                                                            <div className='w-4/5 flex flex-row'>
                                                                                <input type="checkbox" name="" id="" className='mr-3 form-checkbox h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
                                                                                    checked={checkboxAllQuestions.some(q => q.id === question.id)}
                                                                                    onChange={() => handleCheckboxChangeForAll(question)}
                                                                                />
                                                                                <small className=' w-4/5'>{parse(question.text)}</small>
                                                                            </div>
                                                                            <PencilSquareIcon className='cursor-pointer' width={20} height={20} onClick={() => {
                                                                                expandEditablePanel(question.id);
                                                                            }} />

                                                                        </div >
                                                                    </>
                                                            }
                                                            <div className='flex flex- text-left mt-2 gap-3'>
                                                                <div className='border-gray-400   p-2 flex rounded-lg	 items-center space-x-3 mx-2 gap-2 border text-xs font-medium leading-7 text-gray-900 sm:text-sm sm:tracking-tight dark:text-gray-400 text-left'>
                                                                    <QuestionMarkCircleIcon color='blue' height={20} className='space-x-2' />
                                                                    {question.type.toUpperCase()}</div>
                                                                <div className='border-gray-400   p-2 flex rounded-lg	 items-center space-x-3 mx-2 gap-2 border text-xs font-medium leading-7 text-gray-900 sm:text-sm sm:tracking-tight dark:text-gray-400 text-left'>
                                                                    <ClockIcon color='blue' height={20} className='space-x-2' />
                                                                    {question.time}</div>
                                                                <div className='border-gray-400   p-2 flex rounded-lg	 items-center space-x-3 mx-2 gap-2 border text-xs font-medium leading-7 text-gray-900 sm:text-sm sm:tracking-tight dark:text-gray-400 text-left'>
                                                                    <TagIcon color='blue' height={20} className='space-x-2' />
                                                                    {question.tags}</div>
                                                                <div>
                                                                    <div className='border-gray-400   p-2 flex rounded-lg	 items-center space-x-3 mx-2 gap-2 border text-xs font-medium leading-7 text-gray-900 sm:text-sm sm:tracking-tight dark:text-gray-400 text-left'>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="#FFD700" strokeWidth="2" >
                                                                            <circle cx="12" cy="12" r="10" />
                                                                            <circle cx="12" cy="12" r="6" />
                                                                            <circle cx="12" cy="12" r="2" />
                                                                            <line x1="8" y1="12" x2="14" y2="12" />
                                                                        </svg>
                                                                        {question.accuracy !== null && question.accuracy !== undefined && (question.type !== "video" && question.type !== "audio" && question.type !== "text") ? (
                                                                        <> {question.accuracy.toFixed(2)} ﹪ </>
                                                                    ) : "Not Applicable"}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div >
                                                )}
                                            </Draggable >
                                        ))}
                                        {isLoading && <Loading />}
                                        {provided.placeholder}
                                    </div >
                                )}
                            </Droppable >
                        </div >
                    </div >

                </DragDropContext >


            </div >}
            {
                isModalOpen && (
                    <AddQuestionModal
                        title="Are you Sure?"
                        content="Are you sure you want to delete this Question?"
                        onAdd={confirmAdd}
                        onCancel={cancelAdd}
                        isModalClosed={isModalClosed}
                        size="large"
                        addedQuestions={addedQuestions.length > 0 ? addedQuestions : checkboxAllQuestions}
                        removedQuestions={removedQuestions.length > 0 ? removedQuestions : checkboxSelectedQuestions}
                    />
                )
            }

            <ConfirmModal
                open={isConfirmModalOpen}
                setOpen={setIsConfirmModalOpen}
                onConfirm={confirmModal}
                type={modalInfo.type}
                title={modalInfo.title}
                description={modalInfo.description}
            />
            <Notification
                show={assessmentNotification}
                setShow={setAssessmentNotification}
                title="Success"
                description="Questions Updated Successfully"
                type="success"
            />


        </div >
    );
}

export default Kanban;
