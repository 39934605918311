import {
  ArrowDownIcon,
  ArrowUpIcon,
  EllipsisVerticalIcon,
  InformationCircleIcon,
} from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";
import { QuarantineCompany, QuarantineUniversity } from "../../../Models";
import { sort } from "../Quarantine";
import AbbreviationModal from "../SubComponents/AbbreviationModal";
import Notification from "../../Shared/Notification";
import {
  confirmQuarantineCompany,
  confirmQuarantineUniversity,
  deleteQuarantineCompany,
  deleteQuarantineUniversity,
  fetchCompanyLocations,
  fetchConfirmedUniversities,
  Filters,
} from "../../../WebCalls";
import ReactTooltip from "../../Shared/ReactToolTip";
import ConfirmModal from "../../Shared/ConfirmModal";
import RejectModal from "../SubComponents/RejectModal";
import { quartersToMonths } from "date-fns";

interface HeaderProps {
  quarantineData?: (QuarantineCompany | QuarantineUniversity)[];
  sortOption: sort | undefined;
  setSortOption: React.Dispatch<React.SetStateAction<sort | undefined>>;
  selectedRows: QuarantineUniversity[];
  setSelectedRows: React.Dispatch<React.SetStateAction<QuarantineUniversity[]>>;
  token: string;
  selected: string;
  setQuarantineData: React.Dispatch<
    React.SetStateAction<QuarantineUniversity[] | QuarantineCompany[]>
  >;
  fetchQuarantineData: (
    sortType?: string,
    sortDesc?: boolean,
    Filters?: Filters[]
  ) => Promise<void>;
  toggleRow: (data: QuarantineUniversity) => void;
  selectedCompanyLocations: string[];
  setSelectedCompanyLocations: React.Dispatch<React.SetStateAction<string[]>>;
  setPage: React.Dispatch<React.SetStateAction<number>>
}

const tabs = ["Companies", "Universities"];

function TableHeader({
  quarantineData,
  sortOption,
  setSortOption,
  selectedRows,
  setSelectedRows,
  token,
  selected,
  setQuarantineData,
  fetchQuarantineData,
  toggleRow,
  selectedCompanyLocations,
  setSelectedCompanyLocations,
  setPage
}: HeaderProps) {
  const [headCheckboxState, setHeadCheckboxState] = useState<boolean | "minus">(
    false
  );
  const [showConfirmBox, setShowConfirmBox] = useState<boolean>(false);
  const [showAbbBox, setShowAbbBox] = useState<boolean>(false);
  const [openConfirmationBox, setOpenConfirmationBox] =
    useState<boolean>(false);
  const [OpenCityConfirmBox, setOpenCityConfirmBox] = useState<boolean>(false);
  const [showAbbNotification, setShowAbbNotification] =
    useState<boolean>(false);
  const [confirmNotification, setConfirmNotification] =
    useState<boolean>(false);
  const [errorNotification, setErrorNotification] = useState<boolean>(false);
  const [rejectNotification, setRejectNotification] = useState<boolean>(false);
  const [showRejectModal, setShowRejectModal] = useState<boolean>(false);
  const [confirmedData, setConfirmedData] = useState<
    QuarantineUniversity[] | QuarantineCompany[]
  >();

  useEffect(() => {
    if (quarantineData && selectedRows.length === quarantineData.length) {
      setHeadCheckboxState(true);
    } else if (selectedRows.length > 0) {
      setHeadCheckboxState("minus");
    } else {
      setHeadCheckboxState(false);
    }
  }, [selectedRows, quarantineData]);

  useEffect(() =>{
    fetchConfirmedData();
  },[selected])

  const initialSortOptions: sort[] = [
    { name: "Name", dsc: true },
    { name: "State", dsc: true },
    { name: "City", dsc: true },
  ];

  const fetchConfirmedData = async (name?: string) => {
    if (selected === tabs[0]) {
      try {
        const comp = await fetchCompanyLocations(name);
        if (comp) {
          setConfirmedData(comp);
        }
      } catch (err) {
        console.log(err);
      }
    } else if (selected === tabs[1]) {
      try {
        const uni = await fetchConfirmedUniversities(name, 1, 500, "");
        if (uni) {
          setConfirmedData(uni);
        } else console.error("Token is undefined or empty.");
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleTableSort = async (sortType: string) => {
    if (!quarantineData) {
      return;
    }

    if (sortOption && sortOption.name === sortType) {
      const newSortOption = { name: sortOption.name, dsc: !sortOption.dsc };
      setSortOption(newSortOption);
    } else {
      const newSortOption = initialSortOptions.find((s) => s.name === sortType);
      setSortOption(newSortOption);
    }
  };

  const toggleAllRows = () => {
    if (headCheckboxState === true || headCheckboxState === "minus") {
      setSelectedRows([]);
    } else {
      setSelectedRows(quarantineData!.map((u) => u));
    }
  };

  const deleteInstitute = async (quarantineId: string, confirmedId: string) => {
    try {
      if (selected === tabs[1]) {
        const message = await deleteQuarantineUniversity(
          token,
          quarantineId,
          confirmedId
        );
        if (message) setRejectNotification(true);
      } else {
        const message = await deleteQuarantineCompany(
          token,
          quarantineId,
          confirmedId
        );
        if (message) setRejectNotification(true);
      }
      setPage(1);
      setQuarantineData([]);
      await fetchQuarantineData();
    } catch (error) {
      console.error("Failed to delete university:", error);
    }
    setShowRejectModal(false);
    
  };
  
  // console.log(quarantineData)
  const handleConfirm = async (newId: string, abbreviation?: string) => {
    const selectedInstitute = selectedRows.find((institute: any) =>
      selected == tabs[0]
        ? institute.companyLocations!.find(
            (location: any) => location.id === newId
          )
        : institute.cities!.find((location: any) => location.id === newId)
    );
    if (!selectedInstitute) {
      return;
    }
    const Data = {
      Id: newId,
      IsConfirmed: true,
      IsRejected: false,
      Abbreviation: abbreviation,
    };
    try {
      if (selected === tabs[1]) {
        const message = await confirmQuarantineUniversity(token, newId, Data);
      } else {
        const message = await confirmQuarantineCompany(token, newId, Data);
      }
      setQuarantineData([]);
      await fetchQuarantineData();
      await fetchConfirmedData();
    } catch (error) {
      console.error(`Failed to update ${selected}:, ${error}`);
    }
  };

  // this one is for companies only
  const handleMultiConfirm = async () => {
    if (selectedRows.length === 0) {
      setShowConfirmBox(false);
      return;
    }
    if (selected === tabs[0]) {
      selectedRows.forEach((obj: any) => {
        obj.companyLocations?.forEach((cl: any) => {
          if (selectedCompanyLocations.includes(cl.id) === false) {
            handleConfirm(cl.id);
          }
        });
      });
      setConfirmNotification(true);
      setOpenConfirmationBox(false);
    }
  };

  // this one is for universities only
  const handleAbbrevationBox = async (updatedRows: any[]) => {
    for (const obj of updatedRows) {
      if (!obj.abbreviation || obj.abbreviation === "") {
        setShowAbbBox(false);
        setShowAbbNotification(true);
        return;
      }
    }
    for (const obj of updatedRows) {
      if (obj.abbreviation) handleConfirm(obj.id, obj.abbreviation);
    }
    setPage(1);
    setSelectedRows([]);
    setConfirmNotification(true);
    setShowConfirmBox(false);
    setShowAbbBox(false);
  };

  return (
    <>
      <AbbreviationModal
        type="info"
        open={showAbbBox}
        setOpen={setShowAbbBox}
        onConfirm={handleAbbrevationBox}
        title="Confirm Universities"
        description="Set Abbreviations for Universities and Confirm."
        selectedRows={selectedRows}
        selectedOptions={selectedCompanyLocations}
      />

      <thead className="text-xs text-gray-800 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400 ">
        <tr>
          <th scope="col" className="w-[3rem]">
            <div className="py-2 px-5 flex">
              <input
                type="checkbox"
                className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 dark:border-gray-100"
                checked={headCheckboxState === true}
                ref={(input) => {
                  if (input)
                    input.indeterminate = headCheckboxState === "minus";
                }}
                onChange={toggleAllRows}
              />
              <EllipsisVerticalIcon
                className="w-4 ml-2 cursor-pointer"
                onClick={() => setShowConfirmBox((prev) => !prev)}
              />
              {selectedRows.length > 0 && (
                <div className="flex pt-1">
                  <button
                    onClick={() => {
                      if (selected == tabs[0]) setOpenConfirmationBox(true);
                      else setShowAbbBox(true);
                    }}
                    className="absolute top-[0.2rem] left-[4rem] py-2 px-3 rounded-md text-white bg-entntblue"
                  >
                    Confirm{" "}
                    {selected === "Companies" ? "Company" : "University"} in
                    City
                  </button>
                  <button
                    onClick={() => setShowRejectModal(true)}
                    className="absolute top-[0.2rem] left-[15rem] py-2 px-3 rounded-md text-white bg-entntblue"
                  >
                    Reject and Replace
                  </button>
                </div>
              )}
            </div>
          </th>
          <th className="flex items-center py-2 px-3">
            URL
            <InformationCircleIcon
              data-tooltip-id="URL"
              className="w-6 h-6 ml-2"
            />
          </th>
          <th className="py-2 px-3">Count</th>
          <th className="py-2 px-3">
            <div className="flex items-center">
              City
              <InformationCircleIcon
                data-tooltip-id="city"
                className="w-6 h-6 ml-2"
              />
            </div>
          </th>
          <th className="py-2 px-3">
            <div className="flex items-center">
              Name
              {sortOption?.name === "Name" && sortOption.dsc ? (
                <ArrowDownIcon
                  className="w-4 cursor-pointer ml-1"
                  onClick={() => handleTableSort("Name")}
                />
              ) : (
                <ArrowUpIcon
                  className="w-4 cursor-pointer ml-1"
                  onClick={() => handleTableSort("Name")}
                />
              )}
            </div>
          </th>
        </tr>
      </thead>

      <ConfirmModal
        type="info"
        open={openConfirmationBox}
        setOpen={setOpenConfirmationBox}
        title="Confirm Companies"
        description={`Confirm Selected ${selected} in chosen Cities?`}
        onConfirm={handleMultiConfirm}
      />

      <RejectModal
        type="danger"
        open={showRejectModal}
        setOpen={setShowRejectModal}
        deleteInstitute={deleteInstitute}
        title={`Reject ${selected === "Companies" ? "Company" : "University"}`}
        description={`Choose a ${
          selected === "Companies" ? "Company" : "University"
        }`}
        dropDownPlaceHolder={
          selected === "Companies" ? "Companies" : "Universities"
        }
        dropDownValues={confirmedData ? confirmedData : []}
        hideOthers
        selected={selected}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        nonSelectedOptions={selectedCompanyLocations}
        fetchConfirmedData={fetchConfirmedData}
      />

      <Notification
        title="Do not Multi-Select"
        description={`Please Select a Single Entry.`}
        type="error"
        show={errorNotification}
        setShow={setErrorNotification}
      />
      <Notification
        title="Rejection Successful"
        description={`${
          selected === "Companies" ? "Company" : "University"
        } Rejected Successfully.`}
        type="success"
        show={rejectNotification}
        setShow={setRejectNotification}
      />
      <Notification
        title="Set Abbreviation"
        description={`Please Select an Abbreviation for all Universities`}
        type="error"
        show={showAbbNotification}
        setShow={setShowAbbNotification}
      />
      <Notification
        title="Confirmation Successful"
        description={`${selected} Confirmed Successfully.`}
        type="success"
        show={confirmNotification}
        setShow={setConfirmNotification}
      />
      <ReactTooltip
        key="city"
        id="city"
        content={`The number indicates how many times the city appeared across all Quarantine ${selected == tabs[0] ? "Companies" : "Universities"}.`}
      />
      <ReactTooltip
        key="URL"
        id="URL"
        content="URLs displayed in red indicate that a confirmed company with the same URL already exists in DB."
      />
    </>
  );
}

export default TableHeader;
